import { ManageExtrasIngredient, MenuOption } from "@/app/_types/menuDetails";

import { useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { OfferMenuIngredientProvider } from "../../../context";
import toast from "react-hot-toast";
import {
  AddDealMenuIngredientHandler,
  RemoveDealMenuIngredientsHandler,
} from "../../../offerOptionChangeUtil";
import { MenuOfferForm } from "@/app/_types/menuOffers.interface";
import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";

interface MenuOfferEditOptionItemProps {
  item: MenuOption;
  isExtraOption: boolean;
  optionGroups: OptionGroup[];
}

function MenuOfferEditOptionItem({
  item,
  isExtraOption = false,
  optionGroups,
}: MenuOfferEditOptionItemProps) {
  const { setValue, getValues, control } = useFormContext<MenuOfferForm>();
  const fieldArray = useContext(OfferMenuIngredientProvider);

  const selectedOfferIndex =
    useWatch({
      control,
      name: `config.selectedOfferIndex`,
    }) || 0;

  const watchIsSplit = useWatch({
    control,
    name: `menuOffer.${selectedOfferIndex}.config.isSplit`,
  });

  const AddIngredient = () => {
    if (!fieldArray) return toast.error("Cannot get Options");

    const finalData: ManageExtrasIngredient = {
      id: item?.id,
      name: item?.name,
      isSingle: item?.isSingle,
      optionGroupId: item?.optionGroupId,
      menuSizes: item?.menuSizes?.map((el) => ({
        id: el?.sizeId,
        sizeId: el?.sizeId,
        extraPrice: el?.extraPrice,
      })),
    };

    AddDealMenuIngredientHandler(
      fieldArray,
      getValues,
      setValue,
      finalData,
      optionGroups
    );
  };
  if (!fieldArray) return toast.error("Cannot get Options");

  return (
    <div className="flex justify-between px-4 py-2">
      <p
        className={
          item?.qty === 0
            ? "line-through text-red-400"
            : isExtraOption
            ? "no-underline text-purple-500"
            : "no-underline"
        }
      >
        {item?.name}
        {item?.total && item?.total > 0 ? (
          <span className="text-sm">
            {" "}
            + ${watchIsSplit ? item?.total / 2 : item?.total}
          </span>
        ) : null}
      </p>
      <div className="flex items-center gap-2">
        {item?.qty > 0 && (
          <>
            <button
              type="button"
              onClick={() =>
                RemoveDealMenuIngredientsHandler(
                  fieldArray,
                  getValues,
                  setValue,
                  item
                )
              }
            >
              <img
                src="/images/icons/minus.svg"
                alt="minus"
                width="30"
                height="30"
                className="bg-slate-200 p-2 rounded-full"
              />
            </button>
            <p>{item?.qty}</p>
          </>
        )}
        <button type="button" onClick={AddIngredient}>
          <img
            src="/images/icons/add.svg"
            alt="add"
            width="30"
            height="30"
            className="bg-gray-300 p-2 rounded-md"
          />
        </button>
      </div>
    </div>
  );
}

export default MenuOfferEditOptionItem;
