import { MenuDealForm } from "@/app/_types/menuDeals.interface";

import { ChangeEvent, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const MenuDealQty = () => {
  const { control, setValue } = useFormContext<MenuDealForm>();
  const [localQty, setLocalQty] = useState<number | string>(1);

  const Qty = useWatch({
    control,
    name: "qty",
  });

  useEffect(() => {
    // Sync local state with form value whenever it changes
    setLocalQty(Qty);
  }, [Qty]);

  const AddQty = () => {
    setValue("qty", (Qty || 0) + 1);
  };

  const RemoveQty = () => {
    if (Qty > 1) setValue("qty", Qty - 1);
  };

  const handleQtyChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    // Allow temporarily empty input for better UX
    if (inputValue === "") {
      setLocalQty("");
      return;
    }

    const newQty = parseInt(inputValue, 10);
    if (!isNaN(newQty) && newQty >= 1 && newQty <= 1000) {
      setLocalQty(newQty);
      setValue("qty", newQty);
    }
  };

  const handleBlur = () => {
    // Reset to 1 if input is left empty on blur
    if (localQty === "") {
      setLocalQty(1);
      setValue("qty", 1);
    }
  };

  return (
    <div className="flex gap-3 items-center justify-center">
      <button type="button" onClick={RemoveQty} disabled={Qty === 1}>
        <img
          src="/images/icons/minus.svg"
          alt="minus"
          width="35"
          height="35"
          className="bg-slate-200 p-2 rounded-full"
        />
      </button>

      <input
        type="number"
        value={localQty}
        onChange={handleQtyChange}
        onBlur={handleBlur} // Reset to 1 if left empty
        min={1}
        className="w-10 h-10 text-center border border-gray-300 rounded-md 
                   focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]"
      />

      <button type="button" disabled={Qty >= 1000} onClick={AddQty}>
        <img
          src="/images/icons/add.svg"
          alt="add"
          width="35"
          height="35"
          className="bg-gray-300 p-2 rounded-md"
        />
      </button>
    </div>
  );
};

export default MenuDealQty;
