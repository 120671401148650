import {
  AddMenuItemsForm,
  IndividualOrder,
} from "@/app/_types/menuDetails";
import { useFormContext, useWatch } from "react-hook-form";
import AddMenuHalfAndHalfItem from "./AddMenuHalfAndHalfItem";
import { SizeGroup } from "@/app/_types/extraMenuPriceInfo";

function AddMenuHalfAndHalf({ sizeGroup }: { sizeGroup?: SizeGroup }) {
  const { control } = useFormContext<AddMenuItemsForm>();
  const watchData: IndividualOrder[] = useWatch({
    control,
    name: "data",
  });

  return (
    <div className="bg-gray-200 px-4 pt-2 pb-4">
      <h2 className="text-lg font-extrabold mb-2">Choose Your Half and Half</h2>
      <div className="flex flex-col gap-2">
        {watchData?.map((data, index) => (
          <AddMenuHalfAndHalfItem
            key={index}
            data={data}
            index={index}
            sizeGroup={sizeGroup}
          />
        ))}
      </div>
    </div>
  );
}

export default AddMenuHalfAndHalf;
