import {
  AddMenuItemsForm,
  IndividualOrder,
  MenuDetailsEntity,
} from "@/app/_types/menuDetails";
import { useContext, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormMenuDataProvider } from "../MenuDetailsForm";
import toast from "react-hot-toast";
import SelectMenuModal from "@/app/[location]/components/SelectMenuModal/SelectMenuModal";
import { MenuItems } from "@/app/_types/menuWithCategories";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { getSizeList } from "../../utils/features";
import { SizeGroup } from "@/app/_types/extraMenuPriceInfo";
import { useParams } from "react-router-dom";
import {
  getIndividualMenuComboPrice,
  ManageComboPayInitial,
} from "../../utils/sizeChangeUtils";

function AddMenuHalfAndHalfButton({ sizeGroup }: { sizeGroup?: SizeGroup }) {
  const { control, setValue, getValues } = useFormContext<AddMenuItemsForm>();
  const [openModal, setOpenModal] = useState(false);
  const { location } = useParams<{ location: string }>();

  const DataField = useContext(FormMenuDataProvider);

  const watchConfig = useWatch({
    control,
    name: "config",
  });

  const watchData: IndividualOrder[] = useWatch({
    control,
    name: "data",
  });

  const watchSizeGroupId = useWatch({
    control,
    name: "sizeGroupId",
  });

  const watchSize = useWatch({
    control,
    name: "size",
  });

  const watchCategoryId = useWatch({
    control,
    name: "categoryId",
  });

  const periodId = useWatch({
    control,
    name: `periodId`,
  });

  const isDataValid = watchData?.map((data) => data?.id).includes(null);

  // const ChangeHalfAndHalf = () => {
  //   if (watchData?.[watchConfig?.selectedIndex as number]?.combo) {
  //     const isWatchDataCombo = watchData?.[
  //       watchConfig?.selectedIndex || 0
  //     ].combo?.reduce((acc, current) => {
  //       const comboItems = current?.options
  //         .map((el) => el?.qty > 0)
  //         .includes(true);
  //       return comboItems;
  //     }, false);

  //     if (!isWatchDataCombo) {
  //       toast.error("Please select at least one item from the required combo");
  //       return;
  //     }
  //   }
  //   setOpenModal(true);

  //   // setValue(`config.isSplit`, true);
  //   // DataField?.append({
  //   //   id: null,
  //   //   name: "",
  //   //   note: "",
  //   //   basePrice: 0,
  //   //   menuSizeId: null,
  //   //   menuSizeList: [],
  //   //   imageUrl: "",
  //   //   isComplete: false,
  //   //   position: "RIGHT",
  //   //   ingredientPrice: 0,
  //   //   comboPrice: 0,
  //   //   combo: [],
  //   //   ingredients: [],
  //   // });
  // };

  const ChangeHalfAndHalf = () => {
    const isValid = () => {
      const isTrueList: boolean[] = [];
      watchData?.forEach((item) => {
        const comboList = item?.optionGroups;
        comboList?.forEach((combos) => {
          const isMandatory = combos?.isMandatory;
          if (isMandatory) {
            const isComboValid = combos?.options?.some(
              (option) => option?.qty > 0
            );

            if (isComboValid) {
              isTrueList.push(true);
            } else {
              isTrueList.push(false);
            }
          } else {
            isTrueList.push(true);
          }
        });
      });
      const checkIsTrueList = isTrueList.every((item) => item === true);
      return checkIsTrueList;
    };
    const isComboValid = isValid();

    if (!isComboValid) {
      toast.error("Please select at least one item from the required combo");
      return;
    }

    setOpenModal(true);
  };

  const ChangeItemsHandler = () => {
    if (isDataValid) {
      DataField?.remove(1);
      setValue("config.isSplit", false);
      setValue("config.isRemove", false);
      setValue("config.selectedIndex", 0);
      return;
    }

    if (watchConfig?.isRemove) {
      return setValue("config.isRemove", false);
    }
    setValue("config.isRemove", true);
  };

  const getSelectedMenu = async (menu: MenuItems) => {
    if (!location) {
      toast.error("Sorry, Store Information Not Found. Please Try Again");
      return;
    }

    // const storeDetails = await getCurrentStoreDetails(
    //   params?.location as string
    // );
    // if (!storeDetails)
    //   return toast.error(
    //     "Sorry, Store Information Not Found. Please Try Again"
    //   );
    const menuDetails: MenuDetailsEntity = await fetchRequest(
      serverPath.getMenuDetails(location, menu?.id, periodId),
      "GET",
      undefined,
      { cache: "no-store" }
    );

    const comboList = ManageComboPayInitial(
      menuDetails?.menuOptionGroups || [],
      menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
    );

    const comboPrices = getIndividualMenuComboPrice(comboList);
    if (menu) {
      const getSize = getValues("size");
      const findMenuSizeId = menuDetails?.menuSizes?.find(
        (el) => el?.sizeId === getSize
      )?.id;

      DataField?.append({
        id: menuDetails?.menu?.id,
        name: menuDetails?.menu?.name,
        basePrice: menuDetails?.menu?.basePrice,
        menuSizeId: findMenuSizeId || null,
        menuSizeList: menuDetails?.menuSizes,
        note: "",
        // combo: comboList,
        optionGroups: comboList,
        imageUrl: menuDetails?.menu?.imageUrl || "",
        position: "RIGHT",
        ingredientPrice: 0,
        comboPrice: comboPrices,
        ingredients: menuDetails?.menuOptions,
      });
    }
    const newName = `1/2 (${getValues(`name`)},${menuDetails?.menu?.name})`;
    setValue("name", newName);

    const getData = getValues("data");

    const getList = getSizeList(getData, sizeGroup);
    if (getList) {
      setValue(`sizeList`, getList);
      const getSize = getValues("size");
      const currentMenuSize = getList?.find((el) => el?.sizeId === getSize);
      if (currentMenuSize) {
        setValue("basePrice", currentMenuSize?.basePrice || 10);
        setValue("sizePrice", currentMenuSize?.additionalPrice);
      }
    }
    setValue(`config.isSplit`, true);
    setValue(`config.selectedIndex`, 1);
    setValue(`config.canAddToCart`, false);
  };

  return (
    <>
      <div className="flex items-center justify-end">
        {watchConfig?.isSplit ? (
          <button
            className="p-1  text-red-500 rounded-md flex ml-auto mr-4 underline"
            onClick={ChangeItemsHandler}
          >
            {watchConfig?.isRemove || isDataValid ? "Go Back" : "Remove Items"}
          </button>
        ) : (
          <button
            className="p-2 bg-slate-500 text-white rounded-md flex ml-auto mr-4"
            onClick={ChangeHalfAndHalf}
          >
            Half / Half
          </button>
        )}
      </div>
      {openModal && watchSize ? (
        <SelectMenuModal
          open={openModal}
          setOpen={setOpenModal}
          sizeGroupId={watchSizeGroupId}
          categoryId={watchCategoryId}
          getSelectedMenu={getSelectedMenu}
          sizeId={watchSize}
        />
      ) : null}
    </>
  );
}

export default AddMenuHalfAndHalfButton;
