import {
  changeActiveCategory,
  changeSubActiveCategory,
} from "@/app/_redux/slice/UISlice/UISlice";
import { useAppDispatch } from "@/app/_redux/store";

import { useInView } from "react-intersection-observer";

type MenuSubListClient = {
  children: React.ReactNode;
  subcategories: string;
  category: string;
};

function MenuSubListClient({
  children,
  subcategories,
  category,
}: MenuSubListClient) {
  const dispatch = useAppDispatch();
  const { ref } = useInView({
    threshold: 0.4,
    rootMargin: "-50px 0px 0px 0px",
    onChange: (view) => {
      if (!view) return;

      dispatch(changeActiveCategory(category));
      dispatch(changeSubActiveCategory(subcategories));
      // dispatch(changeActiveCategory(category));
      // dispatch(changeSubActiveCategory(subcategories));
    },
  });

  return (
    // <div ref={ref} className="even:bg-gray-100">
    <div ref={ref}>
      <div>{children}</div>
    </div>
  );
}

export default MenuSubListClient;
