import axios, { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";
import { serverPath } from "./path";

const instance = axios.create({
  baseURL: "/",
});

export interface IgetNewAccessTokenWithRefreshToken {
  refreshToken: string;
  acessToken: string;
  loginType: string;
}

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export function checkLocalStorage() {
  const getToken = localStorage.getItem("session");

  let session = null;
  if (getToken && getToken !== undefined) {
    try {
      session = JSON.parse(getToken);
    } catch (error) {
      console.error("Failed to parse session storage:", error);
      session = null; // Set session to null if parsing fails
    }
  } else {
    session = null; // If storage is null, explicitly set session to null
  }
  if (!session) return "";

  const localStorageToken = session?.acessToken;
  return `Bearer ${localStorageToken}`;
}

const isTokenExpired = (token: string) => {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return (decodedToken?.exp || 0) < currentTime;
};

// interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
//   allowAuth: boolean; // Custom property to skip or allow Authorization
// }

// type CustomAxiosRequestConfig = InternalAxiosRequestConfig & {
//   allowAuth: boolean;
// };

instance.interceptors.request.use(
  (config) => {
    const urlPath = config?.url?.split("/");

    console.log("headers config => ", config, config?.url, urlPath);
    if (urlPath?.includes("public") || urlPath?.includes("refreshtoken"))
      return config;

    const token = checkLocalStorage();
    config.headers.Authorization = token;
    return config;
  },
  (error: AxiosError) => {
    console.error("Request Error:", error.message);
    localStorage.clear();
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const storages = localStorage.getItem("session");
    const parseStorages = storages ? JSON.parse(storages) : null;
    const refreshToken = parseStorages?.refreshToken;
    const accessToken = parseStorages?.acessToken;

    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest &&
      !originalRequest._retry
    ) {
      if (!accessToken || !refreshToken) {
        localStorage.clear();
        return Promise.reject(error);
      }

      if (isRefreshing) {
        // Add the request to the queue
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      if (isTokenExpired(refreshToken)) {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(error);
      }

      try {
        const finalData: IgetNewAccessTokenWithRefreshToken = {
          refreshToken: refreshToken,
          acessToken: accessToken,
          loginType: "WEB",
        };
        console.log("401 error inside refresh token => ", finalData);

        const newURL = serverPath?.resetRefreshToken;

        const options = {
          url: newURL,
          method: "POST",
          data: finalData,
        };

        const response = await instance(options);
        console.log("401 error response => ", response);
        if (response?.status === 200) {
          const data = await response?.data;

          localStorage.setItem("session", JSON.stringify(data));

          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${data.acessToken}`;
          processQueue(null, data.acessToken);

          return instance(originalRequest);
        } else {
          localStorage.clear();
          window.location.reload();
          return Promise.reject(error);
        }
      } catch (err) {
        // @ts-ignore
        processQueue(err, null);
        localStorage.clear();
        window.location.reload();
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    } else {
      return Promise.resolve(error);
    }
  }
);

export default instance;
