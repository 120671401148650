import AddToCartDealList from "@/app/[location]/components/AddToCart/AddToCartDealList";
import AddToCartMenuList from "@/app/[location]/components/AddToCart/AddToCartMenuList";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { CheckoutPageDetails } from "@/app/_redux/slice/UISlice/UISlice.d";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import {
  ConfirmOrderForm,
  EventDetils,
  // SurchargeDetails,
  // VoucherConf,
  VoucherEntity,
} from "@/app/_types/checkout.interface";
import { CustomerInfo } from "@/app/_types/customer.interface";
import {
  useGetAuthTokenWithBearer,
  useGetRefreshToken,
} from "@/app/hooks/useGetAuthToken";
import { fixedDecimal } from "@/app/utils/DecimalAndNumbers";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CheckoutOrderSummary({ customer }: { customer?: CustomerInfo }) {
  const [actualTotalPromosPrice, setActualTotalPromosPrice] =
    useState<number>(0);
  const [actualTotalMenuPrice, setActualTotalMenuPrice] = useState<number>(0);

  const [totalMenuPrice, setTotalMenuPrice] = useState<number>(0);

  const [totalPromosPrice, setTotalPromosPrice] = useState<number>(0);

  const { OrderList, OrderLocation, dealList, schedule } = useSelector(order);
  const { storeDT } = useSelector(selectUI);
  // const {} = useSelector(order);
  // const [voucherConfDetails, setVoucherConfDetails] =
  //   useState<VoucherConf | null>(null);

  const [pointsLoading, setPointsLoading] = useState<boolean>(false);

  const accessToken = useGetAuthTokenWithBearer();
  const refreshToken = useGetRefreshToken();
  const [redeemCode, setRedeemCode] = useState<string>("");
  const { setValue, getValues, control, register } =
    useFormContext<ConfirmOrderForm>();

  const addressType = useWatch({
    control,
    name: "addressType",
  });

  const watchCustomerAddress = useWatch({
    control,
    name: `customer.address`,
  });

  const storeLocationId = useWatch({
    control,
    name: "storeLocationId",
  });

  const orderType = useWatch({
    control,
    name: "orderType",
  });

  const paymentGatewayType = useWatch({
    control,
    name: "paymentGatewayType",
  });

  const selectedDate = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const body = {
    storeId: storeLocationId,
    // storeDt: storeDT,
    // tradingPeriodId: OrderList[0]?.periodId || dealList[0]?.periodId
  };

  const client = useQueryClient();
  const CheckoutPageDetails = client.getQueryData<CheckoutPageDetails>([
    "getCheckoutPageDetails",
    body,
  ]);

  const [voucherDetails, setVoucherDetails] = useState<VoucherEntity[]>([]);
  const [deliveryPrice, setDeliveryPriceDetails] = useState(0);

  const [selectedVoucher, setSelectedVoucher] =
    useState<VoucherEntity | null>();
  const [selectedPublicVoucher, setSelectedPublicVoucher] = useState<
    VoucherEntity[] | []
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const storages = localStorage?.getItem("session");

  const auth = storages ? JSON.parse(storages) : "";

  const storeId = OrderLocation?.id || "";

  const [actualDiscount, setActualDiscount] = useState<number>(0);

  const router = useNavigate();

  useEffect(() => {
    async function getDeliveryDetails() {
      if (!accessToken || !storeLocationId) return;
      if (watchCustomerAddress.find((el) => el?.address?.streetNo)) {
        setIsLoading(true);
        const DeliveryDetails = await fetchRequest(
          serverPath?.deliveryPrice(addressType, storeLocationId),
          "GET",
          undefined,
          {
            Authorization: accessToken,
          },
          "data"
        );
        if (typeof DeliveryDetails === "number") {
          setDeliveryPriceDetails(DeliveryDetails);
          setValue("deliveryOrderDistance", true);
        } else if (DeliveryDetails?.message) {
          setDeliveryPriceDetails(0);
          setValue("deliveryOrderDistance", false);
          // toast.error(DeliveryDetails)
        } else {
          setDeliveryPriceDetails(0);
          setValue("deliveryOrderDistance", false);
        }
        setIsLoading(false);
      }
    }
    if (orderType === "DELIVERY") {
      getDeliveryDetails();
    } else {
      setValue("deliveryOrderDistance", true);
    }
  }, [
    accessToken,
    addressType,
    orderType,
    refreshToken,
    setValue,
    storeLocationId,
    watchCustomerAddress,
  ]);

  const ApplyVoucherHandler = async (searchTxt: string) => {
    if (searchTxt.length < 1) return toast.error("Input Voucher Code");
    setIsLoading(true);
    if (accessToken) {
      const data = await fetchRequest(
        serverPath.customerVoucher(storeId, searchTxt),
        "GET",
        undefined,
        {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        "data"
      );
      console.log("Applied Voucher Details", data);
      if (data?.message?.length > 0) {
        toast.error(data?.response?.data?.message);
        setIsLoading(false);
        return;
      } else if (
        selectedPublicVoucher?.findIndex((el) => el.id === data.id) !== -1
      ) {
        toast.error("Voucher code already applied");
      } else {
        handlePublicVoucher(data);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getOrderDetails() {
      if (!accessToken || !storeId) return;
      setIsLoading(true);
      const VoucherDetails: VoucherEntity[] = await fetchRequest(
        serverPath?.getCustomerVoucher(storeId),
        "GET",
        undefined,
        {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
        "data"
      );

      setVoucherDetails(VoucherDetails);
      setIsLoading(false);
    }
    getOrderDetails();
  }, [storeId, accessToken, refreshToken]);

  const getSubtotal = OrderList?.reduce((acc, current) => {
    const currentPrice = current?.totalPrice || 0;
    const total = currentPrice * current?.qty;
    return acc + total;
  }, 0);

  const deal = dealList?.filter((el) => el.cartType === "Deal");
  const offer = dealList?.filter((el) => el.cartType === "Offer");

  const getDealSubtotal = deal?.reduce((acc, current) => {
    const currentPrice = current?.totalPrice || 0;
    const total = currentPrice * current?.qty;
    return acc + total;
  }, 0);

  const getOfferSubtotal = offer?.reduce((acc, current) => {
    const currentPrice = current?.totalPrice || 0;
    const total = currentPrice * current?.qty;
    return acc + total;
  }, 0);

  const getActualTotalPrice = getSubtotal + getDealSubtotal + getOfferSubtotal;

  useEffect(() => {
    setTotalMenuPrice(getSubtotal);
    setActualTotalMenuPrice(getSubtotal);
    const points = getValues("appliedPoints") ?? 0;
    if (points > 0) {
      PointValueAppliedHandle();
    }
    if (selectedVoucher) {
      handlePrivateVoucher(selectedVoucher);
    }
    if (selectedPublicVoucher.length > 0) {
      setActualDiscount(0);
      // applyPublicVoucher();
      setSelectedPublicVoucher([]);
    }
  }, [getActualTotalPrice]);

  useEffect(() => {
    setActualTotalPromosPrice(getDealSubtotal + getOfferSubtotal);
    const points = getValues("appliedPoints") ?? 0;

    if (points > 0) {
      PointValueAppliedHandle();
    }
    if (selectedVoucher) {
      handlePrivateVoucher(selectedVoucher);
    }
    if (selectedPublicVoucher.length > 0) {
      setActualDiscount(0);
      setSelectedPublicVoucher([]);
      // applyPublicVoucher();
    }
  }, [getActualTotalPrice]);

  const handlePrivateVoucher = (voucher: VoucherEntity) => {
    const voucherConf =
      CheckoutPageDetails?.multipleDiscountConfs?.multipleDiscountConfs?.find(
        (el) => el.type === "PRIVATE_VOUCHER"
      );

      if(voucher?.discountType !== "PERCENT" && getActualTotalPrice <= voucher?.discount){
        toast?.error("Voucher cannot be applied as the total amount would fall below 0.")
        return;
      }

    const forMenus = voucherConf?.isForMenus;
    const forPromos = voucherConf?.isForPromos;

    const totalMenuPrice = getSubtotal;
    const totalPromosPrice = getDealSubtotal + getOfferSubtotal;

    if (!forMenus && !forPromos) return;
    if (forMenus && !forPromos) {
      if (totalMenuPrice < voucher?.minPurchaseAmount) {
        // setDiscontValue(getActualTotalPrice);
        toast?.error(
          `Total Menu Price should be more than ${voucher?.minPurchaseAmount}`
        );
        setSelectedVoucher(null);
        setActualDiscount(0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
        setAvailablePoints(customer?.points ?? 0);
        return;
      }
      if (totalMenuPrice > voucher?.maxPurchaseAmount) {
        // setDiscontValue(getActualTotalPrice);
        toast?.error(
          `Total Menu Price should be less than ${voucher?.maxPurchaseAmount}`
        );
        setSelectedVoucher(null);
        setActualDiscount(0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
        setAvailablePoints(customer?.points ?? 0);
        return;
      } else {
        if (voucher?.discountType === "PERCENT") {
          const discountAmount = (voucher?.discount / 100) * totalMenuPrice;
          setActualDiscount(discountAmount);
          setDiscontValue(totalMenuPrice - discountAmount + totalPromosPrice);
        } else {
          const discountAmount = voucher?.discount;
          if (totalMenuPrice - discountAmount < 0) {
            setTotalMenuPrice(0);
            setDiscontValue(totalPromosPrice);
            setActualDiscount(totalMenuPrice);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: true, usedAmount: totalMenuPrice },
            ]);
          } else {
            setTotalMenuPrice(totalMenuPrice - discountAmount);
            setDiscontValue(totalMenuPrice - discountAmount + totalPromosPrice);
            setActualDiscount(discountAmount);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: false },
            ]);
          }
        }
        setSelectedVoucher({
          ...voucher,
          applyForMenu: true,
          applyForBoth: false,
          applyForPromos: false,
        });
        toast?.success("Voucher Applied");
        setSelectedPublicVoucher([]);
        setAvailablePoints(customer?.points ?? 0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
      }
    }
    if (!forMenus && forPromos) {
      if (totalPromosPrice < voucher?.minPurchaseAmount) {
        // setDiscontValue(getActualTotalPrice);
        toast?.error(
          `Total Promos Price should be more than ${voucher?.minPurchaseAmount}`
        );
        setSelectedVoucher(null);
        setActualDiscount(0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
        setAvailablePoints(customer?.points ?? 0);
        return;
      }
      if (totalPromosPrice > voucher?.maxPurchaseAmount) {
        // setDiscontValue(getActualTotalPrice);
        toast?.error(
          `Total Promos Price should be less than ${voucher?.maxPurchaseAmount}`
        );
        setSelectedVoucher(null);
        setActualDiscount(0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
        setAvailablePoints(customer?.points ?? 0);
        return;
      } else {
        if (voucher?.discountType === "PERCENT") {
          const discountAmount = (voucher?.discount / 100) * totalPromosPrice;
          setActualDiscount(discountAmount);
          setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
        } else {
          const discountAmount = voucher?.discount;
          if (totalPromosPrice - discountAmount < 0) {
            setTotalPromosPrice(0);
            setDiscontValue(totalMenuPrice);
            setActualDiscount(totalPromosPrice);
            setValue("appliedVouchers", [
              {
                ...voucher,
                isPartiallyUsed: true,
                usedAmount: totalPromosPrice,
              },
            ]);
          } else {
            setTotalPromosPrice(totalPromosPrice - discountAmount);
            setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
            setActualDiscount(discountAmount);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: false },
            ]);
          }
        }
        setSelectedVoucher({
          ...voucher,
          applyForMenu: false,
          applyForPromos: true,
          applyForBoth: false,
        });
        toast?.success("Voucher Applied");
        setSelectedPublicVoucher([]);
        setAvailablePoints(customer?.points ?? 0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
      }
    }
    if (forMenus && forPromos) {
      if (totalMenuPrice + totalPromosPrice < voucher?.minPurchaseAmount) {
        // setDiscontValue(getActualTotalPrice);
        toast?.error(
          `Total Price should be more than ${voucher?.minPurchaseAmount}`
        );
        setSelectedVoucher(null);
        setActualDiscount(0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
        setAvailablePoints(customer?.points ?? 0);
        return;
      }
      if (totalMenuPrice + totalPromosPrice > voucher?.maxPurchaseAmount) {
        // setDiscontValue(getActualTotalPrice);
        toast?.error(
          `Total Price should be less than ${voucher?.maxPurchaseAmount}`
        );
        setSelectedVoucher(null);
        setActualDiscount(0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
        setAvailablePoints(customer?.points ?? 0);

        return;
      } else {
        if (voucher?.discountType === "PERCENT") {
          const discountAmount =
            (voucher?.discount / 100) * (totalMenuPrice + totalPromosPrice);
          setActualDiscount(discountAmount);
          setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
        } else {
          const discountAmount = voucher?.discount;
          const total = totalMenuPrice + totalPromosPrice;
          if (total - discountAmount < 0) {
            setTotalMenuPrice(0);
            setTotalPromosPrice(0);
            setDiscontValue(0);
            setActualDiscount(total);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: true, usedAmount: total },
            ]);
          } else {
            setTotalMenuPrice(totalMenuPrice - discountAmount / 2);
            setTotalPromosPrice(totalPromosPrice - discountAmount / 2);
            setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
            setActualDiscount(discountAmount);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: false },
            ]);
          }
        }
        setSelectedVoucher({
          ...voucher,
          applyForBoth: true,
          applyForMenu: false,
          applyForPromos: false,
        });
        setSelectedPublicVoucher([]);
        toast?.success("Voucher Applied");
        setAvailablePoints(customer?.points ?? 0);
        setValue("appliedPoints", 0);
        setValue("discountFromPoints", 0);
      }
    }
  };

  const handlePublicVoucher = (voucher: VoucherEntity) => {
    const totalUseableVouchers =
      CheckoutPageDetails?.multipleDiscountConfs?.maxApplicableOffers ?? 1;

      console.log(voucher)
    if (selectedPublicVoucher.length >= totalUseableVouchers) {
      toast.error(`You can only apply ${totalUseableVouchers} vouchers`);
      return;
    }

    if(voucher?.discountType !== "PERCENT" && getActualTotalPrice <= voucher?.discount){
      toast?.error("Voucher cannot be applied as the total amount would fall below 0.")
      return;
    }
    const voucherConf =
      CheckoutPageDetails?.multipleDiscountConfs?.multipleDiscountConfs?.find(
        (el) => el.type === "PUBLIC_VOUCHER"
      );
    const forMenus = voucherConf?.isForMenus;
    const forPromos = voucherConf?.isForPromos;

    if (!forMenus && !forPromos) {
      toast.error("Voucher not applicable for this store");
      return;
    }

    if (selectedPublicVoucher?.length === 0) {
      if (forMenus && !forPromos) {
        if (actualTotalMenuPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Menu Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (actualTotalMenuPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Menu Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount =
              (voucher?.discount / 100) * actualTotalMenuPrice;
            setTotalMenuPrice(actualTotalMenuPrice - discountAmount);
            setActualDiscount(discountAmount);
            setDiscontValue(
              actualTotalMenuPrice - discountAmount + actualTotalPromosPrice
            );
          } else {
            const discountAmount = voucher?.discount;
            if (actualTotalMenuPrice - discountAmount < 0) {
              setTotalMenuPrice(0);
              setDiscontValue(actualTotalPromosPrice);
              setActualDiscount(actualTotalMenuPrice);
              setValue("appliedVouchers", [
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: actualTotalMenuPrice,
                },
              ]);
            } else {
              setTotalMenuPrice(actualTotalMenuPrice - discountAmount);
              setActualDiscount(discountAmount);
              setDiscontValue(
                actualTotalMenuPrice - discountAmount + actualTotalPromosPrice
              );
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
          setSelectedPublicVoucher([
            ...selectedPublicVoucher,
            {
              ...voucher,
              applyForMenu: true,
              applyForBoth: false,
              applyForPromos: false,
            },
          ]);
          setSelectedVoucher(null);
          toast?.success("Voucher Applied");
          setAvailablePoints(customer?.points ?? 0);
          setValue("appliedPoints", 0);
          setValue("discountFromPoints", 0);
        }
      } else if (!forMenus && forPromos) {
        if (actualTotalPromosPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Promos Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (actualTotalPromosPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Promos Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount =
              (voucher?.discount / 100) * actualTotalPromosPrice;
            setTotalPromosPrice(actualTotalPromosPrice - discountAmount);
            setActualDiscount(discountAmount);
            setDiscontValue(
              actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
            );
          } else {
            const discountAmount = voucher?.discount;
            if (actualTotalPromosPrice - discountAmount < 0) {
              setTotalPromosPrice(0);
              setDiscontValue(actualTotalMenuPrice);
              setActualDiscount(actualTotalPromosPrice);
              setValue("appliedVouchers", [
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: actualTotalPromosPrice,
                },
              ]);
            } else {
              setTotalPromosPrice(actualTotalPromosPrice - discountAmount);
              setDiscontValue(
                actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
              );
              setActualDiscount(discountAmount);
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
          setSelectedPublicVoucher([
            ...selectedPublicVoucher,
            {
              ...voucher,
              applyForMenu: false,
              applyForPromos: true,
              applyForBoth: false,
            },
          ]);
          setSelectedVoucher(null);
          toast?.success("Voucher Applied");
          setAvailablePoints(customer?.points ?? 0);
          setValue("appliedPoints", 0);
          setValue("discountFromPoints", 0);
        }
      } else if (forMenus && forPromos) {
        if (
          actualTotalMenuPrice + actualTotalPromosPrice <
          voucher?.minPurchaseAmount
        ) {
          toast.error(
            `Total Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (
          actualTotalMenuPrice + actualTotalPromosPrice >
          voucher?.maxPurchaseAmount
        ) {
          toast.error(
            `Total Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const menuDiscountAmount =
              (voucher?.discount / 100) * actualTotalMenuPrice;
            const promoDiscountAmount =
              (voucher?.discount / 100) * actualTotalPromosPrice;
            const calculatedMenu = actualTotalMenuPrice - menuDiscountAmount;
            const calculatedPromos =
              actualTotalPromosPrice - promoDiscountAmount;
            setTotalMenuPrice(calculatedMenu);
            setTotalPromosPrice(calculatedPromos);
            const discountAmount = menuDiscountAmount + promoDiscountAmount;
            setDiscontValue(
              actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
            );
            setActualDiscount(discountAmount);
          } else {
            const total = actualTotalMenuPrice + actualTotalPromosPrice;
            const discountAmount = voucher?.discount;
            if (total - discountAmount < 0) {
              setTotalMenuPrice(0);
              setTotalPromosPrice(0);
              setDiscontValue(0);
              setActualDiscount(total);
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: true, usedAmount: total },
              ]);
            } else {
              const calculatedMenu = actualTotalMenuPrice - discountAmount / 2;
              const calculatedPromos =
                actualTotalPromosPrice - discountAmount / 2;
              setTotalMenuPrice(calculatedMenu);
              setTotalPromosPrice(calculatedPromos);
              setDiscontValue(
                actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
              );
              setActualDiscount(discountAmount);
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
          setSelectedPublicVoucher([
            ...selectedPublicVoucher,
            {
              ...voucher,
              applyForBoth: true,
              applyForMenu: false,
              applyForPromos: false,
            },
          ]);
          setSelectedVoucher(null);
          toast?.success("Voucher Applied");
          setAvailablePoints(customer?.points ?? 0);
          setValue("appliedPoints", 0);
          setValue("discountFromPoints", 0);
        }
      }
    } else if (selectedPublicVoucher?.length >= 1) {
      if (forMenus && !forPromos) {
        if (totalMenuPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Menu Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (totalMenuPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Menu Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount = (voucher?.discount / 100) * totalMenuPrice;
            setTotalMenuPrice(totalMenuPrice - discountAmount);
            setDiscontValue(totalMenuPrice - discountAmount + totalPromosPrice);
            setActualDiscount(actualDiscount + discountAmount);
          } else {
            const discountAmount = voucher?.discount;
            if (totalMenuPrice - discountAmount < 0) {
              setTotalMenuPrice(0);
              setDiscontValue(totalPromosPrice);
              setActualDiscount(actualDiscount + totalMenuPrice);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: totalMenuPrice,
                },
              ]);
            } else {
              setTotalMenuPrice(totalMenuPrice - discountAmount);
              setDiscontValue(
                totalMenuPrice - discountAmount + totalPromosPrice
              );
              setActualDiscount(actualDiscount + discountAmount);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
          setSelectedPublicVoucher([
            ...selectedPublicVoucher,
            {
              ...voucher,
              applyForMenu: true,
              applyForPromos: false,
              applyForBoth: false,
            },
          ]);
          toast?.success("Voucher Applied");
          setAvailablePoints(customer?.points ?? 0);
        }
      } else if (!forMenus && forPromos) {
        if (totalPromosPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Promos Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (totalPromosPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Promos Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount = (voucher?.discount / 100) * totalPromosPrice;
            setTotalPromosPrice(totalPromosPrice - discountAmount);
            setActualDiscount(actualDiscount + discountAmount);
            setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
          } else {
            const discountAmount = voucher?.discount;
            if (totalPromosPrice - discountAmount < 0) {
              setTotalPromosPrice(0);
              setDiscontValue(totalMenuPrice);
              setActualDiscount(actualDiscount + totalPromosPrice);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: totalPromosPrice,
                },
              ]);
            } else {
              setTotalPromosPrice(totalPromosPrice - discountAmount);
              setDiscontValue(
                totalMenuPrice + totalPromosPrice - discountAmount
              );
              setActualDiscount(actualDiscount + discountAmount);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
          setSelectedPublicVoucher([
            ...selectedPublicVoucher,
            {
              ...voucher,
              applyForMenu: false,
              applyForPromos: true,
              applyForBoth: false,
            },
          ]);
          toast?.success("Voucher Applied");
          setAvailablePoints(customer?.points ?? 0);
        }
      } else if (forMenus && forPromos) {
        if (totalMenuPrice + totalPromosPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (totalMenuPrice + totalPromosPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const menuDiscountAmount =
              (voucher?.discount / 100) * totalMenuPrice;
            const promoDiscountAmount =
              (voucher?.discount / 100) * totalPromosPrice;
            setTotalMenuPrice(totalMenuPrice - menuDiscountAmount);
            setTotalPromosPrice(totalPromosPrice - promoDiscountAmount);
            const discountAmount =
              (voucher?.discount / 100) * (totalMenuPrice + totalPromosPrice);
            setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
            setActualDiscount(actualDiscount + discountAmount);
          } else {
            const discountAmount = voucher?.discount;
            const total = totalMenuPrice + totalPromosPrice;
            if (total - discountAmount < 0) {
              setTotalMenuPrice(0);
              setTotalPromosPrice(0);
              setDiscontValue(0);
              setActualDiscount(actualDiscount + total);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: true, usedAmount: total },
              ]);
            } else {
              setTotalMenuPrice(totalMenuPrice - discountAmount / 2);
              setTotalPromosPrice(totalPromosPrice - discountAmount / 2);
              setDiscontValue(
                totalMenuPrice + totalPromosPrice - discountAmount
              );
              setActualDiscount(actualDiscount + discountAmount);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
          setSelectedPublicVoucher([
            ...selectedPublicVoucher,
            {
              ...voucher,
              applyForBoth: true,
              applyForMenu: false,
              applyForPromos: false,
            },
          ]);
          toast?.success("Voucher Applied");
          setAvailablePoints(customer?.points ?? 0);
        }
      }
    }
  };

  const handleRemovePublicVoucher = (item: VoucherEntity) => {
    // filter out the voucher that is removed
    const newVoucherList = selectedPublicVoucher.filter(
      (el) => el.id !== item.id
    );
    setSelectedPublicVoucher(newVoucherList);
    toast?.success("Voucher Removed");

    // Reset the prices to their initial states
    setTotalMenuPrice(actualTotalMenuPrice);
    setTotalPromosPrice(actualTotalPromosPrice);
    setDiscontValue(actualTotalMenuPrice + actualTotalPromosPrice);
    setValue("appliedVouchers", null);
    setActualDiscount(0);

    // Reapply all remaining vouchers
    newVoucherList.forEach((voucher) => {
      applyVoucherLogic(voucher);
    });
  };

  const applyVoucherLogic = (voucher: VoucherEntity) => {
    const voucherConf =
      CheckoutPageDetails?.multipleDiscountConfs?.multipleDiscountConfs?.find(
        (el) => el.type === "PUBLIC_VOUCHER"
      );
    const forMenus = voucherConf?.isForMenus;
    const forPromos = voucherConf?.isForPromos;

    if (selectedPublicVoucher?.length === 2) {
      if (forMenus && !forPromos) {
        if (actualTotalMenuPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Menu Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (actualTotalMenuPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Menu Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount =
              (voucher?.discount / 100) * actualTotalMenuPrice;
            setTotalMenuPrice(actualTotalMenuPrice - discountAmount);
            setDiscontValue(
              actualTotalMenuPrice - discountAmount + actualTotalPromosPrice
            );
            setActualDiscount(discountAmount);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: false },
            ]);
          } else {
            const discountAmount = voucher?.discount;
            if (actualTotalMenuPrice - discountAmount < 0) {
              setTotalMenuPrice(0);
              setDiscontValue(actualTotalPromosPrice);
              setActualDiscount(actualTotalMenuPrice);
              setValue("appliedVouchers", [
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: actualTotalMenuPrice,
                },
              ]);
            } else {
              setTotalMenuPrice(actualTotalMenuPrice - discountAmount);
              setDiscontValue(
                actualTotalMenuPrice - discountAmount + actualTotalPromosPrice
              );
              setActualDiscount(discountAmount);
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
        }
      }

      if (!forMenus && forPromos) {
        if (actualTotalPromosPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Promos Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (actualTotalPromosPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Promos Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount =
              (voucher?.discount / 100) * actualTotalPromosPrice;
            setTotalPromosPrice(actualTotalPromosPrice - discountAmount);
            setDiscontValue(
              actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
            );
            setActualDiscount(discountAmount);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: false },
            ]);
          } else {
            const discountAmount = voucher?.discount;
            if (actualTotalPromosPrice - discountAmount < 0) {
              setTotalPromosPrice(0);
              setDiscontValue(actualTotalMenuPrice);
              setActualDiscount(actualTotalPromosPrice);
              setValue("appliedVouchers", [
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: actualTotalPromosPrice,
                },
              ]);
            } else {
              setTotalPromosPrice(actualTotalPromosPrice - discountAmount);
              setDiscontValue(
                actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
              );
              setActualDiscount(discountAmount);
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
        }
      }

      if (forMenus && forPromos) {
        if (
          actualTotalMenuPrice + actualTotalPromosPrice <
          voucher?.minPurchaseAmount
        ) {
          toast.error(
            `Total Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (
          actualTotalMenuPrice + actualTotalPromosPrice >
          voucher?.maxPurchaseAmount
        ) {
          toast.error(
            `Total Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const menuDiscountAmount =
              (voucher?.discount / 100) * actualTotalMenuPrice;

            const promoDiscountAmount =
              (voucher?.discount / 100) * actualTotalPromosPrice;

            const calculatedMenu = actualTotalMenuPrice - menuDiscountAmount;

            const calculatedPromos =
              actualTotalPromosPrice - promoDiscountAmount;

            setTotalMenuPrice(calculatedMenu);
            setTotalPromosPrice(calculatedPromos);
            const discountAmount = menuDiscountAmount + promoDiscountAmount;
            setDiscontValue(
              actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
            );
            setActualDiscount(discountAmount);
            setValue("appliedVouchers", [
              { ...voucher, isPartiallyUsed: false },
            ]);
          } else {
            const totalPrice = actualTotalMenuPrice + actualTotalPromosPrice;
            const discountAmount = voucher?.discount;
            if (totalPrice - discountAmount < 0) {
              setTotalMenuPrice(0);
              setTotalPromosPrice(0);
              setDiscontValue(0);
              setActualDiscount(totalPrice);
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: true, usedAmount: totalPrice },
              ]);
            } else {
              const calculatedMenu = actualTotalMenuPrice - discountAmount / 2;
              const calculatedPromos =
                actualTotalPromosPrice - discountAmount / 2;

              setTotalMenuPrice(calculatedMenu);
              setTotalPromosPrice(calculatedPromos);
              setActualDiscount(discountAmount);
              setDiscontValue(
                actualTotalMenuPrice + actualTotalPromosPrice - discountAmount
              );
              setValue("appliedVouchers", [
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
        }
      }
    }

    if (selectedPublicVoucher?.length > 2) {
      if (forMenus && !forPromos) {
        if (totalMenuPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Menu Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (totalMenuPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Menu Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount = (voucher?.discount / 100) * totalMenuPrice;
            setTotalMenuPrice(totalMenuPrice - discountAmount);
            setDiscontValue(totalMenuPrice - discountAmount + totalPromosPrice);
            setActualDiscount(actualDiscount + discountAmount);
          } else {
            const discountAmount = voucher?.discount;
            if (totalMenuPrice - discountAmount < 0) {
              setTotalMenuPrice(0);
              setDiscontValue(totalPromosPrice);
              setActualDiscount(actualDiscount + totalMenuPrice);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: totalMenuPrice,
                },
              ]);
            } else {
              setTotalMenuPrice(totalMenuPrice - discountAmount);
              setDiscontValue(
                totalMenuPrice - discountAmount + totalPromosPrice
              );
              setActualDiscount(actualDiscount + discountAmount);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
        }
      }
      if (!forMenus && forPromos) {
        if (totalPromosPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Promos Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (totalPromosPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Promos Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const discountAmount = (voucher?.discount / 100) * totalPromosPrice;
            setTotalPromosPrice(totalPromosPrice - discountAmount);
            setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
            setActualDiscount(actualDiscount + discountAmount);
          } else {
            const discountAmount = voucher?.discount;
            if (totalPromosPrice - discountAmount < 0) {
              setTotalPromosPrice(0);
              setDiscontValue(totalMenuPrice);
              setActualDiscount(actualDiscount + totalPromosPrice);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                {
                  ...voucher,
                  isPartiallyUsed: true,
                  usedAmount: totalPromosPrice,
                },
              ]);
            } else {
              setTotalPromosPrice(totalPromosPrice - discountAmount);
              setDiscontValue(
                totalMenuPrice + totalPromosPrice - discountAmount
              );
              setActualDiscount(actualDiscount + discountAmount);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
        }
      }
      if (forMenus && forPromos) {
        if (totalMenuPrice + totalPromosPrice < voucher?.minPurchaseAmount) {
          toast.error(
            `Total Price should be more than ${voucher?.minPurchaseAmount}`
          );
          return;
        }
        if (totalMenuPrice + totalPromosPrice > voucher?.maxPurchaseAmount) {
          toast.error(
            `Total Price should be less than ${voucher?.maxPurchaseAmount}`
          );
          return;
        } else {
          if (voucher?.discountType === "PERCENT") {
            const menuDiscountAmount =
              (voucher?.discount / 100) * totalMenuPrice;
            const promoDiscountAmount =
              (voucher?.discount / 100) * totalPromosPrice;
            setTotalMenuPrice(totalMenuPrice - menuDiscountAmount);
            setTotalPromosPrice(totalPromosPrice - promoDiscountAmount);
            const discountAmount =
              (voucher?.discount / 100) * (totalMenuPrice + totalPromosPrice);
            setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
            setActualDiscount(actualDiscount + discountAmount);
          } else {
            const totalPrice = totalMenuPrice + totalPromosPrice;
            const discountAmount = voucher?.discount;
            if (totalPrice - discountAmount < 0) {
              setTotalMenuPrice(0);
              setTotalPromosPrice(0);
              setDiscontValue(0);
              setActualDiscount(actualDiscount + totalPrice);
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: true, usedAmount: totalPrice },
              ]);
            } else {
              const calculatedMenu = totalMenuPrice - discountAmount / 2;
              const calculatedPromos = totalPromosPrice - discountAmount / 2;
              setTotalMenuPrice(calculatedMenu);
              setTotalPromosPrice(calculatedPromos);
              setActualDiscount(actualDiscount + discountAmount);
              setDiscontValue(
                totalMenuPrice + totalPromosPrice - discountAmount
              );
              const prevVoucherDetails = getValues("appliedVouchers");
              setValue("appliedVouchers", [
                ...prevVoucherDetails,
                { ...voucher, isPartiallyUsed: false },
              ]);
            }
          }
        }
      }
    }
  };

  const PointValueAppliedHandle = () => {
    setPointsLoading(true);
    // Reset the prices to their initial states
    setTotalMenuPrice(actualTotalMenuPrice);
    setTotalPromosPrice(actualTotalPromosPrice);
    setDiscontValue(actualTotalMenuPrice + actualTotalPromosPrice);
    setValue("appliedVouchers", null);

    const pointsConf =
      CheckoutPageDetails?.multipleDiscountConfs?.multipleDiscountConfs?.find(
        (el) => el.type === "OFFER_AND_POINTS"
      );

    const forMenus = pointsConf?.isForMenus;
    const forPromos = pointsConf?.isForPromos;

    const customerPoints = customer?.points ?? 0;

    if (customerPoints === 0) {
      toast?.error("No points Avaialble");
      setPointsLoading(false);
      return;
    }
    const pointValuePerDollar = OrderLocation?.pointValuePerDollar ?? 1;

    const discountDollar = customerPoints / pointValuePerDollar;

    const totalMenuPrice = getSubtotal;
    const totalPromosPrice = getDealSubtotal + getOfferSubtotal;

    if (forMenus && !forPromos) {
      const discountAmount = discountDollar;
      if (totalMenuPrice < discountAmount) {
        setTotalMenuPrice(0);
        setDiscontValue(totalPromosPrice);
        const usedPoints = totalMenuPrice * pointValuePerDollar;
        const discountAmt = usedPoints / pointValuePerDollar;
        setAvailablePoints(customerPoints - usedPoints);
        setActualDiscount(discountAmt);
        setValue("appliedPoints", usedPoints);
        setValue("discountFromPoints", totalMenuPrice);
      } else {
        setTotalMenuPrice(totalMenuPrice - discountAmount);
        setDiscontValue(totalMenuPrice - discountAmount + totalPromosPrice);
        setAvailablePoints(customerPoints - customerPoints);
        setActualDiscount(discountAmount);
        setValue("appliedPoints", customerPoints);
        setValue("discountFromPoints", discountAmount);
      }
      toast?.success("Points Redeemed");
      setSelectedVoucher(null);
      setSelectedPublicVoucher([]);
    }
    if (!forMenus && forPromos) {
      const discountAmount = discountDollar;
      if (totalPromosPrice < discountAmount) {
        setTotalPromosPrice(0);
        setDiscontValue(totalMenuPrice);
        const usedPoints = totalPromosPrice * pointValuePerDollar;
        const discountAmt = usedPoints / pointValuePerDollar;
        setAvailablePoints(customerPoints - usedPoints);
        setActualDiscount(discountAmt);
        setValue("appliedPoints", usedPoints);
        setValue("discountFromPoints", totalPromosPrice);
      } else {
        setTotalPromosPrice(totalPromosPrice - discountAmount);
        setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
        setAvailablePoints(customerPoints - customerPoints);
        setActualDiscount(discountAmount);
        setValue("appliedPoints", customerPoints);
        setValue("discountFromPoints", discountAmount);
      }
      toast?.success("Points Redeemed");
      setSelectedVoucher(null);
      setSelectedPublicVoucher([]);
    }
    if (forMenus && forPromos) {
      const discountAmount = discountDollar;
      const total = totalMenuPrice + totalPromosPrice;
      if (total < discountAmount) {
        setTotalMenuPrice(0);
        setTotalPromosPrice(0);
        setDiscontValue(0);
        const usedPoints = total * pointValuePerDollar;
        const discountAmt = usedPoints / pointValuePerDollar;
        setActualDiscount(discountAmt);
        setAvailablePoints(customerPoints - usedPoints);
        setValue("appliedPoints", usedPoints);
        setValue("discountFromPoints", total);
      } else {
        const calculatedMenu = totalMenuPrice - discountAmount / 2;
        const calculatedPromos = totalPromosPrice - discountAmount / 2;
        setTotalMenuPrice(calculatedMenu);
        setTotalPromosPrice(calculatedPromos);
        setDiscontValue(totalMenuPrice + totalPromosPrice - discountAmount);
        setAvailablePoints(customerPoints - customerPoints);
        setActualDiscount(discountAmount);
        setValue("appliedPoints", customerPoints);
        setValue("discountFromPoints", discountAmount);
      }

      toast?.success("Points Redeemed");
      setSelectedVoucher(null);
      setSelectedPublicVoucher([]);
    }
    setPointsLoading(false);
  };

  const handleRemoveVoucher = () => {
    setSelectedVoucher(null);
    setDiscontValue(actualTotalMenuPrice + actualTotalPromosPrice);
    setActualDiscount(0);
    setTotalMenuPrice(actualTotalMenuPrice);
    setTotalPromosPrice(actualTotalPromosPrice);
    setValue("appliedVouchers", null);
  };

  const [discountValue, setDiscontValue] = useState(getActualTotalPrice);

  console.log(discountValue);

  const [availablePoints, setAvailablePoints] = useState(customer?.points ?? 0);

  const calculatePointValue = () => {
    const customerPoints = availablePoints;
    const pointValuePerDollar = OrderLocation?.pointValuePerDollar ?? 0;
    const discountDollar = fixedDecimal(
      (customerPoints || 0) / (pointValuePerDollar || 0)
    );
    return discountDollar;
  };

  const [cardSurchargePrice, setCardSurchargePrice] = useState(0);
  const [specialSurchargePrice, setSpecialSurchargePrice] = useState(0);
  const [eventDetails, setEventDetails] = useState<EventDetils[]>([]);

  useEffect(() => {
    async function getHolidaysDetails() {
      if (!storeLocationId) return;
      const finalData = {
        // startDate: selectedDate,
        // endDate: selectedDate,
        // storeId: storeLocationId,
        storeDt: selectedDate,
      };

      const HolidaysDetails: EventDetils[] = await fetchRequest(
        serverPath?.getHolidays(storeLocationId),
        "POST",
        finalData,
        {
          "Content-Type": "application/json",
        }
      );
      setEventDetails(HolidaysDetails);
    }
    getHolidaysDetails();
  }, [selectedDate, storeLocationId]);

  useEffect(() => {
    async function getSurchargeDetails() {
      if (!storeLocationId) return;
      setIsLoading(true);
      // const surchargeDetails: SurchargeDetails = await fetchRequest(
      //   serverPath?.getSurcharge(storeLocationId),
      //   "GET",
      //   undefined,
      //   {
      //     "Content-Type": "application/json",
      //   }
      // );
      const surchargeDetails = CheckoutPageDetails?.surcharge;
      if (!surchargeDetails) return;

      // const finalData = {
      //   // startDate: selectedDate,
      //   // endDate: selectedDate,
      //   // storeId: storeLocationId,
      //   storeDt: selectedDate,
      // };

      // const eventDetails: EventDetils[] = await fetchRequest(
      //   serverPath?.getHolidays(storeLocationId),
      //   "POST",
      //   finalData,
      //   {
      //     "Content-Type": "application/json",
      //   }
      // );
      // const eventDetails = CheckoutPageDetails?.holidaysAtDt

      let specialSurcharge = 0;
      const dayOfWeek = moment(selectedDate).format("ddd").toUpperCase();
      const matchingSurcharge = surchargeDetails?.surchargeWeekDays?.find(
        (item) => item.day === dayOfWeek && item.isEnabled
      );
      const holidayEventDetails = eventDetails
        ?.filter((event) => {
          const start = moment(event.start);
          const end = moment(event.end);
          const selectedMoment = moment(selectedDate);
          return selectedMoment.isBetween(start, end, null, "[]");
        })
        ?.filter((el) => el?.type === "HOLIDAY");
      if (holidayEventDetails?.length > 0) {
        specialSurcharge = surchargeDetails?.specialDaySurchargeWeb;
      } else {
        const selectedTime = moment(selectedDate).format("HH:mm:ss");
        const startTime = moment(matchingSurcharge?.startTime, "HH:mm:ss");
        const endTime = moment(matchingSurcharge?.endTime, "HH:mm:ss");
        if (moment(selectedTime, "HH:mm:ss").isBetween(startTime, endTime)) {
          specialSurcharge = matchingSurcharge?.surcharge || 0;
        } else {
          specialSurcharge = 0;
        }
      }
      const cardSurcharge = surchargeDetails?.cardSurchargeWeb || 1;
      // const totalPriceWithDiscount =
      //   getActualTotalPrice - actualDiscount || 0;
      const totalPriceWithDiscount = getActualTotalPrice || 0;
      // if(surchargeDetails?.cardSurchargeWeb > 0){
      //   const getActualCardSurcharge =
      //   (totalPrice * cardSurcharge) / 100;
      //   setCardSurchargePrice(getActualCardSurcharge);
      //   setValue("cardSurcharge", getActualCardSurcharge);
      // }

      if (paymentGatewayType === "PAYPAL" || paymentGatewayType === "STRIPE") {
        if (surchargeDetails?.cardSurchargeWeb > 0) {
          const getActualCardSurcharge =
            (totalPriceWithDiscount * cardSurcharge) / 100;
          setCardSurchargePrice(getActualCardSurcharge);
          setValue("cardSurcharge", getActualCardSurcharge);
          if (specialSurcharge > 0) {
            const specialSurchargePrice =
              // ((totalPrice + getActualCardSurcharge) * specialSurcharge) / 100;
              (getActualTotalPrice * specialSurcharge) / 100;
            setSpecialSurchargePrice(specialSurchargePrice);
            setValue("specialSurcharge", specialSurchargePrice);
          } else {
            setSpecialSurchargePrice(0);
            setValue("specialSurcharge", 0);
          }
        }
      } else {
        setCardSurchargePrice(0);
        setValue("cardSurcharge", 0);
        console.log("hit here", totalPriceWithDiscount);
        if (specialSurcharge > 0) {
          const specialSurchargePrice =
            (totalPriceWithDiscount * specialSurcharge) / 100;
          setSpecialSurchargePrice(specialSurchargePrice);
          setValue("specialSurcharge", specialSurchargePrice);
        } else {
          setSpecialSurchargePrice(0);
          setValue("specialSurcharge", 0);
        }
      }
      setIsLoading(false);
    }

    getSurchargeDetails();
  }, [
    CheckoutPageDetails,
    actualDiscount,
    eventDetails,
    getActualTotalPrice,
    paymentGatewayType,
    selectedDate,
    setValue,
    storeLocationId,
  ]);

  const totalAmount =
    getActualTotalPrice -
    actualDiscount +
    (orderType === "DELIVERY" ? deliveryPrice : 0) +
    (paymentGatewayType === "PAYPAL" || paymentGatewayType === "STRIPE"
      ? cardSurchargePrice
      : 0) +
    specialSurchargePrice;

  // const [deliveryMinOrderAmount, setDeliverMinOrderAMount] = useState(0);
  const deliveryMinOrderAmount =
    CheckoutPageDetails?.storeDetails?.minOrderAmount || 0;
  // useEffect(() => {
  //   async function DeliveryMinAmount() {
  //     if (!storeLocationId) return;
  //     const MinOrderAmount = await fetchRequest(
  //       serverPath?.getMinOrderAmount(storeLocationId),
  //       "GET",
  //       undefined,
  //       {
  //         "Content-Type": "application/json",
  //       }
  //     );
  //     setDeliverMinOrderAMount(MinOrderAmount);
  //   }
  //   DeliveryMinAmount();
  // }, [storeLocationId]);

  useEffect(() => {
    if (totalAmount <= 0) return;
    console.log("testing here", totalAmount);
    setValue("config.finalTotalOrderForWallet", Number(totalAmount.toFixed(2)));
  }, [totalAmount, setValue]);

  useEffect(() => {
    if (orderType === "DELIVERY") {
      if (Number(getActualTotalPrice) < deliveryMinOrderAmount) {
        setValue("deliveryOrderStatus", false);
      } else {
        setValue("deliveryOrderStatus", true);
      }
    } else {
      setValue("deliveryOrderStatus", true);
    }
  }, [
    deliveryMinOrderAmount,
    getActualTotalPrice,
    orderType,
    setValue,
    totalAmount,
  ]);

  if (!OrderLocation?.id) return null;

  const ContinueOrderHandle = () => {
    const location = OrderLocation?.id;

    router(`/${location}`);
  };

  return (
    <div className="min-w-[10rem] md:min-w-[30rem] relative ">
      <div className="flex items-center justify-between">
        <h2 className="font-bold text-lg pb-3">Order Summary</h2>
        <button
          className="p-3 border rounded-md bg-black text-white"
          onClick={ContinueOrderHandle}
        >
          <div className="flex flex-row gap-2">
            <img
              src="/images/icons/back.svg"
              width={25}
              height={25}
              alt="Go back"
              style={{ filter: "invert(100%)" }}
            />
            <p>Continue Order</p>
          </div>
        </button>
      </div>
      <div>
        <AddToCartDealList
          location={OrderLocation?.id}
          dealList={deal}
          title="Deal"
        />
        <AddToCartDealList
          location={OrderLocation?.id}
          dealList={offer}
          title="Offer"
        />
        <AddToCartMenuList location={OrderLocation?.id} />
      </div>
      <div className="flex items-center w-full rounded p-2 border border-black mb-4">
        <input
          {...register("specialInstruction")}
          className="w-full outline-none"
          type="text"
          placeholder="Notes"
        />
      </div>
      <div className="flex gap-2 flex-col pb-2">
        <h1 className="font-bold">Voucher Details</h1>
        {/* Private Voucher Section */}
        <div className="flex gap-2">
          {voucherDetails
            ?.filter((voucher) => !voucher.isUsed)
            .map((voucher, index) => (
              <div key={index} className="relative ">
                {selectedVoucher?.id === voucher?.id && (
                  <div
                    className="absolute right-1  text-red-500 cursor-pointer"
                    onClick={handleRemoveVoucher}
                  >
                    {/* <Icon icon="material-symbols:close" width={20} /> */}
                    <img
                      src="/images/icons/cross.svg"
                      width="20"
                      height="20"
                      alt="x"
                    />
                  </div>
                )}
                <button
                  className={`border rounded-md p-4 border-black ${
                    selectedVoucher?.id === voucher?.id
                      ? "bg-black text-white"
                      : ""
                  }`}
                  type="button"
                  onClick={() => handlePrivateVoucher(voucher)}
                >
                  <p>
                    {voucher?.vcode} -{" "}
                    {voucher?.discountType === "PERCENT"
                      ? `${voucher?.discount}%`
                      : `$${voucher?.discount}`}
                  </p>
                  <p>
                    {voucher?.startDt &&
                      new Date(voucher.startDt).toLocaleString("default", {
                        month: "short",
                        day: "numeric",
                      })}
                    {" - "}
                    {voucher?.endDt &&
                      new Date(voucher.endDt).toLocaleString("default", {
                        month: "short",
                        day: "numeric",
                      })}
                  </p>
                  <p className="text-xs">
                    {/* {selectedVoucher?.applyForBoth ? "Both" : selectedVoucher?.applyForMenu ? "Menu" : !selectedVoucher?.applyForMenu ? "promos": ""}  */}
                    {selectedVoucher?.applyForBoth &&
                      "Applied to Menu and Promos"}
                    {selectedVoucher?.applyForMenu && "Applied to Menu"}
                    {selectedVoucher?.applyForPromos && "Applied to Promos"}
                  </p>
                </button>
              </div>
            ))}
        </div>
      </div>
      {/* Public Voucher Section */}
      <div className="flex justify-center pb-2 gap-2">
        <div className="flex items-center w-full rounded p-2 border border-black ">
          <input
            type="text"
            placeholder="Voucher Code"
            className="w-full outline-none"
            onChange={(e) => setRedeemCode(e.target.value)}
            value={redeemCode}
          />
          {redeemCode && (
            <div
              className=" text-red-500 cursor-pointer"
              onClick={() => setRedeemCode("")}
            >
              {/* <Icon icon="material-symbols:close" width={20} /> */}
              <img
                src="/images/icons/cross.svg"
                width="20"
                height="20"
                alt="x"
              />
            </div>
          )}
        </div>
        <button
          className="bg-black text-white rounded px-5"
          type="button"
          onClick={() => ApplyVoucherHandler(redeemCode)}
        >
          {isLoading ? "........." : "Apply"}
        </button>
      </div>
      {selectedPublicVoucher.length > 0 &&
        selectedPublicVoucher?.map((item, index) => (
          <button
            className="border rounded-md p-4 border-black relative  bg-black text-white"
            type="button"
            key={index}
          >
            {selectedPublicVoucher?.findIndex((el) => el.id === item.id) !==
              -1 && (
              <div
                className="absolute right-1  text-red-500 cursor-pointer"
                onClick={() => handleRemovePublicVoucher(item)}
              >
                {/* <Icon icon="material-symbols:close" width={20} /> */}
                <img
                  src="/images/icons/red-close.svg"
                  width="20"
                  height="20"
                  alt="x"
                />
              </div>
            )}
            <p>
              {item?.vcode} -{" "}
              {item?.discountType === "PERCENT"
                ? `${item?.discount}%`
                : `$${item?.discount}`}
            </p>
            <p>
              {item?.startDt &&
                new Date(item.startDt).toLocaleString("default", {
                  month: "short",
                  day: "numeric",
                })}
              {" - "}
              {item?.endDt &&
                new Date(item.endDt).toLocaleString("default", {
                  month: "short",
                  day: "numeric",
                })}
            </p>
            <p className="text-xs">
              {item?.applyForBoth && "Applied to Menu and Promos"}
              {item?.applyForMenu && "Applied to Menu"}
              {item?.applyForPromos && "Applied to Promos"}
            </p>
          </button>
        ))}
      {/* Points Section */}

      {OrderLocation?.isPointEnabled && (
        <div className="flex gap-2 flex-row justify-between items-center pb-2 pt-2">
          <div className="flex gap-2 items-center ">
            <h1 className="font-bold">
              Points{" "}
              <span className="text-gray-500">
                ({availablePoints} points available = ${calculatePointValue()})
              </span>
            </h1>
          </div>
          {/* <div className="w-full max-w-sm mx-auto p-4 bg-white shadow rounded">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-600">
                Points balance
              </span>
              <span className="text-lg font-bold text-gray-900">
                {availablePoints} / {10000}
              </span>
            </div>
            <div className="relative w-full h-4 bg-gray-200 rounded-full">
              <div
                className="absolute top-0 left-0 h-full bg-black rounded-full"
                style={{
                  width: `${Math.min((availablePoints / 10000) * 100, 100)}%`,
                }}
              ></div>
            </div>
            <div className="mt-2 text-sm text-gray-600">
              {availablePoints >= 10000
                ? "You can collected your reward!"
                : `${
                    10000 - availablePoints
                  } more points to collect your reward`}
            </div>
          </div> */}
          <div className="flex items-center">
            <button
              className="bg-black text-white rounded p-2 px-2.5"
              type="button"
              onClick={PointValueAppliedHandle}
            >
              {pointsLoading ? "........." : "Redeem"}
            </button>
          </div>
        </div>
      )}

      <div className="child:py-2 flex flex-col gap-2">
        <div className="flex flex-col gap-2 py-2 border-y-2">
          <p className="flex items-center justify-between">
            SubTotal <span>$ {fixedDecimal(getActualTotalPrice || 0)}</span>
          </p>
          {actualDiscount > 0 && (
            <p className="flex items-center justify-between">
              Discount <span>$ {fixedDecimal(actualDiscount)}</span>
            </p>
          )}
          {orderType === "DELIVERY" && deliveryPrice > 0 && (
            <p className="flex items-center justify-between">
              Delivery Charge : <span>$ {deliveryPrice}</span>
            </p>
          )}
          {/* {(paymentGatewayType === "PAYPAL" || paymentGatewayType ===  "STRIPE") && (
            <p className="flex items-center justify-between">
            Surcharge : <span>$ {surchargePrice}</span>
          </p>
          )} */}
          {specialSurchargePrice > 0 && (
            <p className="flex items-center justify-between">
              Public Surcharge <span>$ {specialSurchargePrice.toFixed(2)}</span>
            </p>
          )}
          {cardSurchargePrice > 0 && (
            <p className="flex items-center justify-between">
              Card Surcharge: <span>$ {cardSurchargePrice.toFixed(2)}</span>
            </p>
          )}
          {/* {(cardSurchargePrice > 0 || specialSurchargePrice > 0) && (
            <p className="flex items-center justify-between">
              Surcharge:{" "}
              <span>
                {paymentGatewayType === "PAYPAL" ||
                paymentGatewayType === "STRIPE"
                  ? // Calculate total surcharge if paymentGatewayType is PAYPAL or STRIPE
                    `$${fixedDecimal(
                      (cardSurchargePrice > 0 ? cardSurchargePrice : 0) +
                      (specialSurchargePrice > 0 ? specialSurchargePrice : 0))
                    }`
                  : // Only show specialSurchargePrice if paymentGatewayType is not PAYPAL or STRIPE
                  specialSurchargePrice > 0
                  ? `$${fixedDecimal(specialSurchargePrice)}`
                  : ""}
              </span>
            </p>
          )} */}
        </div>
        <p className="flex items-center justify-between">
          Total <span>$ {fixedDecimal(totalAmount)}</span>
        </p>
        {orderType === "DELIVERY" &&
          Number(getActualTotalPrice) < deliveryMinOrderAmount && (
            <p className="flex items-center justify-center  text-red-500">
              For delivery, must order at least ${deliveryMinOrderAmount}
            </p>
          )}
        {/* {!deliveryDistancePossible && (
          <p className="flex items-center justify-center text-red-500">Delivery is Not Possible</p>
        )} */}
      </div>
      {!auth && <div className="absolute bg-black/60 inset-0 rounded-md" />}
      {/* {isUserEdit === true && (
        <div className="absolute bg-black/60 inset-0 rounded-md" />
      )} */}
    </div>
  );
}

export default CheckoutOrderSummary;
