import MenuDetailsCombo from "./AddMenuCart/MenuDetailsCombo/MenuDetailsCombo";
import MenuDetailsCurrentOption from "./AddMenuCart/MenuDetailsCurrentOption";
import MenuDetailsAdditionalOption from "./AddMenuCart/MenuDetailsAdditionalOption/MenuDetailsAdditionalOption";
import MenuDetailsExtraOptions from "./AddMenuCart/MenuDetailsExtraOptions";
import MenuDetailsAddToCart from "./AddMenuCart/MenuDetailsAddToCart";
import { AddMenuItemsForm, MenuDetailsEntity } from "@/app/_types/menuDetails";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import AddMenuHalfAndHalfButton from "./AddMenuHalfAndHalf/AddMenuHalfAndHalfButton";
import { useFormContext } from "react-hook-form";
import AddMenuHalfAndHalf from "./AddMenuHalfAndHalf/AddMenuHalfAndHalf";
import MenuDetailsTitle from "./AddMenuCart/MenuDetailsTitle";
import MenuDetailsSizeHandler from "./AddMenuCart/MenuDetailsSizeHandler";

interface MenuDetailsManageForm {
  menuDetails: MenuDetailsEntity;
  data?: ExtraPriceInfo;
  isModal: boolean;
}

function MenuDetailsManageForm({
  menuDetails,
  data,
  isModal,
}: MenuDetailsManageForm) {
  const { watch, register } = useFormContext<AddMenuItemsForm>();
  const watchConfig = watch("config");

  const sizeGroup = data?.sizeGroups?.find(
    (el) => el?.id === menuDetails?.menu?.sizeGroupId
  );

  const optionGroups =
    data?.sizeGroups?.find(
      (group) => group?.id === menuDetails?.menu?.sizeGroupId
    )?.optionGroups || [];

  return (
    <div className="w-full md:w-1/2 flex flex-col h-[75dvh] relative pb-32">
      <div className="overflow-auto">
      <div className="px-4 py-2 border-b-2 border-gray-100">
        <MenuDetailsTitle isModal={isModal} />
      </div>
      <div className="flex flex-col gap-4  py-2 relative">
        {watchConfig?.canAddToCart || !watchConfig?.isSplit ? (
          <>
            {menuDetails?.menu?.hasHalfAndHalf && (
              // <AddMenuHalfAndHalfButton sizeGroup={sizeGroup} />
              <AddMenuHalfAndHalfButton sizeGroup={sizeGroup} />
            )}
            <MenuDetailsSizeHandler />
          </>
        ) : null}
        {watchConfig?.isSplit && watchConfig?.canAddToCart ? (
          // <AddMenuHalfAndHalf sizeGroup={sizeGroup} />
          <AddMenuHalfAndHalf sizeGroup={sizeGroup} />
        ) : (
          <>
            <MenuDetailsCombo />

            <MenuDetailsCurrentOption optionGroups={optionGroups} />
            <MenuDetailsAdditionalOption optionGroups={optionGroups} />

            <MenuDetailsExtraOptions
              extraOptions={data}
              menuSizeGroupId={menuDetails?.menu?.sizeGroupId}
            />
          </>
        )}
      </div>
      <div className="flex flex-col gap-4 px-4">
        <div className="flex items-center w-full p-2 rounded  border border-black">
          <input
            {...register(`note`)}
            className="w-full outline-none"
            type="text"
            placeholder="Notes"
          />
        </div>
      </div>
      <MenuDetailsAddToCart isModal={isModal} />
      </div>
    </div>
  );
}

export default MenuDetailsManageForm;
