import {
  DealCategoryMenu,
  IndividualDealOrderItems,
  MenuDealForm,
} from "@/app/_types/menuDeals.interface";
import { createContext, useContext, useEffect, useState } from "react";
import {
  UseFieldArrayReturn,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";
import { DealSelectItems } from "../MenuDealSelectMenu/MenuDealSelectMenu";
import { useSelector } from "react-redux";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import toast from "react-hot-toast";
import MenuDealEditLogic from "./MenuDealEditLogic";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { serverPath } from "@/app/_axios/path";
import fetchRequest from "@/app/_axios/fetchRequest";
import { MenuDetailsEntity } from "@/app/_types/menuDetails";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import { DealMenuIngredientProvider } from "../../../context";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "@/app/[location]/menu/utils/sizeChangeUtils";

type FieldName = `menuDeal.${number}.menus.${number}.ingredients`;

// export const DealMenuIngredientProvider = createContext<
//   | UseFieldArrayReturn<
//       MenuDealForm,
//       `menuDeal.${number}.menus.${number}.ingredients`,
//       `fieldId`
//     >
//   | undefined
// >(undefined);

export const FormMenuDataProvider = createContext<
  | UseFieldArrayReturn<MenuDealForm, `menuDeal.${number}.menus`, `keyId`>
  | undefined
>(undefined);

function MenuDealEditMenu() {
  const { control, setValue, getValues } = useFormContext<MenuDealForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.selectedMenuIndex`,
  });

  const menuDetails = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.menus.${selectedMenuIndex}`,
  });

  const fieldName: FieldName =
    `menuDeal.${selectedIndex}.menus.${selectedMenuIndex}.ingredients` as const;

  const fieldIngredients = useFieldArray({
    control,
    name: fieldName,
    keyName: `fieldId`,
  });

  const dataFieldArray = useFieldArray({
    control,
    name: `menuDeal.${selectedIndex}.menus`,
    keyName: `keyId`,
  });

  const { replace } = fieldIngredients;

  useEffect(() => {
    const values =
      getValues(
        `menuDeal.${selectedIndex}.menus.${selectedMenuIndex}.ingredients`
      ) || [];
    replace(values);
  }, [getValues, replace, selectedIndex, selectedMenuIndex, setValue]);

  if (!menuDetails) return <p>No Selected Menu Found. Please Try Again</p>;

  console.log(fieldIngredients);
  return (
    <FormMenuDataProvider.Provider value={dataFieldArray}>
      <DealMenuIngredientProvider.Provider value={fieldIngredients}>
        <div>
          <ButtonManager hasHalfHalf={menuDetails?.hasHalfHalf} />
          <MenuDealEditLogic />
        </div>
      </DealMenuIngredientProvider.Provider>
    </FormMenuDataProvider.Provider>
  );
}

function ButtonManager({ hasHalfHalf }: { hasHalfHalf: boolean }) {
  return (
    <div className="flex py-2  gap-4 items-center mr-4">
      <ChangeMenuButton />
      {hasHalfHalf && <HalfHalfButton />}
    </div>
  );
}

function ChangeMenuButton() {
  const { control, setValue } = useFormContext<MenuDealForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const watchConfig = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config`,
  });

  const ChangeMenuHandler = () => {
    setValue(`config.selectedPage`, "SELECT_MENU");
  };

  if (watchConfig?.isSplit && watchConfig?.canAddToCart) return null;
  return (
    <button
      className=" text-primary underline  ml-auto flex"
      onClick={ChangeMenuHandler}
    >
      Change Menu
    </button>
  );
}

function HalfHalfButton() {
  const [open, setOpen] = useState(false);
  const [dealCategories, setDealCategories] = useState<DealCategoryMenu[]>([]);
  const { dealDetails } = useSelector(order);
  const { selectedStore } = useSelector(selectStore);
  const { control, setValue, getValues } = useFormContext<MenuDealForm>();

  const fieldData = useContext(FormMenuDataProvider);

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const watchConfig = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config`,
  });

  const watchData = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.menus`,
  });

  const periodId = useWatch({
    control,
    name: `periodId`,
  });

  const isDataValid = watchData
    ?.map((data) => (data?.id ? data?.id : null))
    .includes(null);

  // const selectedDealId = useWatch({
  //   control,
  //   name: `menuDeal.${selectedIndex}.dealId`,
  // });

  const ChangeHalfAndHalf = () => {
    // const selectedMenuIndex = getValues(
    //   `menuDeal.${selectedIndex}.config.selectedMenuIndex`
    // );

    const isValid = () => {
      const isTrueList: boolean[] = [];
      watchData?.forEach((item) => {
        const comboList = item?.optionGroups;
        comboList?.forEach((combos) => {
          const isMandatory = combos?.isMandatory;
          if (isMandatory) {
            const isComboValid = combos?.options?.some(
              (option) => option?.qty > 0
            );

            if (isComboValid) {
              isTrueList.push(true);
            } else {
              isTrueList.push(false);
            }
          } else {
            isTrueList.push(true);
          }
        });
      });
      const checkIsTrueList = isTrueList.every((item) => item === true);
      return checkIsTrueList;
    };
    const isComboValid = isValid();

    if (!isComboValid) {
      toast.error("Please select at least one item from the required combo");
      return;
    }

    const getDealId = getValues(`menuDeal.${selectedIndex}.dealCategoryId`);
    const dealCategories = dealDetails?.dealCategories?.find(
      (el) => el?.id === getDealId
    );
    if (!dealCategories) {
      toast.error(
        "Something Went Wrong while getting Deals. Please Try Again."
      );
    }
    const halfDealCategories = dealCategories?.dealCategoryMenus?.filter(
      (el) => el?.hasHalfHalf
    );
    setDealCategories(halfDealCategories || []);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectedMenu = async (menu: DealCategoryMenu) => {
    if (!selectedStore?.id) {
      return toast.error("Something went wrong. Please Try Again.");
    }

    // getting selected menu information
    const [PromiseMenuDetails, PromiseExtraPriceInfo] =
      await Promise.allSettled([
        fetchRequest(
          serverPath.getMenuDetails(selectedStore?.id, menu?.menuId, periodId),
          "GET",
          undefined,
          { cache: "no-store" }
        ),
        fetchRequest(
          serverPath.getExtraPriceInfo(selectedStore?.id),
          "GET",
          undefined
        ),
      ]);

    if (PromiseMenuDetails.status === "rejected")
      return toast.error("Cannot get Menu Details. Please Try Again");
    if (PromiseExtraPriceInfo.status === "rejected")
      return toast.error("Error While getting Extra Price Info");

    const menuDetails: MenuDetailsEntity = PromiseMenuDetails?.value;
    const priceInfo: ExtraPriceInfo = PromiseExtraPriceInfo?.value;

    const selectedDealIndex = getValues("config.selectedDealIndex");
    const sizeId = getValues(`menuDeal.${selectedDealIndex}.size`);

    const menuSizeId = menuDetails?.menuSizes?.find(
      (size) => size?.sizeId === sizeId
    )?.id;

    if (!menuSizeId)
      return toast.error("Menu Size Not Found. Please Try Again");

    const comboList = ManageComboPayInitial(
      menuDetails?.menuOptionGroups || [],
      menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
    );

    const comboPrices = getIndividualMenuComboPrice(comboList);

    const menuData: IndividualDealOrderItems = {
      id: menuDetails?.menu?.id,
      menuSizeId,
      name: menuDetails?.menu?.name,
      hasHalfHalf: menuDetails?.menu?.hasHalfAndHalf,
      basePrice: menuDetails?.menu?.basePrice,
      sizeGroupId: menuDetails?.menu?.sizeGroupId,
      specialPrice: menu?.specialPrice || 0,
      comboPrice: comboPrices,
      finalPrice: menu?.finalPrice || 0,
      hasExtras: menu?.hasExtras,
      ingredientPrice: 0,
      optionGroups: comboList,
      ingredients: menuDetails?.menuOptions,
      tradingHours: menu?.tradingHours,
      menuSizeCount: menu?.menuSizeCount,
    };

    fieldData?.append(menuData);

    setValue(`menuDeal.${selectedIndex}.config.isSplit`, true);
    setValue(`menuDeal.${selectedIndex}.config.canAddToCart`, false);
    setValue(`menuDeal.${selectedIndex}.config.selectedMenuIndex`, 1);
    // setValue(`config.selectedPage`, "EDIT_MENU");

    const getMenus = getValues(`menuDeal.${selectedIndex}.menus`);
    const getDealType = getValues("dealType");

    const sizeGroup = priceInfo?.sizeGroups?.find(
      (el) => el?.id === menu?.sizeGroupId
    );

    const additionalPrice =
      sizeGroup?.halfHalfSizes?.find((el) => el?.sizeId === sizeId)
        ?.additionalPrice || 0;

    const halfHalfStatus = sizeGroup?.halfHalfType || "AVERAGE";

    if (getDealType === "FIXED") {
      const firstSpecialPrice = getMenus?.[0]?.specialPrice || 0;
      const secondSpecialPrice = getMenus?.[1]?.specialPrice || 0;
      const finalAmount =
        findFinalNumber(firstSpecialPrice, secondSpecialPrice, halfHalfStatus) +
        additionalPrice;
      setValue(
        `menuDeal.${selectedIndex}.menusHalfHalfPrice`,
        finalAmount || 0
      );
    }

    if (getDealType === "PERCENT") {
      const firstSpecialPrice = getMenus?.[0]?.finalPrice || 0;
      const secondSpecialPrice = getMenus?.[1]?.finalPrice || 0;
      if (firstSpecialPrice > 0 || secondSpecialPrice > 0) {
        const finalAmount = findFinalNumber(
          firstSpecialPrice,
          secondSpecialPrice,
          halfHalfStatus
        );
        const discountAmount = getValues("discountRate") || 0;
        const finalDiscountAmount = (discountAmount / 100) * finalAmount;
        const finalPrice = finalAmount - finalDiscountAmount + additionalPrice;

        setValue(
          `menuDeal.${selectedIndex}.menusHalfHalfPrice`,
          finalPrice || 0
        );
      }
    }

    setValue(`config.isMenuHalfHalf`, true);
    setOpen(false);
    // setValue(`menuDeal.${selectedIndex}.config.isSplit`, true);
  };

  const RemoveItemHandler = () => {
    if (watchConfig?.isRemove) {
      return setValue(`menuDeal.${selectedIndex}.config.isRemove`, false);
    }

    setValue(`menuDeal.${selectedIndex}.config.isRemove`, true);
  };

  return (
    <>
      {watchConfig?.isSplit ? (
        <>
          {watchConfig?.canAddToCart ? (
            <button
              className="p-1  text-red-500 rounded-md flex  underline ml-auto"
              onClick={RemoveItemHandler}
            >
              {watchConfig?.isRemove || isDataValid
                ? "Go Back"
                : "Remove Items"}
            </button>
          ) : null}
        </>
      ) : (
        <button
          className="p-2 bg-slate-500 text-white rounded-md flex"
          onClick={ChangeHalfAndHalf}
        >
          Half / Half
        </button>
      )}
      {open ? (
        <DealHalfHalfModal
          open={open}
          handleClose={handleClose}
          dealCategoriesList={dealCategories}
          onSelectedMenu={onSelectedMenu}
        />
      ) : null}
    </>
  );
}

function DealHalfHalfModal({
  open,
  handleClose,
  dealCategoriesList,
  onSelectedMenu,
}: {
  open: boolean;
  handleClose(): void;
  dealCategoriesList: DealCategoryMenu[];
  onSelectedMenu: (menu: DealCategoryMenu, index: number) => void;
}) {
  return (
    <CustomModalClients
      open={open}
      handleClose={handleClose}
      className="modal-white-parallel"
      scrollDisable={true}
      disableCloseOnOverlay={true}
    >
      <div className="flex justify-between items-center p-4 md:p-6 border-b-2">
        <h1 className="font-bold text-xl ">Select Your Other Half </h1>
        <button
          onClick={handleClose}
          // className={twMerge("lg:hidden", !isModal && "hidden")}
        >
          <img
            src="/images/icons/close.svg"
            width={25}
            height={25}
            alt="close"
          />
        </button>
      </div>
      <DealSelectItems
        dealCategoriesList={dealCategoriesList || []}
        onSelectedMenu={onSelectedMenu}
      />
    </CustomModalClients>
  );
}

// for deals
export function findFinalNumber(
  num1: number,
  num2: number,
  value: "HIGHEST" | "LOWEST" | "AVERAGE"
) {
  switch (value) {
    case "HIGHEST":
      return Math.max(num1, num2);
    case "LOWEST":
      return Math.min(num1, num2);
    case "AVERAGE":
      return (num1 + num2) / 2;
    default:
      return 0;
  }
}

export default MenuDealEditMenu;
