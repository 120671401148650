import { MenuDealForm } from "@/app/_types/menuDeals.interface";

import { useFormContext, useWatch } from "react-hook-form";
import MenuDealEditHalfHalf from "./MenuDealEditExtra/MenuDealEditHalfHalf";
import MenuDealEditCombo from "./MenuDealEditCombo";
import MenuDealEditOptionsGroup from "./MenuDealEditOptionsGroup";

function MenuDealEditLogic() {
  const { control } = useFormContext<MenuDealForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.selectedMenuIndex`,
  });

  const watchConfig = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config`,
  });

  return (
    <div>
      {!watchConfig?.isSplit || !watchConfig?.canAddToCart ? (
        // <h2 className="px-4  font-bold text-xl">Edit {selectedMenuName}</h2>
        <MenuTitle />
      ) : null}
      {watchConfig?.isSplit && watchConfig?.canAddToCart ? (
        // <MenuDealEditHalfHalf />
        <MenuDealEditHalfHalf />
      ) : (
        <>
          {/* <MenuDealEditCombo key={selectedMenuIndex} /> */}
          {/* <MenuDealEditOptionsGroup /> */}
          <MenuDealEditCombo key={selectedMenuIndex} />
          <MenuDealEditOptionsGroup />
        </>
      )}
    </div>
  );
}

function MenuTitle() {
  const { control } = useFormContext<MenuDealForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.selectedMenuIndex`,
  });

  const selectedMenuName = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.menus.${selectedMenuIndex}.name`,
  });

  const isSplit = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.isSplit`,
  });

  return (
    <h2 className="px-4  font-bold text-xl">
      Edit {isSplit ? `1/2 ${selectedMenuName}` : selectedMenuName}
    </h2>
  );
}

export default MenuDealEditLogic;
