import { getSchedules, order } from "@/app/_redux/slice/Order/OrderSlice";
import  { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useFormContext, useWatch } from "react-hook-form";
import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
import CheckoutManageFutureOrderButton from "./CheckoutManageFutureOrderButton";
// import CheckoutManageDeliveryFutureOrder from "./CheckoutManageDeliveryFutureOrder"; // already comment
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import moment from "moment";
import { CalculateMenuTradingPeriod } from "@/app/[location]/components/MenuTradingPeriods/CalculateTradingPeriod";

function CheckoutManageOrderType() {
  const { tradingHours, schedule, OrderList, dealList, todayTradingHours } =
    useSelector(order);

  const dispatch = useDispatch();

  const {  storeDT, selectedOrderStatus } = useSelector(selectUI);
  const { control } = useFormContext<ConfirmOrderForm>();
  const [orderStatus, setOrderStatus] = useState(true);

  const handleClearSchedule = () => {
    dispatch(getSchedules(null));
    localStorage.removeItem("scheduleData");
  };

  const orderDetails = useMemo(
    () => [...OrderList, ...dealList],
    [OrderList, dealList]
  );

  useEffect(() => {
    if (!storeDT) return;

    setOrderStatus(true);

    const calculateAndSetStatus = () => {
      orderDetails.forEach((item) => {
        // const tradingStatus = CalculateTradingPeriod({
        //   tradingPeriodId: item?.periodId,
        //   todayTradingHoursData: todayTradingHours,
        //   selectedOrderStatus,
        //   storeDT,
        // });

        const selectedDay = schedule?.selectedDay?.day
          ? schedule?.selectedDay?.day
          : moment(storeDT).format("ddd").toUpperCase();

        const tradingStatus = CalculateMenuTradingPeriod({
          selectedOrderStatus,
          tradingHoursData: item?.tradingHours,
          tradingPeriodId: item?.periodId,
          selectedTime: schedule?.selectedTime?.date
            ? schedule?.selectedTime?.date
            : storeDT,
          selectedDay,
          storeDT,
        });

        if (tradingStatus.status === false) {
          setOrderStatus(false);
          return;
        }
      });
    };

    calculateAndSetStatus();
  }, [orderDetails, selectedOrderStatus, storeDT, todayTradingHours, schedule]);

  const watchOrderType = useWatch({ control, name: "orderType" });
  // const watchIsOrderForFuture = useWatch({
  //   control,
  //   name: "config.isOrderForFuture",
  // });
  // const watchSelectedDay = useWatch({ control, name: "selectedDay" });

  const tradingHourList = tradingHours?.filter(
    (el) => el?.orderType === watchOrderType
  );

  if (!tradingHourList?.length) return <p>No Trading Hours</p>;

  // today date
  // const days = tradingHourList?.find((el) => el?.day === watchSelectedDay);

  // console.log("watch Days => ", tradingHourList, days);

  // order for future button
  // if (!days || days?.isClosed || !days?.startTime || !days?.endTime)
  //   return (
  //     <CheckoutManageDeliveryStoreCloses tradingHourList={tradingHourList} />
  //   );

  // console.log("Time Array => ", timeArray);

  return (
    <div>
      <div className="pb-2 flex items-center justify-between">
        <p>Select {watchOrderType === "STORE" ? "Pick Up" : "Delivery"} Time</p>
        <div className="">
          <CheckoutManageFutureOrderButton />
        </div>
      </div>
      <div className="flex justify-end">
        {schedule?.selectedTradingPeriod && schedule?.selectedTime && (
          <p className="text-sm ">
            {schedule?.selectedTradingPeriod?.data} {schedule?.orderType} at{" "}
            {schedule?.selectedTime?.label}{" "}
            {schedule?.orderType === "DELIVERY" &&
              " - " +
                moment(schedule?.selectedTime?.label, "HH:mm A")
                  .add(30, "minutes")
                  .format("h:mm A")}{" "}
            <button className="text-red-500" onClick={handleClearSchedule}>
              clear
            </button>
          </p>
        )}
        {!schedule && !orderStatus ? (
          <p>is Not Available</p>
        ) : orderStatus && !schedule ? (
          <div className="flex gap-2 items-center text-sm text-neutral-500">
            <img
              src="/images/icons/clock.svg"
              width="15"
              height="15"
              alt="clock"
            />
            <p>ASAP</p>
          </div>
        ) : null}
      </div>
      <div className="flex gap-2">
        {/* {watchIsOrderForFuture && (
          <CheckoutManageDeliveryFutureOrder
            tradingHourList={tradingHourList}
          />
        )} */}
      </div>
    </div>
  );
}

export default CheckoutManageOrderType;
