import { useQueryClient } from "@tanstack/react-query";
import SelectStore from "../selectStore/SelectStore";
import Logo from "@/app/global/logo/Logo";
import CartNavBarButton from "./CartNavBarButton";
import { CustomerInfo } from "@/app/_types/customer.interface";
// import CartNavBarButton from "./CartNavBarButton";
// import { getServerSession } from "next-auth";
// import { authOptions } from "@/app/lib/next-auth";

function CartNavBar() {
  // const data = await getServerSession(authOptions);
  const client = useQueryClient();
  const customerInfo = client.getQueryData<CustomerInfo>(["currentCustomer"]);

  return (
    <nav className="navbars relative">
      <div className="fixed w-full z-50 bg-white">
      <ol className=" h-full flex items-center px-2 md:px-4 w-full">
        <li className="flex items-center w-fit h-[inherit] p-0">
          <Logo />
        </li>
        {/* for handling store and cart button in mobile and desktop */}
        <li>
          <div className="text-sm ml-4 md:ml-8 p-2 bg-gray-200 rounded-full">
            <SelectStore />
          </div>
        </li>
        <ol className="md:child:p-2 flex-1 h-[inherit] flex items-center gap-2">
          <CartNavBarButton session={customerInfo} />
        </ol>
      </ol>
      </div>
    </nav>
  );
}

export default CartNavBar;
