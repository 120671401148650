import { AddMenuItemsForm } from "@/app/_types/menuDetails";
import { fixedDecimal } from "@/app/utils/DecimalAndNumbers";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

function MenuDetailsTotalPrice() {
  const { control, setValue } = useFormContext<AddMenuItemsForm>();

  const watchConfig = useWatch({
    control,
    name: "config",
  });

  const watchTotalPrice =
    useWatch({
      control,
      name: "basePrice",
    }) || 0;

  const watchSizePrice =
    useWatch({
      control,
      name: "sizePrice",
    }) || 0;

  const watchData = useWatch({
    control,
    name: "data",
  });

  const watchQty = useWatch({
    control,
    name: "qty",
  });

  console.log("menu qty watch", watchQty);

  const getAllPrice = watchData?.reduce((acc, current) => {
    const currentPrice =
      (current?.comboPrice || 0) + (current?.ingredientPrice || 0);
    return acc + currentPrice;
  }, 0);
  const getAllPriceConfig = watchConfig?.isSplit
    ? getAllPrice / 2
    : getAllPrice;

  const totalPrice = watchTotalPrice + watchSizePrice + getAllPriceConfig;

  useEffect(() => {
    setValue("totalPrice", totalPrice);
  }, [totalPrice, setValue]);

  return (
    <p className="font-bold pl-4 text-2xl">
      $ {fixedDecimal(totalPrice * watchQty)}
    </p>
  );
}

export default MenuDetailsTotalPrice;
