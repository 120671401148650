// import jwt from "jsonwebtoken";

import { DealDetailsInCart } from "@/app/_types/menuDeals.interface";
import { AddMenuItemsForm } from "@/app/_types/menuDetails";

export function GETCART(tokenString: string):
  | {
      itemList: AddMenuItemsForm[];
      dealList: DealDetailsInCart[];
      location: string;
      status: 200;
    }
  | {
      message: string;
      status: 403;
    } {
  const { token: cartToken }: { token: string } = { token: tokenString };

  if (cartToken) {
    try {
      // const allItems = jwt.verify(
      //   cartToken,
      //   import.meta.env.VITE_CART_TOKEN as string
      // );

      const allItems = JSON.parse(cartToken);
      console.log("token => ", allItems);
      if (typeof allItems === "object") {
        return {
          itemList: allItems?.itemList || [],
          dealList: allItems?.dealList || [],
          location: allItems?.location,
          status: 200,
        };

        // return NextResponse.json({
        //   itemList: allItems?.itemList,
        //   dealList: allItems?.dealList,
        //   location: allItems?.location,
        // });
      } else {
        return {
          message: "Something went wrong",
          status: 403,
        };

        // return NextResponse.json(
        //   {
        //     message: "Something went wrong",
        //   },
        //   { status: 403 }
        // );
      }
    } catch (error) {
      console.log("err => ", error);
      return {
        message: "Something went wrong",
        status: 403,
      };

      // return NextResponse.json(
      //   { message: "Something went wrong" },
      //   { status: 403 }
      // );
    }
  } else {
    return {
      message: "Something went wrong",
      status: 403,
    };

    // return NextResponse.json(
    //   { message: "Something went wrong" },
    //   { status: 403 }
    // );
  }
}
