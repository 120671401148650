import CheckoutUserInformation from "./CheckoutUserInformation";
import CheckoutLogin from "./CheckoutLogin";
import { CustomerInfo } from "@/app/_types/customer.interface";

interface CheckoutUserPreference {
  customer: CustomerInfo | null;
}

function CheckoutUserPreference({ customer }: CheckoutUserPreference) {
  if (!customer?.firstName) {
    return <CheckoutLogin />;
  }
  return (
    <>
      <CheckoutUserInformation />
    </>
  );
}

export default CheckoutUserPreference;
