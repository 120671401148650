import { useState } from "react";
import LoginModal from "@/app/auth/login/components/LoginModal";
import UserDetailsNavItems from "./UserDetailsNavItems";
import AddToCartButton from "@/app/[location]/components/AddToCart/AddtoCartButton";
import { CustomerInfo } from "@/app/_types/customer.interface";
// import { Link, useLocation } from "react-router-dom";

function CartNavBarButton({ session }: { session?: CustomerInfo }) {
  // const location = useLocation();

  const [open, setOpen] = useState<{
    openModal: boolean;
    status: "LOGIN" | "REGISTER";
  }>({
    openModal: false,
    status: "LOGIN",
  });

  // const openModal = (status: "LOGIN" | "REGISTER") => {
  //   setOpen({
  //     openModal: true,
  //     status,
  //   });
  // };

  const handleClose = () => {
    setOpen({
      openModal: false,
      status: "LOGIN",
    });
  };

  return (
    <>
      <li className="flex-1 flex items-end justify-end">
        {/* <SearchProductBar /> */}
      </li>
      <li>
        <AddToCartButton />
      </li>
      {session?.firstName ? (
        <UserDetailsNavItems customerDetails={session} />
      ) : (
        <>
          {/* <li className="text-sm">
            <Link
              to={{
                pathname: "/auth/login",
                search: `?redirect=${location?.pathname}`,
              }}
              className="border-2 rounded-lg h-full px-3 py-2"
            >
              Login
            </Link>
          </li>
          <li className="text-md font-bold hidden md:block">
            <Link
              to={{
                pathname: "/auth/register",
                search: `?redirect=${location?.pathname}`,
              }}
            >
              Sign Up
            </Link>
          </li> */}
        </>
      )}

      {open?.openModal && (
        <LoginModal
          status={open?.status}
          open={open?.openModal}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default CartNavBarButton;
