import { Deals } from "@/app/_types/menuWithCategories";
import { Link } from "react-router-dom";

import MenuDealItem from "./MenuDealItem";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { CalculateMenuTradingPeriod } from "../MenuTradingPeriods/CalculateTradingPeriod";
import moment from "moment";

interface MenuDealLinkProps {
  status: boolean;
  deal: Deals;
  params: string;
  tradingPeriodId: string;
}

function MenuDealLink({
  status,
  deal,
  params,
  tradingPeriodId,
}: MenuDealLinkProps) {
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);
  const { schedule, reActivationTimes } = useSelector(order);

  if (!storeDT) return;

  if (!deal?.tradingHours) return;

  const selectedTime = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();

  const tradingHoursData = deal?.tradingHours;

  const ItemTradingPeriodAvailable = CalculateMenuTradingPeriod({
    selectedOrderStatus,
    tradingHoursData,
    tradingPeriodId,
    selectedTime,
    selectedDay,
    storeDT,
  });

  return (
    <Link
      to={
        !status ||
        !ItemTradingPeriodAvailable?.status ||
        deal?.visibilityStatus === "OUT_OF_STOCK" ||
        !reActivationTimes
          ? "#"
          : `/${params}/deal/${tradingPeriodId}/${deal?.id}`
      }
      className={`${
        deal?.visibilityStatus === "HIDDEN" ||
        deal?.visibilityStatus === "HIDE_FOR_APP_AND_WEB"
          ? "hidden"
          : ""
      }`}
      // onClick={() => {
      //   window.scrollTo(0, 0); // Scroll to the top
      // }}
    >
      <MenuDealItem
        item={deal}
        status={status}
        dealStatus={ItemTradingPeriodAvailable?.status}
        reActivationTimes={reActivationTimes}
      />
    </Link>
  );
}

export default MenuDealLink;
