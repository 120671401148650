import { MenuHalfHalfItemShow } from "@/app/[location]/menu/components/AddMenuHalfAndHalf/AddMenuHalfAndHalfItem";
import {
  IndividualDealOrderItems,
  MenuDealForm,
} from "@/app/_types/menuDeals.interface";
import { useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormMenuDataProvider } from "../MenuDealEditMenu";

function MenuDealEditHalfHalf() {
  const { control } = useFormContext<MenuDealForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const selectedMenu = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.menus`,
  });

  return (
    <div className="bg-gray-200 px-4 pt-2 pb-16">
      <h2 className="text-lg font-extrabold mb-2">Choose Your Half and Half</h2>
      <div className="flex flex-col gap-2">
        {selectedMenu?.map((data, index) => (
          <DealMenuHalfAndHalfItem data={data} index={index} key={index} />
        ))}
      </div>
    </div>
  );
}

function DealMenuHalfAndHalfItem({
  data,
  index,
}: {
  data: IndividualDealOrderItems;
  index: number;
}) {
  const { control, setValue } = useFormContext<MenuDealForm>();

  const DataField = useContext(FormMenuDataProvider);
  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const isSplit = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.isSplit`,
  });

  const isRemove = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.isRemove`,
  });

  const ChangeMenuDetailsHandler = () => {
    setValue(`menuDeal.${selectedIndex}.config.selectedMenuIndex`, index);
    setValue(`menuDeal.${selectedIndex}.config.canAddToCart`, false);
    setValue(`config.isMenuHalfHalf`, true);
  };

  const RemoveItemHandler = () => {
    DataField?.remove(index);
    setValue(`menuDeal.${selectedIndex}.config.selectedMenuIndex`, 0);
    setValue(`menuDeal.${selectedIndex}.config.isSplit`, false);
    setValue(`menuDeal.${selectedIndex}.config.canAddToCart`, true);
    setValue(`menuDeal.${selectedIndex}.config.isRemove`, false);
  };

  return (
    <div className="bg-white rounded-md flex flex-col justify-start  gap-2 relative">
      {isRemove && (
        <div className="bg-black/70 text-white absolute inset-0 flex">
          <button
            className="gap-2 flex items-center justify-center h-full flex-1"
            onClick={RemoveItemHandler}
          >
            <img
              src="/images/icons/pajamasRemove.svg"
              width="15"
              height="15"
              alt="x"
            />

            <p>Remove Item</p>
          </button>
        </div>
      )}

      <button
        className={`flex items-center justify-between p-4`}
        onClick={ChangeMenuDetailsHandler}
      >
        <MenuHalfHalfItemShow data={data} isSplit={isSplit} />
      </button>
    </div>
  );
}
export default MenuDealEditHalfHalf;
