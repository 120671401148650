import ErrorAstrick from "@/app/global/elements/ErrorAstrick";

import { useFormContext } from "react-hook-form";
import { RegisterFormInterface } from "./RegisterForm";
import { countriesCode } from "../../login/countryCode";
import { twMerge } from "tailwind-merge";
import toast from "react-hot-toast";

interface RegisterOTPForm {
    handleCountryChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    selectedCountry: {
        iso: string;
        code: string;
    },
    isLoading: boolean
    changeStatus?(status: "LOGIN" | "REGISTER"): void;
    setTimer: React.Dispatch<React.SetStateAction<number>>
    setStep: React.Dispatch<React.SetStateAction<number>>
    requestOTP: (phoneNo: string, countryCode: string) => Promise<unknown>
}



const RegisterOTP = ({handleCountryChange, selectedCountry, isLoading, changeStatus, setTimer, setStep, requestOTP}: RegisterOTPForm) => {
  const { handleSubmit, register, formState: { errors }, setValue, clearErrors } = useFormContext<RegisterFormInterface>();

  const OTPCheck = async (data: RegisterFormInterface) => {
    console.log(data);

    if (!data?.phoneNo) {
      return toast.error("Enter Phone Number");
    }

    // const phoneNo = selectedCountry?.code + data?.phoneNo;
    const phoneNo = data?.phoneNo;
    const responseData = await requestOTP(phoneNo, selectedCountry?.code);
    console.log(responseData);
    if (responseData !== null) {
      console.log(responseData);
      if (responseData === false) {
        toast.error("Phone Number is Already Register");
        if (changeStatus) {
          changeStatus("LOGIN");
        }
      } else {
        setTimer(30);
        toast.success("OTP sent to the Phone Number");
        setStep(2);
      }
    }
  };

  return (
    <>
      <div className="form-div">
        <label htmlFor="phoneNo" className="form-label">
          Phone No
          {errors?.phoneNo && <ErrorAstrick />}
        </label>
        {errors.phoneNo && (
          <p className="text-red-500 text-sm">{errors?.phoneNo?.message}</p>
        )}
        <div className="flex gap-2">
          <select
            value={selectedCountry?.iso}
            onChange={(e) => handleCountryChange(e)}
            className="form-select"
            style={{ width: "150px" }}
          >
            {countriesCode?.map((country, index) => (
              <option key={index} value={country?.iso}>
                {country?.iso} ({country?.code})
              </option>
            ))}
          </select>
          <input
            type="tel"
            pattern="[0-9]*"
            id="phoneNo"
            maxLength={16}
            {...register("phoneNo", {
              required: "Phone number is required",
            })}
            className={`form-input w-full`}
            onChange={(e) => {
              setValue("phoneNo", e.target.value);
              clearErrors();
            }}
          />
        </div>
      </div>
      <div className="flex justify-end items-end my-4">
        <button
          className={twMerge(
            "form-btn flex gap-2 items-center",
            isLoading && "bg-gray-300"
          )}
          // type="button"
          onClick={handleSubmit(OTPCheck)}
          disabled={isLoading}
        >
          {isLoading && (
            <div className="animate-spin w-5 h-5 rounded-full border-4 border-black" />
          )}
          Continue
        </button>
      </div>
    </>
  );
};

export default RegisterOTP;
