import CheckoutUserAddress from "./CheckoutUserDetails/CheckoutUserAddress";
import CheckoutUserPersonalDetails from "./CheckoutUserDetails/CheckoutUserPersonalDetails";

function CheckoutUserInformation() {
  return (
    <div>
      <div className="flex flex-col gap-2">
        <CheckoutUserAddress />
        <CheckoutUserPersonalDetails />
      </div>
    </div>
  );
}

export default CheckoutUserInformation;
