import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";
import {
  AddMenuItemsForm,
  ManageExtrasIngredient,
} from "@/app/_types/menuDetails";
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import toast from "react-hot-toast";

type AddMenuFieldArray = UseFieldArrayReturn<
  AddMenuItemsForm,
  `data.${number}.ingredients`,
  `fieldId${number}`
>;

type AddMenuGetValues = UseFormGetValues<AddMenuItemsForm>;
type AddMenuSetValue = UseFormSetValue<AddMenuItemsForm>;

const updateTotalPriceOfMenuIngredient = (
  getValues: AddMenuGetValues,
  setValue: AddMenuSetValue
) => {
  const watchConfigSelected = getValues("config.selectedIndex");

  const getIngredient = getValues(`data.${watchConfigSelected}.ingredients`);
  const ingredientTotal = getIngredient?.reduce(
    (acc, current) => (current?.total || 0) + acc,
    0
  );

  setValue(`data.${watchConfigSelected}.ingredientPrice`, ingredientTotal);
};

export const AddMenuIngredientHandler = (
  fieldArray: AddMenuFieldArray,
  getValues: AddMenuGetValues,
  setValue: AddMenuSetValue,
  item: ManageExtrasIngredient,
  optionGroups: OptionGroup[]
) => {
  console.log("AddMenuIngredientHandler", item, optionGroups);
  const getSizes = getValues("size");
  let distinctQuantity = 0;
  const checkIngredient = fieldArray?.fields?.find(
    (obj) => obj?.id === item?.id
  );

  const option = optionGroups.find(
    (option) => option?.id === item?.optionGroupId
  );

  const TopinsBaseIngredient = fieldArray?.fields?.filter((ingredient) => {
    return ingredient?.optionGroupId === item?.optionGroupId;
  });

  console.log(TopinsBaseIngredient);

  const TotalDistinctSelectable = () => {
    if (option?.distinctSelectableOptions === 0) {
      console.log(option?.distinctSelectableOptions, option?.options?.length);
      return option?.options?.length;
    } else {
      return option?.distinctSelectableOptions;
    }
  };

  const SelectableDistincts = TotalDistinctSelectable();

  console.log(TopinsBaseIngredient);

  TopinsBaseIngredient.forEach((ingredient) => {
    if (ingredient?.qty > 0) {
      distinctQuantity++;
      console.log(
        distinctQuantity,
        option?.distinctSelectableOptions,
        ingredient,
        fieldArray
      );
    }
  });

  const ingPriceonSize: number =
    item?.menuSizes?.find((size) => size?.id === getSizes)?.extraPrice || 0;

  if (option) {
    if (!checkTotalSelectableOptions(TopinsBaseIngredient, option)) {
      return;
    }
  }

  if (!checkIngredient) {
    if (SelectableDistincts && distinctQuantity < SelectableDistincts) {
      fieldArray?.append({
        price: ingPriceonSize,
        qty: 1,
        name: item?.name,
        id: item?.id,
        status: "added",
        total: ingPriceonSize,
        menuSizes: item?.menuSizes,
        isSingle: item?.isSingle,
        optionGroupId: item?.optionGroupId,
      });
    } else {
      toast.error(`Only ${SelectableDistincts} ingredients allow`);
      return;
    }
  } else {
    const ingredientIndex = fieldArray?.fields?.findIndex(
      (obj) => obj?.id === item?.id
    );
    const ingredientQty = checkIngredient?.qty;
    if (item?.isSingle && ingredientQty > 0) {
      return toast.error("Ingredient can only be added once");
    }
    // current status case
    if (checkIngredient?.status === "current") {
      if (
        checkIngredient?.qty === 0 &&
        SelectableDistincts &&
        distinctQuantity < SelectableDistincts
      ) {
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          price: ingPriceonSize,
          qty: ingredientQty + 1,
          total: 0,
        });
      }
      // current status - greater than 0
      if (checkIngredient?.qty > 0) {
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          price: ingPriceonSize,
          qty: ingredientQty + 1,
          total: ingredientQty * ingPriceonSize,
        });
      }
      // added ingredient
    } else {
      fieldArray?.update(ingredientIndex, {
        ...checkIngredient,
        price: ingPriceonSize,
        qty: ingredientQty + 1,
        total: (ingredientQty + 1) * ingPriceonSize,
      });
    }
  }
  updateTotalPriceOfMenuIngredient(getValues, setValue);
};

const checkTotalSelectableOptions = (
  TopinsBaseIngredient: FieldArrayWithId<
    AddMenuItemsForm,
    `data.${number}.ingredients`,
    `fieldId${number}`
  >[],
  option: OptionGroup
) => {
  let totalQuantity = 0;
  TopinsBaseIngredient?.forEach((ingredient) => {
    if (ingredient?.qty > 0) {
      totalQuantity += ingredient?.qty;
    }
  });

  const totalSelectableOptions = () => {
    if (option?.totalSelectableOptions === 0) {
      return option?.options?.length * 10;
    } else {
      return option?.totalSelectableOptions;
    }
  };

  const selectableOptions = totalSelectableOptions();

  if (selectableOptions && totalQuantity >= selectableOptions) {
    toast.error("Total selectable options exceeded.");
    return false;
  }

  return true;
};

export const RemoveMenuIngredientsHandler = (
  fieldArray: AddMenuFieldArray,
  getValues: AddMenuGetValues,
  setValue: AddMenuSetValue,
  item: ManageExtrasIngredient
) => {
  const getSizes = getValues("size");

  // finding ing from available ing
  const checkIngredient = fieldArray?.fields?.find(
    (obj) => obj?.id === item?.id
  );
  
  console.log(getSizes)
  console.log(item)
  const ingPriceonSize: number =
    item?.menuSizes?.find((size) => size?.sizeId === getSizes)?.extraPrice || 0;

  console.log(ingPriceonSize)

  if (checkIngredient) {
    const ingredientQty = checkIngredient?.qty;
    const ingredientIndex = fieldArray?.fields?.findIndex(
      (obj) => obj?.id === item?.id
    );
    // for current ing
    if (checkIngredient?.status === "current") {
      // current ing - qty 0 - do nothing
      if (ingredientQty === 0) {
        return;
      }
      if (ingredientQty === 1) {
        // current ing - qty 1 - make qty 0 and total price 0
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          price: ingPriceonSize,
          qty: ingredientQty - 1,
          total: 0,
        });
      } else {
        // reason for -2 is that current ingredient has already 1 ing by default which price is 0 so its basically - 1 for current ing and -1 for actually removing it
        // current ing - qty remove and calculate total ( reason for -2 is that in default we have 1 which doesn't have price)
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          price: ingPriceonSize,
          qty: ingredientQty - 1,
          // total: (ingredientQty - 2) * (checkIngredient?.price || 0),
          total: (ingredientQty - 2) * (ingPriceonSize || 0),
        });
      }
      // for added ingredient
    } else {
      if (ingredientQty > 1) {
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          price: ingPriceonSize,
          qty: ingredientQty - 1,
          // total: (ingredientQty - 1) * (checkIngredient?.price || 0),
          total: (ingredientQty - 1) * (ingPriceonSize || 0),
        });
      } else {
        fieldArray?.remove(ingredientIndex);
      }
    }
  }
  updateTotalPriceOfMenuIngredient(getValues, setValue);
};
