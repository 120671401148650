

interface ManageLoadingClient {
  isLoading?: boolean;
  children: React.ReactNode;
  loadingProgress: React.ReactNode;
}

function ManageLoadingClient({
  isLoading = false,
  children,
  loadingProgress,
}: ManageLoadingClient) {
  return <>{isLoading ? <>{loadingProgress}</> : <>{children}</>}</>;
}

export default ManageLoadingClient;
