import { createContext, useContext, useEffect, useState } from "react";
import {
  UseFieldArrayReturn,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";

import { OfferSelectItems } from "../MenuOfferSelectMenu/MenuOfferSelectMenu";
import { useSelector } from "react-redux";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import toast from "react-hot-toast";
import MenuOfferEditLogic from "./MenuOfferEditLogic";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { serverPath } from "@/app/_axios/path";
import fetchRequest from "@/app/_axios/fetchRequest";
import { MenuDetailsEntity } from "@/app/_types/menuDetails";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import { OfferMenuIngredientProvider } from "../../../context";
import {
  IndividualOfferOrderItems,
  MenuOfferForm,
  OfferCategoryMenu,
} from "@/app/_types/menuOffers.interface";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "@/app/[location]/menu/utils/sizeChangeUtils";
import { findFinalNumber } from "@/app/[location]/deal/[periodId]/[name]/components/MenuDealForms/MenuDealEditMenu/MenuDealEditMenu";

type FieldName = `menuOffer.${number}.menus.${number}.ingredients`;

export const FormMenuDataProvider = createContext<
  | UseFieldArrayReturn<MenuOfferForm, `menuOffer.${number}.menus`, `keyId`>
  | undefined
>(undefined);

function MenuDealEditMenu() {
  const { control, setValue, getValues } = useFormContext<MenuOfferForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedOfferIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.config.selectedMenuIndex`,
  });

  const menuDetails = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.menus.${selectedMenuIndex}`,
  });

  const fieldName: FieldName =
    `menuOffer.${selectedIndex}.menus.${selectedMenuIndex}.ingredients` as const;

  const fieldIngredients = useFieldArray({
    control,
    name: fieldName,
    keyName: `fieldId`,
  });

  const dataFieldArray = useFieldArray({
    control,
    name: `menuOffer.${selectedIndex}.menus`,
    keyName: `keyId`,
  });

  const { replace } = fieldIngredients;

  useEffect(() => {
    const values =
      getValues(
        `menuOffer.${selectedIndex}.menus.${selectedMenuIndex}.ingredients`
      ) || [];
    replace(values);
  }, [getValues, replace, selectedIndex, selectedMenuIndex, setValue]);

  if (!menuDetails) return <p>No Selected Menu Found. Please Try Again</p>;
  return (
    <FormMenuDataProvider.Provider value={dataFieldArray}>
      <OfferMenuIngredientProvider.Provider value={fieldIngredients}>
        <div>
          <ButtonManager hasHalfHalf={menuDetails?.hasHalfHalf} />
          <MenuOfferEditLogic />
        </div>
      </OfferMenuIngredientProvider.Provider>
    </FormMenuDataProvider.Provider>
  );
}

function ButtonManager({ hasHalfHalf }: { hasHalfHalf: boolean }) {
  return (
    <div className="flex py-2  gap-4 items-center mr-4">
      <ChangeMenuButton />
      {hasHalfHalf && <HalfHalfButton />}
    </div>
  );
}

function ChangeMenuButton() {
  const { control, setValue } = useFormContext<MenuOfferForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedOfferIndex`,
  });

  const watchConfig = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.config`,
  });

  const ChangeMenuHandler = () => {
    setValue(`config.selectedPage`, "SELECT_MENU");
  };

  if (watchConfig?.isSplit && watchConfig?.canAddToCart) return null;
  return (
    <button
      className=" text-primary underline  ml-auto flex"
      onClick={ChangeMenuHandler}
    >
      Change Menu
    </button>
  );
}

function HalfHalfButton() {
  const [open, setOpen] = useState(false);
  const [dealCategories, setDealCategories] = useState<OfferCategoryMenu[]>([]);
  const { offerDetails } = useSelector(order);
  const { selectedStore } = useSelector(selectStore);
  const { control, setValue, getValues } = useFormContext<MenuOfferForm>();

  const fieldData = useContext(FormMenuDataProvider);

  const selectedIndex = useWatch({
    control,
    name: `config.selectedOfferIndex`,
  });

  console.log({ selectedIndex });

  const watchConfig = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.config`,
  });

  const watchData = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.menus`,
  });

  const periodId = useWatch({
    control,
    name: `periodId`,
  });

  const isDataValid = watchData
    ?.map((data) => (data?.id ? data?.id : null))
    .includes(null);

  // const selectedDealId = useWatch({
  //   control,
  //   name: `menuDeal.${selectedIndex}.dealId`,
  // });

  const ChangeHalfAndHalf = () => {

    const isValid = () => {
      const isTrueList: boolean[] = [];
      watchData?.forEach((item) => {
        const comboList = item?.optionGroups;
        comboList?.forEach((combos) => {
          const isMandatory = combos?.isMandatory;
          if (isMandatory) {
            const isComboValid = combos?.options?.some(
              (option) => option?.qty > 0
            );

            if (isComboValid) {
              isTrueList.push(true);
            } else {
              isTrueList.push(false);
            }
          } else {
            isTrueList.push(true);
          }
        });
      });
      const checkIsTrueList = isTrueList.every((item) => item === true);
      return checkIsTrueList;
    };
    const isComboValid = isValid();

    if (!isComboValid) {
      toast.error("Please select at least one item from the required combo");
      return;
    }

    const getDealId = getValues(`menuOffer.${selectedIndex}.offerCategoryId`);
    const dealCategories = offerDetails?.offerCategories?.find(
      (el) => el?.id === getDealId
    );
    if (!dealCategories) {
      toast.error(
        "Something Went Wrong while getting Deals. Please Try Again."
      );
    }
    const halfDealCategories = dealCategories?.offerCategoryMenus?.filter(
      (el) => el?.hasHalfHalf
    );
    setDealCategories(halfDealCategories || []);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectedMenu = async (menu: OfferCategoryMenu) => {
    if (!selectedStore?.id) {
      return toast.error("Something went wrong. Please Try Again.");
    }

    // getting selected menu information
    const [PromiseMenuDetails, PromiseExtraPriceInfo] =
      await Promise.allSettled([
        fetchRequest(
          serverPath.getMenuDetails(selectedStore?.id, menu?.menuId, periodId),
          "GET",
          undefined,
          { cache: "no-store" }
        ),
        fetchRequest(
          serverPath.getExtraPriceInfo(selectedStore?.id),
          "GET",
          undefined
        ),
      ]);

    if (PromiseMenuDetails.status === "rejected")
      return toast.error("Cannot get Menu Details. Please Try Again");
    if (PromiseExtraPriceInfo.status === "rejected")
      return toast.error("Error While getting Extra Price Info");

    const menuDetails: MenuDetailsEntity = PromiseMenuDetails?.value;
    const priceInfo: ExtraPriceInfo = PromiseExtraPriceInfo?.value;

    const selectedDealIndex = getValues("config.selectedOfferIndex");
    const sizeId = getValues(`menuOffer.${selectedDealIndex}.size`);

    const menuSizeId = menuDetails?.menuSizes?.find(
      (size) => size?.sizeId === sizeId
    )?.id;

    if (!menuSizeId)
      return toast.error("Menu Size Not Found. Please Try Again");

    const comboList = ManageComboPayInitial(
      menuDetails?.menuOptionGroups || [],
      menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
    );

    const comboPrices = getIndividualMenuComboPrice(comboList);

    const menuData: IndividualOfferOrderItems = {
      id: menuDetails?.menu?.id,
      menuSizeId,
      finalPrice: menu?.finalPrice,
      hasExtras: menu?.hasExtras,
      name: menuDetails?.menu?.name,
      hasHalfHalf: menuDetails?.menu?.hasHalfAndHalf,
      basePrice: menuDetails?.menu?.basePrice,
      sizeGroupId: menuDetails?.menu?.sizeGroupId,
      specialPrice: menu?.finalPrice || 0,
      comboPrice: comboPrices,
      ingredientPrice: 0,
      optionGroups: comboList,
      ingredients: menuDetails?.menuOptions,
      tradingHours: menu?.tradingHours,
      menuSizeCount: menu?.menuSizeCount
    };

    fieldData?.append(menuData);

    const getMenus = getValues(`menuOffer.${selectedIndex}.menus`);
    const sizeGroup = priceInfo?.sizeGroups?.find(
      (el) => el?.id === menu?.sizeGroupId
    );

    const additionalPrice =
      sizeGroup?.halfHalfSizes?.find((el) => el?.sizeId === sizeId)
        ?.additionalPrice || 0;

    const halfHalfStatus = sizeGroup?.halfHalfType || "AVERAGE";

    const menuDiscount = getValues(`menuOffer.${selectedIndex}.discount`) || 0;

    const firstSpecialPrice = getMenus?.[0]?.finalPrice || 0;
    const secondSpecialPrice = getMenus?.[1]?.finalPrice || 0;
    if (firstSpecialPrice > 0 || secondSpecialPrice > 0) {
      const finalAmount = findFinalNumber(
        firstSpecialPrice,
        secondSpecialPrice,
        halfHalfStatus
      );

      
      
      const finalDiscountAmount = (menuDiscount / 100) * finalAmount;
      const finalPrice = finalAmount - finalDiscountAmount + additionalPrice;

      setValue(
        `menuOffer.${selectedIndex}.menusHalfHalfPrice`,
        finalPrice || 0
      );
    }

    setValue(`menuOffer.${selectedIndex}.config.isSplit`, true);
    setValue(`menuOffer.${selectedIndex}.config.canAddToCart`, false);
    setValue(`menuOffer.${selectedIndex}.config.selectedMenuIndex`, 1);
    // setValue(`config.selectedPage`, "EDIT_MENU");

    setValue(`config.isMenuHalfHalf`, true);
    setOpen(false);
    // setValue(`menuDeal.${selectedIndex}.config.isSplit`, true);
  };

  const RemoveItemHandler = () => {
    if (watchConfig?.isRemove) {
      return setValue(`menuOffer.${selectedIndex}.config.isRemove`, false);
    }

    setValue(`menuOffer.${selectedIndex}.config.isRemove`, true);
  };

  return (
    <>
      {watchConfig?.isSplit ? (
        <>
          {watchConfig?.canAddToCart ? (
            <button
              className="p-1  text-red-500 rounded-md flex  underline ml-auto"
              onClick={RemoveItemHandler}
            >
              {watchConfig?.isRemove || isDataValid
                ? "Go Back"
                : "Remove Items"}
            </button>
          ) : null}
        </>
      ) : (
        <button
          className="p-2 bg-slate-500 text-white rounded-md flex"
          onClick={ChangeHalfAndHalf}
        >
          Half / Half
        </button>
      )}
      {/* <button
          className="p-2 bg-slate-500 text-white rounded-md flex "
          onClick={ChangeHalfAndHalf}
        >
          Half / Half
        </button> */}
      {open ? (
        <DealHalfHalfModal
          open={open}
          handleClose={handleClose}
          offerCategoriesList={dealCategories}
          onSelectedMenu={onSelectedMenu}
        />
      ) : null}
    </>
  );
}

function DealHalfHalfModal({
  open,
  handleClose,
  offerCategoriesList,
  onSelectedMenu,
}: {
  open: boolean;
  handleClose(): void;
  offerCategoriesList: OfferCategoryMenu[];
  onSelectedMenu: (menu: OfferCategoryMenu, index: number) => void;
}) {
  return (
    <CustomModalClients
      open={open}
      handleClose={handleClose}
      className="modal-white-parallel"
      scrollDisable={true}
      disableCloseOnOverlay={true}
    >
      <div className="flex justify-between items-center p-4 md:p-6 border-b-2">
        <h1 className="font-bold text-xl ">Select Your Other Half </h1>
        <button
          onClick={handleClose}
          // className={twMerge("lg:hidden", !isModal && "hidden")}
        >
          <img
            src="/images/icons/close.svg"
            width={25}
            height={25}
            alt="close"
          />
        </button>
      </div>
      <OfferSelectItems
        offerCategoriesList={offerCategoriesList || []}
        onSelectedMenu={onSelectedMenu}
      />
    </CustomModalClients>
  );
}

export default MenuDealEditMenu;
