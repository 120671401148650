import { CalculateMenuTradingPeriod } from "@/app/[location]/components/MenuTradingPeriods/CalculateTradingPeriod";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import {
  DealCategoryMenu,
  MenuDealForm,
} from "@/app/_types/menuDeals.interface";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import { formatString } from "@/app/utils/formatString";
import moment from "moment";


import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

interface MenuDealSelectMenuItemCardProps {
  menu: DealCategoryMenu;
  onSelectedMenu: (menu: DealCategoryMenu, index: number) => void;
  index: number;
}

function MenuDealSelectMenuItemCard({
  menu,
  onSelectedMenu,
  index,
}: MenuDealSelectMenuItemCardProps) {
  // this comment code should be inside MenuDealSelectMenuItemCard component
  const { control } = useFormContext<MenuDealForm>();

  const tradingPeriodId = useWatch({
    control,
    name: `periodId`,
  });

  const { schedule } = useSelector(order);
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);

  if (!storeDT) return toast.error("No store Time");
  const selectedTime = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();

  const tradingHoursData: TradingHour[] = menu?.tradingHours;

  const CheckTradingPeriod = CalculateMenuTradingPeriod({
    tradingPeriodId,
    tradingHoursData,
    selectedOrderStatus,
    selectedTime,
    selectedDay,
    storeDT,
  });

  console.log(CheckTradingPeriod);

  return (
    <button
      onClick={() => onSelectedMenu(menu, index)}
      disabled={!CheckTradingPeriod?.status}
    >
      <div
        className={` 
  shadow-[1.95px_1.95px_2.6px_rgba(0,0,0,0.06)]  
  cursor-pointer pb-3 flex flex-col rounded-md relative `}
      >
        {menu?.visibilityStatus === "OUT_OF_STOCK" && (
          <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
            Out of Stock
          </p>
        )}
        {menu?.visibilityStatus !== "OUT_OF_STOCK" &&
          !CheckTradingPeriod?.status && (
            <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
              Not Available
            </p>
          )}
        {menu?.thumbnailUrl ? (
          <div>
            <img
              src={menu?.thumbnailUrl}
              width={100}
              height={100}
              alt={menu?.menuName}
              className="object-cover object-center h-20  md:h-30  min-w-full rounded-t-md"
            />
          </div>
        ) : (
          <div>
            <img
              src="/images/icons/noMenuImage.jpg"
              width={100}
              height={100}
              alt={menu?.menuName}
              className="object-cover object-center h-20 md:h-30  min-w-full rounded-t-md"
            />
          </div>
        )}
        <div className="pt-1 pl-1 flex items-center justify-between">
          <h3 className="font-medium text-left">
            {formatString(menu?.menuName)}
          </h3>
          <p className="text-sm text-secondaryText-600 text-left pr-2">
            {menu?.specialPrice ? `+ $${menu?.specialPrice}` : null}
          </p>
        </div>
      </div>
    </button>
  );
}

export default MenuDealSelectMenuItemCard;
