import { MenuOption } from "@/app/_types/menuDetails";

import MenuDealEditOptionItem from "../MenuOfferEditOptionItem";
import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";

interface MenuDealAdditionalExtraOptionProps {
  menuOptions: MenuOption[];
  optionGroups: OptionGroup[];
}

function MenuDealAdditionalExtraOption({
  menuOptions,
  optionGroups,
}: MenuDealAdditionalExtraOptionProps) {
  const additionalItems = menuOptions?.filter(
    (item) => item?.status === "added"
  );

  return (
    <>
      {additionalItems?.length ? (
        <div className="px-4 py-2">
          <p className="text-lg font-bold">Additional Toppings</p>
          <div className="divide-y-2  ">
            {additionalItems?.map((option, index: number) => (
              <MenuDealEditOptionItem
                item={option}
                key={index}
                isExtraOption={true}
                optionGroups={optionGroups}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MenuDealAdditionalExtraOption;
