// convert 1234 345 678 to 1234356789
export function trimPhoneNumber(phone: string) {
  return phone.replace(/[^0-9]/g, "");
}

// convert 1234345678 to 1234 356 789
export function makeTwelveDigitPhone(phone: string) {
  if (!phone) return "";

  return phone.replace(/(\d{4})(\d{3})(\d{3})/g, "$1 $2 $3");
}
