import { useEffect, useMemo } from "react";
import MenuDetailsForm from "./MenuDetailsForm";
// import { MenuDetailsEntity } from "@/app/_types/menuDetails";
import MenuDetailsImage from "./MenuDetailsImage";
import { serverPath } from "@/app/_axios/path";
import NotFound404 from "@/app/global/NoData/NotFound404";
// import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
// import { StoreDTTypes } from "@/app/_redux/slice/UISlice/UISlice.d";
import { useQueries } from "@tanstack/react-query";
// import { MenuWithCategories } from "@/app/_types/menuWithCategories";
// import { TradingHour } from "@/app/_types/tradingHours.interface";
import MenuDetailsLoadingScreen from "../../components/MenuDetails/MenuDetailsLoadingScreen";
import moment from "moment";
// import { StoreEntity } from "@/app/_types/store.interface";
import { useAppDispatch } from "@/app/_redux/store";
import { addSelectedStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import {
  getTodayTradingHours,
  getTradingHourAction,
} from "@/app/_redux/slice/Order/OrderSlice";
import fetchRequest from "@/app/_axios/fetchRequest";

function MenuDetailsInformation({
  params,
  isModal,
}: {
  params: { name: string; location: string; periodId: string };
  isModal: boolean;
}) {
  const { location: storeId, name, periodId: tradingPeriodId } = params;
  const dispatch = useAppDispatch();

  // Queries with `params` included in queryKey
  const [
    categoriesWithMenuQuery,
    tradingHoursQuery,
    todayTradingHoursQuery,
    getMenuDetailsQuery,
    getExtraPriceInfoQuery,
    getStoreDTQuery,
    getStoreDetailsQuery,
  ] = useQueries({
    queries: [
      {
        queryKey: ["categoriesWithMenu", storeId, tradingPeriodId],
        queryFn: () =>
          fetchRequest(
            serverPath?.categoriesWithMenu(storeId, tradingPeriodId),
            "GET"
          ),
        enabled: !!storeId && !!tradingPeriodId,
      },
      {
        queryKey: ["tradingHours", storeId],
        queryFn: () =>
          fetchRequest(
            serverPath?.tradingHours(storeId),
            "GET"
          ),
        enabled: !!storeId,
      },
      {
        queryKey: ["todayTradingHours", storeId],
        queryFn: () =>
          fetchRequest(
            serverPath?.todayTradingHours(storeId),
            "GET"
          ),
        enabled: !!storeId,
      },
      {
        queryKey: ["getMenuDetails", storeId, name, tradingPeriodId],
        queryFn: () =>
          fetchRequest(
            serverPath?.getMenuDetails(storeId, name, tradingPeriodId),
            "GET"
          ),
        enabled: !!storeId && !!name && !!tradingPeriodId,
      },
      {
        queryKey: ["getExtraPriceInfo", storeId],
        queryFn: () =>
          fetchRequest(
            serverPath?.getExtraPriceInfo(storeId),
            "GET"
          ),
        enabled: !!storeId,
      },
      {
        queryKey: ["getStoreDT", storeId],
        queryFn: () =>
          fetchRequest(
            serverPath?.getStoreDT(storeId),
            "GET"
          ),
        enabled: !!storeId,
      },
      {
        queryKey: ["getStoreDetails", storeId],
        queryFn: () =>
          fetchRequest(
            serverPath?.getStoreEntityDetails(storeId),
            "GET"
          ),
        enabled: !!storeId,
      },
    ],
  });

  // Determine if any query is loading
  const isLoading = useMemo(() => {
    return [
      categoriesWithMenuQuery,
      tradingHoursQuery,
      todayTradingHoursQuery,
      getMenuDetailsQuery,
      getStoreDTQuery,
      getExtraPriceInfoQuery,
      getStoreDetailsQuery,
    ]?.some((query) => query?.isLoading);
  }, [
    categoriesWithMenuQuery,
    tradingHoursQuery,
    todayTradingHoursQuery,
    getMenuDetailsQuery,
    getStoreDTQuery,
    getExtraPriceInfoQuery,
    getStoreDetailsQuery,
  ]);

  // Update Redux store when data changes
  useEffect(() => {
    if (
      !getStoreDetailsQuery?.data ||
      !todayTradingHoursQuery?.data ||
      !tradingHoursQuery?.data
    )
      return;

    dispatch(addSelectedStore(getStoreDetailsQuery?.data));
    dispatch(getTodayTradingHours(todayTradingHoursQuery?.data));
    dispatch(getTradingHourAction(tradingHoursQuery?.data));
  }, [
    getStoreDetailsQuery?.data,
    tradingHoursQuery?.data,
    todayTradingHoursQuery?.data,
    dispatch,
  ]);

  // Handle loading state
  if (isLoading) return <MenuDetailsLoadingScreen />;

  // Handle errors or missing data
  if (
    getMenuDetailsQuery?.isError ||
    getExtraPriceInfoQuery?.isError ||
    !getMenuDetailsQuery?.data ||
    !getExtraPriceInfoQuery?.data
  )
    return <NotFound404 />;

  // Render MenuDetailsForm
  return (
    <MenuDetailsForm
      name={name}
      extraPrices={getExtraPriceInfoQuery?.data}
      menuDetails={getMenuDetailsQuery?.data}
      isModal={isModal}
      periodId={tradingPeriodId}
      storeDT={
        getStoreDTQuery?.data?.storeCurrentDt ||
        moment().format("YYYY-MM-DD HH:mm:ss")
      }
    >
      <MenuDetailsImage />
    </MenuDetailsForm>
  );
}

export default MenuDetailsInformation;
