import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import {
  order,
  removeDealList,
  removeOrderList,
} from "@/app/_redux/slice/Order/OrderSlice";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { useAppDispatch } from "@/app/_redux/store";
import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";
import { useElements, useStripe } from "@stripe/react-stripe-js";

import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  calculatScheduleAndStoreMinsDiff,
  calculateTotalAmount,
  checkOrderStatus,
} from "../../paymentLogic";
import { validateCustomerOrderType } from "./CheckoutPayLaterData";
import { clearOrderSuccessStorage } from "./CheckoutOrderPayment";

function CheckoutCardPaymentButton() {
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const { handleSubmit, setValue, control } =
    useFormContext<ConfirmOrderForm>();
  const dispatch = useAppDispatch();
  const { OrderList, OrderLocation, dealList, todayTradingHours, schedule } =
    useSelector(order);

  const { selectedOrderStatus, storeDT } = useSelector(selectUI);

  const allCartDetails = [...dealList, ...OrderList];

  const watchOrderFuture = useWatch({
    control,
    name: "config.isOrderForFuture",
  });

  const watchDeliveryOrderStatus = useWatch({
    control,
    name: `deliveryOrderStatus`,
  });

  const deliveryDistancePossible = useWatch({
    control,
    name: `deliveryOrderDistance`,
  });

  console.log(watchDeliveryOrderStatus);

  const stripe = useStripe();
  const elements = useElements();

  const ConfirmPayment = handleSubmit(async (data) => {
    const validateCustomer = validateCustomerOrderType(
      data?.orderType,
      data?.customer
    );

    if (!validateCustomer) {
      setValue("config.isUserInformationEdit", true);
      return;
    }

    const tradingStatus = checkOrderStatus({
      selectedOrderStatus,
      allCartDetails,
      dealList,
      todayTradingHours,
      storeDT,
      schedule,
    });

    if (!tradingStatus) return;

    if (!stripe || !elements) {
      return toast.error("Please Provide Card Details");
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      toast.error(submitError.message || "Please Enter Valid Card Details");
      return;
    }

    // const cardNumberElement = elements.getElement(CardNumberElement);
    // const cardExpiryElement = elements.getElement(CardExpiryElement);
    // const cardCvcElement = elements.getElement(CardCvcElement);

    // if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
    //   return toast.error("Please Provide Card Details");
    // }
    setIsPaymentLoading(true);
    const finalData = {
      appliedVouchers: data?.appliedVouchers,
      appliedPoints: data?.appliedPoints || 0,
      discountFromPoints: data?.discountFromPoints || 0,
      orderType: data?.orderType === "STORE" ? "PICKUP" : data?.orderType,
      storeId: OrderLocation?.id,
      paymentGatewayType: data?.paymentGatewayType,
      addressType: data?.addressType,
      // deals: dealList?.filter((el) => el?.cartType === "Deal"),
      deals: dealList
        ?.filter((el) => el?.cartType === "Deal")
        ?.map((deal) => {
          return {
            // ...deal,
            note: deal?.note,
            id: deal?.id,
            periodId: deal?.periodId,
            qty: deal?.qty,
            dealType: deal?.dealType,
            dealItems: deal?.dealItems?.map((els) => ({
              sizeId: els?.sizeId,
              sizeName: els?.sizeName,
              discount: els?.discount,
              config: els?.config,
              menus: els?.menus.map((menu) => ({
                id: menu?.id,
                menuSizeId: menu?.menuSizeId,
                name: menu?.name,
                optionGroups: menu?.optionGroups,
                ingredients: menu?.ingredients,
              })),
              dealCategoryId: els?.dealCategoryId,
            })),
            name: deal?.name,
            totalPrice: deal?.totalPrice * deal?.qty,
          };
        }),
      offers:
        dealList
          ?.filter((el) => el?.cartType === "Offer")
          ?.map((offer) => {
            return {
              // ...offer,
              note: offer?.note,
              totalPrice: offer?.totalPrice * offer?.qty,
              qty: offer?.qty,
              name: offer?.name,
              offerItems: offer?.dealItems?.map((els) => ({
                sizeId: els?.sizeId,
                sizeName: els?.sizeName,
                discount: els?.discount,
                config: els?.config,
                menus: els?.menus.map((menu) => ({
                  id: menu?.id,
                  menuSizeId: menu?.menuSizeId,
                  name: menu?.name,
                  optionGroups: menu?.optionGroups,
                  ingredients: menu?.ingredients,
                })),
                offerCategoryId: els?.dealCategoryId,
              })),
              id: offer?.id,
              periodId: offer?.periodId,
            };
          }) || [],
      customerOrderItems: OrderList?.map((order) => ({
        sizeId: order?.size,
        menus: order?.data,
        totalPrice: order?.totalPrice * order?.qty,
        qty: order?.qty,
        categoryId: order?.categoryId,
        categoryName: order?.categoryName,
        sizeName: order?.sizeName,
        config: order?.config,
        remove: order?.config?.isRemove,
        note: order?.note,
        // ...order,
      })),
      tradingPeriodId:
        OrderList.length > 0 ? OrderList[0]?.periodId : dealList[0]?.periodId,
      scheduledDay: schedule?.selectedDay?.day,
      scheduledDT: schedule?.selectedTime?.date,
      scheduledMins: calculatScheduleAndStoreMinsDiff({
        storeDT,
        scheduleDate: schedule?.selectedTime?.date,
      }),
      specialInstruction: data?.specialInstruction || undefined,
      totalAmount: calculateTotalAmount({ OrderList, dealList }),
      cardSurcharge: data?.cardSurcharge || 0,
      specialSurcharge: data?.specialSurcharge || 0,
    };
    const storages = localStorage.getItem("session");
    const authToken = storages ? JSON.parse(storages)?.acessToken : null;

    const response = await fetchRequest(
      serverPath.createOrder,
      "POST",
      finalData,
      {
        Authorization: `Bearer ${authToken}`,
        "Content-type": "application/json",
      },
      "res"
    );

    console.log("Card Payment response", response);
    console.log("hit place card success", response);

    if (response.status === 200) {
      const data = await response?.data;
      const clientSecret = data?.clientSecret;

      const returnUrl = import.meta.env.VITE_STRIPE_RETURN_URL + "/checkout";

      const result = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
        redirect: "if_required",
      });

      // const result = await stripe.confirmCardPayment(clientSecret, {
      //   payment_method: {
      //     card: cardNumberElement,
      //   },
      //   return_url: import.meta.env.VITE_STRIPE_RETURN_URL,
      //   receipt_email: data?.customer?.email || "",
      // });
      if (result.error) {
        console.log("result error ", result.error.message);
        // Show error to your customer (for example, insufficient funds)
        toast.error(result.error.message || "Please Enter Valid Card Details");
        setIsPaymentLoading(false);
      } else {
        // The payment has been processed!
        // if (result.paymentIntent.status === "succeeded") {
        setIsPaymentLoading(false);
        dispatch(removeDealList());
        dispatch(removeOrderList());
        // localStorage.clear();
        clearOrderSuccessStorage();
        setValue("config.isPaymentSuccess", true);
        setValue("config.orderId", response?.data?.customerOrderDTO?.id);
        setValue("config.orderNumber", response?.data?.orderNumber);
        setValue(
          "config.finalTotalResponse",
          response?.data?.customerOrderDTO?.finalAmount
        );
        setValue("config.paymentsuccessStatus", "PAID");
        // router.push("/checkout/success" + "?" + "success=true");
        // }
      }
    } else {
      // toast.error("Something Went Wrong. Please Try Again");
      toast.error(
        response?.response?.data?.message ||
          "Something Went Wrong. Please Try Again"
      );
      setIsPaymentLoading(false);
    }
  });

  return (
    <>
      <button
        className={`${
          !watchOrderFuture ||
          !watchDeliveryOrderStatus ||
          !deliveryDistancePossible
            ? "bg-gray-500"
            : "bg-[rgba(141,190,73)]"
        } text-center p-4 rounded-lg m-2 text-white flex-1`}
        onClick={ConfirmPayment}
        disabled={
          isPaymentLoading ||
          !watchOrderFuture ||
          !watchDeliveryOrderStatus ||
          !deliveryDistancePossible
        }
      >
        Confirm and Make Payment
      </button>

      {isPaymentLoading && (
        <CustomModalClients open={isPaymentLoading} handleClose={() => {}}>
          <div className="p-4 flex items-center justify-center flex-col">
            <img
              src="/images/icons/loading.svg"
              width="50"
              height="50"
              alt="Loading..."
            />
            <p className="py-4 font-bold text-lg">
              Please Wait.. Payment In Process
            </p>
          </div>
        </CustomModalClients>
      )}
    </>
  );
}

export default CheckoutCardPaymentButton;
