import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { order, scheduleTable } from "@/app/_redux/slice/Order/OrderSlice";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { useGetAuthToken } from "@/app/hooks/useGetAuthToken";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { number, object, string } from "yup";
import TableOrderModal from "./TableOrderModal";

const TableOrderSchema = object().shape({
  firstName: string().required(""),
  lastName: string().required(""),
  email: string().email(""),
  phone: string().required(""),
  reservationDate: string().required(""),
  reservationTime: string().required(""),
  totalPeople: number().nullable(),
  // totalTime: string(),
  message: string().required(""),
  specialRequest: string(),
  hours: number().required(""),
  minutes: number().required(""),
});

function TableOrderButton() {
  const token = useGetAuthToken();
  const { scheduleTableOpen } = useSelector(order);
  const { selectedStore } = useSelector(selectStore);

  const dispatch = useAppDispatch();
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reservationDate: "",
    reservationTime: "",
    totalPeople: null,
    totalTime: "",
    hours: 0,
    minutes: 0,
    specialRequest: "",
    message: "",
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(TableOrderSchema),
  });

  const handleOpen = () => {
    dispatch(scheduleTable(true));
  };
  const handleClose = () => {
    dispatch(scheduleTable(false));
  };
  const getTotalTime = (hours: number, minutes: number) => {
    const min = hours * 60 + minutes;
    return min;
  };
  const onSubmit = async (data: any) => {
    const finalData = {
      ...data,
      totalTime: getTotalTime(data.hours, data.minutes),
      storeId: selectedStore?.id,
    };

    const response = await fetchRequest(
      serverPath.tableBook,
      "POST",
      finalData,
      {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      "res"
    );
    if (response?.status === 200) {
      toast.success("Table Booked Successfully");
      handleClose();
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <FormProvider {...methods}>
      <div>
        <button
          type="button"
          className={twMerge(
            "flex flex-row gap-1 items-center rounded-full h-10 px-3 text-sm font-medium bg-gray-200"
          )}
          onClick={handleOpen}
        >
          <img
            src="/images/icons/booktable.svg"
            width="18"
            height="18"
            alt="table"
          />
          <p className="hidden sm:block">Book Table</p>
        </button>
      </div>
      {scheduleTableOpen && (
        <TableOrderModal
          handleClose={handleClose}
          onSubmit={onSubmit}
          scheduleTableOpen={scheduleTableOpen}
        />
      )}
    </FormProvider>
  );
}

export default TableOrderButton;
