import MenuDetails from "../../components/MenuDetailsInformation";
import GoBackButtonForItems from "@/app/[location]/components/GoBackButtonForItems";
import NotFound404 from "@/app/global/NoData/NotFound404";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

function MenuDetailsPage() {
  const { name, location, periodId } = useParams<{
    name: string;
    location: string;
    periodId: string;
  }>();
  const headingRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!headingRef) return;
    headingRef?.current?.scrollIntoView({ behavior: "smooth" });
    window.scrollTo(0, 0);
  }, [headingRef]);

  if (!name || !location || !periodId) {
    return <NotFound404 />;
  }

  return (
    <div className="w-full md:w-3/4 m-auto my-4 min-h-[75dvh]" ref={headingRef}>
      <GoBackButtonForItems />
      <MenuDetails params={{ name, location, periodId }} isModal={false} />
    </div>
  );
}

export default MenuDetailsPage;
