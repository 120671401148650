import MenuDealTotalPrice from "./MenuDealTotalPrice";
import MenuDealCartButton from "./MenuDealCartButton";
import MenuDealQty from "./MenuDealQty";

function MenuDealAddToCart({ isModal }: { isModal: boolean }) {
  return (
    <div className="bg-white absolute bottom-0 w-full">
      <div className=" p-2 flex flex-col justify-between">
        <div className="flex gap-4 items-center justify-between mb-3">
        <MenuDealTotalPrice />
        <MenuDealQty/>
        </div>
        <MenuDealCartButton isModal={isModal} />
      </div>
    </div>
  );
}

export default MenuDealAddToCart;
