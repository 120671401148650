import React, { ChangeEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface ICustomRadioButtonClick {
  name: string;
  id: string | number;
  children: React.ReactNode;
  value: number | string;
  onChange?(): void;
  parentStyle?: string;
  watchOrderValid?: boolean;
}

// name should be same for radio Button
function CustomRadioButtonClick({
  name,
  id,
  children,
  value,
  onChange,
  parentStyle,
  watchOrderValid = true,
}: ICustomRadioButtonClick) {
  const { register, control, setValue } = useFormContext();
  const watchValue = useWatch({
    control,
    name,
  });

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onChange && onChange();
  };

  let isValid = false;
  if (typeof value === "number" && Number(value) === Number(watchValue))
    isValid = true;

  if (typeof value === "string" && value === watchValue) isValid = true;
  return (
    <div className={parentStyle}>
      <input
        type="radio"
        {...register(name)}
        id={`radio${id}`}
        className={`peer/menuSize`}
        value={value}
        onChange={(e) => onChangeValue(e)}
        hidden
        disabled={!watchOrderValid}
      />
      <label
        htmlFor={`radio${id}`}
        className={`px-4 py-2 cursor-pointer bg-gray-200 peer-checked/menuSize:bg-black peer-checked/menuSize:text-white rounded-full font-medium flex items-center gap-2`}
      >
        {!isValid && !watchOrderValid ? (
          <div className="flex items-center gap-2 animate-pulse">
            {/* <Icon icon="eos-icons:loading" className="animate-spin" /> */}
            <img
              src="/images/icons/eosLoading.svg"
              width="16"
              height="16"
              alt="loading"
              className="animate-spin"
            />
            <p>Loading...</p>
          </div>
        ) : (
          children
        )}
        {isValid ? (
          // <Icon icon="charm:circle-tick" width={"1rem"}/>
          <img
            src="/images/icons/circleTick.svg"
            width="16"
            height="16"
            alt="tick"
          />
        ) : (
          <span className="w-4" />
        )}
      </label>
    </div>
  );
}

export default CustomRadioButtonClick;
