import CheckoutManageDelivery from "../CheckoutDelivery/CheckoutManageDelivery";

function CheckoutUserAddress() {
  // const { control } = useFormContext<ConfirmOrderForm>();

  return (
    <div className="flex flex-col gap-2 divide-y-2 border-b-2">
      <CheckoutManageDelivery />
      {/* {watchOrderType && (
        <div className={"flex gap-4 flex-col"}>
          {watchAddress?.map((el, index) => {
            if (el?.type === "SECONDARY" && isNewUser) return null;

            return (
              <CheckUserAddressItem
                key={index}
                item={el}
                findIndex={index}
                setIsNewUser={setIsNewUser}
                isNewUser={isNewUser}
              />
            );
          })}
        </div>
      )} */}
    </div>
  );
}

export default CheckoutUserAddress;
