import CheckoutRHFWrapper from "./CheckoutRHFWrapper";
import CheckoutOrderSummary from "./CheckoutOrderSummary";
import CheckoutUserPreference from "./CheckoutUserPreference";
import CheckoutStoreInformation from "./CheckoutStoreInformation";
import CheckoutPayment from "./CheckoutPayment";
import { CustomerInfo } from "@/app/_types/customer.interface";

function CheckoutForm({ customer }: { customer: CustomerInfo }) {
  return (
    <CheckoutRHFWrapper customer={customer}>
      <div className=" py-4 px-4 lg:px-12  min-h-screen bg-background-100 flex gap-4 flex-col  ">
        <div className="maxContainer">
          <div>
            <h1 className="font-bold text-xl text-gray-800">Checkout</h1>
          </div>
          <div className="flex gap-8 flex-col lg:flex-row">
            <div className="child:px-4 child:py-4 child:rounded-md child:bg-white flex flex-col gap-4">
              <CheckoutStoreInformation />
              <CheckoutUserPreference customer={customer} />
            </div>
            <div className="child:p-4 child:rounded-md child:bg-white flex flex-col gap-4">
              <CheckoutOrderSummary customer={customer} />
              {customer?.firstName && <CheckoutPayment />}
            </div>
          </div>
        </div>
      </div>
    </CheckoutRHFWrapper>
  );
}

export default CheckoutForm;
