import DeliveryType from "../MenuDetails/DeliveryType";
import CategoryListTradingHours from "./CategoryListTradingHours";
import { StoreEntity } from "@/app/_types/store.interface";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import {
  MenuWithCategories,
  reActivationTimes,
} from "@/app/_types/menuWithCategories";
import { EstimatedTime } from "@/app/_redux/slice/UISlice/UISlice.d";
import ScheduleButton from "../ScheduleButton";
import { useDispatch, useSelector } from "react-redux";
import { getSchedules, order } from "@/app/_redux/slice/Order/OrderSlice";
// import { CalculateOrderManagement } from "../MenuTradingPeriods/CalculateTradingPeriod";
// import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";

import TableOrderButton from "../TableOrderButton";
import { useState } from "react";
import MoreInfoModal from "./MoreInfoModal";

interface CategoryListHeadersInfoProps {
  store: StoreEntity;
  tradingHours: TradingHour[];
  estimatedTime?: EstimatedTime;
  data: MenuWithCategories;
  storeDT: string;
  handleSelectedTradingPeriod: ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => Promise<void>;
  reActivationTimesData: reActivationTimes[];
}

const CategorylListHeadersInfo = ({
  store,
  tradingHours,
  estimatedTime,
  storeDT,
  data,
  handleSelectedTradingPeriod,
}: // reActivationTimesData,
CategoryListHeadersInfoProps) => {
  const { schedule } = useSelector(order);
  const dispatch = useDispatch();
  // const { selectedOrderStatus } = useSelector(selectUI);
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const handleClearSchedule = () => {
    dispatch(getSchedules(null));
    localStorage.removeItem("scheduleData");
  };

  // const OrderManagement = CalculateOrderManagement({
  //   selectedOrderStatus,
  //   storeDT,
  //   reActivationTimesData,
  // });

  console.log("tradingHours CategoryListHeadersInfo", tradingHours)
  console.log("schedule data clear", schedule)
  return (
    <div className="pt-8">
      <h1 className="text-4xl md:text-4xl">{store?.name}</h1>
      <div className="flex flex-col sm:flex-row gap-2 justify-between">
        <div>
          <div className="flex gap-1 items-center">
            <img
              src="/images/icons/address.svg"
              alt="minus"
              width="25"
              height="25"
            />
            <p>{store?.address}</p>
          </div>
          <div className="flex gap-1 items-center">
            <img
              src="/images/icons/contact.svg"
              alt="minus"
              width="25"
              height="25"
            />
            <p>{store?.phone}</p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-center gap-1 sm:gap-4 items-center">
            {(store?.hasTable ?? true) && (
              <TableOrderButton />
            )}
            <DeliveryType />
            <ScheduleButton
              tradingHours={tradingHours}
              handleSelectedTradingPeriod={handleSelectedTradingPeriod}
              isDelivery={store?.isDeliveryEnabled ?? true}
            />
          </div>
          <div className="flex items-center gap-1 justify-center md:justify-start text-sm pt-1">
            <div className="flex">
              {schedule?.selectedTradingPeriod && schedule?.selectedTime && (
                <p>
                  {schedule?.selectedTradingPeriod?.data} {schedule?.orderType}{" "}
                  at {schedule?.selectedTime?.label}{" "}
                  <button
                    className="text-red-500"
                    onClick={handleClearSchedule}
                  >
                    clear
                  </button>
                </p>
              )}
            </div>
            {/* <div className={`flex w-[35%] justify-end`}>
            {!OrderManagement?.status && (
              <p className="text-red-500">{selectedOrderStatus} is Paused</p>
            )}
          </div> */}
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-col sm:flex-row items-center sm:items-start">
        <div>
          {tradingHours?.length ? (
            <>
            <CategoryListTradingHours
              tradingHours={tradingHours}
              storeDT={storeDT}
              openTime={data?.openTime}
              closeTime={data?.closeTime}
              />
              <p
                style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                className="flex justify-center md:justify-start"
                onClick={() => setMoreInfoOpen(true)}
              >
                More Info
              </p>
              </>
          ) : (
            <p className="text-lg font-semibold pt-2">No Trading Hour Found</p>
          )}
        </div>
        <div className="bg-gray-200 rounded p-2">
          <p>Delivery Estimate~ {estimatedTime?.webDeliveryMins} MINS</p>
          <p>Pickup Estimate~ {estimatedTime?.webPickupMins} MINS</p>
        </div>
      </div>
      { moreInfoOpen && (
        <MoreInfoModal
        open={moreInfoOpen}
        handleClose={() => setMoreInfoOpen(false)}
        tradingHours={tradingHours}
        storeId={store?.id}
        />
      )
      }
    </div>
  );
};

export default CategorylListHeadersInfo;
