import { useEffect, useState } from 'react'
import CartExistModal from './CartExistModal'
import { AddMenuItemsForm } from '@/app/_types/menuDetails';
import { useDispatch } from 'react-redux';
import { getEntireDealList, getEntireOrderList, getSchedules, removeOrderList } from '@/app/_redux/slice/Order/OrderSlice';
import { newMenuWithCategoryList } from '@/app/_redux/slice/menuWithCategories.ts/menuWithCategoriesSlice';
import { getCartList } from '@/app/_axios/serverApi';
import { DealDetailsInCart } from '@/app/_types/menuDeals.interface';

const CartExistsOtherLocation = ({location}:{location:string}) => {
    const dispatch = useDispatch()
      const [checkData, setCheckData] = useState<{
          isModalOpen: boolean;
          previousLocation: string;
          data: AddMenuItemsForm[];
        }>({ isModalOpen: false, previousLocation: "", data: [] });

    const handleIsModalClose = () => {
        setCheckData({ isModalOpen: false, previousLocation: "", data: [] });
      };
    
      const acceptDataHandler = () => {
        localStorage.removeItem("cart");
        localStorage.removeItem("scheduleData");
        localStorage.removeItem("selectedOrderStatus")
        handleIsModalClose();
        dispatch(getEntireOrderList([]));
        dispatch(getEntireDealList([]));
        dispatch(getSchedules(null));
        dispatch(newMenuWithCategoryList());
      };

      useEffect(() => {
          const cartToken = localStorage.getItem("cart");
          if (!cartToken) {
            // localStorage.removeItem("scheduleData");
            localStorage.removeItem("selectedOrderStatus")
            // dispatch(getSchedules(null));
            dispatch(newMenuWithCategoryList());
          }
          dispatch(removeOrderList());
          const getTokenData = () => {
            if (cartToken) {
              console.log("data info cart token => ", JSON.parse(cartToken));
              const res = getCartList(cartToken);
      
              console.log("Data inf outside  running", cartToken, res);
              if (res?.status === 200) {
                const data: {
                  itemList: AddMenuItemsForm[];
                  location: string;
                  dealList: DealDetailsInCart[];
                } = res;
      
                if (data?.location === location) {
                  dispatch(getEntireOrderList(data.itemList));
                  dispatch(getEntireDealList(data?.dealList));
                } else {
                  setCheckData({
                    isModalOpen: true,
                    data: data?.itemList,
                    previousLocation: data?.location,
                  });
                }
              }
            }
          };
          getTokenData();
        }, [dispatch, location]);

  return (
    <>
        {checkData?.isModalOpen && (
        <CartExistModal
          open={checkData?.isModalOpen}
          handleClose={handleIsModalClose}
          previousLocation={checkData?.previousLocation}
          acceptData={acceptDataHandler}
        />
      )}
    </>
  )
}

export default CartExistsOtherLocation