import React, { useEffect } from "react";

export default function CustomModalClient({
  open,
  setOpen,
  children,
  title,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  title: string;
}) {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => document.body.classList.remove("modal-open");
  }, []);

  return (
    <>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 bg-white outline-none focus:outline-none">
            <div className=" rounded-md relative bg-white min-w-[5] w-full h-full md:h-[90vh]">
              {/* content */}
              <div className="flex flex-col">
                {/* headers */}
                <div className="flex items-start justify-between p-4 border-b border-solid border-slate-200 fixed bg-white z-40 top-0 right-0 left-0">
                  <h3 className="text-xl font-semibold">{title}</h3>
                  <button
                    className=" text-black font-semibold"
                    onClick={() => setOpen(false)}
                  >
                    <img
                      src="/images/icons/close.svg"
                      alt="Close"
                      width={25}
                      height={25}
                      className="text-black"
                    />
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto ">{children}</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
