import instance from "./axiosInstance";
import * as Sentry from "@sentry/react";

const urlDomain = import.meta.env.VITE_SERVER_API;

// Function to handle API requests with token refresh
async function fetchRequest(
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" = "GET",
  body?: Record<string, any>,
  // @ts-ignore
  headers?: Record<string, string> = {},
  resStatus?: "res" | "data"
) {
  const newUrl = `${urlDomain}${url}`;

  //@ts-ignore
  const executeFetch = async () => {
    try {
      // const res = await fetch(newUrl, options);

      const res = await instance({
        method: method,
        url: newUrl,
        data: body,
      });

      if (resStatus === "res") {
        if (res?.status === 200) return res;
        throw res;
      }

      if (res?.status === 200) return res?.data;
      throw res;
      // throw error;
    } catch (error: any) {
      console.log("error in fetchRequest", error);

      const errorMessageForSentr = JSON.stringify({
        message: error?.message,
        status: error?.status,
        code: error?.code,
        response: {
          data: error?.response?.data,
          status: error?.response?.status,
          statusText: error?.response?.statusText,
        },
      });

      // Sentry.captureMessage(`Error in fetchRequest: ${errorMessageForSentr}`);
      Sentry.captureException(errorMessageForSentr);

      if (headers?.Authorization && error?.response) {
        return error;
      }
      // Handle Axios-specific error responses
      if (error?.response) {
        console.error("Axios error response details:", error.response);
        throw error;
        // return error
      }
      // Handle network or unexpected errors
      throw error;
    }
  };

  return executeFetch();
}

export default fetchRequest;
