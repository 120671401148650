
function CartEmpty() {
  return (
    <div className="flex items-center justify-center h-[30vh] w-full">
      <h2 className="text-3xl ">Cart Empty !</h2>
    </div>
  );
}

export default CartEmpty;
