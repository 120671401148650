import {
  AddMenuItemsForm,
  MenuOption,
} from "@/app/_types/menuDetails";
import { useContext } from "react";
import { IngredientProvider } from "../MenuDetailsForm";
import { useFormContext, useWatch } from "react-hook-form";

import toast from "react-hot-toast";
import {
  AddMenuIngredientHandler,
  RemoveMenuIngredientsHandler,
} from "../../utils/changeIngredientlogic";
import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";

interface MenuDetailsCurrentOptionItem {
  item: MenuOption;
  isExtraOption: boolean;
  optionGroups: OptionGroup[];
}

function MenuDetailsCurrentOptionItem({
  item,
  isExtraOption = false,
  optionGroups,
}: MenuDetailsCurrentOptionItem) {
  const ingredientContext = useContext(IngredientProvider);
  const { control, getValues, setValue } = useFormContext<AddMenuItemsForm>();

  const watchIsSplit = useWatch({
    control,
    name: "config.isSplit",
  });

  if (!ingredientContext) return toast.error("Ing Context not working");

  return (
    <div className="flex justify-between px-4 py-2">
      <p
        className={
          item?.qty === 0
            ? "line-through text-red-400"
            : isExtraOption
            ? "no-underline text-purple-500"
            : "no-underline"
        }
      >
        {item?.name}
        {item?.total && item?.total > 0 ? (
          <span className="text-sm">
            {" "}
            + ${watchIsSplit ? item?.total / 2 : item?.total}
          </span>
        ) : null}
      </p>
      <div className="flex items-center gap-2">
        {item?.qty > 0 && (
          <>
            <button
              type="button"
              onClick={() =>
                RemoveMenuIngredientsHandler(
                  ingredientContext,
                  getValues,
                  setValue,
                  item
                )
              }
            >
              <img
                src="/images/icons/minus.svg"
                alt="minus"
                width="30"
                height="30"
                className="bg-slate-200 p-2 rounded-full"
              />
            </button>
            <p>{item?.qty}</p>
          </>
        )}
        <button
          type="button"
          onClick={() =>
            AddMenuIngredientHandler(
              ingredientContext,
              getValues,
              setValue,
              {
                id: item?.id,
                name: item?.name,
                optionGroupId: item?.optionGroupId,
                menuSizes: item?.menuSizes?.map((el) => ({
                  id: el?.sizeId,
                  sizeId: el?.sizeId,
                  extraPrice: el?.extraPrice,
                })),
                isSingle: item?.isSingle,
              },
              optionGroups
            )
          }
        >
          <img
            src="/images/icons/add.svg"
            alt="add"
            width="30"
            height="30"
            className="bg-gray-300 p-2 rounded-md"
          />
        </button>
      </div>
    </div>
  );
}

export default MenuDetailsCurrentOptionItem;
