import { useState } from "react";
import OrderNowButton from "./OrderNowButton";
import { twMerge } from "tailwind-merge";
import { SelectedColor } from "@/app/_types/EcommerceTheme.interface";
import { StoreEntity } from "@/app/_types/store.interface";

function NavBarHamBurger({
  EcommerceColor,
  storeList,
}: {
  EcommerceColor: SelectedColor;
  storeList: StoreEntity[];
}) {
  const [open, setOpen] = useState(false);
  const hamBurgerManage = () => {
    setOpen((prev) => !prev);
  };

  const AboutUsHandler = () => {
    console.log("About us handler");
    const section = document.getElementById("what-we-offer-id");
    if (section) {
      const sectionTop = section.offsetTop;
      window.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  };

  const genericHamburgerLine = `h-[0.15rem] w-8 my-1 rounded-full bg-black transition ease transform duration-300`;

  return (
    <>
      <li className="md:hidden relative flex items-center h-[inherit]">
        <button
          className="flex flex-col h-12 w-12  rounded justify-center items-center group ml-auto relative z-[21]"
          onClick={hamBurgerManage}
        >
          <div
            className={`${genericHamburgerLine} ${
              open
                ? "rotate-45 translate-y-[0.65rem] opacity-50 group-hover:opacity-100"
                : "opacity-50 group-hover:opacity-100"
            }`}
          />
          <div
            className={twMerge(
              `h-1 w-5 my-1 rounded-full bg-black transition ease transform duration-300`,
              open ? "opacity-0" : "opacity-50 group-hover:opacity-100"
            )}
          />
          <div
            className={`${genericHamburgerLine} ${
              open
                ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100"
                : "opacity-50 group-hover:opacity-100"
            }`}
          />
        </button>
      </li>
      <ol
        className={twMerge(
          "child:py-2 navContent",
          open
            ? "scale-100 visible "
            : "scale-0 invisible md:scale-100 md:visible "
        )}
      >
        <li className="px-12 cursor-pointer" onClick={AboutUsHandler}>
          About Us
        </li>
        <li>
          <OrderNowButton
            bgColor={EcommerceColor?.secondary}
            storeList={storeList}
          />
        </li>
      </ol>
    </>
  );
}

export default NavBarHamBurger;
