import { AxiosError } from "axios";

export type AxiosErrorMessage = AxiosError<{ message: string }>;

export const axiosErrorMessage = (
  error: AxiosErrorMessage,
  randomError?: string
) => {
  console.log("error => ", error);
  if (error?.response?.data?.message) {
    return error.response.data.message;
  } else {
    return randomError || "Something Went Wrong. Please Try Again";
  }
};
