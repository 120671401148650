import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { OrderSlices } from "./OrderSlices";
import { AddMenuItemsForm } from "@/app/_types/menuDetails";
import { StoreEntity } from "@/app/_types/store.interface";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import {
  DealDetails,
  DealDetailsInCart,
} from "@/app/_types/menuDeals.interface";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import { OfferDetails } from "@/app/_types/menuOffers.interface";
import { ScheduleButtonForm } from "@/app/[location]/components/ScheduleButton";

const initialState: OrderSlices = {
  OrderList: [],
  dealList: [],
  OrderLocation: null,
  userStoreAddress: [],
  tradingHours: [],
  todayTradingHours: [],
  dealDetails: null,
  offerDetails: null,
  isLoading: false,
  schedule: null,
  scheduleOpen: false,
  scheduleTableOpen: false,
  reActivationTimes: false,
};

export const OrderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
    getEntireOrderList(state, action: PayloadAction<AddMenuItemsForm[]>) {
      state.OrderList = action.payload;
    },
    updateOrderList(state, action: PayloadAction<AddMenuItemsForm>) {
      state.OrderList.push(action.payload);
    },
    removeOrderList(state) {
      state.OrderList = [];
    },
    updateOrderDetails(
      state,
      action: PayloadAction<{
        order: AddMenuItemsForm;
        status: "ADD" | "REMOVE";
      }>
    ) {
      const orderDetails = action.payload.order;
      const orderStatus = action.payload.status;

      const findOrder = state.OrderList?.find(
        (order) => order?.uuid === orderDetails?.uuid
      );
      if (findOrder) {
        if (orderStatus === "ADD") {
          findOrder.qty = findOrder?.qty + 1;
        }
        if (orderStatus === "REMOVE") {
          if (orderDetails?.qty === 1) {
            const findIndex = state.OrderList.findIndex(
              (el) => el?.uuid === orderDetails?.uuid
            );
            state.OrderList.splice(findIndex, 1);
          } else {
            findOrder.qty = findOrder?.qty - 1;
          }
        }
      }
    },
    updateDealDetails(
      state,
      action: PayloadAction<{
        deal: DealDetailsInCart;
        status: "ADD" | "REMOVE";
      }>
    ) {
      const orderDetails = action.payload.deal;
      const orderStatus = action.payload.status;

      const findOrder = state.dealList?.find(
        (order) => order?.uuidDeal === orderDetails?.uuidDeal
      );
      if (findOrder) {
        if (orderStatus === "ADD") {
          findOrder.qty = findOrder?.qty + 1;
        }
        if (orderStatus === "REMOVE") {
          if (orderDetails?.qty === 1) {
            const findIndex = state.dealList.findIndex(
              (el) => el?.uuidDeal === orderDetails?.uuidDeal
            );
            console.log("In qty => ", findIndex);
            state.dealList.splice(findIndex, 1);
          } else {
            findOrder.qty = findOrder?.qty - 1;
          }
        }
      }
    },
    updateDealPriceDetails(
      state,
      action: PayloadAction<{ index: number; extraPrice: ExtraPriceInfo }>
    ) {
      const dealEntity =
        state.dealDetails?.dealCategories[action.payload.index];
      if (dealEntity) dealEntity.extraPriceInfo = action.payload.extraPrice;
    },
    updateOfferPriceDetails(
      state,
      action: PayloadAction<{ index: number; extraPrice: ExtraPriceInfo }>
    ) {
      const offerEntity =
        state.offerDetails?.offerCategories[action.payload.index];
      if (offerEntity) offerEntity.extraPriceInfo = action.payload.extraPrice;
    },
    getEntireDealList(state, action: PayloadAction<DealDetailsInCart[]>) {
      state.dealList = action.payload;
    },
    updateDealList(state, action: PayloadAction<DealDetailsInCart>) {
      state.dealList.push(action.payload);
    },
    removeDealList(state) {
      state.dealList = [];
    },

    setOrderLocation(state, action: PayloadAction<StoreEntity>) {
      state.OrderLocation = action.payload;
    },
    getTradingHourAction(state, action: PayloadAction<TradingHour[]>) {
      state.tradingHours = action.payload;
    },
    getTodayTradingHours(state, action: PayloadAction<TradingHour[]>) {
      state.todayTradingHours = action.payload;
    },

    // deals
    getDealDetails(state, action: PayloadAction<DealDetails>) {
      state.dealDetails = action.payload;
    },
    removeDealDetails(state) {
      state.dealDetails = null;
    },
    changeIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },

    //offers
    getOfferDetails(state, action: PayloadAction<OfferDetails>) {
      state.offerDetails = action.payload;
    },
    removeOfferDetails(state) {
      state.offerDetails = null;
    },
    //schedule
    getSchedules(state, action: PayloadAction<ScheduleButtonForm | null>) {
      state.schedule = action.payload;
    },
    scheduleModel(state, action: PayloadAction<boolean>) {
      state.scheduleOpen = action.payload;
    },
    scheduleTable(state, action: PayloadAction<boolean>) {
      state.scheduleTableOpen = action.payload;
    },
    setReActivationTimes(state, action: PayloadAction<boolean>){
      state.reActivationTimes = action.payload
    }
  },
});

export const {
  updateOrderList,
  updateOrderDetails,
  removeOrderList,
  updateDealPriceDetails,
  updateOfferPriceDetails,
  getEntireOrderList,
  getTradingHourAction,
  getTodayTradingHours,
  setOrderLocation,
  getEntireDealList,
  updateDealList,
  removeDealList,
  updateDealDetails,
  getDealDetails,
  removeDealDetails,
  getOfferDetails,
  removeOfferDetails,
  changeIsLoading,
  getSchedules,
  scheduleModel,
  scheduleTable,
  setReActivationTimes,
} = OrderSlice.actions;

export const order = (state: RootState) => state.order;

export default OrderSlice.reducer;
