export function useGetAuthToken() {
  const storages = localStorage.getItem("session");
  let session = null;
  if (storages && storages !== undefined) {
    try {
      session = JSON.parse(storages);
    } catch (error) {
      console.error("Failed to parse session storage:", error);
      session = null; // Set session to null if parsing fails
    }
  } else {
    session = null; // If storage is null, explicitly set session to null
  }
  const tokenDTO = session ? session : null;
  const accessToken = tokenDTO?.acessToken;
  // const session = JSON.parse(localStorage.getItem("session"));
  // const token = session?.data?.user?.tokenDTO?.acessToken;
  if (accessToken) {
    return accessToken;
  } else return null;
}

export function useGetAuthTokenWithBearer() {
  const session = localStorage.getItem("session");
  const tokenDTO = session ? JSON.parse(session) : null;
  const accessToken = tokenDTO?.acessToken;
  // const session = JSON.parse(localStorage.getItem("session"));
  // const token = session?.data?.user?.tokenDTO?.acessToken;
  if (accessToken) {
    return `Bearer ${accessToken}`;
  } else return null;
}

export function useGetRefreshToken() {
  const session = localStorage.getItem("session");
  const tokenDTO = session ? JSON.parse(session) : null;
  const refreshToken = tokenDTO?.refreshToken;

  // const refreshToken = session?.data?.user?.tokenDTO?.refreshToken;
  if (refreshToken) {
    return refreshToken;
  }
}
