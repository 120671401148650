import { useNavigate } from "react-router-dom";

function MenuDealTitle() {
  const router = useNavigate();

  const CloseHandler = () => {
    router(-1);
  };

  return (
    <button onClick={CloseHandler}>
      <img src="/images/icons/cross.svg" width="10" height="10" alt="x" />
    </button>
  );
}

export default MenuDealTitle;
