import { useNavigate } from "react-router-dom";

function MenuOfferTitle() {
  const router = useNavigate();

  const CloseHandler = () => {
    router(-1);
  };

  return (
    <button onClick={CloseHandler}>
      <img src="/images/icons/cross.svg" width="20" height="20" alt="x" />
    </button>
  );
}

export default MenuOfferTitle;
