import { Link } from "react-router-dom";
import MenuItemCard from "./MenuItemCard";
import { MenuItems } from "@/app/_types/menuWithCategories";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { CalculateMenuTradingPeriod } from "../MenuTradingPeriods/CalculateTradingPeriod";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import { useParams } from "react-router-dom";
import moment from "moment";

interface TradingPeriod {
  startTime: string | null;
  endTime: string | null;
  status: boolean;
  isClosed: boolean | null;
}

interface MenuLinkProps {
  CheckTradingPeriod: TradingPeriod;
  menu: MenuItems;
  params: string;
  tradingPeriodId: string;
  menuType?: string
}

function MenuLink({
  CheckTradingPeriod,
  menu,
  tradingPeriodId,
  menuType,
}: MenuLinkProps) {
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);
  const { schedule, reActivationTimes } = useSelector(order);
  const { location } = useParams();
  if (!storeDT) return;

  if (!menu?.tradingHours) return;

  const selectedTime = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();

  const tradingHoursData: TradingHour[] = menu?.tradingHours;

  const ItemTradingPeriodAvailable = CalculateMenuTradingPeriod({
    selectedOrderStatus,
    tradingHoursData,
    tradingPeriodId,
    selectedTime,
    selectedDay,
    storeDT,
  });

  // console.log("state values MenuLink", menu?.id)

  return (
    <Link
    id={!menuType ? menu?.id : ""}
      to={{
        pathname:
          !CheckTradingPeriod.status ||
          menu?.visibilityStatus === "OUT_OF_STOCK" ||
          !ItemTradingPeriodAvailable?.status || 
          !reActivationTimes
            ? ""
            : `/${location}/menu/${tradingPeriodId}/${menu?.id}`,
      }}
      className={`${
        menu?.visibilityStatus === "HIDDEN" ||
        menu?.visibilityStatus === "HIDE_FOR_APP_AND_WEB"
          ? "hidden"
          : ""
      }`}
      // onClick={() => {
      //   window.scrollTo(0, 0); // Scroll to the top
      // }}
    >
      <MenuItemCard
        menu={menu}
        status={CheckTradingPeriod.status}
        menuItemStatus={ItemTradingPeriodAvailable?.status}
        reActivationTimes={reActivationTimes}
        />
    </Link>
  );
}

export default MenuLink;
