import { useQueryClient } from "@tanstack/react-query";
import CheckoutForm from "./components/CheckoutForm";
// import NotLoggedInSignInHandler from "./components/NotLoggedInSignInHandler";
import { CustomerInfo } from "../_types/customer.interface";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function CheckoutPage() {
  // checking if the user is available or not..
  const client = useQueryClient();
  const data = client.getQueryData<CustomerInfo>(["currentCustomer"]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!data?.firstName) {
      navigate(`/auth/login?redirect=${location?.pathname}`);
    }
  }, [data, location, navigate]);

  // if (!data?.firstName) {
  //   return <NotLoggedInSignInHandler />;
  // }

  if (!data?.firstName) {
    return <> User Not SignIn</>
  }

  return (
    <>
      <CheckoutForm customer={data} />
    </>
  );
}

export default CheckoutPage;
