import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { changeCartOpen } from "@/app/_redux/slice/UISlice/UISlice";
import { useDispatch, useSelector } from "react-redux";

const AddtoCartMiniButton = () => {
  const { OrderList, dealList } = useSelector(order);
  const dispatch = useDispatch();

  const dealLength = dealList?.reduce((acc, current) => current?.qty + acc, 0);
  const orderLength = OrderList?.reduce(
    (acc, current) => current?.qty + acc,
    0
  );

  const totalLength = dealLength + orderLength;

  const handleOpen = () => {
    dispatch(changeCartOpen(true));
  };
  return (
    <button
      className="fixed bottom-24 md:bottom-32 right-5 md:right-24 bg-white text-white rounded-full w-14 h-14 flex justify-center items-center shadow-lg hover:bg-gray-200 z-30"
      onClick={handleOpen}
    >
      <div className="relative">  
    {/* Cart Icon */}
    <img src="/images/icons/cart.svg" width={20} height={20} alt="cart"/>

    {/* Badge for total number */}
    {totalLength > 0 && (
      <span className="absolute top-0 -right-1 text-xs font-bold bg-red-500 rounded-full w-5 h-5 flex items-center justify-center transform translate-x-1/2 -translate-y-1/2">
        {totalLength}
      </span>
    )}
  </div>
    </button>
  );
};

export default AddtoCartMiniButton;
