import {
  UseFormGetValues,
  UseFormSetValue,
  useFormContext,
  useWatch,
} from "react-hook-form";
import MenuOfferEditCombo from "./MenuOfferEditCombo";
import MenuOfferEditOptionsGroup from "./MenuOfferEditOptionsGroup";
import MenuOfferEditHalfHalf from "./MenuOfferEditExtra/MenuOfferEditHalfHalf";
import { MenuOfferForm } from "@/app/_types/menuOffers.interface";

function MenuOfferEditLogic() {
  const { control } = useFormContext<MenuOfferForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedOfferIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.config.selectedMenuIndex`,
  });

  const watchConfig = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.config`,
  });

  return (
    <div>
      {!watchConfig?.isSplit || !watchConfig?.canAddToCart ? (
        <MenuTitle />
      ) : null}
      {watchConfig?.isSplit && watchConfig?.canAddToCart ? (
        // <MenuOfferEditHalfHalf />
        <MenuOfferEditHalfHalf />
      ) : (
        <>
          {/* <MenuOfferEditCombo key={selectedMenuIndex} /> */}
          {/* <MenuOfferEditOptionsGroup /> */}
          <MenuOfferEditCombo key={selectedMenuIndex} />
          <MenuOfferEditOptionsGroup />
        </>
      )}
    </div>
  );
}

function MenuTitle() {
  const { control } = useFormContext<MenuOfferForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedOfferIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.config.selectedMenuIndex`,
  });

  const selectedMenuName = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.menus.${selectedMenuIndex}.name`,
  });

  const isSplit = useWatch({
    control,
    name: `menuOffer.${selectedIndex}.config.isSplit`,
  });

  return (
    <h2 className="px-4  font-bold text-xl">
      Edit {isSplit ? `1/2 ${selectedMenuName}` : selectedMenuName}
    </h2>
  );
}

export const handleOptionalMenu = (
  getValues: UseFormGetValues<MenuOfferForm>,
  setValue: UseFormSetValue<MenuOfferForm>
) => {
  const menuOffer = getValues("menuOffer");
  const currentMenu = getValues("config.selectedOfferIndex");
  const maxOptionalAllow = getValues("maxOptionalCategorySelectable");
  const newMenuLength = menuOffer?.filter(
    (el) => el.isOptional && el.isSelected
  )?.length;

  if (maxOptionalAllow < newMenuLength) {
    for (let i = 0; i < menuOffer.length; i++) {
      if (
        i !== currentMenu &&
        menuOffer[i].isOptional &&
        menuOffer[i].isSelected
      ) {
        setValue(`menuOffer.${i}.isSelected`, false);
        setValue(`menuOffer.${i}.menus`, []);
        break;
      }
    }
  }
};

export default MenuOfferEditLogic;
