import {
  updateCartInformation,
  UpdateCartInformation,
  // updateCartInformation,
} from "@/app/_axios/serverApi";
import { DealDetailsInCart } from "@/app/_types/menuDeals.interface";

import { useParams, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { twMerge } from "tailwind-merge";
import { useAppDispatch } from "@/app/_redux/store";
import {
  removeOrderList,
  updateDealList,
} from "@/app/_redux/slice/Order/OrderSlice";
import toast from "react-hot-toast";
import { MenuOfferForm } from "@/app/_types/menuOffers.interface";
import { handleOptionalMenu } from "../MenuOfferEditLogic";

function MenuOfferCartButton({ isModal }: { isModal: boolean }) {
  const { control, handleSubmit, setValue, getValues, watch } =
    useFormContext<MenuOfferForm>();
  const dispatch = useAppDispatch();

  const watchSelectedPage = useWatch({
    control,
    name: "config.selectedPage",
  });

  const offerList = useWatch({
    control,
    name: "menuOffer",
  });

  const canConfirm = useWatch({
    control,
    name: "config.canConfirm",
  });

  const isMenuHalfHalf = useWatch({
    control,
    name: "config.isMenuHalfHalf",
  });

  const params: { location?: string; name?: string; periodId?: string } =
    useParams();

  const router = useNavigate();

  const onDismiss = useCallback(() => {
    router(-1);
  }, [router]);

  const AddToCartHandler = async (data: MenuOfferForm) => {
    const getToken = localStorage.getItem("cart") || "";

    console.log("offer data for form ", data);
    const dealDetails: DealDetailsInCart = {
      periodId: data?.periodId,
      cartType: data?.cartType,
      id: data?.id,
      name: data?.name,
      uuidDeal: uuidv4(),
      description: data?.description,
      imageUrl: data?.imageUrl,
      sellingPrice: data?.sellingPrice,
      totalPrice: data?.totalPrice,
      qty: data?.qty,
      tradingHours: data?.tradingHours,
      note: data?.note || "",
      dealItems: data?.menuOffer
        ?.filter((offer) => offer?.isSelected)
        ?.map((item) => ({
          sizeId: item?.size,
          sizeName: item?.sizeName,
          discount: item?.discount,
          dealCategoryId: item?.offerCategoryId,
          config: item?.config,
          menus: item?.menus?.map((menu) => ({
            id: menu?.id,
            finalPrice: menu?.finalPrice,
            specialPrice: 0,
            name: menu?.name,
            menuSizeId: menu?.menuSizeId,
            optionGroups: menu?.optionGroups,
            ingredients: menu?.ingredients,
            tradingHours: menu?.tradingHours,
            menuSizeCount: menu?.menuSizeCount || 0,
          })),
        })),
    };

    const finalData: UpdateCartInformation = {
      dealDetails,
      status: "NEW",
      itemStatus: "DEAL",
      location: params?.location,
    };

    setValue("config.isLoading", true);
    const res = updateCartInformation(finalData, getToken);

    if (res?.status === 200) {
      const datas: { message: string; newToken: string } = res;
      localStorage.setItem("cart", datas?.newToken);
      dispatch(updateDealList(dealDetails));
      setValue("config.isLoading", false);
      toast.success("Offer Added to Cart");
    } else {
      localStorage.removeItem("cart");
      dispatch(removeOrderList());
      setValue("config.isLoading", false);
    }

    if (isModal) {
      onDismiss();
    } else {
      // const referrer = document.referrer;
      // if (referrer && referrer.includes(window.location.origin)) {
      //   router.back();
      // } else {
      //   router(`/${params?.location}?tradingPeriodId=${params?.periodId}`);
      // }
      // router(-1);
      const backPath = `/${params?.location}`;
      router(backPath, { state: {scrollId: `${params?.periodId}offersList`}});
    }
  };

  const OnConfirm = () => {
    setValue("config.selectedPage", "SELECT_DEAL");
    handleOptionalMenu(getValues, setValue);
  };

  const onIsMenuHalfHalf = () => {
    const getSelectedMenu = getValues("config.selectedOfferIndex");
    setValue(`menuOffer.${getSelectedMenu}.config.canAddToCart`, true);
    setValue("config.isMenuHalfHalf", false);
  };

  console.log(watch());

  // const handleAddToCartEnable = () => {
  //   const maxOptionalAllow = getValues("maxOptionalCategorySelectable");
  //   console.log(maxOptionalAllow)
  //   const maxOptionalAllowValue = () => {
  //     if (offerList.filter((el) => el.isOptional).length >= 1) {
  //       return maxOptionalAllow;
  //     } else {
  //       return 0;
  //     }
  //   };
  //   const SelectedOffer = offerList?.filter((el) => !el.isOptional);
  //   const OptionalOfferLength = offerList?.filter(
  //     (el) => el.isOptional && el.isSelected
  //   )?.length;
  //   console.log(SelectedOffer, OptionalOfferLength);
  //   if (SelectedOffer.every((el) => el.isSelected)) {
  //     console.log(
  //       "OptionalOffer",
  //       OptionalOfferLength,
  //       maxOptionalAllowValue()
  //     );
  //     if (OptionalOfferLength === maxOptionalAllowValue()) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const handleAddToCartEnable = () => {
    const maxOptionalAllow = getValues("maxOptionalCategorySelectable");
    const maxOptionalAllowValue = () => {
      if (offerList?.filter((el) => el.isOptional).length >= 1) {
        return maxOptionalAllow;
      } else {
        return 0;
      }
    };
    const SelectedOffer = offerList?.filter((el) => !el.isOptional);
    const OptionalOfferLength = offerList?.filter(
      (el) => el.isOptional && el.isSelected
    )?.length;
    console.log(SelectedOffer, OptionalOfferLength);
    if (SelectedOffer.every((el) => el.isSelected)) {
      console.log(
        "OptionalOffer",
        OptionalOfferLength,
        maxOptionalAllowValue()
      );
      if (OptionalOfferLength === maxOptionalAllowValue()) {
        return false;
      }
    }
    return true;
  };

  // const isSelected = offerList?.some((el) => !el?.isSelected);

  const correctButton = () => {
    switch (true) {
      case isMenuHalfHalf:
        return (
          <button
            className={twMerge(
              "bg-[rgba(141,190,73)] text-white p-3 mr-4 rounded-lg flex items-center gap-2 w-full justify-center",
              !canConfirm && "bg-gray-200"
            )}
            onClick={onIsMenuHalfHalf}
            disabled={!canConfirm}
          >
            <img
              src={"/images/icons/cart-white.svg"}
              width={20}
              height={20}
              alt="cart"
            />
            Confirm Half
          </button>
        );

      case watchSelectedPage === "SELECT_DEAL":
        return (
          <button
            className={twMerge(
              "bg-[rgba(141,190,73)] text-white p-3 mr-4 rounded-lg flex items-center gap-2 w-full justify-center",
              handleAddToCartEnable() && "bg-gray-200"
            )}
            disabled={handleAddToCartEnable()}
            onClick={handleSubmit(AddToCartHandler)}
            // disabled={isWatchData || isComboNotOptional || isLoading}
          >
            <img
              src={"/images/icons/cart-white.svg"}
              width={20}
              height={20}
              alt="cart"
            />
            Add to Cart
          </button>
        );

      default:
        return (
          <button
            className={twMerge(
              "bg-[rgba(141,190,73)] text-white p-3 mr-4 rounded-lg flex items-center gap-2 w-full justify-center",
              !canConfirm && "bg-gray-200"
            )}
            onClick={OnConfirm}
            disabled={!canConfirm}
          >
            <img
              src={"/images/icons/cart-white.svg"}
              width={20}
              height={20}
              alt="cart"
            />
            Confirm
          </button>
        );
    }
  };

  return (
    <>
      {/* {watchSelectedPage === "SELECT_DEAL" ? (
        <button
          className={twMerge(
            "bg-[rgba(141,190,73)] text-white p-3 mr-4 rounded-lg flex items-center gap-2",
            isSelected && "bg-gray-200"
          )}
          disabled={isSelected}
          onClick={handleSubmit(AddToCartHandler)}
          // disabled={isWatchData || isComboNotOptional || isLoading}
        >
          <img
            src={"/images/icons/cart-white.svg"}
            width={20}
            height={20}
            alt="cart"
          />
          Add to Cart
        </button>
      ) : (
        <button
          className={twMerge(
            "bg-purple-200 p-3 mr-4 rounded-lg flex items-center gap-2 ",
            !canConfirm && "bg-gray-200"
          )}
          onClick={OnConfirm}
          disabled={!canConfirm}
        >
          <img
            src={"/images/icons/cart.svg"}
            width={20}
            height={20}
            alt="cart"
          />
          Confirm
        </button>
      )} */}

      {correctButton()}
    </>
  );
}

export default MenuOfferCartButton;
