import {
  changeIsLoading,
  removeDealList,
  removeOrderList,
  updateOrderDetails,
} from "@/app/_redux/slice/Order/OrderSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { AddMenuItemsForm } from "@/app/_types/menuDetails";

import AddToCartItemsName from "./AddToCartItemsName";
import { fixedDecimal } from "@/app/utils/DecimalAndNumbers";
import {
  UpdateCartInformation,
  updateCartInformation,
} from "@/app/_axios/serverApi";

import { useSelector } from "react-redux";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import { CalculateMenuTradingPeriod } from "../MenuTradingPeriods/CalculateTradingPeriod";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { ScheduleButtonForm } from "../ScheduleButton";
import moment from "moment";
interface AddToCartOrderItems {
  order: AddMenuItemsForm;
  isLoading?: boolean;
  location: string;
  todayTradingHoursData: TradingHour[];
  schedule: ScheduleButtonForm | null;
}

function AddToCartOrderItems({
  order,
  isLoading,
  location,
  schedule,
}: AddToCartOrderItems) {
  const dispatch = useAppDispatch();
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);

  const getAllPrice = order?.data?.reduce((acc, current) => {
    const currentPrice =
      (current?.comboPrice || 0) + (current?.ingredientPrice || 0);
    return acc + currentPrice;
  }, 0);

  const getAllPriceConfig = order?.config?.isSplit
    ? getAllPrice / 2
    : getAllPrice;

  const totalPrice =
    (order?.basePrice || 0) + (order?.sizePrice || 0) + getAllPriceConfig;

  const OrderManage = (status: "ADD" | "REMOVE") => {
    const getToken = localStorage.getItem("cart") || "";
    const finalData: UpdateCartInformation = {
      data: order,
      location: location,
      status,
      itemStatus: "MENU",
    };
    dispatch(changeIsLoading(true));

    const res = updateCartInformation(finalData, getToken);
    if (res?.status === 200) {
      const datas: { message: string; newToken: string } = res;
      localStorage.setItem("cart", datas?.newToken);
      dispatch(updateOrderDetails({ order, status }));
      dispatch(changeIsLoading(false));
    } else {
      localStorage.removeItem("cart");
      dispatch(removeOrderList());
      dispatch(removeDealList());

      dispatch(changeIsLoading(true));

      window.location.reload();
    }
  };

  if (!storeDT) return;

  // const CheckTradingPeriod = CalculateTradingPeriod({
  //   tradingPeriodId: order.periodId,
  //   todayTradingHoursData,
  //   selectedOrderStatus,
  //   storeDT,
  // });

  const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();

  const CheckTradingPeriod = CalculateMenuTradingPeriod({
    selectedOrderStatus,
    tradingHoursData: order?.tradingHours,
    tradingPeriodId: order?.periodId,
    selectedTime: schedule?.selectedTime?.date
      ? schedule?.selectedTime?.date
      : storeDT,
    selectedDay,
    storeDT,
  });

  return (
    <>
      <div className=" px-2 py-4">
        <div className="flex  gap-2 justify-between">
          <div className="flex gap-2 items-center">
            {!CheckTradingPeriod.status && (
              <img
                src={"images/icons/red-close.svg"}
                alt="Close"
                width="20"
                height="20"
              />
            )}
            {order?.data?.[0]?.imageUrl && !order?.config?.isSplit ? (
              <>
                <img
                  src={order?.data?.[0]?.imageUrl || ""}
                  alt="image"
                  width="50"
                  height="50"
                  className="rounded-md w-12 h-8 md:w-14 md:h-10 object-cover object-center"
                />
              </>
            ) : (
              <div className="w-14 h-10" />
            )}
            <p className={!CheckTradingPeriod.status ? "text-red-500" : ""}>
              {order?.config?.isSplit
                ? "Half / Half"
                : order?.menuSizeCount !== 1 ? order?.sizeName + " " + order?.name : order?.name}
            </p>
          </div>
          <div className=" grid grid-cols-[0.5fr_0.6fr_0.5fr_1fr] items-center justify-items-center  w-[47%]">
            <button
              type="button"
              onClick={() => OrderManage("REMOVE")}
              disabled={isLoading}
              className={isLoading ? "cursor-wait" : ""}
            >
              <img
                src="/images/icons/minus.svg"
                alt="minus"
                width="30"
                height="30"
                className="bg-slate-200 p-2 rounded-full"
              />
            </button>
            <p className="text-sm">{order?.qty}</p>
            <button
              type="button"
              onClick={() => OrderManage("ADD")}
              disabled={isLoading}
              className={isLoading ? "cursor-wait" : ""}
            >
              <img
                src="/images/icons/add.svg"
                alt="add"
                width="30"
                height="30"
                className="bg-gray-300 p-2 rounded-md"
              />
            </button>
            <div className="ml-auto">
              <p className="text-xs">
                $ {fixedDecimal(totalPrice * order?.qty)}
              </p>
            </div>
          </div>
        </div>
        <AddToCartItemsName order={order} />
        {!CheckTradingPeriod.status && (
          <p className="pl-3 pt-2 text-xs text-red-500">
            Menu is not available in {selectedOrderStatus}
          </p>
        )}
        {order?.note && (
          <div className="flex items-center space-x-2">
            <img
              src="/images/icons/receipt.svg"
              alt="L"
              width={15}
              height={15}
            />
            <p className="text-sm text-gray-500">{order?.note}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default AddToCartOrderItems;
