import NavBarHamBurger from "./NavBarHamBurger";
import Logo from "@/app/global/logo/Logo";
import { SelectedColor } from "@/app/_types/EcommerceTheme.interface";
import { StoreEntity } from "@/app/_types/store.interface";

function HomePageNavBar({
  EcommerceColor,
  storeList,
}: {
  EcommerceColor: SelectedColor;
  storeList: StoreEntity[];
}) {
  return (
    <nav className="navbars">
      <ul className="  h-full flex items-center px-2 md:px-4 w-full ">
        <li className="flex items-center w-fit  h-[inherit] p-0">
          <Logo />
        </li>

        <ol className="md:child:p-2 flex-1 h-[inherit]">
          <NavBarHamBurger
            EcommerceColor={EcommerceColor}
            storeList={storeList}
          />
        </ol>
      </ul>
    </nav>
  );
}

export default HomePageNavBar;
