import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { MenuWithCategories } from "@/app/_types/menuWithCategories";

const initialState: { menuWithCategories: MenuWithCategories } = {
  menuWithCategories: {
    mostLikedMenus: [],
    deals: [],
    offers: [],
    tradingPeriods: [],
    categories: [],
    openTime: "",
    closeTime: "",
  },
};

export const menuWithCategorySlice = createSlice({
  name: "menuWithCategories",
  initialState,
  reducers: {
    updateMenuWithCategoryList(
      state,
      action: PayloadAction<MenuWithCategories>
    ) {
      state.menuWithCategories = action.payload;
    },
    newMenuWithCategoryList(state){
      state.menuWithCategories = {mostLikedMenus: [],
        deals: [],
        offers: [],
        tradingPeriods: [],
        categories: [],
        openTime: "",
        closeTime: "",
      }}
  },
});

export const { updateMenuWithCategoryList, newMenuWithCategoryList } = menuWithCategorySlice.actions;
export const selectMenuWithCategories = (state: RootState) =>
  state.menuWithCategories;

export default menuWithCategorySlice.reducer;
