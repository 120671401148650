import toast from "react-hot-toast";
import {
  CalculateMenuTradingPeriod,
} from "../[location]/components/MenuTradingPeriods/CalculateTradingPeriod";
import { DealDetailsInCart } from "../_types/menuDeals.interface";
import { AddMenuItemsForm } from "../_types/menuDetails";
import { TradingHour } from "../_types/tradingHours.interface";
import { ScheduleButtonForm } from "../[location]/components/ScheduleButton";
import moment from "moment";

interface checkOrderStatusProps {
  selectedOrderStatus: string | null;
  allCartDetails: (AddMenuItemsForm | DealDetailsInCart)[];
  dealList: DealDetailsInCart[]
  todayTradingHours: TradingHour[];
  storeDT: string | null;
  schedule: ScheduleButtonForm | null;
}

export const checkOrderStatus = ({
  selectedOrderStatus,
  allCartDetails,
  dealList,
  storeDT,
  schedule,
}: checkOrderStatusProps) => {
  console.log("selectedOrderStatus", selectedOrderStatus);
  if (!storeDT) return;

  let tradingPeriodStatus = true;

  allCartDetails.forEach((item) => {
    // const cartStatus = CalculateTradingPeriod({
    //   tradingPeriodId: item?.periodId,
    //   todayTradingHoursData: todayTradingHours,
    //   selectedOrderStatus,
    //   storeDT,
    // });

    const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();
    const cartStatus = CalculateMenuTradingPeriod({
      selectedOrderStatus,
      tradingHoursData: item?.tradingHours,
      tradingPeriodId: item?.periodId,
      selectedTime: schedule?.selectedTime?.date
        ? schedule?.selectedTime?.date
        : storeDT,
        selectedDay,
        storeDT,
    });

    if (cartStatus?.status === false) {
      tradingPeriodStatus = false;
    }

    console.log("cartStatus.status", cartStatus.status);
  });

  dealList?.forEach((deals) => {
    deals?.dealItems?.some(dealItem => {
      return dealItem?.menus?.some(menu => {
        const tradingHours = menu?.tradingHours;
        const selectedDay = schedule?.selectedDay?.day
        ? schedule?.selectedDay?.day
        : moment(storeDT).format("ddd").toUpperCase();
        const result = CalculateMenuTradingPeriod({
          selectedOrderStatus,
          tradingHoursData: tradingHours,
          tradingPeriodId: deals?.periodId,
          selectedTime: schedule?.selectedTime?.date ? schedule?.selectedTime?.date : storeDT,
          selectedDay,
          storeDT,
        });
        console.log(menu?.name, result);
  
        // Check if the status is false
        if (!result.status) {
          tradingPeriodStatus = false
          return true;
        }
      });
    });
  })
  if (!tradingPeriodStatus) {
    toast.error(`Please remove the crossed orders.`);
  }
  return tradingPeriodStatus;
};

export const calculateTotalAmount = ({OrderList, dealList}: {OrderList: AddMenuItemsForm[], dealList: DealDetailsInCart[]}) => {
  const getSubtotal = OrderList?.reduce((acc, current) => {
    const currentPrice = current?.totalPrice || 0;
    const total = currentPrice * current?.qty;
    return acc + total;
  }, 0);

  const deal = dealList?.filter((el) => el.cartType === "Deal");
  const offer = dealList?.filter((el) => el.cartType === "Offer");

  const getDealSubtotal = deal?.reduce((acc, current) => {
    const currentPrice = current?.totalPrice || 0;
    const total = currentPrice * current?.qty;
    return acc + total;
  }, 0);

  const getOfferSubtotal = offer?.reduce((acc, current) => {
    const currentPrice = current?.totalPrice || 0;
    const total = currentPrice * current?.qty;
    return acc + total;
  }, 0);

  const getActualTotalPrice = getSubtotal + getDealSubtotal + getOfferSubtotal;

  return getActualTotalPrice
}

interface calculatScheduleAndStoreMinsDiffProps {
  storeDT?: string | null,
  scheduleDate?: string,
}

export const calculatScheduleAndStoreMinsDiff = ({storeDT, scheduleDate}:calculatScheduleAndStoreMinsDiffProps) => {
  if(!storeDT || !scheduleDate) return

  // Format storeDT to remove milliseconds
  const formattedStoreDT = moment(storeDT).format('YYYY-MM-DDTHH:mm')
  const formattedscheduleDate = moment(scheduleDate).format('YYYY-MM-DDTHH:mm')

  console.log(storeDT, scheduleDate)
  console.log(formattedStoreDT, formattedscheduleDate)

  const momentscheduleDate = moment(formattedscheduleDate)
  const momentStoreDT = moment(formattedStoreDT)

  console.log(momentStoreDT, momentscheduleDate)

  const scheduleMins = momentscheduleDate?.diff(momentStoreDT, "minutes")

  console.log(scheduleMins)
  return scheduleMins
}
