import React, { useMemo } from "react";
import moment from "moment";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import { useQueryClient } from "@tanstack/react-query";
import { DeliveryInfoDetail } from "@/app/_types/store.interface";

interface MoreInfoModalProps {
  open: boolean;
  handleClose: () => void;
  tradingHours: TradingHour[];
  storeId: string;
}

const MoreInfoModal: React.FC<MoreInfoModalProps> = ({
  open,
  handleClose,
  tradingHours,
  storeId,
}) => {
  const client = useQueryClient();

  const deliveryInfo = client.getQueryData<DeliveryInfoDetail>([
    "getDeliveryInfo",
    storeId,
  ]);

  console.log("hit place deliveryInfo", deliveryInfo, storeId);

  // Convert day codes to full names
  const dayNameMap: Record<string, string> = {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
    SAT: "Saturday",
    SUN: "Sunday",
  };

  // Group trading hours by day
  const tradingHoursByDay = useMemo(() => {
    const grouped: Record<string, TradingHour[]> = {};
    tradingHours.forEach((th) => {
      if (!grouped[th.day]) {
        grouped[th.day] = [];
      }
      grouped[th.day].push(th);
    });
    return grouped;
  }, [tradingHours]);

  return (
    <CustomModalClients
      open={open}
      handleClose={handleClose}
      className="min-w-64 w-full sm:w-2/3 md:w-3/5 lg:w-2/5 h-full md:h-[90vh] bg-white overflow-y-auto"
    >
      <div className="relative p-6 space-y-6">
        {/* Close/cross button at top-right */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 bg-transparent border-none cursor-pointer"
        >
          <img
            src="/images/icons/close.svg"
            width={25}
            height={25}
            alt="close"
          />
        </button>

        {/* Store Trading Hours Section */}
        <div>
          {/* Larger heading */}
          <h2 className="mb-4 font-bold text-xl">Store Trading Hours</h2>

          {Object.keys(dayNameMap).map((dayCode) => {
            const entries = tradingHoursByDay[dayCode] || [];
            // Filter out closed entries and those without valid times
            const openEntriesWithTime = entries.filter(
              (e) => !e.isClosed && e.startTime && e.endTime
            );

            // If no entries with valid times, display "Closed"
            if (openEntriesWithTime.length === 0) {
              return (
                <div
                  key={dayCode}
                  className="grid grid-cols-1 sm:grid-cols-[120px,1fr] gap-x-4 items-center border-b border-gray-300 py-3 text-base"
                >
                  <strong>{dayNameMap[dayCode]}:</strong>
                  <span>Closed</span>
                </div>
              );
            }

            // Find earliest opening and latest closing among open entries
            const validStartTimes = openEntriesWithTime.map((e) =>
              moment(e.startTime, "HH:mm:ss")
            );
            const validEndTimes = openEntriesWithTime.map((e) =>
              moment(e.endTime, "HH:mm:ss")
            );

            // Ensure that all dates are valid
            const allValid =
              validStartTimes.every((m) => m.isValid()) &&
              validEndTimes.every((m) => m.isValid());

            if (!allValid) {
              return (
                <div
                  key={dayCode}
                  className="grid grid-cols-1 sm:grid-cols-[120px,1fr] gap-x-4 items-center border-b border-gray-300 py-3 text-base"
                >
                  <strong>{dayNameMap[dayCode]}:</strong>
                  <span>Invalid trading hours</span>
                </div>
              );
            }

            const earliest = moment.min(validStartTimes);
            const latest = moment.max(validEndTimes);
            const earliestStr = earliest.format("h:mm A");
            const latestStr = latest.format("h:mm A");

            return (
              <div
                key={dayCode}
                className="flex justify-between items-center border-b border-gray-300 py-3 text-base px-4"
              >
                <strong className="mr-2 w-32">{dayNameMap[dayCode]}</strong>
                <span>
                  {earliestStr} - {latestStr}
                </span>
              </div>
            );
          })}
        </div>

        {/* Delivery Information Section */}
        {deliveryInfo && (
          <div className="bg-gray-100 rounded p-2">
            {/* Delivery Information Heading */}
            <h2 className="mb-4 font-bold text-xl">Delivery Information</h2>

            <div className="space-y-3">
              {/* Delivery Distance */}
              <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                <strong className="w-full sm:w-auto">Delivery Distance:</strong>
                <span>{deliveryInfo.maxDeliveryDistance} km</span>
              </div>

              {/* Minimum Order Amount */}
              <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                <strong className="w-full sm:w-auto">
                Minimum order(excl. Delivery):
                </strong>
                <span>${deliveryInfo.minOrderAmount}</span>
              </div>

              {/* Suburbs */}
              <div>
                <div className="block mb-4 text-xl font-bold">
                  We Deliver in
                </div>
                {deliveryInfo.suburbs && deliveryInfo.suburbs.length > 0 ? (
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    {deliveryInfo.suburbs.map((suburb) => (
                      <div
                        key={suburb.id}
                        className="text-gray-700 font-bold border rounded-lg p-2 flex items-center justify-center shadow"
                      >
                        {suburb.name}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-gray-700 text-center border rounded-lg p-4">
                    No suburbs available
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Handle case where deliveryInfo is not available */}
        {!deliveryInfo && (
          <div>
            <h2 className="mb-4 font-bold text-xl">Delivery Information</h2>
            <span className="text-gray-700">
              No delivery information available.
            </span>
          </div>
        )}
      </div>
    </CustomModalClients>
  );
};

export default MoreInfoModal;
