import { useEffect, useState } from "react";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import {
  DealCategory,
  DealDetails,
  IndividualDealOrderItems,
  MenuDealFormArray,
} from "@/app/_types/menuDeals.interface";

import NotFound404 from "@/app/global/NoData/NotFound404";
import MenuDealForm from "./MenuDealForms/MenuDealForm";

import MenuDealDetailClientWrapper from "./MenuDealDetailClientWrapper";
import SomethingWentWrong from "@/app/global/NoData/SomethingWentWrong";
import { v4 as uuidv4 } from "uuid";
import StoreClientWrapper from "@/app/global/StoreWrapper/StoreClientWrapper";
import MenuDealTitle from "./MenuDealForms/MenuDealTitle";
import toast from "react-hot-toast";
import { MenuDetailsEntity } from "@/app/_types/menuDetails";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "@/app/[location]/menu/utils/sizeChangeUtils";
import { StoreEntity } from "@/app/_types/store.interface";
import { useSelector } from "react-redux";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import moment from "moment";
import MenuDetailsLoadingScreen from "@/app/[location]/components/MenuDetails/MenuDetailsLoadingScreen";

async function createObject(
  deals: DealCategory,
  dealIndex: number,
  storeId: string,
  periodId: string
) {
  const quantity = deals?.qty;
  const arrayData: MenuDealFormArray[] = [];

  let getIsExtraMenu: IndividualDealOrderItems | undefined = undefined;
  const dealCategoriesMenuLength = deals?.dealCategoryMenus?.length;

  if (dealCategoriesMenuLength === 1) {
    const menu = deals?.dealCategoryMenus?.[0];

    const menuDetails: MenuDetailsEntity = await fetchRequest(
      serverPath.getMenuDetails(storeId, menu?.menuId, periodId),
      "GET",
      undefined,
      { cache: "no-store" }
    );

    if (!menuDetails) {
      toast.error(
        "Menu Details Not Found. Please Refresh the Page and Try Again"
      );
      return;
    }

    const comboList = ManageComboPayInitial(
      menuDetails?.menuOptionGroups || [],
      menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
    );

    const comboPrices = getIndividualMenuComboPrice(comboList);

    if (!menu?.hasExtras) {
      getIsExtraMenu = {
        id: menuDetails?.menu?.id,
        menuSizeId: menu?.menuSizeId,
        name: menu?.menuName,
        hasExtras: menu?.hasExtras,
        sizeGroupId: menu?.sizeGroupId,
        finalPrice: menu?.finalPrice || 0,
        basePrice: menuDetails?.menu?.basePrice || 0,
        specialPrice: menu?.specialPrice || 0,
        comboPrice: comboPrices,
        ingredientPrice: 0,
        hasHalfHalf: menu?.hasHalfHalf,
        optionGroups: comboList || [],
        ingredients: menuDetails?.menuOptions || [],
        tradingHours: menu?.tradingHours || [],
        menuSizeCount: menu?.menuSizeCount,
      };
    }
  }

  for (let i = 1; i <= quantity; i++) {
    arrayData.push({
      uuid: uuidv4(),
      dealCategoryId: deals?.id,
      dealCategoryIndex: dealIndex,
      dealId: deals?.dealId,
      categoryId: deals?.categoryId,
      name: deals?.categoryName,
      size: deals?.sizeId,
      isSelected: getIsExtraMenu ? true : false,
      sizeName: deals?.sizeName,
      menus: getIsExtraMenu ? [getIsExtraMenu] : [],
      config: {
        isSplit: false,
        selectedMenuIndex: 0,
        isRemove: false,
        canAddToCart: true,
      },
    });
  }
  return arrayData;
}

function MenuDealsDetails({
  params,
  isModal,
}: {
  params: { name: string; location: string; periodId: string };
  isModal: boolean;
}) {
  const [storeDetails, setStoreDetails] = useState<StoreEntity | null>(null);
  const [getStoreDT, setGetStoreDT] = useState<string>("");
  const [menuDealDetails, setMenuDealDetails] = useState<DealDetails | null>(
    null
  );
  const [dealsForm, setDealsForm] = useState<MenuDealFormArray[]>([]);
  const [loading, setLoading] = useState(true);
  const { schedule } = useSelector(order);

  const { name, location, periodId } = params;

  console.log("hit", params);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const storeDetails = await getCurrentStoreDetails(location);

        const storeDetails = await fetchRequest(
          serverPath?.getStoreEntityDetails(location)
        );

        if (!storeDetails) {
          setLoading(false);
          return;
        }

        setStoreDetails(storeDetails);

        const storeDT = await fetchRequest(
          serverPath.getStoreDT(storeDetails?.id),
          "GET",
          undefined,
          {
            cache: "no-cache",
          }
        );
        setGetStoreDT(storeDT?.storeCurrentDt);

        let selectedDay;
        if (schedule) {
          selectedDay = moment(schedule?.selectedTime?.date)
            .format("ddd")
            .toUpperCase();
          console.log(selectedDay);
        } else {
          selectedDay = moment(storeDT?.storeCurrentDt)
            .format("ddd")
            .toUpperCase();
          console.log(selectedDay);
        }

        console.log(selectedDay);
        const menuDealDetails: DealDetails = await fetchRequest(
          serverPath.getMenuDealDetails(
            storeDetails?.id,
            name,
            selectedDay,
            periodId
          ),
          "GET",
          undefined,
          { cache: "no-store" }
        );
        if (!menuDealDetails?.id) {
          setLoading(false);
          return;
        }

        setMenuDealDetails(menuDealDetails);

        const dealCategories = menuDealDetails?.dealCategories;
        const dealsFormArray: MenuDealFormArray[] = [];

        for (let i = 0; i < dealCategories.length; i++) {
          const dealCategoriesEntity = dealCategories[i];
          const newDeal = await createObject(
            dealCategoriesEntity,
            i,
            storeDetails?.id,
            periodId
          );
          if (newDeal) {
            dealsFormArray.push(...newDeal);
          }
        }
        console.log("dealsFormArray",dealsFormArray, dealCategories[2])
        setDealsForm(dealsFormArray);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [location, name, periodId, schedule]);

  if (loading) {
    return <MenuDetailsLoadingScreen />;
  }

  if (!storeDetails) {
    return <NotFound404 />;
  }

  if (!menuDealDetails) {
    return <SomethingWentWrong />;
  }

  return (
    <StoreClientWrapper selectedStore={storeDetails} storeDT={getStoreDT}>
      <MenuDealDetailClientWrapper menuDeals={menuDealDetails}>
        <div className="flex relative" style={{height: "75dvh"}}>
          <div className="hidden md:block w-1/2">
            <div className="h-full relative">
              <img
                src={
                  menuDealDetails?.imageUrl
                    ? menuDealDetails?.imageUrl
                    : "/images/icons/noMenuImage.jpg"
                }
                width={400}
                height={400}
                alt="Deal Image"
                className="w-full h-full object-cover object-center"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 flex flex-col relative pb-32" style={{height: "75dvh"}}>
          <div className="overflow-auto">
            <div className="px-4 py-2 border-b-2 border-gray-100">
              <h1 className="text-2xl font-extrabold flex items-center justify-between">
                {menuDealDetails?.title}
                {isModal ? <MenuDealTitle /> : null}
              </h1>
              <p>{menuDealDetails?.description}</p>
            </div>
            <MenuDealForm
              dealForm={dealsForm}
              isModal={isModal}
              menuDealDetails={menuDealDetails}
              periodId={periodId}
            />
          </div>
        </div>
        </div>
      </MenuDealDetailClientWrapper>
    </StoreClientWrapper>
  );
}

export default MenuDealsDetails;
