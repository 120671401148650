import {
  AddMenuItemsForm,
  IndividualOrder,
  OrderIngredient,
} from "@/app/_types/menuDetails";
import { useContext,  } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormMenuDataProvider } from "../MenuDetailsForm";
import { twMerge } from "tailwind-merge";
import { SizeGroup } from "@/app/_types/extraMenuPriceInfo";

import { IndividualDealOrderItems } from "@/app/_types/menuDeals.interface";
import { ComboPortion } from "@/app/[location]/components/AddToCart/AddToCartItemsName";

function AddMenuHalfAndHalfItem({
  data,
  index,
}: {
  data: IndividualOrder;
  index: number;
  sizeGroup?: SizeGroup;
}) {
  const { control, setValue, getValues } = useFormContext<AddMenuItemsForm>();
  const DataField = useContext(FormMenuDataProvider);

  const watchConfig = useWatch({
    control,
    name: "config",
  });

  const ChangeMenuDetailsHandler = () => {
    setValue(`config.selectedIndex`, index);
    setValue(`config.canAddToCart`, false);
  };

  const RemoveItemHandler = () => {
    DataField?.remove(index);
    setValue("config.selectedIndex", 0);
    setValue("config.isSplit", false);
    setValue("config.canAddToCart", true);
    setValue("config.isRemove", false);

    const getData = getValues("data");
    const getSize = getValues("size");
    const findSize = getData?.[0].menuSizeList?.find(
      (el) => el?.sizeId === getSize
    );
    if (findSize) {
      setValue(`basePrice`, findSize?.basePrice || 10);
      setValue(`sizeList`, getData?.[0]?.menuSizeList);

      setValue(`sizePrice`, findSize?.additionalPrice);
    }

    setValue(`name`, getData?.[0].name);
  };

  return (
    <div className="bg-white rounded-md flex flex-col justify-start  gap-2 relative">
      {watchConfig?.isRemove && (
        <div className="bg-black/70 text-white absolute inset-0 flex">
          <button
            className="gap-2 flex items-center justify-center h-full flex-1"
            onClick={RemoveItemHandler}
          >
            {/* <Icon icon="pajamas:remove" /> */}
            <img
              src="/images/icons/pajamasRemove.svg"
              width="15"
              height="15"
              alt="x"
            />
            <p>Remove Item</p>
          </button>
        </div>
      )}
      <button
        className={
          watchConfig?.isRemove
            ? `flex items-center justify-between p-4`
            : "flex items-center justify-between p-4"
        }
        onClick={ChangeMenuDetailsHandler}
      >
        {data?.id ? (
          <MenuHalfHalfItemShow
            data={data}
            isSplit={watchConfig?.isSplit || false}
          />
        ) : (
          <p className="text-lg font-bold animate-pulse">Choose Another Item</p>
        )}

        <img src="/images/icons/right.svg" width={20} height={20} alt=">" />
      </button>
    </div>
  );
}

export function MenuHalfHalfItemShow({
  data,
  isSplit,
}: {
  data: IndividualOrder | IndividualDealOrderItems;
  isSplit: boolean;
}) {
  // const checkCombo = data?.optionGroups?.some((el) => {
  //   const options = el?.options?.some((item) => item?.qty > 0);
  //   return options;
  // });

  const removeIngredient = data?.ingredients?.filter(
    (el) => el?.status === "current" && el?.qty <= 0
  );

  console.log("ADDmenuhalfandhalfItem", data);

  return (
    <>
      <div className="flex-1 flex items-start flex-col">
        <p className="font-bold text-base text-left mb-2">
          <span className="text-sm font-medium">1/2 </span>
          {data?.name}
        </p>
        <div className="text-sm text-left w-full">
          {data?.optionGroups?.length
            ? data?.optionGroups?.map((combo, index) => (
                <ComboPortion combo={combo} isSplit={isSplit} key={index} />
              ))
            : null}
          {removeIngredient && removeIngredient?.length > 0 && (
            <p className="flex flex-wrap bg-gray-200 rounded-md p-1">
              Removed :{" "}
              {removeIngredient?.length > 0 &&
                removeIngredient.map((ing, index) => {
                  const isRemoved = !!(ing?.qty <= 0);
                  return (
                    <>
                      <span
                        className={twMerge(
                          "italic flex items-center gap-[4px]",
                          isRemoved && "text-red-500 line-through"
                        )}
                        key={index}
                      >
                        {ing?.name}
                        {removeIngredient?.length - 1 !== index && ",  "}{" "}
                      </span>
                    </>
                  );
                })}
            </p>
          )}
          <AdditionalItems ingredients={data?.ingredients} isSplit={isSplit} />
        </div>
      </div>
    </>
  );
}

function AdditionalItems({
  ingredients,
  isSplit,
}: {
  ingredients: OrderIngredient[];
  isSplit: boolean;
}) {
  const filterIngredients = ingredients?.filter(
    (el) => el?.status === "added" || (el?.status === "current" && el?.qty > 1)
  );

  if (!filterIngredients?.length) return null;

  return (
    <p className="flex flex-wrap">
      Additional :
      {filterIngredients?.map((ing, index) => {
        return (
          <>
            <span className={"italic flex items-center gap-[4px]"} key={index}>
              <span>{ing?.name}</span>
              <>
                {/* <Icon icon="uim:multiply" width="10px" /> */}
                <img
                  src="/images/icons/cross.svg"
                  width="15"
                  height="15"
                  alt="x"
                />
                <span>
                  {ing?.status === "current" ? ing?.qty - 1 : ing?.qty}
                </span>
                <span>
                  [$
                  {isSplit ? (ing?.total || 0) / 2 : ing?.total}]
                </span>
              </>
              {filterIngredients?.length - 1 !== index && ", "}{" "}
            </span>
          </>
        );
      })}
    </p>
  );
}

export default AddMenuHalfAndHalfItem;
