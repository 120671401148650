
function SomethingWentWrong() {
  const ReloadHandler = () => {
    window.location.reload();
  };

  return (
    <div className="h-[100vh] flex items-center justify-center flex-col mx-auto ">
      <img
        src="/images/icons/oops-error.svg"
        alt="404"
        width={500}
        height={500}
        className="w-auto h-44 object-cover"
      />
      <div className="flex flex-col gap-4">
        <h1 className="text-4xl font-semibold text-center">
          Something Went Wrong !
        </h1>
        <p className="text-center text-lg text-gray-500">
          The page didn&apos;t load as expected. Please reload or Contact Store
          for Support.
        </p>
        <button
          className="underline text-primary text-lg"
          onClick={ReloadHandler}
        >
          Try Again
        </button>
      </div>
    </div>
  );
}

export default SomethingWentWrong;
