import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./_redux/store";
import Router from "./_router/router";
import Footer from "./component/HomePage/Footer";
import { Toaster } from "react-hot-toast";
import AuthProvider from "./guard/AuthProvider";
import { ErrorBoundary } from "react-error-boundary";
import SomethingWentWrong from "./global/NoData/SomethingWentWrong";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      retry: 2,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <Toaster position="top-center" />
            <AuthProvider>
              <ErrorBoundary fallback={<SomethingWentWrong/>}>
              <Router />
              <Footer />
              </ErrorBoundary>
            </AuthProvider>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </>
  );
}

export default App;
