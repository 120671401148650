import { useQuery } from "@tanstack/react-query";

import fetchRequest from "../_axios/fetchRequest";
import { serverPath } from "../_axios/path";
import LoadingLogo from "../global/loading/LoadingLogo";

function AuthProvider({ children }: { children: React.ReactNode }) {
  const storages = localStorage.getItem("session");

  let session = null;
  if (storages && storages !== undefined) {
    try {
      session = JSON.parse(storages);
    } catch (error) {
      console.error("Failed to parse session storage:", error);
      session = null; // Set session to null if parsing fails
    }
  } else {
    session = null; // If storage is null, explicitly set session to null
  }

  const { isLoading, data } = useQuery({
    queryKey: ["currentCustomer"],
    queryFn: () => fetchRequest(serverPath?.currentCustomer),
    enabled: !!session?.acessToken,
  });

  console.log("auth session data => ", isLoading, data);

  if (!!session?.acessToken && isLoading)
    return <LoadingLogo className="min-h-[100vh] bg-gray-100" />;

  return <>{children}</>;
}

export default AuthProvider;
