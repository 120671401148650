import { MenuOption } from "@/app/_types/menuDetails";

import MenuDealEditOptionItem from "./MenuDealEditOptionItem";
import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";

interface MenuDetailEditOptionProps {
  menuOptions: MenuOption[];
  optionGroups: OptionGroup[];
}

function MenuDetailEditOption({
  menuOptions,
  optionGroups,
}: MenuDetailEditOptionProps) {
  console.log(menuOptions);
  return (
    <div className="px-y py-2">
      {menuOptions?.length > 0 && (
        <div className="px-4">
          <p className="text-lg font-bold">Current Toppings</p>
          <div className="divide-y-2">
            {menuOptions
              ?.filter((item) => item.status === "current")
              .map((option, index: number) => (
                <MenuDealEditOptionItem
                  item={option}
                  key={index}
                  isExtraOption={false}
                  optionGroups={optionGroups}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default MenuDetailEditOption;
