import { Customer } from "@/app/_types/checkout.interface";

export function validateCustomerOrderType(
  orderType: "DELIVERY" | "STORE" | "PICKUP" | "TABLE",
  customerDetails: Customer
) {
  const checkUser =
    (orderType === "DELIVERY" &&
      customerDetails?.firstName &&
      customerDetails?.address?.[0]?.address?.streetName) ||
    (orderType === "STORE" && customerDetails?.firstName)
      ? true
      : false;

  return checkUser;
}
