import {
  getSchedules,
  order,
  scheduleModel,
} from "@/app/_redux/slice/Order/OrderSlice";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import {
  changeSelectedOrderStatus,
  selectUI,
} from "@/app/_redux/slice/UISlice/UISlice";
import { useAppDispatch } from "@/app/_redux/store";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";

interface DeliveryType {
  id: number;
  name: string;
  value: "DELIVERY" | "PICKUP";
}

const DELIVERY_TYPE: DeliveryType[] = [
  { id: 1, name: "Delivery", value: "DELIVERY" },
  { id: 2, name: "Pick Up", value: "PICKUP" },
];

function AddToCartDeliveryType() {
  const dispatch = useAppDispatch();
  const { selectedStore } = useSelector(selectStore);


  const { selectedOrderStatus } = useSelector(selectUI);
  const { schedule } = useSelector(order);

  // const ChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value as "DELIVERY" | "PICKUP";
  //   dispatch(changeSelectedOrderStatus(value));
  //   localStorage.setItem("selectedOrderStatus", value)
  //   if (schedule?.orderType !== value && schedule !== null) {
  //     dispatch(getSchedules(null));
  //     dispatch(scheduleModel(true));
  //   }
  // };

  const ChangeHandler = (value: "DELIVERY" | "PICKUP") => {
    // const value = e.target.value as "DELIVERY" | "PICKUP";
    localStorage.setItem("selectedOrderStatus", value);
    dispatch(changeSelectedOrderStatus(value));
    if (schedule?.orderType !== value && schedule !== null) {
      dispatch(getSchedules(null));
      dispatch(scheduleModel(true));
    }
  };
  return (
    <div className="flex items-center justify-center divide-x-2 ">
      {DELIVERY_TYPE?.map((type, index) => {
        return (
          <div key={index} className={`flex-1 border-2 ${!selectedStore?.isDeliveryEnabled && type?.value === "DELIVERY" ? "hidden" : ""}`}>
            <button
              onClick={() => ChangeHandler(type?.value)}
              className={twMerge(
                `cursor-pointer p-4 flex h-full w-full justify-center`,
                selectedOrderStatus === type?.value
                  ? "bg-black text-white"
                  : "bg-white text-black"
              )}
            >
              {type?.name}
            </button>
          </div>
          // <div key={index} className="w-1/2  border-2">
          //   <input
          //     type="radio"
          //     name="deliveryType"
          //     id={`radio${type?.id}`}
          //     className="peer/menuSize"
          //     onChange={(e) => ChangeHandler(e)}
          //     value={type?.value}
          //     hidden
          //   />
          //   <label
          //     htmlFor={`radio${type?.id}`}
          //     className={twMerge(
          //       `cursor-pointer p-4 flex h-full  peer-checked/menuSize:bg-black peer-checked/menuSize:text-white `,
          //       selectedOrderStatus === type?.value
          //         ? "bg-black text-white"
          //         : "bg-white text-black"
          //     )}
          //   >
          //     {type?.name}
          //   </label>
          // </div>
        );
      })}
    </div>
  );
}

export default AddToCartDeliveryType;
