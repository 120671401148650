import {
  getOfferDetails,
} from "@/app/_redux/slice/Order/OrderSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { Children } from "@/app/_types/common";
import { OfferDetails } from "@/app/_types/menuOffers.interface";
import { useEffect } from "react";

interface MenuOfferDetailClientWrapperProps extends Children {
  menuOffers: OfferDetails;
}

function MenuOfferDetailClientWrapper({
  children,
  menuOffers,
}: MenuOfferDetailClientWrapperProps) {
  console.log({ menuOffers });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOfferDetails(menuOffers));
  }, [dispatch, menuOffers]);
  return <>{children}</>;
}

export default MenuOfferDetailClientWrapper;
