import type {
  DealDetails,
  MenuDealForm,
  MenuDealFormArray,
} from "@/app/_types/menuDeals.interface";
import MenuDealFormList from "./MenuDealFormList";
import { useForm, FormProvider } from "react-hook-form";

interface MenuDealFormProps {
  dealForm: MenuDealFormArray[];
  isModal: boolean;
  menuDealDetails: DealDetails;
  periodId: string;
}

function MenuDealForm({
  dealForm,
  isModal,
  menuDealDetails,
  periodId,
}: MenuDealFormProps) {
  const defaultValues: MenuDealForm = {
    periodId: periodId,
    cartType: "Deal",
    menuDeal: dealForm,
    id: menuDealDetails?.id,
    isForCard: menuDealDetails?.isForCard,
    isForCash: menuDealDetails?.isForCash,
    isForDelivery: menuDealDetails?.isForDelivery,
    isForPickup: menuDealDetails?.isForPickup,
    isForStore: menuDealDetails?.isForStore,
    isForTable: menuDealDetails?.isForTable,
    comboPriceType: "AVERAGE",
    discountPrice: menuDealDetails?.discountRate,
    discountRate: menuDealDetails?.discountRate,
    name: menuDealDetails?.title,
    dealType: menuDealDetails?.dealType,
    qty: 1,
    description: menuDealDetails?.description || null,
    imageUrl: menuDealDetails?.imageUrl || null,
    sellingPrice: menuDealDetails?.sellingPrice || 0,
    tradingHours: menuDealDetails?.tradingHours,
    totalPrice: 0,
    config: {
      isLoading: false,
      selectedDealIndex: 0,
      selectedDealCategoryIndex: 0,
      canConfirm: true,
      isMenuHalfHalf: false,
      selectedPage: "SELECT_DEAL",
    },
  };

  const methods = useForm<MenuDealForm>({ defaultValues });

  console.log("watch => ", methods.watch());
  return (
    <FormProvider {...methods}>
      <MenuDealFormList isModal={isModal} />
    </FormProvider>
  );
}

export default MenuDealForm;
