import { useMemo } from "react";
import HomePageHeader from "./component/HomePage/HomePageHeader";
import WhatWeOffer from "./component/HomePage/WhatWeOffer";
import { useQueries } from "@tanstack/react-query";
// import {
//   ImageData,
//   ImageUUIDMapValue,
//   OriginalData,
//   TransformedItem,
// } from "./_types/EcommerceTheme.interface";
import { serverPath } from "./_axios/path";
import { StoreEntity } from "./_types/store.interface";
import NotFound404 from "./global/NoData/NotFound404";
import LoadingLogo from "./global/loading/LoadingLogo";
import StoreLocation from "./component/HomePage/StoreLocation";
import fetchRequest from "./_axios/fetchRequest";
import AvailableDevices from "./component/HomePage/AvailableDevices";
import { useSelector } from "react-redux";
import { selectUI } from "./_redux/slice/UISlice/UISlice";

const HomePage = () => {
  const [ getStoreQuery] =
    useQueries({
      queries: [
        // {
        //   queryKey: ["getTheme"],
        //   queryFn: () => fetchRequest(serverPath?.getTheme, "GET"),
        // },
        {
          queryKey: ["storeList"],
          queryFn: () => fetchRequest(serverPath?.storeList, "GET"),
        },
      ],
    });

  const isLoading = useMemo(() => {
    return [ getStoreQuery]?.some(
      (query) => query?.isLoading
    );
  }, [ getStoreQuery]);

  // const client = useQueryClient();
  //   const getThemeColor = client.getQueryData<TransformedItem[]>(["getThemeColor"]);

  const { storeThemeData } = useSelector(selectUI);

  if (isLoading)
    return <LoadingLogo className="h-[100vh]" />;

  if (
    getStoreQuery?.isError ||
    !getStoreQuery?.data || !storeThemeData
  )
    return <NotFound404 />;

  const getStore: StoreEntity[] = getStoreQuery?.data;

  return (
    <div>
      <HomePageHeader
        hero={storeThemeData?.data?.hero}
        storeList={getStore}
      />
      <div className="maxContainer px-4 sm:px-14">
        <StoreLocation
          ThemeLocationData={storeThemeData?.data?.location}
          storeList={getStore}
        />
        <WhatWeOffer
          // colors={ThemeColorFormattedData?.data?.chooseColor?.color}
          about={storeThemeData?.data?.about}
        />
        {/* <StoreDetailsInfo 
          ThemeLocationData={ThemeFormattedData?.data?.location}
          storeList={getStore}
          color={ThemeColorFormattedData?.data?.chooseColor?.color}
          /> */}
        {/* <HowToOrder /> */}
        <AvailableDevices />
        {/* <Footer data={getColors} /> */}
      </div>
    </div>
  );
};

export default HomePage;
