import { useEffect, useState } from "react";
import MiniCategorySidebar from "./components/CategoryListSidebar/MiniCategorySidebar";
import {
  MenuWithCategories,
  reActivationTimes,
} from "../_types/menuWithCategories";
import { TradingHour } from "../_types/tradingHours.interface";
import fetchRequest from "../_axios/fetchRequest";
import { serverPath } from "../_axios/path";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMenuWithCategories,
  updateMenuWithCategoryList,
} from "../_redux/slice/menuWithCategories.ts/menuWithCategoriesSlice";
import CategorylListHeadersInfo from "./components/Headers/CategorylListHeadersInfo";
import { EstimatedTime } from "@/app/_redux/slice/UISlice/UISlice.d";
import { StoreEntity } from "../_types/store.interface";
import MenuList from "./components/MenuList/MenuList";

interface MenuDetailsInfo {
  data: MenuWithCategories;
  location: string;
  reActivationTimesData: reActivationTimes[];
  tradingHours: TradingHour[];
  store: StoreEntity;
  storeDT: string;
  estimatedTime?: EstimatedTime;
}

const MenuDetailsInfo = ({
  data,
  location,
  reActivationTimesData,
  tradingHours,
  store,
  storeDT,
  estimatedTime,
}: MenuDetailsInfo) => {
  const { menuWithCategories } = useSelector(selectMenuWithCategories);

  const [MenuInfoData, setMenuInfoData] = useState<MenuWithCategories>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(menuWithCategories)
    console.log( data)
    if (menuWithCategories?.tradingPeriods?.length < 1) {
      setMenuInfoData(data);
    } else {
      setMenuInfoData(menuWithCategories);
    }
  }, [menuWithCategories, data]);

  const findDataAvailable = (tradingPeriodId: string) => {
    const tradingPeriod = MenuInfoData?.tradingPeriods?.find(
      (el) => el?.id === tradingPeriodId
    );
    if (tradingPeriod) {
      if (
        (tradingPeriod.offers && tradingPeriod.offers.length > 0) ||
        (tradingPeriod.deals && tradingPeriod.deals.length > 0) ||
        (tradingPeriod.mostLikedMenus &&
          tradingPeriod.mostLikedMenus.length > 0)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSelectedTradingPeriod = async ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => {
    const currentMenu = findDataAvailable(tradingPeriodId);
    if (!currentMenu) return;
    setLoading(true);
    const catWithMenu: MenuWithCategories = await fetchRequest(
      serverPath.categoriesWithMenu(store?.id, tradingPeriodId),
      "GET",
      undefined,
      { cache: "no-cache" }
    );
    if (catWithMenu?.tradingPeriods) {
      setMenuInfoData(catWithMenu);
      dispatch(updateMenuWithCategoryList(catWithMenu));
      const section = document.getElementById("top-level-id");
      if (section) {
        const sectionTop = section.offsetTop;
        window.scrollTo({
          top: sectionTop,
          behavior: "smooth",
        });
      }
    } else {
      toast.error("Something went wrong.While fetching category");
    }
    setLoading(false);
  };

  // if (isLoading) return <LocationDownLoading />;
  if (!MenuInfoData) return;
  return (
    <div>
      <CategorylListHeadersInfo
        data={MenuInfoData}
        storeDT={storeDT}
        tradingHours={tradingHours}
        estimatedTime={estimatedTime}
        store={store}
        handleSelectedTradingPeriod={handleSelectedTradingPeriod}
        reActivationTimesData={reActivationTimesData}
      />
      <div id="top-level-id">
        <MiniCategorySidebar
          data={MenuInfoData}
          handleSelectedTradingPeriod={handleSelectedTradingPeriod}
        />
        {loading ? (
          <div className="flex items-center justify-center min-h-[90vh]">
            <img
              src="/images/icons/loading.svg"
              width="24"
              height="24"
              alt="Loading..."
              className="w-20"
            />
          </div>
        ) : (
          <MenuList
            data={MenuInfoData}
            params={location}
            reActivationTimesData={reActivationTimesData}
            tradingHours={tradingHours}
            handleSelectedTradingPeriod={handleSelectedTradingPeriod}
          />
        )}
      </div>
    </div>
  );
};

export default MenuDetailsInfo;
