// import LogoNavBar from "../../component/NavBar/LogoNavBar";
import LoginForm from "./components/LoginForm";
import { StoreEntityDetails } from "@/app/_types/store.interface";
import { serverPath } from "@/app/_axios/path";
import fetchRequest from "@/app/_axios/fetchRequest";
import StoreDetailsInRedux from "@/app/forget/StoreDetailsInRedux";
import { useQuery } from "@tanstack/react-query";

function LoginPage() {
  // const getStoreDetails: StoreEntityDetails[] = await fetchRequest(
  //   serverPath?.getStoreDetails
  // );

  const { data } = useQuery<StoreEntityDetails[]>({
    queryKey: ["getStoreDetails"],
    queryFn: () => fetchRequest(serverPath?.getStoreDetails),
  });

  if (!data) return;

  return (
    <div>
      {/* <LogoNavBar /> */}
      <div className="flex justify-center items-center my-5 min-h-[85vh]">
        <div className="w-5/6 lg:w-2/3 mx-auto shadow-lg rounded-lg">
          <StoreDetailsInRedux storeDetailList={data}>
            <LoginForm />
          </StoreDetailsInRedux>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
