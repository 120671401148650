import MenuDetailsSize from "./MenuDetailsSize";
import { useFormContext, useWatch } from "react-hook-form";
import { AddMenuItemsForm, MenuSize } from "@/app/_types/menuDetails";
import { SizeGroup } from "@/app/_types/extraMenuPriceInfo";

export interface MenuSizeCompare extends MenuSize {
  basePrice: number;
}

interface MenuDetailsSizeHandler {
  sizeGroup?: SizeGroup;
}

function MenuDetailsSizeHandler() {
  const { control } = useFormContext<AddMenuItemsForm>();

  const watchData = useWatch({
    control,
    name: "data",
  });

  const checkData = watchData?.map((el) => el?.id).includes(null);

  const getSizeList = useWatch({
    control,
    name: "sizeList",
  });

  return (
    <>{checkData ? <> </> : <MenuDetailsSize menuSizes={getSizeList} />}</>
  );
}

export default MenuDetailsSizeHandler;
