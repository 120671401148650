import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";

function CustomModalClients({
  children,
  open,
  handleClose,
  className,
  scrollDisable = false,
  disableCloseOnOverlay = false,
}: {
  children: React.ReactNode | React.ReactPortal;
  open: boolean;
  handleClose?(): void;
  className?: string;
  scrollDisable?: boolean;
  disableCloseOnOverlay?: boolean;
}): React.ReactElement | null {
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null);
  const overlay = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setModalRoot(document.getElementById("modal-root"));
  }, []);

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.target === overlay.current && handleClose) {
        !disableCloseOnOverlay && handleClose();
      }
    },
    [overlay, handleClose, disableCloseOnOverlay]
  );

  useEffect(() => {
    if (open && !scrollDisable) {
      document.body.classList.add("modal-open");
    }

    return () => {
      if (!scrollDisable) {
        document.body.classList.remove("modal-open");
      }
    };
  }, [open, scrollDisable]);

  const modalContent = (
    <div
      ref={overlay}
      className="justify-start items-center flex overflow-x-hidden fixed inset-0 z-[2500] outline-none focus:outline-none bg-black/60"
      onClick={onClick}
    >
      <div
        className={twMerge(
          "flex-none m-auto rounded-md relative bg-white",
          className && className
        )}
      >
        {children}
      </div>
    </div>
  );

  if (open && modalRoot && document.getElementById("modal-root")) {
    // @ts-ignore
    return createPortal(modalContent, document.getElementById("modal-root")!);
  }

  return null;
}

export default CustomModalClients;
