import { memo, useContext } from "react";
import MenuDetailEditOption from "./MenuDetailEditOption";
import MenuDealEditExtra from "./MenuDealEditExtra";
import {  useFormContext, useWatch } from "react-hook-form";
import { MenuDealForm } from "@/app/_types/menuDeals.interface";
import { DealMenuIngredientProvider } from "../../../context";
import MenuDealAdditionalExtraOption from "./MenuDealEditExtra/MenuDealAdditionalExtraOption";
import { useSelector } from "react-redux";
import { order } from "@/app/_redux/slice/Order/OrderSlice";

function MenuDealEditOptionsGroup() {
  const { control } = useFormContext<MenuDealForm>();
  const ingredientFields = useContext(DealMenuIngredientProvider);

  const { dealDetails } = useSelector(order);

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.selectedMenuIndex`,
  });

  const sizeGroupId = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.menus.${selectedMenuIndex}.sizeGroupId`,
  });

  const selectedReduxIndex = useWatch({
    control,
    name: `config.selectedDealCategoryIndex`,
  });

  if (typeof selectedReduxIndex !== "number")
    return <p>Something Went Wrong.</p>;

  const extraInformation =
    dealDetails?.dealCategories?.at(selectedReduxIndex)?.extraPriceInfo;

  if (!extraInformation || !extraInformation?.options?.length) return <p></p>;

  const { sizeGroups } = extraInformation;

  const optionGroups =
    sizeGroups?.find((el) => el?.id === sizeGroupId)?.optionGroups || [];

  // const fieldArray = useFieldArray({
  //   control,
  //   name: `menuDeal.${selectedIndex}.menus.${selectedMenuIndex}.ingredients`,
  //   keyName: "fieldId",
  // });

  // const { fields } = fieldArray;

  console.log(ingredientFields);
  console.log("menu edit rendering options group");
  // if (!ingredientFields) return <p>Getting Data</p>;

  return (
    // <DealMenuIngredientProvider.Provider value={fieldArray}>
    <>
      <MenuDetailEditOption
        menuOptions={ingredientFields?.fields || []}
        optionGroups={optionGroups}
      />
      <MenuDealAdditionalExtraOption
        menuOptions={ingredientFields?.fields || []}
        optionGroups={optionGroups}
      />
      <MenuDealEditExtra sizeGroupId={sizeGroupId} />
    </>
    // </DealMenuIngredientProvider.Provider>
  );
}

export default memo(MenuDealEditOptionsGroup);
