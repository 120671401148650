import ErrorAstrick from "@/app/global/elements/ErrorAstrick";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";


import { useFormContext } from "react-hook-form";

interface TableOrderModalProps {
  scheduleTableOpen: boolean;
  handleClose: () => void;
  onSubmit: (data: any) => Promise<void>;
}

const TableOrderModal = ({
  scheduleTableOpen,
  handleClose,
  onSubmit,
}: TableOrderModalProps) => {
  const methods = useFormContext();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  return (
    <CustomModalClients
      open={scheduleTableOpen}
      handleClose={handleClose}
      className="modal-table-order p-3 bg-gray-100"
    >
      <>
        <div className="flex justify-between items-center p-2 border-b-2 ">
          <h1 className="font-bold text-lg sm:text-xl">
            Restaurant Reservation Form{" "}
          </h1>
          <button onClick={handleClose}>
            <img
              src="/images/icons/close.svg"
              width={25}
              height={25}
              alt="close"
            />
          </button>
        </div>
        <div className="flex flex-col justify-between px-4 mb-2">
          <label className="form-label pl-5 sm:pl-0">
            Name
            {(errors?.firstName || errors?.lastName) && <ErrorAstrick />}
          </label>
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="form-div px-1">
              <input
                type="text"
                id="firstName"
                {...register("firstName", { required: true })}
                className="form-input"
                placeholder="First Name"
              />
            </div>
            <div className="form-div px-1">
              <input
                type="text"
                id="lastName"
                {...register("lastName", { required: true })}
                className="form-input"
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row  justify-between items-center px-4 mb-2">
          <div className="form-div px-1">
            <label htmlFor="email" className="form-label">
              Email
              {errors?.email && <ErrorAstrick />}
            </label>
            <input
              type="text"
              id="email"
              {...register("email", { required: true })}
              className="form-input"
            />
          </div>
          <div className="form-div px-1">
            <label htmlFor="phone" className="form-label">
              Phone Number
              {errors?.phone && <ErrorAstrick />}
            </label>
            <input
              type="text"
              id="phone"
              {...register("phone", { required: true })}
              className="form-input"
              placeholder="##########"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row  justify-between items-center px-4 mb-2">
          <div className="form-div px-1">
            <label htmlFor="reservationDate" className="form-label">
              Reservation Date
              {errors?.reservationDate && <ErrorAstrick />}
            </label>
            <input
              type="date"
              id="reservationDate"
              {...register("reservationDate")}
              className="form-input"
              style={{ width: "240px" }}
            />
          </div>
          <div className="form-div px-1">
            <label htmlFor="reservationTime" className="form-label">
              Reservation Time
              {errors?.reservationTime && <ErrorAstrick />}
            </label>
            <input
              type="time"
              id="reservationTime"
              {...register("reservationTime")}
              className="form-input"
              style={{ width: "240px" }}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row  justify-between items-center px-4 mb-2">
          <div className="form-div fullWidth px-1">
            <label htmlFor="totalPeople" className="form-label">
              Total No. of People
              {errors?.totalPeople && <ErrorAstrick />}
            </label>
            <input
              type="number"
              id="totalPeople"
              {...register("totalPeople")}
              className="form-input"
            />
          </div>
          {/* <div className="form-div fullWidth px-1">
                        <label htmlFor="totalTime" className="form-label">
                           Total Stay Time
                        </label>
                        <input
                           type="text"
                           id="totalTime"
                           {...register("totalTime")}
                           className="form-input"
                        />
                     </div> */}
          <div className="form-div fullWidth px-1">
            <label htmlFor="hours" className="form-label">
              Total Stay Time (HH:MM)
            </label>
            <div className="time-input-wrapper">
              <input
                type="number"
                className="time-input"
                {...register("hours")}
                placeholder="HH"
                min={0}
                max={23}
              />
              <span>:</span>
              <input
                type="number"
                className="time-input"
                {...register("minutes")}
                placeholder="MM"
                min={0}
                max={59}
              />
            </div>
          </div>
        </div>
        <div className="px-7 sm:px-4 mb-2">
          <div className="form-div px-1">
            <label htmlFor="specialRequest" className="form-label">
              Special Message
              {errors?.specialRequest && <ErrorAstrick />}
            </label>
            <textarea
              id="specialRequest"
              {...register("specialRequest")}
              className="form-input"
              rows={1}
            />
            {/* <input
                           type="text"
                           id="specialRequest"
                           {...register("specialRequest")}
                           className="form-input"
                           rows="2"
                        /> */}
          </div>
        </div>
        <div className="px-7 sm:px-4 mb-2">
          <div className="form-div px-1">
            <label htmlFor="message" className="form-label">
              Notes
              {errors?.message && <ErrorAstrick />}
            </label>
            <textarea
              id="message"
              {...register("message")}
              className="form-input"
              rows={2}
            />
          </div>
        </div>
        <div className="pt-2 flex justify-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className={` p-3 lg:p-4 border rounded-md text-sm lg:text-base ${"bg-black"} text-white`}
            // disabled={selectedTime === null ? true : false}
          >
            Send
          </button>
        </div>
      </>
    </CustomModalClients>
  );
};

export default TableOrderModal;
