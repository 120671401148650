export const countriesCode = [
  {
    code: "+7 840",
    name: "Abkhazia",
    iso: "ABH",
  },
  {
    code: "+93",
    name: "Afghanistan",
    iso: "AFG",
  },
  {
    code: "+355",
    name: "Albania",
    iso: "ALB",
  },
  {
    code: "+213",
    name: "Algeria",
    iso: "DZA",
  },
  {
    code: "+1 684",
    name: "American Samoa",
    iso: "ASM",
  },
  {
    code: "+376",
    name: "Andorra",
    iso: "AND",
  },
  {
    code: "+244",
    name: "Angola",
    iso: "AGO",
  },
  {
    code: "+1 264",
    name: "Anguilla",
    iso: "AIA",
  },
  {
    code: "+1 268",
    name: "Antigua and Barbuda",
    iso: "ATG",
  },
  {
    code: "+54",
    name: "Argentina",
    iso: "ARG",
  },
  {
    code: "+374",
    name: "Armenia",
    iso: "ARM",
  },
  {
    code: "+297",
    name: "Aruba",
    iso: "ABW",
  },
  {
    code: "+247",
    name: "Ascension",
    iso: "SHN",
  },
  {
    code: "+61",
    name: "Australia",
    iso: "AUS",
  },
  {
    code: "+672",
    name: "Australian External Territories",
    iso: "NFK",
  },
  {
    code: "+43",
    name: "Austria",
    iso: "AUT",
  },
  {
    code: "+994",
    name: "Azerbaijan",
    iso: "AZE",
  },
  {
    code: "+1 242",
    name: "Bahamas",
    iso: "BHS",
  },
  {
    code: "+973",
    name: "Bahrain",
    iso: "BHR",
  },
  {
    code: "+880",
    name: "Bangladesh",
    iso: "BGD",
  },
  {
    code: "+1 246",
    name: "Barbados",
    iso: "BRB",
  },
  {
    code: "+1 268",
    name: "Barbuda",
    iso: "ATG",
  },
  {
    code: "+375",
    name: "Belarus",
    iso: "BLR",
  },
  {
    code: "+32",
    name: "Belgium",
    iso: "BEL",
  },
  {
    code: "+501",
    name: "Belize",
    iso: "BLZ",
  },
  {
    code: "+229",
    name: "Benin",
    iso: "BEN",
  },
  {
    code: "+1 441",
    name: "Bermuda",
    iso: "BMU",
  },
  {
    code: "+975",
    name: "Bhutan",
    iso: "BTN",
  },
  {
    code: "+591",
    name: "Bolivia",
    iso: "BOL",
  },
  {
    code: "+387",
    name: "Bosnia and Herzegovina",
    iso: "BIH",
  },
  {
    code: "+267",
    name: "Botswana",
    iso: "BWA",
  },
  {
    code: "+55",
    name: "Brazil",
    iso: "BRA",
  },
  {
    code: "+246",
    name: "British Indian Ocean Territory",
    iso: "IOT",
  },
  {
    code: "+1 284",
    name: "British Virgin Islands",
    iso: "VGB",
  },
  {
    code: "+673",
    name: "Brunei",
    iso: "BRN",
  },
  {
    code: "+359",
    name: "Bulgaria",
    iso: "BGR",
  },
  {
    code: "+226",
    name: "Burkina Faso",
    iso: "BFA",
  },
  {
    code: "+257",
    name: "Burundi",
    iso: "BDI",
  },
  {
    code: "+855",
    name: "Cambodia",
    iso: "KHM",
  },
  {
    code: "+237",
    name: "Cameroon",
    iso: "CMR",
  },
  {
    code: "+1",
    name: "Canada",
    iso: "CAN",
  },
  {
    code: "+238",
    name: "Cape Verde",
    iso: "CPV",
  },
  {
    code: "+ 345",
    name: "Cayman Islands",
    iso: "CYM",
  },
  {
    code: "+236",
    name: "Central African Republic",
    iso: "CAF",
  },
  {
    code: "+235",
    name: "Chad",
    iso: "TCD",
  },
  {
    code: "+56",
    name: "Chile",
    iso: "CHL",
  },
  {
    code: "+86",
    name: "China",
    iso: "CHN",
  },
  {
    code: "+61",
    name: "Christmas Island",
    iso: "CXR",
  },
  {
    code: "+61",
    name: "Cocos-Keeling Islands",
    iso: "CCK",
  },
  {
    code: "+57",
    name: "Colombia",
    iso: "COL",
  },
  {
    code: "+269",
    name: "Comoros",
    iso: "COM",
  },
  {
    code: "+242",
    name: "Congo",
    iso: "COG",
  },
  {
    code: "+243",
    name: "Congo, Dem. Rep. of (Zaire)",
    iso: "COD",
  },
  {
    code: "+682",
    name: "Cook Islands",
    iso: "COK",
  },
  {
    code: "+506",
    name: "Costa Rica",
    iso: "CRI",
  },
  {
    code: "+385",
    name: "Croatia",
    iso: "HRV",
  },
  {
    code: "+53",
    name: "Cuba",
    iso: "CUB",
  },
  {
    code: "+599",
    name: "Curacao",
    iso: "CUW",
  },
  {
    code: "+537",
    name: "Cyprus",
    iso: "CYP",
  },
  {
    code: "+420",
    name: "Czech Republic",
    iso: "CZE",
  },
  {
    code: "+45",
    name: "Denmark",
    iso: "DNK",
  },
  {
    code: "+246",
    name: "Diego Garcia",
    iso: "IOT",
  },
  {
    code: "+253",
    name: "Djibouti",
    iso: "DJI",
  },
  {
    code: "+1 767",
    name: "Dominica",
    iso: "DMA",
  },
  {
    code: "+1 809",
    name: "Dominican Republic",
    iso: "DOM",
  },
  {
    code: "+670",
    name: "East Timor",
    iso: "TLS",
  },
  {
    code: "+56",
    name: "Easter Island",
    iso: "CHL",
  },
  {
    code: "+593",
    name: "Ecuador",
    iso: "ECU",
  },
  {
    code: "+20",
    name: "Egypt",
    iso: "EGY",
  },
  {
    code: "+503",
    name: "El Salvador",
    iso: "SLV",
  },
  {
    code: "+240",
    name: "Equatorial Guinea",
    iso: "GNQ",
  },
  {
    code: "+291",
    name: "Eritrea",
    iso: "ERI",
  },
  {
    code: "+372",
    name: "Estonia",
    iso: "EST",
  },
  {
    code: "+251",
    name: "Ethiopia",
    iso: "ETH",
  },
  {
    code: "+500",
    name: "Falkland Islands",
    iso: "FLK",
  },
  {
    code: "+298",
    name: "Faroe Islands",
    iso: "FRO",
  },
  {
    code: "+679",
    name: "Fiji",
    iso: "FJI",
  },
  {
    code: "+358",
    name: "Finland",
    iso: "FIN",
  },
  {
    code: "+33",
    name: "France",
    iso: "FRA",
  },
  {
    code: "+596",
    name: "French Antilles",
    iso: "MTQ",
  },
  {
    code: "+594",
    name: "French Guiana",
    iso: "GUF",
  },
  {
    code: "+689",
    name: "French Polynesia",
    iso: "PYF",
  },
  {
    code: "+241",
    name: "Gabon",
    iso: "GAB",
  },
  {
    code: "+220",
    name: "Gambia",
    iso: "GMB",
  },
  {
    code: "+995",
    name: "Georgia",
    iso: "GEO",
  },
  {
    code: "+49",
    name: "Germany",
    iso: "DEU",
  },
  {
    code: "+233",
    name: "Ghana",
    iso: "GHA",
  },
  {
    code: "+350",
    name: "Gibraltar",
    iso: "GIB",
  },
  {
    code: "+30",
    name: "Greece",
    iso: "GRC",
  },
  {
    code: "+299",
    name: "Greenland",
    iso: "GRL",
  },
  {
    code: "+1 473",
    name: "Grenada",
    iso: "GRD",
  },
  {
    code: "+590",
    name: "Guadeloupe",
    iso: "GLP",
  },
  {
    code: "+1 671",
    name: "Guam",
    iso: "GUM",
  },
  {
    code: "+502",
    name: "Guatemala",
    iso: "GTM",
  },
  {
    code: "+224",
    name: "Guinea",
    iso: "GIN",
  },
  {
    code: "+245",
    name: "Guinea-Bissau",
    iso: "GNB",
  },
  {
    code: "+595",
    name: "Guyana",
    iso: "GUY",
  },
  {
    code: "+509",
    name: "Haiti",
    iso: "HTI",
  },
  {
    code: "+504",
    name: "Honduras",
    iso: "HND",
  },
  {
    code: "+852",
    name: "Hong Kong SAR China",
    iso: "HKG",
  },
  {
    code: "+36",
    name: "Hungary",
    iso: "HUN",
  },
  {
    code: "+354",
    name: "Iceland",
    iso: "ISL",
  },
  {
    code: "+91",
    name: "India",
    iso: "IND",
  },
  {
    code: "+62",
    name: "Indonesia",
    iso: "IDN",
  },
  {
    code: "+98",
    name: "Iran",
    iso: "IRN",
  },
  {
    code: "+964",
    name: "Iraq",
    iso: "IRQ",
  },
  {
    code: "+353",
    name: "Ireland",
    iso: "IRL",
  },
  {
    code: "+972",
    name: "Israel",
    iso: "ISR",
  },
  {
    code: "+39",
    name: "Italy",
    iso: "ITA",
  },
  {
    code: "+225",
    name: "Ivory Coast",
    iso: "CIV",
  },
  {
    code: "+1 876",
    name: "Jamaica",
    iso: "JAM",
  },
  {
    code: "+81",
    name: "Japan",
    iso: "JPN",
  },
  {
    code: "+962",
    name: "Jordan",
    iso: "JOR",
  },
  {
    code: "+7",
    name: "Kazakhstan",
    iso: "KAZ",
  },
  {
    code: "+254",
    name: "Kenya",
    iso: "KEN",
  },
  {
    code: "+686",
    name: "Kiribati",
    iso: "KIR",
  },
  {
    code: "+965",
    name: "Kuwait",
    iso: "KWT",
  },
  {
    code: "+996",
    name: "Kyrgyzstan",
    iso: "KGZ",
  },
  {
    code: "+856",
    name: "Laos",
    iso: "LAO",
  },
  {
    code: "+371",
    name: "Latvia",
    iso: "LVA",
  },
  {
    code: "+961",
    name: "Lebanon",
    iso: "LBN",
  },
  {
    code: "+266",
    name: "Lesotho",
    iso: "LSO",
  },
  {
    code: "+231",
    name: "Liberia",
    iso: "LBR",
  },
  {
    code: "+218",
    name: "Libya",
    iso: "LBY",
  },
  {
    code: "+423",
    name: "Liechtenstein",
    iso: "LIE",
  },
  {
    code: "+370",
    name: "Lithuania",
    iso: "LTU",
  },
  {
    code: "+352",
    name: "Luxembourg",
    iso: "LUX",
  },
  {
    code: "+853",
    name: "Macau SAR China",
    iso: "MAC",
  },
  {
    code: "+389",
    name: "Macedonia",
    iso: "MKD",
  },
  {
    code: "+261",
    name: "Madagascar",
    iso: "MDG",
  },
  {
    code: "+265",
    name: "Malawi",
    iso: "MWI",
  },
  {
    code: "+60",
    name: "Malaysia",
    iso: "MYS",
  },
  {
    code: "+960",
    name: "Maldives",
    iso: "MDV",
  },
  {
    code: "+223",
    name: "Mali",
    iso: "MLI",
  },
  {
    code: "+356",
    name: "Malta",
    iso: "MLT",
  },
  {
    code: "+692",
    name: "Marshall Islands",
    iso: "MHL",
  },
  {
    code: "+596",
    name: "Martinique",
    iso: "MTQ",
  },
  {
    code: "+222",
    name: "Mauritania",
    iso: "MRT",
  },
  {
    code: "+230",
    name: "Mauritius",
    iso: "MUS",
  },
  {
    code: "+262",
    name: "Mayotte",
    iso: "MYT",
  },
  {
    code: "+52",
    name: "Mexico",
    iso: "MEX",
  },
  {
    code: "+691",
    name: "Micronesia",
    iso: "FSM",
  },
  {
    code: "+1 808",
    name: "Midway Island",
    iso: "UMI",
  },
  {
    code: "+373",
    name: "Moldova",
    iso: "MDA",
  },
  {
    code: "+377",
    name: "Monaco",
    iso: "MCO",
  },
  {
    code: "+976",
    name: "Mongolia",
    iso: "MNG",
  },
  {
    code: "+382",
    name: "Montenegro",
    iso: "MNE",
  },
  {
    code: "+1664",
    name: "Montserrat",
    iso: "MSR",
  },
  {
    code: "+212",
    name: "Morocco",
    iso: "MAR",
  },
  {
    code: "+95",
    name: "Myanmar",
    iso: "MMR",
  },
  {
    code: "+264",
    name: "Namibia",
    iso: "NAM",
  },
  {
    code: "+674",
    name: "Nauru",
    iso: "NRU",
  },
  {
    code: "+977",
    name: "Nepal",
    iso: "NPL",
  },
  {
    code: "+31",
    name: "Netherlands",
    iso: "NLD",
  },
  {
    code: "+599",
    name: "Netherlands Antilles",
    iso: "ANT",
  },
  {
    code: "+1 869",
    name: "Nevis",
    iso: "KNA",
  },
  {
    code: "+687",
    name: "New Caledonia",
    iso: "NCL",
  },
  {
    code: "+64",
    name: "New Zealand",
    iso: "NZL",
  },
  {
    code: "+505",
    name: "Nicaragua",
    iso: "NIC",
  },
  {
    code: "+227",
    name: "Niger",
    iso: "NER",
  },
  {
    code: "+234",
    name: "Nigeria",
    iso: "NGA",
  },
  {
    code: "+683",
    name: "Niue",
    iso: "NIU",
  },
  {
    code: "+672",
    name: "Norfolk Island",
    iso: "NFK",
  },
  {
    code: "+850",
    name: "North Korea",
    iso: "PRK",
  },
  {
    code: "+1 670",
    name: "Northern Mariana Islands",
    iso: "MNP",
  },
  {
    code: "+47",
    name: "Norway",
    iso: "NOR",
  },
  {
    code: "+968",
    name: "Oman",
    iso: "OMN",
  },
  {
    code: "+92",
    name: "Pakistan",
    iso: "PAK",
  },
  {
    code: "+680",
    name: "Palau",
    iso: "PLW",
  },
  {
    code: "+970",
    name: "Palestinian Territory",
    iso: "PSE",
  },
  {
    code: "+507",
    name: "Panama",
    iso: "PAN",
  },
  {
    code: "+675",
    name: "Papua New Guinea",
    iso: "PNG",
  },
  {
    code: "+595",
    name: "Paraguay",
    iso: "PRY",
  },
  {
    code: "+51",
    name: "Peru",
    iso: "PER",
  },
  {
    code: "+63",
    name: "Philippines",
    iso: "PHL",
  },
  {
    code: "+48",
    name: "Poland",
    iso: "POL",
  },
  {
    code: "+351",
    name: "Portugal",
    iso: "PRT",
  },
  {
    code: "+1 787",
    name: "Puerto Rico",
    iso: "PRI",
  },
  {
    code: "+974",
    name: "Qatar",
    iso: "QAT",
  },
  {
    code: "+262",
    name: "Reunion",
    iso: "REU",
  },
  {
    code: "+40",
    name: "Romania",
    iso: "ROU",
  },
  {
    code: "+7",
    name: "Russia",
    iso: "RUS",
  },
  {
    code: "+250",
    name: "Rwanda",
    iso: "RWA",
  },
  {
    code: "+685",
    name: "Samoa",
    iso: "WSM",
  },
  {
    code: "+378",
    name: "San Marino",
    iso: "SMR",
  },
  {
    code: "+966",
    name: "Saudi Arabia",
    iso: "SAU",
  },
  {
    code: "+221",
    name: "Senegal",
    iso: "SEN",
  },
  {
    code: "+381",
    name: "Serbia",
    iso: "SRB",
  },
  {
    code: "+248",
    name: "Seychelles",
    iso: "SYC",
  },
  {
    code: "+232",
    name: "Sierra Leone",
    iso: "SLE",
  },
  {
    code: "+65",
    name: "Singapore",
    iso: "SGP",
  },
  {
    code: "+421",
    name: "Slovakia",
    iso: "SVK",
  },
  {
    code: "+386",
    name: "Slovenia",
    iso: "SVN",
  },
  {
    code: "+677",
    name: "Solomon Islands",
    iso: "SLB",
  },
  {
    code: "+27",
    name: "South Africa",
    iso: "ZAF",
  },
  {
    code: "+500",
    name: "South Georgia and the South Sandwich Islands",
    iso: "SGS",
  },
  {
    code: "+82",
    name: "South Korea",
    iso: "KOR",
  },
  {
    code: "+34",
    name: "Spain",
    iso: "ESP",
  },
  {
    code: "+94",
    name: "Sri Lanka",
    iso: "LKA",
  },
  {
    code: "+249",
    name: "Sudan",
    iso: "SDN",
  },
  {
    code: "+597",
    name: "Suriname",
    iso: "SUR",
  },
  {
    code: "+268",
    name: "Swaziland",
    iso: "SWZ",
  },
  {
    code: "+46",
    name: "Sweden",
    iso: "SWE",
  },
  {
    code: "+41",
    name: "Switzerland",
    iso: "CHE",
  },
  {
    code: "+963",
    name: "Syria",
    iso: "SYR",
  },
  {
    code: "+886",
    name: "Taiwan",
    iso: "TWN",
  },
  {
    code: "+992",
    name: "Tajikistan",
    iso: "TJK",
  },
  {
    code: "+255",
    name: "Tanzania",
    iso: "TZA",
  },
  {
    code: "+66",
    name: "Thailand",
    iso: "THA",
  },
  {
    code: "+670",
    name: "Timor Leste",
    iso: "TLS",
  },
  {
    code: "+228",
    name: "Togo",
    iso: "TGO",
  },
  {
    code: "+690",
    name: "Tokelau",
    iso: "TKL",
  },
  {
    code: "+676",
    name: "Tonga",
    iso: "TON",
  },
  {
    code: "+1 868",
    name: "Trinidad and Tobago",
    iso: "TTO",
  },
  {
    code: "+216",
    name: "Tunisia",
    iso: "TUN",
  },
  {
    code: "+90",
    name: "Turkey",
    iso: "TUR",
  },
  {
    code: "+993",
    name: "Turkmenistan",
    iso: "TKM",
  },
  {
    code: "+1 649",
    name: "Turks and Caicos Islands",
    iso: "TCA",
  },
  {
    code: "+688",
    name: "Tuvalu",
    iso: "TUV",
  },
  {
    code: "+1 340",
    name: "U.S. Virgin Islands",
    iso: "VIR",
  },
  {
    code: "+256",
    name: "Uganda",
    iso: "UGA",
  },
  {
    code: "+380",
    name: "Ukraine",
    iso: "UKR",
  },
  {
    code: "+971",
    name: "United Arab Emirates",
    iso: "ARE",
  },
  {
    code: "+44",
    name: "United Kingdom",
    iso: "GBR",
  },
  {
    code: "+1",
    name: "United States",
    iso: "USA",
  },
  {
    code: "+598",
    name: "Uruguay",
    iso: "URY",
  },
  {
    code: "+998",
    name: "Uzbekistan",
    iso: "UZB",
  },
  {
    code: "+678",
    name: "Vanuatu",
    iso: "VUT",
  },
  {
    code: "+58",
    name: "Venezuela",
    iso: "VEN",
  },
  {
    code: "+84",
    name: "Vietnam",
    iso: "VNM",
  },
  {
    code: "+1 808",
    name: "Wake Island",
    iso: "UMI",
  },
  {
    code: "+681",
    name: "Wallis and Futuna",
    iso: "WLF",
  },
  {
    code: "+967",
    name: "Yemen",
    iso: "YEM",
  },
  {
    code: "+260",
    name: "Zambia",
    iso: "ZMB",
  },
  {
    code: "+255",
    name: "Zanzibar",
    iso: "TZA",
  },
  {
    code: "+263",
    name: "Zimbabwe",
    iso: "ZWE",
  },
];
