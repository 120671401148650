import { MenuDealForm } from "@/app/_types/menuDeals.interface";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import MenuDealExtraOptionAccordion from "./MenuDealEditExtra/MenuDealExtraOptionAccordion";
import { useSelector } from "react-redux";
import { order } from "@/app/_redux/slice/Order/OrderSlice";

interface MenuDealEditExtraProps {
  sizeGroupId: string;
}

function MenuDealEditExtra({ sizeGroupId }: MenuDealEditExtraProps) {
  const { control } = useFormContext<MenuDealForm>();
  const { dealDetails } = useSelector(order);
  const [open, setOpen] = useState<number | null>(null);

  const selectedReduxIndex = useWatch({
    control,
    name: `config.selectedDealCategoryIndex`,
  });

  if (typeof selectedReduxIndex !== "number")
    return <p>Something Went Wrong.</p>;

  const extraInformation =
    dealDetails?.dealCategories?.at(selectedReduxIndex)?.extraPriceInfo;

  if (!extraInformation || !extraInformation?.options?.length) return <p></p>;

  const { options, sizeGroups } = extraInformation;

  const menuDealOption = sizeGroups?.find((el) => el?.id === sizeGroupId);

  console.log(menuDealOption);

  console.log("extra ing => ", menuDealOption);

  const ShowOptionGroups = menuDealOption?.optionGroups?.filter(
    (optionGroup) => optionGroup.isHideFromExtras === false
  );

  if (!menuDealOption) return <p>Not Found</p>;

  return (
    <>
      {(ShowOptionGroups || [])?.length > 0 && (
        <div className="pb-2 px-4">
          <p className="text-lg font-bold">Extra Toppings</p>
          <div>
            {ShowOptionGroups?.map((option, index) => (
              <MenuDealExtraOptionAccordion
                option={option}
                open={open}
                setOpen={setOpen}
                index={index}
                optionName={options}
                key={index}
                optionGroups={menuDealOption?.optionGroups}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default MenuDealEditExtra;
