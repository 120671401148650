function LocationLoading() {
  return (
    <div className="p-8 maxContainer">
      <div className="h-[60vh] relative flex flex-col justify-between">
        <div className="loading-images w-full h-[80%]" />
      </div>
      <LocationDownLoading />
    </div>
  );
}

export function LocationDownLoading() {
  return (
    <div className="grid divide-y  md:grid-cols-[0.2fr_1fr] md:divide-x items-start gap-2 maxContainer py-6">
      <ul className="flex pt-4  gap-2  md:flex-col md:divide-y overflow-hidden ">
        {[1, 2, 3, 4].map((el) => {
          return <li className="loading-text" key={el} />;
        })}
      </ul>
      <div className="flex flex-wrap gap-4">
        {[1, 2, 3, 4].map((el) => {
          return (
            <div
              key={el}
              className="flex flex-col items-start justify-start gap-2"
            >
              <div className=" w-42 h-32 md:w-52 md:h-32 animate-pulse bg-background rounded-md" />
              <div className="loading-text" key={el} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LocationLoading;
