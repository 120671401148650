import { useState } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "../../register/components/RegisterForm";
import CustomModalWithoutWhite from "@/app/global/modal/CustomModalWithoutWhite";

interface LoginModal {
  open: boolean;
  handleClose(): void;
  status: "LOGIN" | "REGISTER";
}

function LoginModal({ open, handleClose, status }: LoginModal) {
  const [newStatus, setNewStatus] = useState(status);

  const changeStatus = (data: "LOGIN" | "REGISTER") => {
    setNewStatus(data);
  };

  return (
    <CustomModalWithoutWhite
      open={open}
      // handleClose={handleClose}
      className="w-full md:w-fit min-w-[10rem] md:min-w-[30rem] md:max-w-[60rem]"
    >
      {newStatus === "LOGIN" && (
        <LoginForm
          changeStatus={changeStatus}
          handleClose={handleClose}
          isModal={true}
        />
      )}
      {newStatus === "REGISTER" && (
        <RegisterForm
          changeStatus={changeStatus}
          handleClose={handleClose}
          isModal={true}
        />
      )}
    </CustomModalWithoutWhite>
  );
}

export default LoginModal;
