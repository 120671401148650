import { Link, useParams } from "react-router-dom";
import { selectUI } from "../_redux/slice/UISlice/UISlice";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StoreEntity } from "../_types/store.interface";
import fetchRequest from "../_axios/fetchRequest";
import { serverPath } from "../_axios/path";

const CustomerSupportMsg = () => {
  const { storeLogo } = useSelector(selectUI);
  const client = useQueryClient();

  const { location: locationParam } = useParams<{ location?: string }>();

  const storeListDetails = client.getQueryData<StoreEntity[]>(["storeList"]);

  const { data, isLoading, error } = useQuery(
    ["storeList"],
    () => fetchRequest(serverPath?.storeList, "GET"),
    { enabled: !storeListDetails }
  );

  const storeInfo: StoreEntity[] | undefined = storeListDetails || data;

  let finalLocationId: string | undefined = locationParam;

  const cartFromStorage = localStorage.getItem("cart");
  if (!finalLocationId && cartFromStorage) {
    try {
      const cart = JSON.parse(cartFromStorage);
      if (cart?.location) {
        finalLocationId = cart.location;
      }
    } catch (error) {
      console.error("Failed to parse cart from localStorage", error);
    }
  }

  // Filter contacts based on finalLocationId if it exists
  let contacts: { name: string; phone: string }[] = [];
  if (finalLocationId) {
    contacts = (storeInfo || [])
      .filter((store: StoreEntity) => store.id === finalLocationId)
      .map((store: StoreEntity) => ({
        name: store.name,
        phone: store.phone,
      }));
  } else {
    // If no location is determined, just map out all store contacts or handle differently
    contacts = (storeInfo || []).map((store: StoreEntity) => ({
      name: store.name,
      phone: store.phone,
    }));
  }

  // Limit to 4
  contacts = contacts.slice(0, 4);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <p className="text-sm text-gray-500">Loading contact details...</p>
      </div>
    );
  }

  if (error || !storeInfo) {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <p className="text-sm text-red-500">
          Failed to load contact details. Please try again later.
        </p>
      </div>
    );
  }

  return (
    <section className="flex flex-col items-center justify-center bg-gradient-to-b from-indigo-50 to-purple-50 p-6 rounded-lg shadow-lg w-full h-full max-w-md mx-auto">
      {/* Logo */}
      <Link to="/">
        <img
          src={storeLogo?.logoImageUrl}
          alt="Logo"
          className="h-20 md:h-24 w-auto mb-6 object-contain"
        />
      </Link>

      {/* Message */}
      <p className="text-center text-gray-700 text-sm mb-6">
        Please feel free to contact us at your nearest location for assistance.
      </p>

      {/* Contact Details */}
      <ul className="space-y-3 w-full">
        {contacts.map((contact, index) => (
          <li
            key={index}
            className="flex items-center justify-between bg-white px-4 py-2 rounded-md shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <div>
              <p className="text-gray-800 font-medium text-sm">{contact.name}</p>
              <p className="text-gray-600 text-sm">{contact.phone}</p>
            </div>
            <a
              href={`tel:${contact.phone}`}
              className="text-indigo-500 hover:text-indigo-600 font-medium"
            >
              Call
            </a>
          </li>
        ))}
      </ul>

      {/* No Contacts Message */}
      {contacts.length === 0 && (
        <p className="text-center text-gray-500 text-sm mt-6">
          No contact details available.
        </p>
      )}
    </section>
  );
};

export default CustomerSupportMsg;
