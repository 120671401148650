import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function GoBackButtonForItems() {
  const { selectedStore } = useSelector(selectStore);
  const router = useNavigate();
  const params = useParams();
  const GoBackHandler = () => {
    const location = params?.location;

    if (location) {
      router(`/${location}`,{ state: {scrollId: `${params?.name}`}});
    }
  };

  return (
    <button className="pb-4 hover:text-primary" onClick={GoBackHandler}>
      <div className="flex flex-row gap-2">
        <img
          src="/images/icons/back.svg"
          width={30}
          height={30}
          alt="Go back"
        />
        <p className=" text-base md:text-lg ">
          {selectedStore?.name || "Go Back"}
        </p>
      </div>
      <div></div>
    </button>
  );
}

export default GoBackButtonForItems;
