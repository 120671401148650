import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";
import {
  ManageExtrasIngredient,
  MenuOptionGroupOption,
} from "@/app/_types/menuDetails";
import { MenuOfferForm } from "@/app/_types/menuOffers.interface";
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import toast from "react-hot-toast";

type AddDealMenuFieldArray = UseFieldArrayReturn<
  MenuOfferForm,
  `menuOffer.${number}.menus.${number}.ingredients`,
  "fieldId"
>;

type AddDealMenuGetValues = UseFormGetValues<MenuOfferForm>;
type AddDealMenuSetValue = UseFormSetValue<MenuOfferForm>;

const updateTotalPriceOfMenuIngredient = (
  getValues: AddDealMenuGetValues,
  setValue: AddDealMenuSetValue
) => {
  const watchConfigSelected = getValues("config.selectedOfferIndex");

  const getSelectedMenu = getValues(
    `menuOffer.${watchConfigSelected}.config.selectedMenuIndex`
  );

  const getIngredient = getValues(
    `menuOffer.${watchConfigSelected}.menus.${getSelectedMenu}.ingredients`
  );

  const ingredientTotal = getIngredient?.reduce(
    (acc, current) => (current?.total || 0) + acc,
    0
  );

  setValue(
    `menuOffer.${watchConfigSelected}.menus.${getSelectedMenu}.ingredientPrice`,
    ingredientTotal
  );
};

export const AddDealMenuIngredientHandler = (
  fieldArray: AddDealMenuFieldArray,
  getValues: AddDealMenuGetValues,
  setValue: AddDealMenuSetValue,
  item: ManageExtrasIngredient,
  optionGroups: OptionGroup[]
) => {
  const dealIndex = getValues("config.selectedOfferIndex");
  const getSizes = getValues(`menuOffer.${dealIndex}.size`);
  let distinctQuantity = 0;

  const checkIngredient = fieldArray?.fields?.find(
    (obj) => obj?.id === item?.id
  );

  const option = optionGroups?.find(
    (option) => option?.id === item?.optionGroupId
  );

  const TopinsBaseIngredient = fieldArray?.fields?.filter((ingredient) => {
    return ingredient?.optionGroupId === item?.optionGroupId;
  });

  const TotalDistinctSelectable = () => {
    if (option?.distinctSelectableOptions === 0) {
      console.log(option?.distinctSelectableOptions, option?.options?.length);
      return option?.options?.length;
    } else {
      return option?.distinctSelectableOptions;
    }
  };

  const SelectableDistincts = TotalDistinctSelectable();

  TopinsBaseIngredient?.forEach((ingredient) => {
    if (ingredient?.qty > 0) {
      distinctQuantity++;
    }
  });

  const ingPriceonSize: number =
    item?.menuSizes?.find((size) => size?.id === getSizes)?.extraPrice || 0;

  if (option) {
    if (!checkTotalSelectableOptions(TopinsBaseIngredient, option)) {
      return;
    }
  }

  if (!checkIngredient) {
    if (SelectableDistincts && distinctQuantity < SelectableDistincts) {
      fieldArray?.append({
        price: ingPriceonSize,
        qty: 1,
        name: item?.name,
        id: item?.id,
        status: "added",
        total: ingPriceonSize,
        menuSizes: item?.menuSizes,
        isSingle: item?.isSingle,
        optionGroupId: item?.optionGroupId,
      });
    } else {
      toast.error(`Only ${SelectableDistincts} ingredients allow`);
      return;
    }
  } else {
    const ingredientIndex = fieldArray?.fields?.findIndex(
      (obj) => obj?.id === item?.id
    );
    const ingredientQty = checkIngredient?.qty;
    if (item?.isSingle && ingredientQty > 0) {
      return toast.error("Ingredient can only be added ones");
    }
    // current status case
    if (checkIngredient?.status === "current") {
      if (
        checkIngredient?.qty === 0 &&
        SelectableDistincts &&
        distinctQuantity < SelectableDistincts
      ) {
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          price: ingPriceonSize,
          qty: ingredientQty + 1,
          total: 0,
        });
      }
      // current status - greter than 0
      if (checkIngredient?.qty > 0) {
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          price: ingPriceonSize,
          qty: ingredientQty + 1,
          total: ingredientQty * ingPriceonSize,
        });
      }
      // added ingredient
    } else {
      fieldArray?.update(ingredientIndex, {
        ...checkIngredient,
        price: ingPriceonSize,
        qty: ingredientQty + 1,
        total: (ingredientQty + 1) * ingPriceonSize,
      });
    }
  }
  updateTotalPriceOfMenuIngredient(getValues, setValue);
};

const checkTotalSelectableOptions = (
  TopinsBaseIngredient: FieldArrayWithId<
    MenuOfferForm,
    `menuOffer.${number}.menus.${number}.ingredients`,
    "fieldId"
  >[],
  option: OptionGroup
) => {
  let totalQuantity = 0;
  TopinsBaseIngredient?.forEach((ingredient) => {
    if (ingredient?.qty > 0) {
      totalQuantity += ingredient?.qty;
    }
  });

  const totalSelectableOptions = () => {
    if (option?.totalSelectableOptions === 0) {
      return option?.options?.length * 10;
    } else {
      return option?.totalSelectableOptions;
    }
  };

  const selectableOptions = totalSelectableOptions();

  if (selectableOptions && totalQuantity >= selectableOptions) {
    toast.error("Total selectable options exceeded.");
    return false;
  }

  return true;
};

export const RemoveDealMenuIngredientsHandler = (
  fieldArray: AddDealMenuFieldArray,
  getValues: AddDealMenuGetValues,
  setValue: AddDealMenuSetValue,
  item: ManageExtrasIngredient
) => {
  // finding ing from available ing
  const checkIngredient = fieldArray?.fields?.find(
    (obj) => obj?.id === item?.id
  );
  if (checkIngredient) {
    const ingredientQty = checkIngredient?.qty;
    const ingredientIndex = fieldArray?.fields?.findIndex(
      (obj) => obj?.id === item?.id
    );
    // for current ing
    if (checkIngredient?.status === "current") {
      // current ing - qty 0 - do nothing
      if (ingredientQty === 0) {
        return;
      }
      if (ingredientQty === 1) {
        // current ing - qty 1 - make qty 0 and total price 0
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          qty: ingredientQty - 1,
          total: 0,
        });
      } else {
        // reason for -2 is that current ingredient has already 1 ing by default which price is 0 so its basically - 1 for current ing and -1 for actually removing it
        // current ing - qty remove and calculate total ( reason for -2 is that in default we have 1 which doesn't have price)
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          qty: ingredientQty - 1,
          total: (ingredientQty - 2) * (checkIngredient?.price || 0),
        });
      }
      // for added ingredient
    } else {
      if (ingredientQty > 1) {
        fieldArray?.update(ingredientIndex, {
          ...checkIngredient,
          qty: ingredientQty - 1,
          total: (ingredientQty - 1) * (checkIngredient?.price || 0),
        });
      } else {
        fieldArray?.remove(ingredientIndex);
      }
    }
  }
  updateTotalPriceOfMenuIngredient(getValues, setValue);
};

// for combo items
export function findDealComboPrice(
  comboItems: MenuOptionGroupOption[],
  status: "AVERAGE" | "HIGHEST"
) {
  if (status === "AVERAGE") {
    const getPrice = comboItems?.reduce(
      (acc, current) => {
        if (current?.qty === 0 || current?.price === 0) return acc;
        const sumValue = acc?.sum + (current?.price || 0);
        const countValue = acc?.count + 1;
        return { sum: sumValue, count: countValue };
      },
      { sum: 0, count: 0 }
    );
    return getPrice?.sum / getPrice?.count || 0;
  } else {
    const priceList = comboItems?.map((data) =>
      data?.qty > 0 ? data?.price || 0 : 0
    );
    return Math.max(...priceList);
  }
}

export function getTotalOfferComboPriceOfSingleData(
getValues: UseFormGetValues<MenuOfferForm>, setValue: UseFormSetValue<MenuOfferForm>
  // comboType: "AVERAGE" | "HIGHEST" | "LOWEST"
) {
  const selectedOfferIndex = getValues(`config.selectedOfferIndex`);
  const selectedMenuIndex = getValues(
    `menuOffer.${selectedOfferIndex}.config.selectedMenuIndex`
  );

  const comboList = getValues(
    `menuOffer.${selectedOfferIndex}.menus.${selectedMenuIndex}.optionGroups`
  );
  const finalPrice = comboList?.reduce((acc, current, index) => {
    const comboItems = current?.options;
    // const comboItemPrice = findOfferComboPrice(comboItems, comboType)
    // const comboItemsPrice = comboItems?.reduce((accs, currents) => {
    //   const price =
    //     (currents?.menuSizes?.find((price) => price?.sizeId === size)?.extraPrice || 0) *
    //     currents?.qty
    //   return accs + price
    // }, 0)
    const comboItemsPrice = comboItems?.reduce((accs, current) => {
      return accs + (current?.price || 0);
    }, 0);

    setValue(
      `menuOffer.${selectedOfferIndex}.menus.${selectedMenuIndex}.optionGroups.${index}.itemsPrice`,
      comboItemsPrice
    );

    return acc + comboItemsPrice;
  }, 0);
  setValue(
    `menuOffer.${selectedOfferIndex}.menus.${selectedMenuIndex}.comboPrice`,
    finalPrice
  );
}
