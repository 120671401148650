import { SizeGroup } from "@/app/_types/extraMenuPriceInfo";
import {
  IndividualOrder,
  MenuSize,
} from "@/app/_types/menuDetails";

export function getSizeList(
  watchData: IndividualOrder[],
  sizeGroup?: SizeGroup
) {
  if (Array.isArray(watchData) && watchData?.length === 2) {
    const firstMenu = watchData[0];
    const secondMenu = watchData[1];
    const menuSizeArray: MenuSize[] = [];

    const halfAndHalfConfig = sizeGroup?.halfHalfType;
    const halfAndHalfSizes = sizeGroup?.halfHalfSizes || [];

    console.log("Size and data => ", watchData, sizeGroup);

    firstMenu?.menuSizeList?.forEach((firstMenuSize) => {
      const secondMenuSize = secondMenu?.menuSizeList?.find(
        (size) => size?.sizeId === firstMenuSize?.sizeId
      );

      if (!secondMenuSize) return;

      const halfAndHalfAdditional =
        halfAndHalfSizes?.find((el) => el?.sizeId === firstMenuSize?.sizeId)
          ?.additionalPrice || 0;

      const firstTotal = firstMenu?.basePrice + firstMenuSize?.additionalPrice;

      const secondTotal =
        secondMenu?.basePrice + (secondMenuSize?.additionalPrice || 0);

      switch (halfAndHalfConfig) {
        case "AVERAGE":
          { const basePriceAverage =
            (firstMenu?.basePrice + secondMenu?.basePrice) / 2;

          const priceAverage = (firstTotal + secondTotal) / 2;
          const additionalPrices =
            priceAverage - basePriceAverage + halfAndHalfAdditional;

          return menuSizeArray?.push({
            ...firstMenuSize,
            basePrice: basePriceAverage,
            additionalPrice: additionalPrices,
          }); }
        case "LOWEST":
          { const lowestBasePrice = Math.min(
            firstMenu?.basePrice,
            secondMenu?.basePrice
          );
          const findLowest = Math.min(firstTotal, secondTotal);
          const priceLowest = findLowest - lowestBasePrice;
          const lowestAdditionalPrice = priceLowest + halfAndHalfAdditional;
          return menuSizeArray?.push({
            ...firstMenuSize,
            basePrice: lowestBasePrice,
            additionalPrice: lowestAdditionalPrice,
          }); }
        default:
          { const highestBasePrice = Math.max(
            firstMenu?.basePrice,
            secondMenu?.basePrice
          );
          const findHighest = Math.max(firstTotal, secondTotal);
          const priceHighest = findHighest - highestBasePrice;
          const additionalPrice = priceHighest + halfAndHalfAdditional;
          return menuSizeArray?.push({
            ...firstMenuSize,
            basePrice: highestBasePrice,
            additionalPrice: additionalPrice,
          }); }
      }
    });

    return menuSizeArray;
  }
}
