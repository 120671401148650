import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./globals.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2eb5fa7c6aa153face94ef9b569006e9@o4508708163878912.ingest.de.sentry.io/4508725891760208",
  integrations: [],
});

createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <App />
  // </StrictMode>
);
