// import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";

function ProfileMenuDropDown({ onClose }: { onClose(): void }) {
  const overlay = useRef(null);
  const onCloseHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === overlay.current) {
      onClose();
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("session");
    localStorage.removeItem("cart");
    localStorage.removeItem("scheduleData");
    localStorage.removeItem("selectedOrderStatus");
    window.location.href = "/";
  };

  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => document.body.classList.remove("modal-open");
  }, []);
  return (
    <div
      ref={overlay}
      className="modal-open ease-in-out duration-500 fixed inset-0 z-[70] "
      onClick={(e) => onCloseHandler(e)}
    >
      <div className="absolute top-16 right-6 bg-white shadow-2xl rounded-md min-w-[5rem] ">
        <div className="child:px-8 child:py-4 child:flex child:gap-2 child:flex-row child:items-center child:justify-start flex flex-col divide-y-2 ">
          {/* <Link to="/profile" onClick={onClose}>
            <img
              src="/images/icons/profile.svg"
              width="18"
              height="18"
              alt="return"
            />
            Profile
          </Link> */}
          {/* <Link to="/profile/order-history" onClick={onClose}>
            <img
              src="/images/icons/receipt.svg"
              width="18"
              height="18"
              alt="return"
            />
            Order History
          </Link> */}
          <button onClick={logoutHandler}>
            {/* <Icon icon="material-symbols:logout" /> */}
            <img
              src="/images/icons/logout.svg"
              width="18"
              height="18"
              alt="return"
            />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileMenuDropDown;
