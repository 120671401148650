import { ComboPortion } from "@/app/[location]/components/AddToCart/AddToCartItemsName";
import { CalculateMenuTradingPeriod } from "@/app/[location]/components/MenuTradingPeriods/CalculateTradingPeriod";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "@/app/[location]/menu/utils/sizeChangeUtils";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import {
  order,
  updateDealPriceDetails,
} from "@/app/_redux/slice/Order/OrderSlice";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import {
  MenuDealForm,
  MenuDealFormArray,
} from "@/app/_types/menuDeals.interface";
import {
  MenuDetailsEntity,
  OrderIngredient,
} from "@/app/_types/menuDetails";
import moment from "moment";


import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

interface MenuDealFormItemProps {
  dealCategories: MenuDealFormArray;
  mainIndex: number;
}

function MenuDealFormItem({
  dealCategories,
  mainIndex,
}: MenuDealFormItemProps) {
  const { control, setValue } = useFormContext<MenuDealForm>();
  const { dealDetails, schedule } = useSelector(order);
  const { selectedStore } = useSelector(selectStore);
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);

  const dispatch = useAppDispatch();

  const selectedDealInfo = useWatch({
    control,
    name: `menuDeal.${mainIndex}.isSelected`,
  });

  const menus = useWatch({
    control,
    name: `menuDeal.${mainIndex}.menus`,
  });

  const isSplit = useWatch({
    control,
    name: `menuDeal.${mainIndex}.config.isSplit`,
  });

  const tradingPeriodId = useWatch({
    control,
    name: `periodId`,
  });

  const ChangeDealHandler = async () => {
    setValue("config.selectedDealIndex", mainIndex);

    setValue(
      "config.selectedDealCategoryIndex",
      dealCategories?.dealCategoryIndex
    );

    const dealCategoriesList = dealDetails?.dealCategories?.find(
      (el) => el?.id === dealCategories?.dealCategoryId
    )?.dealCategoryMenus;

    console.log("dealCategoriesList", dealCategoriesList);

    if (dealCategoriesList?.length === 1 && !selectedDealInfo) {
      const menu = dealCategoriesList?.[0];

      if (!selectedStore?.id) {
        return toast.error("Something went wrong. Please Try Again.");
      }

      if (!storeDT) return toast.error("No store Time");

      const selectedTime = schedule?.selectedTime?.date
        ? schedule?.selectedTime?.date
        : storeDT;

      const selectedDay = schedule?.selectedDay?.day
        ? schedule?.selectedDay?.day
        : moment(storeDT).format("ddd").toUpperCase();

      const menuDetails: MenuDetailsEntity = await fetchRequest(
        serverPath.getMenuDetails(
          selectedStore?.id,
          menu?.menuId,
          tradingPeriodId
        ),
        "GET",
        undefined,
        { cache: "no-store" }
      );

      const priceInfo: ExtraPriceInfo = await fetchRequest(
        serverPath.getExtraPriceInfo(selectedStore?.id),
        "GET",
        undefined
      );

      const tradingHoursData = menuDetails?.tradingHours;

      const CheckTradingPeriod = CalculateMenuTradingPeriod({
        tradingPeriodId,
        tradingHoursData,
        selectedOrderStatus,
        selectedTime,
        selectedDay,
        storeDT,
      });

      if (!CheckTradingPeriod?.status)
        return toast.error("Selected option menu is unavailable");

      dispatch(
        updateDealPriceDetails({
          index: dealCategories?.dealCategoryIndex,
          extraPrice: priceInfo,
        })
      );

      setValue("comboPriceType", priceInfo?.combosPricesType);

      const comboList = ManageComboPayInitial(
        menuDetails?.menuOptionGroups || [],
        menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
      );

      const comboPrices = getIndividualMenuComboPrice(comboList);

      const finalData = {
        id: menuDetails?.menu?.id,
        name: menu?.menuName,
        basePrice: menuDetails?.menu?.basePrice || 0,
        finalPrice: menu?.finalPrice || 0,
        sizeGroupId: menuDetails?.menu?.sizeGroupId,
        menuSizeId: menu?.menuSizeId,
        comboPrice: comboPrices,
        hasHalfHalf: menu?.hasHalfHalf,
        hasExtras: menu?.hasExtras,
        ingredientPrice: 0,
        specialPrice: menu?.specialPrice || 0,
        // isComplete: true,
        combo: comboList || [],
        optionGroups: comboList || [],
        ingredients: menuDetails?.menuOptions || [],
        tradingHours: menuDetails?.tradingHours || [],
        menuSizeCount: menuDetails?.menuSizes.length || 0,
      };

      console.log(finalData);
      setValue(`menuDeal.${mainIndex}.isSelected`, true);
      setValue(`menuDeal.${mainIndex}.menus.0`, finalData);
      setValue("config.selectedPage", "EDIT_MENU");
      return;
    }

    if (!selectedDealInfo)
      return setValue("config.selectedPage", "SELECT_MENU");

    console.log("deal menus selected Deal page", menus)

    // if(menus?.length === 1 && !menus?.[0]?.optionGroups)
    setValue("config.selectedPage", "EDIT_MENU");
  };

  return (
    <button
      className="bg-white group rounded-md relative w-full p-4 flex  justify-between gap-2 "
      onClick={ChangeDealHandler}
    >
      {selectedDealInfo ? (
        <div className="flex flex-col gap-2">
          {menus?.map((data, index) => (
            <div className="flex-1 flex items-start flex-col" key={index}>
              <div className="flex gap-2 items-center">
                <p className="font-bold text-base text-left">
                  {menus?.length === 2 ? `Half ${data?.name}` : data?.name}
                </p>
              </div>
              <div className="text-sm text-left w-full pl-2 text-secondaryText">
                {data?.optionGroups?.length
                  ? data?.optionGroups?.map((combo, index) => (
                      <ComboPortion
                        combo={combo}
                        isSplit={isSplit}
                        key={index}
                      />
                    ))
                  : null}
                <AdditionalIngredient
                  ingredientList={data?.ingredients}
                  isSplit={isSplit}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <p>
            Choose {dealCategories?.sizeName} {dealCategories?.name}
          </p>
        </>
      )}
      <div className="flex flex-row items-center gap-2">
        {selectedDealInfo ? (
          <img
            src="/images/icons/check-green.svg"
            width={20}
            height={25}
            alt="- selected"
          />
        ) : null}
        <img
          src={"/images/icons/right.svg"}
          width={20}
          height={20}
          alt={">"}
          className=" group-hover:scale-125 transition-all duration-300"
        />
      </div>
    </button>
  );
}

export function AdditionalIngredient({
  ingredientList,
  isSplit,
}: {
  ingredientList: OrderIngredient[];
  isSplit: boolean;
}) {
  const addedIngredients = ingredientList?.filter(
    (el) =>
      el?.status === "added" ||
      (el?.status === "current" && el?.qty > 1) ||
      (el?.status === "current" && el?.qty === 0)
  );
  // const watchConfig = false;

  if (!addedIngredients?.length) return null;

  const removeIng = addedIngredients?.filter((el) => el?.qty <= 0);

  const addedIng = addedIngredients?.filter((el) => el?.qty > 0);
  return (
    <p className="flex flex-wrap flex-col">
      {addedIng?.length ? (
        <div className="flex flex-wrap gap-1 pl-0.5">
          {addedIng?.map((added, index) => (
            <div
              key={index}
              className={`flex items-center ${
                added?.qty === 0 && "italic line-through text-error"
              }`}
            >
              {added?.name}
              {added?.qty <= 1 ? null : (
                <>
                  {added?.status === "current" &&
                  added?.qty - 1 === 1 ? null : added?.status === "current" ? (
                    <>
                      <img
                        src="/images/icons/cross.svg"
                        width="10"
                        height="10"
                        alt="x"
                      />
                      {added?.qty - 1}
                    </>
                  ) : (
                    <>
                      <img
                        src="/images/icons/cross.svg"
                        width="10"
                        height="10"
                        alt="x"
                      />
                      {added?.qty}
                    </>
                  )}
                </>
              )}
              {added?.qty === 0 ? null : (
                <>
                  <span className="pl-0.2">
                    {(added?.total || added?.price || 0) > 0
                      ? isSplit
                        ? `($${(added?.total || added?.price || 0) / 2})`
                        : `($${added?.total || added?.price || 0})`
                      : null}
                  </span>
                  {index === addedIng?.length - 1 ? null : ","}
                </>
              )}
            </div>
          ))}
        </div>
      ) : null}
      <div className="flex gap-1">
        {removeIng?.length === 0
          ? null
          : removeIng?.map((Ing, index) => (
              <span className="flex" key={index}>
                <div
                  className={`flex items-center italic line-through text-error`}
                >
                  {Ing?.name}
                </div>
                {index === removeIng?.length - 1 ? null : ","}
              </span>
            ))}
      </div>
    </p>
  );
}

export default MenuDealFormItem;
