import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
import CircularSpinner from "@/app/global/CircularSpinner";
import { useGetAuthToken } from "@/app/hooks/useGetAuthToken";

import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  calculatScheduleAndStoreMinsDiff,
  calculateTotalAmount,
  checkOrderStatus,
} from "../../paymentLogic";
import { validateCustomerOrderType } from "./CheckoutPayLaterData";
import { useState } from "react";

function CheckoutPaypalPaymentButton() {
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const { handleSubmit, setValue, control } =
    useFormContext<ConfirmOrderForm>();
  const { OrderLocation, OrderList, dealList, todayTradingHours, schedule } =
    useSelector(order);
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);

  const allCartDetails = [...dealList, ...OrderList];

  const token = useGetAuthToken();

  const watchOrderFuture = useWatch({
    control,
    name: "config.isOrderForFuture",
  });

  const watchDeliveryOrderStatus = useWatch({
    control,
    name: `deliveryOrderStatus`,
  });

  const deliveryDistancePossible = useWatch({
    control,
    name: `deliveryOrderDistance`,
  });

  const PaypalPaymentHandler = handleSubmit(async (data) => {
    const validateCustomer = validateCustomerOrderType(
      data?.orderType,
      data?.customer
    );

    if (!validateCustomer) {
      setValue("config.isUserInformationEdit", true);
      return;
    }

    const tradingStatus = checkOrderStatus({
      selectedOrderStatus,
      allCartDetails,
      dealList,
      todayTradingHours,
      storeDT,
      schedule,
    });

    if (!tradingStatus) return;
    if (!token) return toast.error("Please Login First");

    // const returnUrl = `${import.meta.env.VITE_STRIPE_RETURN_URL}/checkout`;
    // const cancelUrl = `${import.meta.env.VITE_STRIPE_RETURN_URL}/checkout`;

    setIsPaymentLoading(true);
    const finalData = {
      appliedVouchers: data?.appliedVouchers,
      appliedPoints: data?.appliedPoints || 0,
      discountFromPoints: data?.discountFromPoints || 0,
      orderType: data?.orderType === "STORE" ? "PICKUP" : data?.orderType,
      storeId: OrderLocation?.id,
      addressType: data?.addressType,
      // deals: dealList?.filter((el) => el?.cartType === "Deal"),
      deals: dealList
        ?.filter((el) => el?.cartType === "Deal")
        ?.map((deal) => {
          return {
            // ...deal,
            note: deal?.note,
            id: deal?.id,
            periodId: deal?.periodId,
            qty: deal?.qty,
            dealType: deal?.dealType,
            dealItems: deal?.dealItems?.map((els) => ({
              sizeId: els?.sizeId,
              sizeName: els?.sizeName,
              discount: els?.discount,
              config: els?.config,
              menus: els?.menus.map((menu) => ({
                id: menu?.id,
                menuSizeId: menu?.menuSizeId,
                name: menu?.name,
                optionGroups: menu?.optionGroups,
                ingredients: menu?.ingredients,
              })),
              dealCategoryId: els?.dealCategoryId,
            })),
            name: deal?.name,
            totalPrice: deal?.totalPrice * deal?.qty,
          };
        }),
      offers:
        dealList
          ?.filter((el) => el?.cartType === "Offer")
          ?.map((offer) => {
            return {
              // ...offer,
              note: offer?.note,
              totalPrice: offer?.totalPrice * offer?.qty,
              qty: offer?.qty,
              name: offer?.name,
              offerItems: offer?.dealItems?.map((els) => ({
                sizeId: els?.sizeId,
                sizeName: els?.sizeName,
                discount: els?.discount,
                config: els?.config,
                menus: els?.menus.map((menu) => ({
                  id: menu?.id,
                  menuSizeId: menu?.menuSizeId,
                  name: menu?.name,
                  optionGroups: menu?.optionGroups,
                  ingredients: menu?.ingredients,
                })),
                offerCategoryId: els?.dealCategoryId,
              })),
              id: offer?.id,
              periodId: offer?.periodId,
            };
          }) || [],
      customerOrderItems: OrderList?.map((order) => ({
        sizeId: order?.size,
        menus: order?.data,
        totalPrice: order?.totalPrice * order?.qty,
        qty: order?.qty,
        categoryId: order?.categoryId,
        categoryName: order?.categoryName,
        sizeName: order?.sizeName,
        config: order?.config,
        remove: order?.config?.isRemove,
        note: order?.note,
        // ...order,
      })),
      paymentGatewayType: "PAYPAL",
      // returnUrl: returnUrl,
      // cancelUrl: cancelUrl,
      tradingPeriodId:
        OrderList.length > 0 ? OrderList[0]?.periodId : dealList[0]?.periodId,
      scheduledDay: schedule?.selectedDay?.day,
      scheduledDT: schedule?.selectedTime?.date,
      scheduledMins: calculatScheduleAndStoreMinsDiff({
        storeDT,
        scheduleDate: schedule?.selectedTime?.date,
      }),
      specialInstruction: data?.specialInstruction || undefined,
      totalAmount: calculateTotalAmount({ OrderList, dealList }),
      cardSurcharge: data?.cardSurcharge || 0,
      specialSurcharge: data?.specialSurcharge || 0,
    };

    const response = await fetchRequest(
      serverPath.createOrder,
      "POST",
      finalData,
      {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      "res"
    );
    console.log("hit place", response);
    if (response?.status === 200) {
      // const data = await response?.json();
      const data = await response?.data;
      console.log("redirect data => ", data);
      if (data?.redirectUrl) {
        console.log("redirect => ", data?.redirectUrl);
        return window.location.replace(data?.redirectUrl);
      }
    } else {
      // toast.error("Something went wrong.Please Try Again");
      toast.error(response?.response?.data?.message);
    }

    setIsPaymentLoading(false);
  });
  return (
    <div className="flex border-t-2">
      <button
        // className="bg-[rgba(141,190,73)] text-center p-4 rounded-lg m-2 text-white flex-1 "
        className={`${
          !watchOrderFuture ||
          !watchDeliveryOrderStatus ||
          !deliveryDistancePossible
            ? "bg-gray-500"
            : "bg-[rgba(141,190,73)]"
        } text-center p-4 rounded-lg m-2 text-white flex-1`}
        onClick={PaypalPaymentHandler}
        disabled={
          isPaymentLoading ||
          !watchOrderFuture ||
          !watchDeliveryOrderStatus ||
          !deliveryDistancePossible
        }
      >
        {isPaymentLoading ? <CircularSpinner /> : "Confirm and Make Payment"}
      </button>
    </div>
  );
}

export default CheckoutPaypalPaymentButton;
