
import CustomModalClients from "../global/modal/CustomModalWithoutWhite";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Customer, UserAddress } from "../_types/checkout.interface";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { selectUI } from "../_redux/slice/UISlice/UISlice";
import { order } from "../_redux/slice/Order/OrderSlice";
import { trimPhoneNumber } from "../utils/managePhoneNumber";
import fetchRequest from "../_axios/fetchRequest";
import { serverPath } from "../_axios/path";
import { useGetAuthToken } from "../hooks/useGetAuthToken";
import UserEditAddress from "../checkout/components/CheckoutUserDetails/UserEditAddress";
import ErrorAstrick from "../global/elements/ErrorAstrick";
import { addCustomerDetails } from "../_redux/slice/storeslice.ts/storeSlice";
import CircularSpinner from "../global/CircularSpinner";
import { useState } from "react";

const GENDER = [
  { id: 1, value: "MALE", label: "Male" },
  { id: 2, value: "FEMALE", label: "Female" },
  { id: 3, value: "OTHER", label: "Other" },
];

export interface UserEditFormProps {
  open: boolean;
  handleClose: () => void;
  customerInfo: Customer;
  userEditStatus: "EDIT_INFORMATION" | "CUSTOMER_ERROR";
  updateUserData: (data: Customer) => void;
}

export interface UserEditFormForm {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  gender: "MALE" | "FEMALE" | "OTHER" | null;
  address: UserAddress[];
  status: "DELIVERY" | "PICKUP";
  config: {
    isNewUser: boolean;
  };
}

function UserEditFormModal({
  open,
  handleClose,
  customerInfo,
  userEditStatus,
  updateUserData,
}: UserEditFormProps) {
  return (
    <CustomModalClients
      open={open}
      handleClose={handleClose}
      className="w-full md:w-fit min-w-[10rem] md:min-w-[30rem]"
    >
      <div className="p-2 md:p-4">
        <div className="flex justify-end items-center">
          <button
            onClick={handleClose}
            title={
              !customerInfo?.phone || !customerInfo?.firstName
                ? "Please fill all the fields and Save"
                : ""
            }
            type="button"
            // disabled={!customerInfo?.phone || !customerInfo?.firstName}
          >
            <img
              src="/images/icons/close.svg"
              alt="close"
              width={25}
              height={25}
            />
          </button>
        </div>
        <UserEditForm
          customerInfo={customerInfo}
          handleClose={handleClose}
          userEditStatus={userEditStatus}
          updateUserData={updateUserData}
        />
      </div>
    </CustomModalClients>
  );
}

export default UserEditFormModal;

export function UserEditForm({
  customerInfo,
  userEditStatus,
  handleClose,
  updateUserData,
}: {
  customerInfo: Customer;
  userEditStatus: "EDIT_INFORMATION" | "CUSTOMER_ERROR";
  handleClose: () => void;
  updateUserData: (data: Customer) => void;
}) {
  const { selectedOrderStatus } = useSelector(selectUI);
  const { OrderLocation } = useSelector(order);
  const dispatch = useDispatch();

  const token = useGetAuthToken();

  const defaultValue: UserEditFormForm = {
    phone: customerInfo?.phone || "",
    email: customerInfo?.email || "",
    firstName: customerInfo?.firstName || "",
    lastName: customerInfo?.lastName || "",
    gender: customerInfo?.gender || null,
    status: selectedOrderStatus,
    address: customerInfo?.address || [],
    config: {
      isNewUser: customerInfo?.firstName ? false : true,
    },
  };

  const methods = useForm({ defaultValues: defaultValue });

  const {
    register,
    control,
    formState: { errors },
    clearErrors,
    trigger,
    watch,
    handleSubmit,
  } = methods;

  console.log("form watch => ", watch());
  console.log(customerInfo)

  const [isLoading, setIsLoading] = useState(false);
  const watchStatus = useWatch({
    control,
    name: "status",
  });

  const watchAddress = useWatch({
    control,
    name: "address",
  });

  console.log(watchAddress);

  const onSubmit = async (data: UserEditFormForm) => {
    setIsLoading(true);
    const trimPhone = trimPhoneNumber(data?.phone);
    console.log("getCustomer => ", data, trimPhone);
    const UserDetails = {
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      // phone: trimPhone,
      gender: data?.gender,
      storeGroupId: import.meta.env.VITE_STORE_GROUP_ID,
      customerAddresses: (data?.address || [])
        .filter(
          (address) =>
            address && address?.address && address?.address?.streetNo !== ""
        )
        .map((address) => ({
          type: address.type,
          ...address.address,
          storeId: OrderLocation?.id,
        })),
    };

    console.log(UserDetails);

    const customer = await fetchRequest(
      serverPath.createCustomer,
      "PUT",
      UserDetails,
      {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      "res"
    );

    console.log(UserDetails?.customerAddresses);

    const userAddresses =
      UserDetails?.customerAddresses?.map((address) => ({
        type: address?.type,
        address: {
          streetNo: address?.streetNo,
          latitude: address?.latitude ?? 0,
          longitude: address?.longitude ?? 0,
          id: address?.id,
          type: address?.type,
          streetName: address?.streetName,
          suburbId: address?.suburbId,
          suburbName: address?.suburbName,
          unitNo: address?.unitNo,
          pointofInterest: address?.pointOfInterest,
        },
      })) || [];

    const updatedUserAddress: UserAddress[] =
      userAddresses.length < 2
        ? [
            ...userAddresses,
            {
              type: "SECONDARY",
              address: {
                streetNo: undefined,
                latitude: 0,
                longitude: 0,
                id: undefined,
                type: "SECONDARY",
                streetName: undefined,
                suburbId: undefined,
                suburbName: undefined,
                unitNo: undefined,
                pointOfInterest: undefined,
              },
            },
          ]
        : userAddresses;

    const updatedUserDetails = {
      email: UserDetails?.email,
      firstName: UserDetails?.firstName,
      lastName: UserDetails?.lastName,
      gender: UserDetails?.gender,
      storeGroupId: UserDetails?.storeGroupId,
      // address: UserDetails?.customerAddresses,
      address: updatedUserAddress,
      phone: data?.phone,
    };

    if (customer.status === 200) {
      toast.success("Profile Updated Successfully");
      updateUserData(updatedUserDetails);
      handleClose();
      setIsLoading(false);
      dispatch(addCustomerDetails(updatedUserDetails));
      // window.location.reload();
    } else {
      setIsLoading(false);
      toast.error("Error While Updating Profile");
    }
  };

  const validateUserInformation = async () => {
    clearErrors();
    const isValid = await trigger([
      "phone",
      "firstName",
      "lastName",
      "address.0.address.streetNo",
      "address.0.address.streetName",
    ]);

    if (isValid) {
      handleSubmit(onSubmit)();
    } else {
      toast.error("Please Provide All the Information");
    }
  };

  const checkAddress = customerInfo?.address?.find(
    (el) => el?.type === "PRIMARY"
  )?.address?.streetName;

  return (
    <FormProvider {...methods}>
      <div className=" gap-2 ">
        {userEditStatus === "CUSTOMER_ERROR" &&
        !checkAddress &&
        watchStatus === "DELIVERY" ? (
          <div className="w-[90%] pb-4">
            <p className="font-semibold">
              ** Hey, Please Provide{" "}
              {!customerInfo?.firstName ? (
                <span className="font-bold text-primary"> Your First Name</span>
              ) : null}{" "}
              {!customerInfo?.firstName &&
              !customerInfo?.address?.[0]?.address?.streetName ? (
                <span>and </span>
              ) : null}
              {!customerInfo?.address?.[0]?.address?.streetName ? (
                <span>
                  Your
                  <span className="font-bold text-primary">
                    {" "}
                    Address Information{" "}
                  </span>
                  for Delivery{" "}
                </span>
              ) : null}{" "}
              {/* or keep{" "}
              <span
                className="font-semibold underline cursor-pointer"
                onClick={handleClose}
              >
                making changes
              </span> */}
            </p>
          </div>
        ) : null}

        <div className="flex items-start gap-6 flex-wrap">
          <div className="flex flex-col gap-2 p-2">
            <h2 className="font-bold text-lg">Edit User Information</h2>
            <div className="flex gap-2">
              <>
                <img
                  src="/images/icons/profile.svg"
                  width={25}
                  height={25}
                  alt="User :"
                />
                {errors?.firstName && <ErrorAstrick />}
              </>

              <div className="flex gap-2">
                <input
                  type="text"
                  id="firstName"
                  {...register("firstName", { required: true })}
                  className={"form-input w-80"}
                  placeholder="Enter Name.."
                />
              </div>
            </div>
            <div className="flex gap-2">
              <img
                src="/images/icons/email.svg"
                width={25}
                height={25}
                alt="Email :"
              />

              <input
                type="email"
                id="Email"
                {...register("email")}
                className={"form-input w-80"}
                placeholder="Enter Email.."
              />
            </div>

            <div className="flex gap-2">
              <span>
                Gender
                {/* {errors?.customer?.gender && <ErrorAstrick />}: */}
              </span>

              <div className="flex gap-4 ">
                {GENDER?.map((item) => (
                  <div className="flex gap-2" key={item?.id}>
                    <input
                      type="radio"
                      {...register("gender")}
                      value={item?.value}
                      id={item?.label}
                    />
                    <label htmlFor={item?.label}>{item?.label}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="p-2">
            {watchStatus === "DELIVERY" && (
              <>
                <h2 className="font-bold text-lg">Edit Delivery Information</h2>
                <div className={"flex gap-4 flex-col"}>
                  {watchAddress?.map((el, index) => {
                    // if (el?.type === "SECONDARY") return null;
                    const addressType = customerInfo?.address?.find(
                      (el) => el?.type === "PRIMARY"
                    );
                    const checkPrimaryAddress =
                      addressType && addressType?.address?.streetName
                        ? true
                        : false;

                    if (!checkPrimaryAddress && el?.type === "SECONDARY")
                      return null;

                    return (
                      <UserEditAddress
                        key={index}
                        item={el}
                        checkPrimaryAddress={checkPrimaryAddress}
                        findIndex={index}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="form-btn"
            type="button"
            onClick={validateUserInformation}
            disabled={isLoading}
          >
            {isLoading ? <CircularSpinner /> : "Confirm"}
          </button>
        </div>
      </div>
    </FormProvider>
  );
}
