import { AddMenuItemsForm } from "../_types/menuDetails";
import { DealDetailsInCart } from "../_types/menuDeals.interface";
import { POSTCART } from "../api/cart/route";
import { GETCART } from "../api/cart/getCart/route";
// check and update cart information

export interface UpdateCartInformation {
  status: "ADD" | "REMOVE" | "NEW";
  data?: AddMenuItemsForm;
  itemStatus: "MENU" | "DEAL";
  dealDetails?: DealDetailsInCart;
  location?: string;
}

export function updateCartInformation(
  item: UpdateCartInformation,
  getToken: string
) {
  // const res = await fetch(`/api/cart`, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({ item, cartToken: getToken }),
  // });
  const res = POSTCART({
    item,
    cartToken: getToken,
  });

  return res;
}

export function getCartList(getToken: string) {
  const res = GETCART(getToken);
  return res;
}

// export function getCartListLocation(getToken: string) {
//   const res = GETCARTLISTOKEN(getToken);
//   return res;
// }
