import { Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { countriesCode } from "../../login/countryCode";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import RegisterOTP from "./RegisterOTP";
import RegisterUserForm from "./RegisterUserForm";
import { serverPath } from "@/app/_axios/path";
import fetchRequest from "@/app/_axios/fetchRequest";
import toast from "react-hot-toast";
import { axiosErrorMessage } from "@/app/_axios/handleAxiosError";
import CustomerSupportMsg from "../../CustomerSupportMsg";

interface RegisterFormParams {
  changeStatus?(status: "LOGIN" | "REGISTER"): void;
  handleClose?(): void;
  isModal?: boolean;
}

export interface RegisterFormInterface {
  phoneNo: string;
  OTP: string;
  firstName: string;
  lastName: string;
  gender: string;
  // phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  storeGroupId: number;
  provider: string;
  error?: string;
  isLoadingOTP: boolean;
}

function RegisterForm({
  changeStatus,
  handleClose,
  isModal,
}: RegisterFormParams) {
  const { storeDetails } = useSelector(selectStore);
  const router = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") || "/";
  const CountryCodeIndex = countriesCode?.findIndex(
    (country) => country?.iso === (storeDetails?.countryISO ?? "AUS")
  );

  console.log("hit", storeDetails?.countryISO, CountryCodeIndex)

  const methods = useForm<RegisterFormInterface>();

  // const { handleSubmit } = methods;
  const [selectedCountry, setSelectedCountry] = useState({
    iso: countriesCode[CountryCodeIndex]?.iso,
    code: countriesCode[CountryCodeIndex]?.code,
  });

  const [step, setStep] = useState(1);
  const [timer, setTimer] = useState(30);

  // const { mutate, isLoading } = useMutation({
  //   mutationFn: (data: RegisterFormInterface) => {
  //     return instance.post("/customerAuth/customer", data);
  //   },
  // });

  const isLoading = false;

  const LoginHandler = () => {
    if (changeStatus) {
      changeStatus("LOGIN");
    } else {
      router("/auth/login");
    }
  };

  console.log(LoginHandler)

  // const RegisterHandler = handleSubmit((data: RegisterFormInterface) => {
  //   mutate({ ...data, storeGroupId: 1, provider: "local" });
  // });

  const BackPage = () => {
    setStep(step - 1);
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIso = e.target.value;
    const Countryselected =
      countriesCode?.find((country) => country.iso === selectedIso) ||
      selectedCountry;
    setSelectedCountry({
      iso: Countryselected?.iso,
      code: Countryselected?.code,
    });
  };

  const requestOTP = async (phoneNo: string, countryCode: string) => {
    try {
      const response = await fetchRequest(
        serverPath.RequestOTP,
        "POST",
        {
          phone: phoneNo,
          countryCode,
          storeGroupId: import.meta.env.VITE_STORE_GROUP_ID,
        },
        {
          "Content-Type": "application/json",
        },
        "res"
      );

      if (response?.status === 200) {
        console.log("register OPT response", response);
        return response?.data;
      } else {
        const res = response?.data;
        console.log(res);
        throw new Error(res?.message);
      }
    } catch (error: any) {
      toast.error(
        axiosErrorMessage(error, "Something went Wrong. Please Try Again.")
      );
      return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <div>
          <div className="flex items-center justify-between p-4">
            <h1 className="font-bold text-xl ">Create Your New Account</h1>
            <div className="flex gap-2 items-center">
              {step !== 1 && (
                <button
                  type="button"
                  onClick={BackPage}
                  // className={!isModal ? "hidden" : undefined}
                >
                  <img
                    src="/images/icons/back.svg"
                    width={25}
                    height={25}
                    alt="Go back"
                  />
                </button>
              )}
              <button
                type="button"
                onClick={handleClose}
                className={!isModal ? "hidden" : undefined}
              >
                <img
                  src="/images/icons/close.svg"
                  width={25}
                  height={25}
                  alt="close"
                />
              </button>
            </div>
          </div>
          {/* <ExtraSignIn /> */}
          <div className="flex flex-wrap">
            <div className="p-4 md:p-6 flex flex-col gap-4 w-full md:w-1/2 justify-center">
              {step === 1 && (
                <RegisterOTP
                  handleCountryChange={handleCountryChange}
                  isLoading={isLoading}
                  selectedCountry={selectedCountry}
                  requestOTP={requestOTP}
                  setStep={setStep}
                  setTimer={setTimer}
                  changeStatus={changeStatus}
                />
              )}
              {step === 2 && (
                <RegisterUserForm
                  isLoading={isLoading}
                  selectedCountry={selectedCountry}
                  changeStatus={changeStatus}
                  requestOTP={requestOTP}
                  handleClose={handleClose}
                  setTimer={setTimer}
                  timer={timer}
                />
              )}
              <div className="flex justify-start items-end">
                <p>
                  Already Have an Account ?{" "}
                  <Link
                    to={{
                      pathname: "/auth/login",
                      search: `?redirect=${redirect}`,
                    }}
                    className=" text-primary font-semibold"
                  >
                    Login
                  </Link>
                  {/* <button
                    // href={"/auth/login"}
                    type="button"
                    onClick={LoginHandler}
                    className=" text-primary font-semibold"
                  >
                    Login
                  </button> */}
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 pb-4">
              <CustomerSupportMsg />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export default RegisterForm;
