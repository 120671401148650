import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Logo() {
  const { storeLogo } = useSelector(selectUI);

  return (
    <Link to={"/"} className="h-[3rem] md:h-[4rem] w-full max-w-full">
      <img
        // src="/logo/restologo.png"
        src={storeLogo?.logoImageUrl}
        width={200}
        height={200}
        alt="Logo"
        className="h-[3rem] md:h-[4rem] w-full max-w-full object-contain aspect-square"
      />
    </Link>
  );
}

export default Logo;
