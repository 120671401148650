import { useFormContext, useWatch } from "react-hook-form";
import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
// import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { useState } from "react";
import toast from "react-hot-toast";
import {
  axiosErrorMessage,
  AxiosErrorMessage,
} from "@/app/_axios/handleAxiosError";
import { twMerge } from "tailwind-merge";

function PaymentStatus() {
  const { getValues, control } = useFormContext<ConfirmOrderForm>();

  const customer = getValues("customer");

  const [isLoading, setIsLoading] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const [emailExist, setEmailExist] = useState(false);
  const [completeMessage, setCompleteMessage] = useState("");
  const [OTP, setOTP] = useState("");

  const orderNumber = useWatch({
    control,
    name: `config.orderNumber`,
  });
  const totalAmount = useWatch({
    control,
    name: `config.finalTotalResponse`,
  });

  console.log(orderNumber, totalAmount);

  const { mutate } = useMutation({
    mutationFn: ({
      orderId,
      storeId,
      customerEmail,
      otp,
    }: {
      orderId: string;
      storeId: string;
      customerEmail: string;
      otp?: number;
    }) =>
      fetchRequest(
        serverPath?.customerInvoiceEmail,
        "POST",
        {
          orderId,
          storeId,
          customerEmail,
          otp,
        },
        {
          "Content-Type": "application/json",
        }
      ),
    onSuccess: (data) => {
      setIsLoading(false);
      if (data === true) {
        toast.success(
          `The Invoice has been sent successfully to ${customerEmail}`
        );
        setCompleteMessage(
          `The Invoice has been sent successfully to ${customerEmail}`
        );
        setCustomerEmail("");
        setEmailExist(false);
      } else if (data === false) {
        setCompleteMessage(
          "The email is already linked to an account. An OTP has been sent to your Email to proceed."
        );
        toast.error(
          "The email is already linked to an account. An OTP has been sent to your Email to proceed."
        );
        setEmailExist(true);
      } else {
        toast.error("Something went Wrong. Please Try Again.");
      }
    },
    onError: (error: AxiosErrorMessage) => {
      setIsLoading(false);
      toast.error(
        axiosErrorMessage(error || "Something went Wrong. Please Try Again.")
      );
    },
  });

  const InvoiceEmailHandler = () => {
    const orderId = getValues("config.orderId");
    const storeId = getValues("storeLocationId");
    if (!customerEmail) return toast.error("No Email Enter");
    if (!orderId || !storeId)
      return toast.error("Something went Wrong. Please Try Again.");
    setOTP("");
    setIsLoading(true);

    mutate({
      orderId,
      storeId,
      customerEmail,
    });
  };

  const OTPHandler = () => {
    const orderId = getValues("config.orderId");
    const storeId = getValues("storeLocationId");
    if (!customerEmail) return toast.error("No Email Enter");
    if (!orderId || !storeId)
      return toast.error("Something went Wrong. Please Try Again.");
    if (!customerEmail) return toast.error("No Email Enter");
    mutate({
      orderId,
      storeId,
      customerEmail,
      otp: parseInt(OTP),
    });
  };

  return (
    <div className="min-h-[75vh] flex items-center justify-center text-3xl font-bold">
      <div className="flex flex-col items-center justify-center gap-2">
        {/* <Icon icon="gg:check-o" color="#00bf00" width={100} /> */}
        <img
          src="/images/icons/checkO.svg"
          width="100"
          height="100"
          alt="check"
        />
        <h1>Your order has been place successfully .</h1>
        <h1>Thank You.</h1>
        {orderNumber && <p className="text-xl">Order Number: {orderNumber}</p>}
        {totalAmount && (
          <p className="text-xl">Total: $ {(totalAmount || 0).toFixed(2)}</p>
        )}
        {!customer?.email && (
          <div className="flex border border-gray-200 rounded-md pl-1">
            <input
              type="email"
              value={customerEmail}
              placeholder="Enter Your Email to Receive Invoice"
              className="outline-none pl-1 text-sm font-normal min-w-80"
              onChange={(e) => setCustomerEmail(e.target.value)}
            />
            <button
              className={twMerge(
                "text-sm bg-green-700 text-white rounded-r-md py-3 px-2",
                isLoading && "cursor-not-allowed bg-gray-400"
              )}
              onClick={InvoiceEmailHandler}
              disabled={isLoading}
            >
              Invoice Email
            </button>
          </div>
        )}
        {emailExist && (
          <div className="flex border border-gray-200 rounded-md pl-1">
            <input
              type="email"
              className="outline-none pl-1 text-sm font-normal min-w-80"
              placeholder="12345"
              onChange={(e) => setOTP(e.target.value)}
            />
            <button
              className="text-sm bg-green-700 text-white rounded-r-md py-3 px-2"
              onClick={OTPHandler}
              disabled={isLoading}
            >
              OTP Confirm
            </button>
          </div>
        )}
        {completeMessage && (
          <div className="pt-4 pb-4">
            <p className="text-sm font-normal"> * {completeMessage}</p>
          </div>
        )}

        {/* <div className="text-base flex gap-8 items-center justify-center pt-4">
          <Link to={"/profile/order-history"}>
            <button className="form-btn flex gap-2 items-center justify-center ">
              <img
                src="/images/icons/issueTracking.svg"
                width="20"
                height="20"
                alt="Tracking"
              />
              Click here for more details
            </button>
          </Link>
          <Link to={"/"} className="text-blue-700 underline flex items-center">
            Return to HomePage
          </Link>
        </div> */}
      </div>

      {/* <CheckIsSuccessInPayment isSuccess={true} /> */}
    </div>
  );
}

export default PaymentStatus;
