import { MenuDealForm } from "@/app/_types/menuDeals.interface";
import { createContext } from "react";
import { UseFieldArrayReturn } from "react-hook-form";

export const DealMenuIngredientProvider = createContext<
  | UseFieldArrayReturn<
      MenuDealForm,
      `menuDeal.${number}.menus.${number}.ingredients`,
      "fieldId"
    >
  | undefined
>(undefined);
