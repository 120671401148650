import { AddMenuItemsForm, MenuDetailsEntity } from "@/app/_types/menuDetails";
import { v4 as uuidv4 } from "uuid";
import React, { createContext, useEffect } from "react";
import {
  useForm,
  FormProvider,
  UseFieldArrayReturn,
  useFieldArray,
} from "react-hook-form";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import MenuDetailsManageForm from "./MenuDetailsManageForm";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "../utils/sizeChangeUtils";
import { useDispatch } from "react-redux";
import { changeStoreDT } from "@/app/_redux/slice/UISlice/UISlice";

type FieldName = `data.${number}.ingredients`;

export const IngredientProvider = createContext<
  | UseFieldArrayReturn<AddMenuItemsForm, FieldName, `fieldId${number}`>
  | undefined
>(undefined);

export const FormMenuDataProvider = createContext<
  UseFieldArrayReturn<AddMenuItemsForm, "data", `fieldId`> | undefined
>(undefined);

export const MenuDetailsProvider = createContext<MenuDetailsEntity | undefined>(
  undefined
);

interface MenuDetailsForm {
  name: string;
  menuDetails: MenuDetailsEntity;
  isModal: boolean;
  children: React.ReactNode;
  extraPrices: ExtraPriceInfo;
  periodId: string;
  storeDT: string;
}

function MenuDetailsForm({
  menuDetails,
  isModal,
  extraPrices,
  children,
  periodId,
  storeDT,
}: MenuDetailsForm) {
  const dispatch = useDispatch();
  const comboList = ManageComboPayInitial(
    menuDetails?.menuOptionGroups || [],
    menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
  );

  const comboPrices = getIndividualMenuComboPrice(comboList);

  const defaultValues: AddMenuItemsForm = {
    periodId: periodId,
    categoryId: menuDetails?.menu?.categoryId || null,
    categoryName: menuDetails?.menu?.categoryName || "",
    uuid: uuidv4(),
    comboPriceType: extraPrices?.combosPricesType || "HIGHEST",
    name: menuDetails?.menu?.name || "",
    sizeList: menuDetails?.menuSizes,
    size: menuDetails?.menuSizes?.[0]?.sizeId.toString() || "",
    sizeName: menuDetails?.menuSizes?.[0]?.sizeName || "",
    qty: 1,
    sizePrice: menuDetails?.menuSizes?.[0]?.additionalPrice,
    basePrice: menuDetails?.menu?.basePrice,
    totalPrice: 0,
    sizeGroupId: menuDetails?.menu?.sizeGroupId,
    originalPrice: 0,
    tradingHours: menuDetails?.tradingHours,
    menuSizeCount: menuDetails?.menu?.menuSizeCount,
    config: {
      isSplit: false,
      selectedIndex: 0,
      canAddToCart: true,
      isRemove: false,
    },
    data: [
      {
        id: menuDetails?.menu?.id,
        name: menuDetails?.menu?.name,
        description: menuDetails?.menu?.description || "",
        basePrice: menuDetails?.menu?.basePrice,
        menuSizeId: menuDetails?.menuSizes?.[0].id,
        menuSizeList: menuDetails?.menuSizes,
        note: "",
        // combo: comboList,
        optionGroups: comboList,
        imageUrl: menuDetails?.menu?.imageUrl,
        position: "LEFT",
        ingredientPrice: 0,
        comboPrice: comboPrices || 0,
        ingredients: menuDetails?.menuOptions,
      },
    ],
  };

  const methods = useForm({ defaultValues });

  const { watch, control, getValues, setValue } = methods;
  console.log("Watch selected Menu=> ", watch());

  const watchConfigSelected = watch("config.selectedIndex");

  const fieldName: FieldName =
    `data.${watchConfigSelected}.ingredients` as const;

  const fieldArray = useFieldArray<
    AddMenuItemsForm,
    typeof fieldName,
    `fieldId${number}`
  >({
    control,
    name: fieldName,
    keyName: `fieldId${watchConfigSelected}`,
  });

  const { replace } = fieldArray;

  const dataFieldArray = useFieldArray({
    control,
    name: "data",
    keyName: `fieldId`,
  });

  useEffect(() => {
    const value = getValues(`data.${watchConfigSelected}`);
    replace(value?.ingredients);
    dispatch(changeStoreDT(storeDT));
  }, [getValues, watchConfigSelected, replace, setValue, dispatch, storeDT]);

  return (
    <FormProvider {...methods}>
      <MenuDetailsProvider.Provider value={menuDetails}>
        <FormMenuDataProvider.Provider value={dataFieldArray}>
          <IngredientProvider.Provider value={fieldArray}>
            <div className="container mx-auto flex h-[75dvh]">
              {children}
              <MenuDetailsManageForm
                menuDetails={menuDetails}
                data={extraPrices}
                isModal={isModal}
              />
            </div>
          </IngredientProvider.Provider>
        </FormMenuDataProvider.Provider>
      </MenuDetailsProvider.Provider>
    </FormProvider>
  );
}

export default MenuDetailsForm;
