const companyName = "Resto"; // Replace with the desired company name

interface PrivacyPolicyType {
  title?: string;
  content: {
    subtitle?: string;
    description: string;
    listItems?: string[];
  }[];
}

export const privacyPolicySections: PrivacyPolicyType[] = [
  {
    // title: "Overview",
    content: [
      {
        description: `
        Restobrain.ai (656 309 509), located at 2/11 Carrington Street, Millner NT 0810 ("We" or "Us"), acknowledges its responsibility to safeguard and manage personal information in accordance with the National Privacy Principles and the Privacy Act 1988 (Cth). We are dedicated to complying with this policy and ensuring full adherence to the National Privacy Principles, the Privacy Act 1988 (Cth), and the Spam Act 2003 (Cth).
        `,
      },
    ],
  },
  {
    title: "Information We Collect",
    content: [
      {
        subtitle: "Information You Provide to Us",
        description: `We collect information you provide directly to us when you create or update your ${companyName} account, make a purchase, or otherwise interact with our Services. This includes:`,
        listItems: [
          "Account Information: Name, email address, phone number, login credentials, and profile picture.",
          "Payment Information: Payment card details, billing address, and transaction history.",
          "User Content: Messages, feedback, reviews, and other content you upload or submit through our Services.",
          "Demographic Information: Age, gender, and other demographic details when provided.",
          "Verification Information: Government-issued ID, such as a driver’s license or passport, and other verification documents when required.",
        ],
      },
      {
        subtitle: "Information We Collect Automatically",
        description: `When you use our Services, we automatically collect certain information about your device and how you interact with our Services. This includes:`,
        listItems: [
          "Location Data: GPS coordinates and other location data from your mobile device.",
          "Usage Data: Information about your interactions with our Services, such as access times, pages viewed, and app features used.",
          "Device Information: Information about the devices you use to access our Services, such as IP address, device type, operating system, and mobile network information.",
          "Transaction Information: Details about your purchases or orders, including products or services purchased, payment method, and delivery information.",
        ],
      },
      {
        subtitle: "Information from Other Sources",
        description: `We may also collect information from other sources, including:`,
        listItems: [
          "Third-Party Services: If you link your account with third-party services like social media accounts or payment providers, we may receive information from those services.",
          "Business Partners: Information from our business partners, including marketing and advertising partners.",
          "Publicly Available Sources: Information from public databases or other publicly available sources.",
        ],
      },
    ],
  },
  {
    title: "How We Use Your Information",
    content: [
      {
        subtitle: "Provide and Enhance Services",
        description: `We use the information we collect to provide, maintain, and improve our Services. This includes using your information to:`,
        listItems: [
          "Create and manage your account.",
          "Process transactions and fulfill orders.",
          "Personalize your experience with our Services, including providing customized content, recommendations, and advertising.",
          "Improve our Services by conducting research, analysis, and testing.",
        ],
      },
      {
        subtitle: "Safety and Security",
        description: `We use your information to ensure the safety, security, and integrity of our Services. This includes:`,
        listItems: [
          "Verify your identity and protect against fraud and unauthorized access.",
          "Monitor and ensure the security and integrity of our Services.",
          "Detect, investigate, and prevent activities that may violate our policies or be illegal.",
        ],
      },
      {
        subtitle: "Communication",
        description: `We use your information to communicate with you about our Services. This includes:`,
        listItems: [
          "Communicate with you about your account, transactions, and customer service inquiries.",
          "Send you marketing and promotional communications, subject to your preferences.",
          "Provide you with information about events, promotions, and other relevant updates.",
        ],
      },
      {
        subtitle: "Legal Compliance and Protection",
        description: `We use your information to comply with legal obligations and protect the rights, property, and safety of ${companyName}, our users, and the public. This includes:`,
        listItems: [
          "Complying with legal obligations and responding to lawful requests from government authorities.",
          `Protecting the rights, property, and safety of ${companyName}, our users, and the public.`,
        ],
      },
    ],
  },
  {
    title: "Cookies and Similar Technologies",
    content: [
      {
        subtitle: "Usage of Cookies",
        description: `We and our partners use cookies and similar technologies to collect information about your use of our Services. This information helps us:`,
        listItems: [
          "Authenticate users and secure our Services.",
          "Remember your preferences and settings.",
          "Deliver personalized content and advertising.",
          "Analyze site traffic and usage patterns.",
        ],
      },
    ],
  },
  {
    title: "Information Sharing",
    content: [
      {
        subtitle: "With Other Users",
        description: `We may share your information with other users involved in transactions, such as:`,
        listItems: [
          "For transactions: Sharing your name, contact information, and other relevant details with other users involved in the transaction.",
        ],
      },
      {
        subtitle: "With Service Providers and Partners",
        description: `We share your information with service providers who assist us in operating our business. This includes:`,
        listItems: [
          "Payment processors who facilitate transactions.",
          "Customer support services that assist with inquiries and issues.",
          "Marketing partners who help us with promotional activities.",
        ],
      },

      {
        subtitle: "With Your Consent",
        description: `We may share your information with your consent or at your direction.`,
      },
    ],
  },
  {
    title: "Data Retention and Deletion",
    content: [
      {
        subtitle: "Data Retention",
        description: `We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy. This includes:`,
        listItems: [
          "Retaining account information while your account is active.",
          "Retaining transaction data for tax, legal, and audit purposes.",
          "Retaining data related to safety and fraud prevention.",
        ],
      },
      {
        subtitle: "Data Deletion",
        description:
          "Restobrain.ai (656 309 509) is committed to protecting your privacy and ensuring your personal information is managed in accordance with the Privacy Act 1988 (Cth). If you would like to request the deletion of your account and any associated data, you can do so by following the steps outlined below.",
      },
      {
        subtitle: "How to Request Data Deletion:",
        description:
          "You can request the deletion of your account and personal data through the following methods:",
        listItems: [
          "Email: Please send a request to support@restobrain.ai with the subject “Data Deletion Request.” Be sure to include your account details (e.g., name, email) to help us process your request quickly.",
          "Form Submission: Fill out the Data Deletion Request Form available on this page, providing your account details for identity verification and processing.",
        ],
      },
      {
        subtitle: "What Data Will Be Deleted:",
        description:
          "Your account and any associated personal information, including but not limited to your name, email, order history, and payment details.",
      },
    ],
  },
  {
    title: "Your Choices and Rights",
    content: [
      {
        subtitle: "Access and Portability",
        description: `You can request access to the information we hold about you and receive it in a structured, commonly used format.`,
      },
      {
        subtitle: "Correction",
        description: `You can update or correct your account information at any time.`,
      },
      {
        subtitle: "Deletion",
        description: `You can request deletion of your account and personal data.`,
      },
      {
        subtitle: "Objection and Restriction",
        description: `You can object to or request the restriction of our use of your information in certain circumstances.`,
      },
    ],
  },
  {
    title: "Changes to This Privacy Policy",
    content: [
      {
        subtitle: "Policy Updates",
        description: `We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any significant changes through our Services or other means.`,
      },
    ],
  },
  {
    title: "Contact Information",
    content: [
      {
        description:
          "For any questions or further assistance, please reach out to us at support@restobrain.ai.",
      },
    ],
  },
];
