import {
  AboutEntity,
  // SelectedColor,
} from "@/app/_types/EcommerceTheme.interface";

function WhatWeOffer({
  // colors,
  about,
}: {
  // colors: SelectedColor;
  about: AboutEntity;
}) {
  return (
    <div
      className="pb-[5rem] flex w-full flex-col-reverse md:flex-row md:justify-around relative gap-10 items-center"
      id="what-we-offer-id"
    >
      <div className="md:w-1/2 flex justify-center md:pt-10">
        <div className="w-full">
          <img
            src={
              about?.imagesData?.[0]?.image?.[0]?.file ||
              "/public/logo/restologo.png"
            }
            // className="w-[15rem] h-[20rem] md:w-[20rem] md:h-[25rem] object-cover object-center rounded-md homepage-shadows"
            className="w-full h-[20rem] md:h-[25rem] object-cover object-center rounded-md"
            alt="Images"
          />
        </div>
        {/* <div className="absolute top-16 right-[30%] z-[-1] skew-x-[-16deg] skew-y-[12deg] ">
          <img
            src={
              about?.imagesData?.[0]?.image?.[0]?.file || "/logo/restologo.png"
            }
            width={400}
            height={500}
            className=" w-[15rem] h-[20rem] md:w-[20rem] md:h-[25rem] object-cover object-center rounded-md homepage-shadows"
            alt="Images"
          />
        </div> */}
      </div>
      <div className="md:w-1/2 flex justify-center">
      <div>
        <div className="p-8">
          <div className="responsive-heading" dangerouslySetInnerHTML={{ __html: about?.title?.text }} />
          {about?.subTitles?.length > 0 && (
            <ul className="list-disc list-inside text-black/70  text-justify">
              {about?.subTitles?.map((subTitle, index) => (
                <div key={index}>
                  <li>{subTitle?.subTitle}</li>
                </div>
              ))}
              {/* {!about?.subTitles?.length ? (
                <div>
                <p>Welcome to RestoBrain.</p>
                </div>
                ) : null} */}
            </ul>
                )}
        </div>
      </div>
      </div>
      {/* <div className="absolute z-[-2] inset-0">
        <div className="absolute  right-0 left-0 bottom-0"></div>
        <svg height="100%" width="100%">
          <circle
            r="25%"
            cx="5%"
            cy="50%"
            fill={colors?.primary || "rgba(0,0,0,0.3)"}
          />
        </svg>
      </div> */}
    </div>
  );
}

export default WhatWeOffer;
