import {
  Categories,
  Deals,
  Menu,
  Offer,
} from "@/app/_types/menuWithCategories";
import "react-multi-carousel/lib/styles.css";
import MenuSubListClient from "../MenuList/MenuSubListClient";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import { CalculateMenuTradingPeriod } from "./CalculateTradingPeriod";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import MenuDealList from "../MenuDeals/MenuDealList";
import MenuOfferList from "../MenuOffer/MenuOfferList";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import MenuLink from "../MenuList/MenuLink";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

interface MenuTradingPeriodCard {
  categories: Categories[];
  params: string;
  title: string;
  tradingPeriodId: string;
  tradingHours: TradingHour[];
  dealList: Deals[];
  offerList: Offer[];
  mostlikedMenus?: Menu[];
  handleSelectedTradingPeriod: ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => Promise<void>;
}

function MenuTradingPeriodList({
  categories,
  params,
  title,
  tradingPeriodId,
  tradingHours,
  dealList,
  offerList,
  mostlikedMenus,
}: MenuTradingPeriodCard) {
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);
  const { schedule } = useSelector(order);

  const location = useLocation();
  const { state } = location;

  console.log("state values", state)

  useEffect(() => {
    const navbarHeight = 112;
    if (state && state.scrollId) {
      const element = document.getElementById(state.scrollId);
      if (element) {
        const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - navbarHeight,
        behavior: "smooth",
      });
      }
    }
  }, [state]);

  if (title !== schedule?.selectedTradingPeriod?.data && schedule !== null)
    return;

  if (categories?.length === 0) return null;

  if (!storeDT) return;

  const selectedTime = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const tradingHoursData = tradingHours;

  const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();

  const CheckTradingPeriod = CalculateMenuTradingPeriod({
    tradingPeriodId,
    tradingHoursData,
    selectedOrderStatus,
    selectedTime,
    selectedDay,
    storeDT,
  });

  const visibilityStatusMostLikedMenus = mostlikedMenus?.filter(
    (menu) =>
      menu?.visibilityStatus !== "HIDDEN" &&
      menu?.visibilityStatus !== "HIDE_FOR_APP_AND_WEB" &&
      menu?.tradingHours
  );

  return (
    <>
      {!offerList && !dealList && !categories && !mostlikedMenus ? null : (
        <div id={`${title}`} className={`py-2 flex flex-col gap-2 `}>
          <div className="flex gap-2 items-center justify-between flex-wrap">
            <h1 className="font-bold text-2xl">{title}</h1>
            {CheckTradingPeriod.isClosed && (
              <p className="text-red-500">is Close</p>
            )}
            {CheckTradingPeriod?.startTime &&
              CheckTradingPeriod?.endTime &&
              !CheckTradingPeriod?.isClosed && (
                <p className="list-disc">
                  Available from {CheckTradingPeriod?.startTime} -{" "}
                  {CheckTradingPeriod?.endTime}
                </p>
              )}
          </div>
          {visibilityStatusMostLikedMenus &&
          visibilityStatusMostLikedMenus?.length > 0 ? (
            <div id={`${tradingPeriodId}mostLikedMenus`}>
              <h1 className="font-semibold text-xl pb-4">Most Liked Menus</h1>
              <MenuSubListClient
                category={title}
                subcategories={`${tradingPeriodId}mostLikedMenus`}
              >
                <div className="grid lg:grid-cols-2 grid-cols-1 box-border gap-10">
                  {visibilityStatusMostLikedMenus?.map((menu, indexs) => (
                    <MenuLink
                      key={indexs}
                      CheckTradingPeriod={CheckTradingPeriod}
                      menu={menu}
                      params={params}
                      tradingPeriodId={tradingPeriodId}
                      menuType="menusLiked"
                    />
                  ))}
                </div>
              </MenuSubListClient>
            </div>
          ) : null}
          {offerList && (
            <MenuSubListClient
              category={title}
              subcategories={`${tradingPeriodId}offersList`}
            >
              <MenuOfferList
                params={params}
                offerList={offerList}
                status={CheckTradingPeriod?.status}
                tradingPeriodId={tradingPeriodId}
              />
            </MenuSubListClient>
          )}
          {dealList && (
            <MenuSubListClient
              category={title}
              subcategories={`${tradingPeriodId}dealsList`}
            >
              <MenuDealList
                params={params}
                dealList={dealList}
                status={CheckTradingPeriod?.status}
                tradingPeriodId={tradingPeriodId}
              />
            </MenuSubListClient>
          )}
          <div id="TradingPeriodsList" className="flex flex-col gap-4">
            <div className="grid box-border gap-5">
              {categories?.map((categories, index) =>
                !categories?.menus?.length ? null : (
                  <MenuSubListClient
                    key={index}
                    category={title}
                    subcategories={title + categories?.name}
                  >
                    <div
                      id={`${title}${categories?.name}`}
                      className={`p-1 md:px-4 md:py-2 flex flex-col`}
                    >
                      {categories?.menus && categories.menus.length > 0 ? (
                        <>
                          <h1 className="font-semibold text-xl pb-4">
                            {categories?.name}
                          </h1>
                          <div className="grid lg:grid-cols-2 grid-cols-1 box-border gap-10">
                            {categories?.menus?.map((menu, indexs) => (
                              <MenuLink
                                key={indexs}
                                CheckTradingPeriod={CheckTradingPeriod}
                                menu={menu}
                                params={params}
                                tradingPeriodId={tradingPeriodId}
                              />
                            ))}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </MenuSubListClient>
                )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MenuTradingPeriodList;
