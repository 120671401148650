import { UpdateCartInformation } from "@/app/_axios/serverApi";
import { DealDetailsInCart } from "@/app/_types/menuDeals.interface";
import { AddMenuItemsForm } from "@/app/_types/menuDetails";
// import jwt from "jsonwebtoken";

export function POSTCART({
  item,
  cartToken,
}: {
  item: UpdateCartInformation;
  cartToken: string;
}):
  | {
      status: 403;
      message: string;
      newToken?: string;
      location?: string;
      itemList?: AddMenuItemsForm[];
      dealList?: DealDetailsInCart[];
    }
  | {
      message: string;
      status: 200;
      newToken: string;
      location?: string;
      itemList: AddMenuItemsForm[];
      dealList: DealDetailsInCart[];
    }
  | undefined {
  const {
    data: newItem,
    status,
    location,
    itemStatus,
    dealDetails,
  }: UpdateCartInformation = item;

  if (cartToken) {
    try {
      const allItems = JSON.parse(cartToken);

      if (typeof allItems === "object") {
        // checking the status of item -> either adding new item or removing/adding same item

        if (allItems?.location !== location) {
          //   return NextResponse.json(
          //     { message: "Location doesn't match." },
          //     { status: 403 }
          //   );
          return {
            message: "Location doesn't match.",
            status: 403,
          };
        }

        const itemList: AddMenuItemsForm[] = allItems?.itemList;
        const dealList: DealDetailsInCart[] = allItems?.dealList;

        if (itemStatus === "DEAL" && dealDetails) {
          console.log("Deal => ");
          const findDeal = dealList.find(
            (el) => el?.uuidDeal === dealDetails?.uuidDeal
          );
          console.log("Deal => after");
          if (status === "ADD" && findDeal) {
            findDeal.qty = findDeal?.qty + 1;
          } else if (status === "REMOVE" && findDeal) {
            console.log("Deal => REMOVE");

            if (findDeal?.qty === 1) {
              const findDealIndex = dealList.findIndex(
                (el) => el?.uuidDeal === dealDetails?.uuidDeal
              );
              console.log("Deal => REMOVE 1 ", findDealIndex);

              if (typeof findDealIndex === "number") {
                console.log("Deal => REMOVE inside ", findDealIndex);

                dealList.splice(findDealIndex, 1);
              }
            } else findDeal.qty = findDeal?.qty - 1;
          } else {
            dealList?.push(dealDetails);
          }
        }

        if (itemStatus === "MENU" && newItem) {
          const findItems = itemList.find((el) => el?.uuid === newItem?.uuid);

          if (status === "ADD" && findItems) {
            findItems.qty = findItems?.qty + 1;
          } else if (status === "REMOVE" && findItems) {
            if (findItems?.qty === 1) {
              console.log("Qty = 1");
              const findItemIndex = itemList.findIndex(
                (el) => el?.uuid === newItem?.uuid
              );
              if (typeof findItemIndex === "number") {
                itemList.splice(findItemIndex, 1);
              }
            } else findItems.qty = findItems?.qty - 1;
          } else {
            itemList?.push(newItem);
          }
        }

        if (itemList.length === 0 && dealList.length === 0) {
          //   return NextResponse.json(
          //     { message: "Cart is empty." },
          //     { status: 403 }
          //   );

          return {
            message: "Cart is empty.",
            status: 403,
          };
        }

        const finalData = {
          itemList: [...itemList],
          dealList: [...dealList],
          location,
        };
        const newToken = JSON.stringify(
          finalData
          // import.meta.env.VITE_CART_TOKEN as string
        );

        return {
          status: 200,
          message: "success",
          newToken,
          location,
          itemList: finalData?.itemList,
          dealList: finalData?.dealList,
        };

        // return NextResponse.json({
        //   message: "Cookies updated",
        //   newToken,
        //   location,
        //   itemList: finalData?.itemList,
        //   dealList: finalData?.dealList,
        // });
      }
    } catch (error) {
      console.log("err => ", error);
      return {
        message: "Something went wrong",
        status: 403,
      };

      //   return NextResponse.json(
      //     { message: "Something went wrong" },
      //     { status: 403 }
      //   );
    }
  } else {
    const finalData = {
      dealList: dealDetails ? [dealDetails] : [],
      itemList: newItem ? [newItem] : [],
      location,
    };
    const newToken = JSON.stringify(
      finalData
      // import.meta.env.VITE_CART_TOKEN as string
    );

    return {
      status: 200,
      message: "success",
      newToken,
      location,
      itemList: finalData?.itemList,
      dealList: finalData?.dealList,
    };

    // return NextResponse.json({
    //   message: "success",
    //   newToken,
    //   location,
    //   itemList: finalData?.itemList,
    //   dealList: finalData?.dealList,
    // });
  }
}
