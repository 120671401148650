import {
  DealType,
  MenuDealForm,
  MenuDealFormArray,
} from "@/app/_types/menuDeals.interface";
import { fixedDecimal } from "@/app/utils/DecimalAndNumbers";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export function calculateDealOffPrice({
  menuDeal,
  dealType,
  discountRate,
  sellingPrice,
}: {
  menuDeal: MenuDealFormArray[];
  dealType: DealType;
  discountRate: number;
  sellingPrice: number;
}) {
  const selectedMenuDeal = menuDeal?.filter((el) => el?.isSelected);
  console.log("Selected menu Price => ", selectedMenuDeal);

  const finalPriceValueofFixed =
    dealType === "FIXED"
      ? selectedMenuDeal?.reduce((acc, current) => {
          const currentValue = current?.menus?.reduce((accs, currents) => {
            return accs + currents?.finalPrice + (currents?.specialPrice || 0);
          }, 0);
          return acc + currentValue;
        }, 0)
      : 0;

  const finalPriceValueofDiscount =
    dealType === "PERCENT"
      ? (discountRate / 100) *
        selectedMenuDeal?.reduce((acc, current) => {
          const currentValue = current?.menus?.reduce((accs, currents) => {
            return accs + currents?.finalPrice;
          }, 0);
          return acc + currentValue;
        }, 0)
      : 0;

  const fixedPrice = finalPriceValueofFixed
    ? finalPriceValueofFixed - sellingPrice
    : 0;

  return {
    status: dealType,
    offPrice:
      dealType === "FIXED"
        ? fixedDecimal(fixedPrice)
        : fixedDecimal(finalPriceValueofDiscount),
  };
}

function MenuDealTotalPrice() {
  const { control, setValue } = useFormContext<MenuDealForm>();

  const sellingPrice = useWatch({
    control,
    name: "sellingPrice",
  });

  const qty = useWatch({
    control,
    name: "qty",
  });

  const dealType = useWatch({
    control,
    name: "dealType",
  });

  const discountRate =
    useWatch({
      control,
      name: "discountRate",
    }) || 0;

  const menuDeal =
    useWatch({
      control,
      name: "menuDeal",
    }) || [];

  const menuDealAdditionalPrice = menuDeal?.reduce((acc, current) => {
    const menuData = current?.menus;
    const isSplit = current?.config?.isSplit;
    let menuPrice = 0;

    console.log(menuData);
    console.log(isSplit);

    if (isSplit) {
      // this one is a bit confusing, if dealType is percent than this is the total Price excluding additional prices
      // if dealType is fixed than this is the special amount prices
      menuPrice = current?.menusHalfHalfPrice || 0;
      console.log(current);
      console.log("watch price => ", menuPrice);
    } else {
      menuPrice =
        dealType === "FIXED" ? current?.menus?.[0]?.specialPrice || 0 : 0;
    }
    console.log("watch price => ", menuPrice);

    if (dealType === "PERCENT" && !isSplit) {
      menuPrice = menuData?.reduce((accs, currents) => {
        const finalPrice =
          currents?.finalPrice - (discountRate / 100) * currents?.finalPrice;
        return accs + finalPrice;
      }, 0);
    }

    const additionalPrice = menuData?.reduce((accs, currents) => {
      return (
        accs +
        (isSplit
          ? (currents?.comboPrice || 0) / 2
          : currents?.comboPrice || 0) +
        (isSplit
          ? (currents?.ingredientPrice || 0) / 2
          : currents?.ingredientPrice)
      );
    }, 0);
    console.log(additionalPrice);
    console.log(acc);
    console.log(menuPrice);

    return acc + additionalPrice + menuPrice;
  }, 0);

  const fixTypePrice = dealType === "FIXED" ? sellingPrice : 0;

  console.log(
    "watch discount price => ",
    menuDealAdditionalPrice,
    fixTypePrice
  );

  const totalPrice = menuDealAdditionalPrice + fixTypePrice;

  useEffect(() => {
    setValue("totalPrice", totalPrice);
  }, [totalPrice, setValue]);

  return (
    <>
      {dealType === "FIXED" ? (
        <p className="font-bold pl-4 text-2xl">$ {fixedDecimal(totalPrice * qty)}</p>
      ) : (
        <p className="font-bold pl-4 text-2xl">
          {discountRate || 0}% - $ {fixedDecimal(totalPrice * qty)}
        </p>
      )}
    </>
  );
}

export default MenuDealTotalPrice;
