import { getDealDetails } from "@/app/_redux/slice/Order/OrderSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { Children } from "@/app/_types/common";
import { DealDetails } from "@/app/_types/menuDeals.interface";
import { useEffect } from "react";

interface MenuDealDetailClientWrapperProps extends Children {
  menuDeals: DealDetails;
}

function MenuDealDetailClientWrapper({
  children,
  menuDeals,
}: MenuDealDetailClientWrapperProps) {
  console.log("menu deals => ", menuDeals);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDealDetails(menuDeals));
  }, [dispatch, menuDeals]);
  return <>{children}</>;
}

export default MenuDealDetailClientWrapper;
