import { Offer } from "@/app/_types/menuWithCategories";
import { Link } from "react-router-dom";

import MenuOfferItem from "./MenuOfferItem";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { CalculateMenuTradingPeriod } from "../MenuTradingPeriods/CalculateTradingPeriod";
import moment from "moment";

interface MenuOfferLinkProps {
  status: boolean;
  offer: Offer;
  params: string;
  tradingPeriodId: string;
}

function MenuOfferLink({
  status,
  offer,
  params,
  tradingPeriodId,
}: MenuOfferLinkProps) {
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);
  const { schedule, reActivationTimes } = useSelector(order);

  if (!storeDT) return;

  if (!offer?.tradingHours) return;

  const selectedTime = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();

  const tradingHoursData = offer?.tradingHours;

  const ItemTradingPeriodAvailable = CalculateMenuTradingPeriod({
    selectedOrderStatus,
    tradingHoursData,
    tradingPeriodId,
    selectedTime,
    selectedDay,
    storeDT,
  });

  return (
    <Link
      to={
        !status ||
        !ItemTradingPeriodAvailable?.status ||
        offer?.visibilityStatus === "OUT_OF_STOCK" ||
        !reActivationTimes
          ? "#"
          : `/${params}/offer/${tradingPeriodId}/${offer?.id}`
      }
      className={`${
        offer?.visibilityStatus === "HIDDEN" ||
        offer?.visibilityStatus === "HIDE_FOR_APP_AND_WEB"
          ? "hidden"
          : ""
      }`}
      // onClick={() => {
      //   window.scrollTo(0, 0); // Scroll to the top
      // }}
    >
      <MenuOfferItem
        item={offer}
        status={status}
        offerStatus={ItemTradingPeriodAvailable?.status}
        reActivationTimes={reActivationTimes}
      />
    </Link>
  );
}

export default MenuOfferLink;
