import { EcommerceStoreImageData } from "@/app/_types/menuWithCategories";
import { useTransition, animated } from "@react-spring/web";

import { useEffect, useState } from "react";

interface CategoryListHeadersImageProps {
  storeImages: EcommerceStoreImageData[];
}

function CategoryListHeadersImage({
  storeImages,
}: CategoryListHeadersImageProps) {
  const [index, setIndex] = useState(0);

  // Define transitions for fade in and out
  const transitions = useTransition(storeImages[index], {
    key: index, // Ensure unique key for each media item
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  });

  // Automatically go to the next media every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % storeImages.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [storeImages.length]);
  return (
    <div className="h-full">
      {transitions((style, imgData) => (
        // <animated.div style={style} className="absolute w-full h-full">
        <animated.div style={style} className="absolute w-full h-full">
          <img
            src={imgData?.value}
            width="800"
            height="800"
            alt="Location"
            className="w-full h-full object-cover object-center"
          />
        </animated.div>
      ))}
    </div>
  );
}

export default CategoryListHeadersImage;
