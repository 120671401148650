// for combo

import { ComboItem, MenuOptionGroup } from "@/app/_types/menuDetails";

/* getting the average or highest price of combo.
used with getTotalComboPriceOfSingleData() to get itemsPrice and comboPrice
*/
export function findComboPrice(
  comboItems: ComboItem[],
  status: "AVERAGE" | "HIGHEST"
) {
  if (status === "AVERAGE") {
    const getPrice = comboItems?.reduce(
      (acc, current) => {
        if (!current?.isChecked || current?.price === 0) return acc;
        const sumValue = acc?.sum + (current?.price || 0);
        const countValue = acc?.count + 1;
        return { sum: sumValue, count: countValue };
      },
      { sum: 0, count: 0 }
    );
    return getPrice?.sum / getPrice?.count || 0;
  } else {
    const priceList = comboItems?.map((data) =>
      data?.isChecked ? data?.price || 0 : 0
    );
    return Math.max(...priceList);
  }
}

// export function getTotalComboPriceOfSingleData(
//   getValues: UseFormGetValues<AddMenuItemsForm>,
//   setValue: UseFormSetValue<AddMenuItemsForm>,
//   comboType: "AVERAGE" | "HIGHEST",
//   selectedIndex: number
// ) {
//   const comboList = getValues(`data.${selectedIndex}.combo`);
//   const finalPrice = comboList?.reduce((acc, current, index) => {
//     const comboItems = current?.comboItems;
//     const comboItemPrice = findComboPrice(comboItems, comboType);
//     setValue(`data.${selectedIndex}.combo.${index}.itemsPrice`, comboItemPrice);
//     return acc + comboItemPrice;
//   }, 0);
//   setValue(`data.${selectedIndex}.comboPrice`, finalPrice);
// }

export function ManageComboPayInitial(
  comboList: MenuOptionGroup[],
  sizeId: string
) {
  const list: MenuOptionGroup[] = comboList?.map((data) => {
    const newOptions = data?.options?.map((el) => {
      const isFirstFree = el?.isFirstFree;
      let price = 0;

      if (isFirstFree) {
        price = 0;
      } else if (!isFirstFree && el?.qty > 0) {
        const findPrice = el?.menuSizes?.find(
          (price) => price.sizeId === sizeId
        );
        price = (findPrice?.extraPrice || 0) * el?.qty;
      } else {
        price = 0;
      }

      return {
        ...el,
        price,
      };
    });

    const itemsPrice = newOptions?.reduce((acc, el) => {
      return acc + el?.price;
    }, 0);

    console.log("new option, data => ", newOptions, data);

    return {
      ...data,
      itemsPrice,
      name: data?.optionGroupName,
      id: data?.optionGroupId,
      options: newOptions,
    };
  });

  return list;
}

export function getIndividualMenuComboPrice(comboList: MenuOptionGroup[]) {
  const comboPrices = comboList?.reduce((acc, current) => {
    return acc + (current?.itemsPrice || 0);
  }, 0);

  return comboPrices;
}
