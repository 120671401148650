import { StoreEntity } from "@/app/_types/store.interface";

function HeroOrderNowButton({
  bgColor,
  storeList,
}: {
  bgColor: string;
  storeList: StoreEntity[];
}) {
  const handleNavigate = () => {
    if (storeList?.length === 1) {
      const newUrl = `/${storeList[0].id}`;
      window.location.replace(newUrl);
      return;
    }
    document.getElementById("homepage_storeLocation")?.scrollIntoView();
  };
  return (
    <button
      className="
      p-3
    md:p-6 
    rounded-md 
    text-white 
    text-base 
    sm:text-lg 
    md:text-xl 
    lg:text-2xl 
    xl:text-3xl 
    2xl:text-4xl
  "
      style={{ background: bgColor }}
      onClick={handleNavigate}
    >
      Order Now
    </button>
  );
}

export default HeroOrderNowButton;
