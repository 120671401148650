// import StoreLocationItem from "./StoreLocationItem";
import { StoreEntity } from "@/app/_types/store.interface";
import { EcommerceLocationEntity } from "@/app/_types/EcommerceTheme.interface";
import HomePageStoreLocationItem from "./HomePageStoreLocationItem";
import { useState } from "react";
import CateringForm from "./CateringForm";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { useSelector } from "react-redux";

function StoreLocation({
  ThemeLocationData,
  storeList,
}: {
  ThemeLocationData: EcommerceLocationEntity;
  storeList: StoreEntity[];
}) {
  const { UIColors } = useSelector(selectUI);

  console.log(UIColors);

  const [cateringOpen, setCateringOpen] = useState(false);

  const ThemeStoreData = storeList?.map((store) => {
    const location = ThemeLocationData?.stores?.find(
      (loc) => loc?.aboutStore?.storeid === store?.id.toString()
    );
    if (location) {
      return { ...store, imagesData: location?.imagesData };
    } else {
      return store;
    }
  });

  console.log(ThemeStoreData);

  return (
    <>
      {storeList?.length > 1 ? (
        <section
          className="pt-16 md:pt-20 pb-4 md:pb-14"
          id="homepage_storeLocation"
        >
          <h1 className="font-bold text-3xl md:text-4xl py-4 md:py-8 text-black/80 text-center">
            Our Location
          </h1>
          <div className="py-2 grid grid-cols-custom-300 gap-2 md:gap-8 justify-center">
            {ThemeStoreData?.map((store, index) => {
              return (
                // <Link  key={index} to={"/welcome"}>
                // <StoreLocationItem store={store} key={index} />
                <HomePageStoreLocationItem store={store} key={index} />
                // </Link>
              );
            })}
            {import.meta.env.VITE_STORE === "supero" && (
            <div className="bg-white shadow-md rounded-md p-4 text-center flex flex-col">
              <div className="w-full h-44 mb-4">
                <img
                  src={"/catering.jpg"}
                  alt="Store Image"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="flex flex-col justify-between flex-1">
              <button
                  // href={`/${store.id}`}
                  className="inline-block text-white text-sm font-medium py-3 px-4 rounded-full"
                  style={{ background: UIColors?.secondary }}
                  onClick={() => setCateringOpen(true)}
                >
                  Book Now
                </button>
                <div>
                <h2 className="text-lg font-semibold text-gray-800 mb-2">
                  Supero Catering
                </h2>
                <p className="text-sm text-gray-600 mb-4">
                Making every event unforgettable with our catering services!
                </p>
                </div>
                
              </div>
            </div>
            )}

          </div>
        </section>
      ) : null}
      {cateringOpen && (
        <CateringForm open={cateringOpen} setOpen={setCateringOpen} />
      )}
    </>
  );
}

export default StoreLocation;
