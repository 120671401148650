import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import {
  changeActiveCategory,
  changeSubActiveCategory,
  selectUI,
} from "@/app/_redux/slice/UISlice/UISlice";
import {
  MenuWithCategories,
  TradingPeriods,
} from "@/app/_types/menuWithCategories";

type CategoryListSidebar = {
  data: MenuWithCategories;
  // tradingHours: TradingHour[];
  handleSelectedTradingPeriod: ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => Promise<void>;
};

function MiniCategorySidebar({
  data,
  handleSelectedTradingPeriod,
}: CategoryListSidebar) {
  const { activeCategory, subActiveCategory } = useSelector(selectUI);
  const { schedule } = useSelector(order);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [tradingPeriods, setTradingPeriods] = useState<TradingPeriods[]>();
  const [tradingPeriod, setTradingPeriod] = useState<TradingPeriods>(
    data?.tradingPeriods?.[0]
  );

  const visibilityStatusMostLikedMenus = tradingPeriod?.mostLikedMenus?.filter(
    (menu) =>
      menu?.visibilityStatus !== "HIDDEN" &&
      menu?.visibilityStatus !== "HIDE_FOR_APP_AND_WEB" &&
      menu?.tradingHours
  );

  const navbarHeight = 112; // Adjust based on your navbar height

  // console.log("activeCategory and SubActiveCategory", activeCategory, subActiveCategory)

  const ActiveHandler = ({
    category,
    subCategory,
    tradingPeriod,
  }: {
    category: string;
    subCategory?: string;
    tradingPeriod?: TradingPeriods;
  }) => {
    if (tradingPeriod) {
      setTradingPeriod(tradingPeriod);
    }
    const element = document.getElementById(category);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - navbarHeight,
        behavior: "smooth",
      });
    }
    setOpen(false);
    dispatch(changeActiveCategory(category));
    if (subCategory) {
      SubActiveHandler(subCategory);
      dispatch(changeSubActiveCategory(subCategory));
    }
  };

  const SubActiveHandler = (subCategory: string) => {
    const element = document.getElementById(subCategory);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - navbarHeight,
        behavior: "smooth",
      });
    }
  };

  // so that if sub title is selected only the selected menu will be open
  useEffect(() => {
    if (subActiveCategory) {
      setOpen(false);
    }
  }, [subActiveCategory]);

  useEffect(() => {
    if (schedule) {
      const filterTradingPeriod = data?.tradingPeriods?.filter((item) => {
        return item?.title === schedule?.selectedTradingPeriod?.data;
      });
      setTradingPeriods(filterTradingPeriod);
    } else {
      setTradingPeriods(data?.tradingPeriods);
    }
  }, [data?.tradingPeriods, schedule]);

  useEffect(() => {
    if (data?.tradingPeriods && activeCategory) {
      const categoryTradingPeriod = data.tradingPeriods.find(
        (period) => period.title === activeCategory
      );
      if (categoryTradingPeriod) {
        setTradingPeriod(categoryTradingPeriod);
      }
    }
  }, [data?.tradingPeriods, activeCategory]);
  return (
    <div className="gap-2 z-10 sticky top-12 bg-white flex justify-center w-full flex-col h-28">
      <div className="flex justify-end">
        <ul className="flex gap-2 items-center overflow-x-auto">
          {!tradingPeriods?.length
            ? null
            : tradingPeriods?.map((tradingPeriod, index) => (
                <li
                  className="cursor-pointer min-w-max"
                  onClick={() =>
                    handleSelectedTradingPeriod({
                      tradingPeriodId: tradingPeriod?.id,
                    })
                  }
                  key={index}
                >
                  <button
                    onClick={() =>
                      ActiveHandler(
                        {
                          category: tradingPeriod?.title,
                          tradingPeriod: tradingPeriod,
                        }
                        // tradingPeriod?.title,
                        // `${tradingPeriod?.id}offersList`,
                        // tradingPeriod
                      )
                    }
                    className="p-3 w-full text-left flex md:justify-between gap-1 items-center"
                  >
                    <p
                      className={`${
                        activeCategory === tradingPeriod?.title
                          ? "font-semibold text-black text-lg"
                          : null
                      }`}
                    >
                      {tradingPeriod?.title}
                    </p>
                    {/* {activeCategory === tradingPeriod.title ? (
                        <img
                          src="/images/icons/down.svg"
                          width={15}
                          height={15}
                          alt="dropdown arrow"
                        />
                      ) : (
                        <img
                          src="/images/icons/up.svg"
                          width={15}
                          height={15}
                          alt="up arrow"
                        />
                      )} */}
                  </button>
                </li>
              ))}
        </ul>
      </div>
      <>
        <ul className="flex gap-1 overflow-x-auto bg-gray-200 rounded">
          {!visibilityStatusMostLikedMenus?.length ? null : (
            <li
              className={`cursor-pointer md:pl-4 ${
                activeCategory !== tradingPeriod?.title && !open ? "hidden" : ""
              }`}
            >
              <button
                onClick={() =>
                  ActiveHandler({
                    category: tradingPeriod?.title,
                    subCategory: `${tradingPeriod?.id}mostLikedMenus`,
                    tradingPeriod: tradingPeriod,
                  })
                }
                className="px-2 md:px-4 py-2 w-full text-left"
              >
                <p
                  className={`whitespace-nowrap overflow-hidden text-ellipsis ${
                    subActiveCategory === `${tradingPeriod?.id}mostLikedMenus`
                      ? "font-semibold text-black text-lg"
                      : null
                  }`}
                >
                  Most Liked Menus
                </p>
              </button>
            </li>
          )}
          {!tradingPeriod?.offers?.length ? null : (
            <li
              className={`cursor-pointer md:pl-4 ${
                activeCategory !== tradingPeriod?.title && !open ? "hidden" : ""
              }`}
            >
              <button
                onClick={() =>
                  ActiveHandler({
                    category: tradingPeriod?.title,
                    subCategory: `${tradingPeriod?.id}offersList`,
                    tradingPeriod: tradingPeriod,
                  })
                }
                className="px-2 md:px-4 py-2 w-full text-left"
              >
                <p
                  className={`${
                    subActiveCategory === `${tradingPeriod?.id}offersList`
                      ? "font-semibold text-black text-lg"
                      : null
                  }`}
                >
                  Offers
                </p>
              </button>
            </li>
          )}
          {!tradingPeriod?.deals?.length ? null : (
            <li
              className={`cursor-pointer font-medium md:pl-4 ${
                activeCategory !== tradingPeriod?.title && !open ? "hidden" : ""
              }`}
            >
              <button
                onClick={() =>
                  ActiveHandler({
                    category: tradingPeriod?.title,
                    subCategory: `${tradingPeriod?.id}dealsList`,
                    tradingPeriod: tradingPeriod,
                  })
                }
                className="px-2 md:px-4 py-2 w-full text-left"
              >
                <p
                  className={`${
                    subActiveCategory === `${tradingPeriod?.id}dealsList`
                      ? "font-semibold text-black text-lg"
                      : null
                  }`}
                >
                  Deals
                </p>
              </button>
            </li>
          )}
          {!tradingPeriod?.categories?.length
            ? null
            : tradingPeriod?.categories?.map((category, index) =>
                !category?.menus?.length ? null : (
                  <li
                    key={index}
                    className={`cursor-pointer font-medium md:pl-4 ${
                      activeCategory !== tradingPeriod?.title && !open
                        ? "hidden"
                        : ""
                    }`}
                  >
                    <button
                      onClick={() =>
                        ActiveHandler({
                          category: tradingPeriod?.title,
                          subCategory: `${
                            tradingPeriod?.title + category?.name
                          }`,
                          tradingPeriod: tradingPeriod,
                        })
                      }
                      className="px-2 md:px-4 py-2 w-full text-left"
                    >
                      <p
                        className={`whitespace-nowrap overflow-hidden text-ellipsis ${
                          subActiveCategory ===
                          `${tradingPeriod?.title}${category?.name}`
                            ? "font-semibold text-black text-lg"
                            : null
                        }`}
                      >
                        {category?.name}
                      </p>
                    </button>
                  </li>
                )
              )}
        </ul>
      </>
    </div>
  );
}

export default MiniCategorySidebar;
