import { memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
// import {
//   calculatingIsFirstFreeAndGettingPrice,
//   getTotalComboPriceOfSingleData,
// } from "./menuComboUtils";

import {
  AddMenuItemsForm,
  MenuOptionGroup,
  MenuOptionGroupOption,
} from "@/app/_types/menuDetails";
import {
  calculatingIsFirstFreeAndGettingPrice,
  getTotalComboPriceOfSingleData,
} from "./MenuComboUtils";

import toast from "react-hot-toast";

type ComboItems = {
  items: MenuOptionGroupOption;
  mainIndex: number;
  comboIndex: number;
  combo: MenuOptionGroup;
  comboPriceType?: "HIGHEST" | "AVERAGE";
};

function SelectMenuOptionComboItems({
  items,
  mainIndex,
  comboIndex,
  combo,
}: ComboItems) {
  const { control, setValue, getValues } = useFormContext<AddMenuItemsForm>();

  const watchSelectedIndex = useWatch({
    control,
    name: "config.selectedIndex",
  });

  const isSplit = useWatch({
    control,
    name: `config.isSplit`,
  });

  const checkTotalSelectableOptions = (combo: MenuOptionGroup) => {
    let totalQuantity = 0;
    combo?.options?.forEach((ingredient) => {
      if (ingredient?.qty > 0) {
        totalQuantity += ingredient?.qty;
      }
    });

    const totalSelectableOptions = () => {
      if (combo?.totalSelectableOptions === 0) {
        return combo?.options?.length * 10;
      } else {
        return combo?.totalSelectableOptions;
      }
    };

    const selectableOptions = totalSelectableOptions();

    if (selectableOptions && totalQuantity >= selectableOptions) {
      toast.error("Total selectable options exceeded.");
      return false;
    }

    return true;
  };

  const onChangeHandler = (status: "ADD" | "REMOVE") => {
    const size = getValues("size");
    let distinctQuantity = 0;

    const sizePrice =
      items?.menuSizes?.find((el) => el.sizeId === size)?.extraPrice || 0;

    const itemQty = getValues(
      `data.${watchSelectedIndex}.optionGroups.${mainIndex}.options.${comboIndex}.qty`
    );

    if (status === "ADD") {
      combo?.options?.forEach((ingredient) => {
        if (ingredient?.qty > 0) {
          distinctQuantity++;
        }
      });

      const TotalDistinctSelectable = () => {
        if (combo?.distinctSelectableOptions === 0) {
          return combo?.options?.length;
        } else {
          return combo?.distinctSelectableOptions;
        }
      };

      const SelectableDistincts = TotalDistinctSelectable();

      if (!checkTotalSelectableOptions(combo)) {
        return;
      }
      if (itemQty === 0 && distinctQuantity === SelectableDistincts) {
        toast.error(`Only ${SelectableDistincts} options allow`);
        return;
      } else {
        if (items?.isSingle && itemQty === 1) {
          toast.error("only allow to add ones");
        } else {
          const newQty = (itemQty || 0) + 1;
          setValue(
            `data.${watchSelectedIndex}.optionGroups.${mainIndex}.options.${comboIndex}.qty`,
            newQty
          );
          const newPrice = calculatingIsFirstFreeAndGettingPrice(
            items,
            newQty,
            sizePrice
          );
          setValue(
            `data.${watchSelectedIndex}.optionGroups.${mainIndex}.options.${comboIndex}.price`,
            newPrice
          );
        }
      }
    } else if (status === "REMOVE") {
      if (itemQty === 0) return;
      const newQty = (itemQty || 0) - 1;
      setValue(
        `data.${watchSelectedIndex}.optionGroups.${mainIndex}.options.${comboIndex}.qty`,
        newQty
      );
      const newPrice = calculatingIsFirstFreeAndGettingPrice(
        items,
        newQty,
        sizePrice
      );
      setValue(
        `data.${watchSelectedIndex}.optionGroups.${mainIndex}.options.${comboIndex}.price`,
        newPrice
      );
    }

    getTotalComboPriceOfSingleData(
      getValues,
      setValue,
      // "HIGHEST",
      watchSelectedIndex
    );
  };

  const isQuantityAvailable = items?.qty > 0;

  return (
    <div
      className={`p-2 rounded-lg flex items-center gap-1 ${
        isQuantityAvailable ? "bg-black text-white" : "bg-gray-100"
      }`}
    >
      <button
        onClick={() => onChangeHandler("REMOVE")}
        disabled={!isQuantityAvailable}
        className={`${isQuantityAvailable ? `text-white` : `bg-gray-100`}`}
      >
        <img
          src="/images/icons/minus.svg"
          alt="minus"
          width="30"
          height="30"
          className={`${
            !isQuantityAvailable ? "bg-slate-200" : "invert"
          } p-2 rounded-full`}
        />
      </button>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => onChangeHandler("ADD")}
      >
        <p className={`${!isQuantityAvailable ? "text-black" : ""}`}>
          {items?.name}
        </p>
        {items?.qty > 1 && (
          <p className="flex items-center">
            <img
              src="/images/icons/cross.svg"
              width="15"
              height="15"
              alt="x"
              className="invert"
            />
            {items?.qty}
          </p>
        )}
        {items?.price > 0 && (
          <span className="font-mono">
            (${isSplit ? items?.price / 2 : items?.price})
          </span>
        )}
      </div>
      <button
        onClick={() => onChangeHandler("ADD")}
        className={`${isQuantityAvailable ? `text-white` : `bg-gray-100`}`}
      >
        <img
          src="/images/icons/add.svg"
          alt="add"
          width="30"
          height="30"
          // className="bg-gray-200 p-2 rounded-md"
          className={`${
            !isQuantityAvailable ? "bg-slate-200" : "invert"
          } p-2 rounded-full`}
        />
      </button>
    </div>
  );
}

export default memo(SelectMenuOptionComboItems);
