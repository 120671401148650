import { StoreEntity } from "@/app/_types/store.interface";
import { useState } from "react";
import SelectStoreModal from "./SelectStoreModal";

import { useParams } from "react-router-dom";

function SelectStoreButton({ storeList }: { storeList: StoreEntity[] }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const params: { location?: string } = useParams();

  const data =
    storeList?.find((el) => el?.id === params?.location)?.name ||
    "Select Store";

  return (
    <>
      <button onClick={() => setOpen(true)} className="flex gap-2 items-center">
        <img
          src={"/images/icons/store.svg"}
          width={25}
          height={25}
          alt={"Store"}
        />
        <span className="hidden md:block">{data}</span>
      </button>
      {open && (
        <SelectStoreModal
          openModal={open}
          storeList={storeList}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default SelectStoreButton;
