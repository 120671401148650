import {
  changeActiveCategory,
} from "@/app/_redux/slice/UISlice/UISlice";
import { useAppDispatch } from "@/app/_redux/store";
import { TradingPeriods } from "@/app/_types/menuWithCategories";

import { useInView } from "react-intersection-observer";

type MenuListClient = {
  children: React.ReactNode;
  categories: string;
  tradingPeriod: TradingPeriods;
};

function MenuListClient({
  children,
  categories,
  tradingPeriod,
}: MenuListClient) {
  const dispatch = useAppDispatch();

  const { ref } = useInView({
    threshold: 0.2, // Trigger when 20% of the element is in view
    onChange: (view) => {
      if (!view) return; // If not in view, exit early
      // Dispatch the action for the closest element
      const allTradingPeriodDataEmpty =
        !tradingPeriod?.categories?.length &&
        !tradingPeriod?.deals?.length &&
        !tradingPeriod?.mostLikedMenus?.length &&
        !tradingPeriod?.offers?.length;
      if (!allTradingPeriodDataEmpty) {
        dispatch(changeActiveCategory(categories));
      }
    },
  });

  return (
    <div ref={ref}>
      <div>{children}</div>
    </div>
  );
}

export default MenuListClient;
