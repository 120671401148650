import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { StoreEntity } from "@/app/_types/store.interface";
import { useSelector } from "react-redux";

const HomePageStoreLocationItem = ({ store }: { store: StoreEntity }) => {
  const { UIColors } = useSelector(selectUI);

  return (
    <div className="bg-white shadow-md rounded-md p-4 text-center flex flex-col">
      {/* Icon */}
      <div className="w-full h-44 mb-4">
        {store?.imagesData ? (
          store?.imagesData[0]?.image ? (
            <img
              src={
                store?.imagesData?.[0]?.image?.[0]?.file || "/defaultStore.png"
              }
              alt="Store Image"
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={"/defaultStore.png"}
              alt="Store Image"
              className="w-full h-full object-cover"
            />
          )
        ) : (
          <img
            src={"/defaultStore.png"}
            width={100}
            height={100}
            alt="image"
            className="w-full h-full object-cover"
          />
        )}
      </div>
      <div className="flex flex-col justify-between flex-1">
        <a
          href={`/${store.id}`}
          className="inline-block text-white text-sm font-medium py-3 px-4 rounded-full"
          style={{ background: UIColors?.secondary }}
        >
          Order Now
        </a>
        <div>
          <h2 className="text-lg font-semibold text-gray-800 mb-2">
            {store?.name}
          </h2>
          {store?.phone && (
            <p className="text-sm text-gray-600">{store?.phone}</p>
          )}
          <p className="text-sm text-gray-600 mb-4">
            {store?.address || "Address not available"}
            {store?.suburb ? `, ${store.suburb}` : ""}
            {store?.state ? `, ${store.state}` : ""}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePageStoreLocationItem;
