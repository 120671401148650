import {
  MenuWithCategories,
  reActivationTimes,
} from "@/app/_types/menuWithCategories";
import MenuTradingPeriodList from "../MenuTradingPeriods/MenuTradingPeriodsItem";
import { TradingHour } from "@/app/_types/tradingHours.interface";

import { CalculateOrderManagement } from "../MenuTradingPeriods/CalculateTradingPeriod";
import { useDispatch, useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { order, setReActivationTimes } from "@/app/_redux/slice/Order/OrderSlice";
import MenuListClient from "./MenuListClient";
import { useEffect } from "react";

type MenuList = {
  data: MenuWithCategories;
  params: string;
  reActivationTimesData: reActivationTimes[];
  tradingHours: TradingHour[];
  handleSelectedTradingPeriod: ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => Promise<void>;
};

function MenuList({
  data,
  params,
  reActivationTimesData,
  tradingHours,
  handleSelectedTradingPeriod,
}: MenuList) {
  const dispatch = useDispatch()
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);
  const { schedule } = useSelector(order);
  const selectedTime = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const OrderManagement = CalculateOrderManagement({
    selectedOrderStatus,
    storeDT,
    reActivationTimesData,
  });

  useEffect(() => {
    dispatch(setReActivationTimes(OrderManagement?.status))
  },[OrderManagement, dispatch])

  if (!selectedTime) return;
  const sortedTradingPeriods = data?.tradingPeriods;

  return (
    <div className="rounded-md">
      {sortedTradingPeriods?.length
        ? sortedTradingPeriods?.map((tradingPeriod, index) => (
          <div className="menu-container" style={{ position: "relative" }} key={index}>
          {!OrderManagement?.status && (
            <div
              className="transparent-shield"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.5)", 
                zIndex: 30,
                pointerEvents: "all", 
              }}
            >
              <div
                className="paused-text bg-red-500"
                style={{
                  position: "fixed", 
                  bottom: "20px", 
                  right: "20px", 
                  color: "white",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  zIndex: 20, 
                }}
              >
                {selectedOrderStatus} is Paused
              </div>
            </div>
          )}
          <MenuListClient
            // key={index}
            categories={tradingPeriod?.title}
            tradingPeriod={tradingPeriod}
          >
            <MenuTradingPeriodList
              dealList={tradingPeriod?.deals}
              offerList={tradingPeriod?.offers}
              categories={tradingPeriod?.categories}
              params={params}
              title={tradingPeriod?.title}
              tradingPeriodId={tradingPeriod?.id}
              tradingHours={tradingHours}
              mostlikedMenus={tradingPeriod?.mostLikedMenus}
              handleSelectedTradingPeriod={handleSelectedTradingPeriod}
            />
          </MenuListClient>
        </div>
        
          ))
        : null}
      {/* {!OrderManagement?.status ? (
        <div className="flex h-[30vh] justify-center items-center">
          <div className="rounded shadow-md w-4/5 flex justify-center items-center p-10 bg-red-500 text-white">
            <h1 className="font-extrabold text-3xl">
              {selectedOrderStatus} is Paused
            </h1>
          </div>
        </div>
      ) : sortedTradingPeriods?.length ? (
        sortedTradingPeriods?.map((tradingPeriod, index) => (
          <MenuListClient
            key={index}
            categories={tradingPeriod?.title}
            tradingPeriod={tradingPeriod}
          >
            <MenuTradingPeriodList
              dealList={tradingPeriod?.deals}
              offerList={tradingPeriod?.offers}
              categories={tradingPeriod?.categories}
              params={params}
              title={tradingPeriod?.title}
              tradingPeriodId={tradingPeriod?.id}
              tradingHours={tradingHours}
              mostlikedMenus={tradingPeriod?.mostLikedMenus}
              handleSelectedTradingPeriod={handleSelectedTradingPeriod}
            />
          </MenuListClient>
        ))
      ) : null} */}
    </div>
  );
}

export default MenuList;
