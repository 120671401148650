import { TradingHour } from "@/app/_types/tradingHours.interface";
import moment, { Moment } from "moment";

import { useEffect, useState } from "react";

interface CategoryListTradingHours {
  tradingHours: TradingHour[];
  storeDT: string;
  openTime: string;
  closeTime: string;
}

function CategoryListTradingHours({
  tradingHours,
  storeDT,
  openTime,
  closeTime,
}: CategoryListTradingHours) {
  const [openModal, setOpenModal] = useState(false);

  const todayDate = moment(storeDT).format("ddd").toUpperCase();
  // const todayTime = moment(storeDT).format("HH:mm:ss");
  const deliveryTrading = tradingHours?.filter(
    (el) => el?.orderType === "DELIVERY"
  );

  const storeTrading = tradingHours?.filter((el) => el?.orderType === "STORE");

  // const deliveryStatus = deliveryTrading?.find((el) => el?.day === todayDate);
  const todayDateDelivery = deliveryTrading?.filter(
    (el) => el?.day === todayDate
  );
  const deliveryIsClosed = deliveryTrading.every(
    (item) => item.isClosed === true
  );
  const deliveryTime = todayDateDelivery?.reduce(
    (
      acc: { smallestStartTime: Moment | null; largestEndTime: Moment | null },
      curr
    ) => {
      const currentStartTime = curr?.startTime
        ? moment(curr.startTime, "HH:mm:ss")
        : null;
      const currentEndTime = curr?.endTime
        ? moment(curr.endTime, "HH:mm:ss")
        : null;

      if (
        currentStartTime &&
        (!acc.smallestStartTime ||
          currentStartTime.isBefore(acc.smallestStartTime))
      ) {
        acc.smallestStartTime = currentStartTime;
      }

      if (
        currentEndTime &&
        (!acc.largestEndTime || currentEndTime.isAfter(acc.largestEndTime))
      ) {
        acc.largestEndTime = currentEndTime;
      }

      return acc;
    },
    { smallestStartTime: null, largestEndTime: null }
  );

  const storeStatus = storeTrading?.find((el) => el?.day === todayDate);

  useEffect(() => {
    if (!openTime || !closeTime) {
      setOpenModal(true);
    }
  }, [closeTime, openTime, storeStatus]);

  return (
    <>
      <div>
        {deliveryIsClosed ||
        !deliveryTime?.smallestStartTime ||
        !deliveryTime.largestEndTime ? (
          <p>
            {" "}
            Delivery: <span>Closed</span>
          </p>
        ) : (
          <p>
            Delivery Time: {deliveryTime.smallestStartTime?.format("h:mm A")} -{" "}
            {deliveryTime.largestEndTime?.format("h:mm A")}
            {/* {moment(deliveryStatus?.startTime, "HH:mm:ss").format("h:mm A")} -{" "}
            {moment(deliveryStatus?.endTime, "HH:mm:ss").format("h:mm A")} */}
          </p>
        )}
      </div>
      <div>
        {!storeStatus?.startTime ||
        !storeStatus?.endTime ||
        !openTime ||
        !closeTime ? (
          <p>
            {" "}
            Open Time : <span>Closed</span>
          </p>
        ) : (
          <p>
            {" "}
            Open Time : {moment(openTime, "HH:mm:ss").format("h:mm A")} -{" "}
            {moment(closeTime, "HH:mm:ss").format("h:mm A")}
            {/* {moment(storeStatus?.startTime, "HH:mm:ss").format("h:mm A")} -{" "}
            {moment(storeStatus?.endTime, "HH:mm:ss").format("h:mm A")} */}
          </p>
        )}
      </div>
      {storeStatus?.isClosed && (
        <div className="fixed bottom-3 right-0 bg-primary p-4 rounded-md">
          <p>We are closed for now.</p>
        </div>
      )}

      {openModal && (
        <div className="fixed bottom-0 right-0 left-0 bg-primary z-50 min-h-[30vh] p-4 transition-all ease-in duration-200">
          <button className="ml-auto flex " onClick={() => setOpenModal(false)}>
            <img
              src="/images/icons/close.svg"
              alt="close"
              width="30"
              height="30"
              className="fill-white"
            />
          </button>
          <p className="font-bold text-2xl">
            We are Sorry to Announce that our Store is Closed for now.
          </p>
        </div>
      )}
    </>
  );
}

export default CategoryListTradingHours;
