import { twMerge } from "tailwind-merge";

function LoadingLogo({
  className,
  // isLogoShow,
}: {
  className?: string;
  // isLogoShow?: boolean;
}) {
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-center gap-4",
        className
      )}
    >
      {/* {isLogoShow && (
        // <img src="/logo/restologo.png" alt="Loading..." className="w-28" />
        <img src="images/icons/loading.svg" alt="Loading..." className="w-28" />
      )} */}
      <span className="animate-ping w-8 h-8 bg-black rounded-full"></span>
    </div>
  );
}

export default LoadingLogo;
