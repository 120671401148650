import { useEffect, useState } from "react";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import NotFound404 from "@/app/global/NoData/NotFound404";
import SomethingWentWrong from "@/app/global/NoData/SomethingWentWrong";
import StoreClientWrapper from "@/app/global/StoreWrapper/StoreClientWrapper";
import MenuOfferDetailClientWrapper from "./MenuOfferDetailClientWrapper";

import MenuOfferTitle from "./MenuOfferForms/MenuOfferTitle";
import MenuOfferForm from "./MenuOfferForms/MenuOfferForm";
import { v4 as uuidv4 } from "uuid";
import {
  IndividualOfferOrderItems,
  MenuOfferFormArray,
  OfferCategory,
  OfferDetails,
} from "@/app/_types/menuOffers.interface";
import { MenuDetailsEntity } from "@/app/_types/menuDetails";
import toast from "react-hot-toast";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "@/app/[location]/menu/utils/sizeChangeUtils";
import { StoreEntity } from "@/app/_types/store.interface";
import { useSelector } from "react-redux";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import moment from "moment";
import MenuDetailsLoadingScreen from "@/app/[location]/components/MenuDetails/MenuDetailsLoadingScreen";

async function createObject(
  offers: OfferCategory,
  offerIndex: number,
  storeId: string,
  periodId: string
) {
  let getIsExtraMenu: IndividualOfferOrderItems | undefined = undefined;
  const offerCategoriesMenuLength = offers?.offerCategoryMenus?.length;

  if (offerCategoriesMenuLength === 1) {
    const menu = offers?.offerCategoryMenus?.[0];

    const menuDetails: MenuDetailsEntity = await fetchRequest(
      serverPath.getMenuDetails(storeId, menu?.menuId, periodId),
      "GET",
      undefined,
      { cache: "no-store" }
    );

    if (!menuDetails) {
      toast.error(
        "Menu Details Not Found. Please Refresh the Page and Try Again"
      );
      return;
    }

    const comboList = ManageComboPayInitial(
      menuDetails?.menuOptionGroups || [],
      menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
    );

    const comboPrices = getIndividualMenuComboPrice(comboList);

    if (!menu.hasExtras) {
      getIsExtraMenu = {
        id: menuDetails?.menu?.id,
        name: menu?.menuName,
        basePrice: menuDetails?.menu?.basePrice || 0,
        finalPrice: menu?.finalPrice || 0,
        sizeGroupId: menu?.sizeGroupId,
        menuSizeId: menu?.menuSizeId,
        comboPrice: comboPrices,
        hasHalfHalf: menu?.hasHalfHalf,
        hasExtras: menu?.hasExtras,
        ingredientPrice: 0,
        specialPrice: menu?.finalPrice || 0,
        optionGroups: comboList || [],
        ingredients: menuDetails?.menuOptions || [],
        tradingHours: menu?.tradingHours || [],
        menuSizeCount: menu?.menuSizeCount,
      };
    }
  }

  return {
    uuid: uuidv4(),
    offerCategoryId: offers?.id,
    offerCategoryIndex: offerIndex,
    offerId: offers?.offerId,
    discount: offers?.discount,
    categoryId: offers?.categoryId,
    name: offers?.categoryName,
    size: offers?.sizeId,
    isSelected: getIsExtraMenu ? true : false,
    isOptional: offers.isOptional,
    sizeName: offers?.sizeName,
    menus: getIsExtraMenu ? [getIsExtraMenu] : [],
    config: {
      isSplit: false,
      selectedMenuIndex: 0,
      isRemove: false,
      canAddToCart: true,
    },
  };
}

function MenuOfferDetails({
  params,
  isModal,
}: {
  params: { location: string; name: string; periodId: string };
  isModal: boolean;
}) {
  const [storeDetails, setStoreDetails] = useState<StoreEntity | null>(null);
  const [getStoreDT, setGetStoreDT] = useState<string>("");
  const [menuOfferDetails, setMenuOfferDetails] = useState<OfferDetails | null>(
    null
  );
  const [offersForm, setOffersForm] = useState<MenuOfferFormArray[]>([]);
  const [loading, setLoading] = useState(true);
  const { name, location, periodId } = params;
  const { schedule } = useSelector(order);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storeDetails = await fetchRequest(
          serverPath.getStoreEntityDetails(location)
        );
        if (!storeDetails) {
          setLoading(false);
          return;
        }
        setStoreDetails(storeDetails);

        const storeDT = await fetchRequest(
          serverPath.getStoreDT(storeDetails?.id),
          "GET",
          undefined,
          {
            cache: "no-cache",
          }
        );
        setGetStoreDT(storeDT?.storeCurrentDt);

        let selectedDay;
        if (schedule) {
          selectedDay = moment(schedule?.selectedTime?.date)
            .format("ddd")
            .toUpperCase();
          console.log(selectedDay);
        } else {
          selectedDay = moment(storeDT?.storeCurrentDt)
            .format("ddd")
            .toUpperCase();
          console.log(selectedDay);
        }

        const menuOfferDetails: OfferDetails = await fetchRequest(
          serverPath.getMenuOfferDetails(
            storeDetails?.id,
            name,
            selectedDay,
            periodId
          ),
          "GET",
          undefined,
          { cache: "no-store" }
        );
        if (!menuOfferDetails?.id) {
          setLoading(false);
          return;
        }

        setMenuOfferDetails(menuOfferDetails);

        const offerCategories = menuOfferDetails?.offerCategories;
        const offersFormArray: MenuOfferFormArray[] = [];

        for (let i = 0; i < offerCategories.length; i++) {
          const offerCategoriesEntity = offerCategories[i];
          const newDeal = await createObject(
            offerCategoriesEntity,
            i,
            storeDetails?.id,
            periodId
          );
          if (newDeal) {
            offersFormArray.push(newDeal);
          }
        }
        setOffersForm(offersFormArray);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [location, name, periodId, schedule]);

  if (loading) {
    return <MenuDetailsLoadingScreen />;
  }

  if (!storeDetails) {
    return <NotFound404 />;
  }

  if (!menuOfferDetails) {
    return <SomethingWentWrong />;
  }

  return (
    <StoreClientWrapper selectedStore={storeDetails} storeDT={getStoreDT}>
      <MenuOfferDetailClientWrapper menuOffers={menuOfferDetails}>
        <div className="flex relative" style={{height: "75dvh"}}>
          <div className="hidden md:block w-1/2">
            <div className="h-full relative">
              <img
                src={
                  menuOfferDetails?.imageUrl
                    ? menuOfferDetails?.imageUrl
                    : "/images/icons/noMenuImage.jpg"
                }
                width={400}
                height={400}
                alt="Deal Image"
                className="w-full h-full object-cover object-center"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 flex flex-col relative pb-32" style={{height: "75dvh"}}>
          <div className="overflow-auto">
            <div className="px-4 py-2 border-b-2 border-gray-100">
              <h1 className="text-2xl font-extrabold flex items-center justify-between">
                {menuOfferDetails?.title}
                {isModal ? <MenuOfferTitle /> : null}
              </h1>
              <p>{menuOfferDetails?.description}</p>
            </div>
            <MenuOfferForm
              offerForm={offersForm}
              isModal={isModal}
              periodId={periodId}
              offerInformation={{
                id: menuOfferDetails?.id,
                name: menuOfferDetails?.title,
                description: menuOfferDetails?.description,
                imageUrl: menuOfferDetails?.imageUrl,
                maxOptional: menuOfferDetails?.maxOptionalCategorySelectable,
                tradingHours: menuOfferDetails?.tradingHours,
              }}
            />
          </div>
          </div>
        </div>
      </MenuOfferDetailClientWrapper>
    </StoreClientWrapper>
  );
}

export default MenuOfferDetails;
