import { AddMenuItemsForm} from "@/app/_types/menuDetails";
import { memo } from "react";
// import MenuDetailsComboItems from "./MenuDetailsComboItems";
import { useFormContext, useWatch } from "react-hook-form";
import SelectMenuOptionComboItems from "../SelectMenuFirstTab/SelectMenuOptionComboItems";

function MenuDetailsCombo() {
  const { control, getValues } = useFormContext<AddMenuItemsForm>();

  const watchSelectedIndex = useWatch({
    control,
    name: "config.selectedIndex",
  });

  const watchCombo = useWatch({
    control,
    name: `data.${watchSelectedIndex}.optionGroups`,
  });

  if (!watchCombo?.length) return;

  const comboPriceType = getValues("comboPriceType");

  return (
    <>
      <div className="bg-gray-200 px-4 py-2">
        {/* <p className="text-lg font-bold mb-2">Choose Combo</p> */}
        <div className="flex flex-col gap-2 ">
          {watchCombo?.map((combo, index) => (
            <div
              key={index}
              className="bg-white rounded-md flex flex-col  gap-2 p-2 pb-4"
            >
              <div className="flex justify-between">
                <p className="font-bold">
                  {combo?.optionGroupName}
                  {combo?.isMandatory && (
                    <span className="text-error"> *</span>
                  )}{" "}
                </p>
                <IndividualMenuDetailsComboPrice
                  mainIndex={watchSelectedIndex}
                  index={index}
                />
              </div>
              {/* <div className="flex flex-wrap gap-4">
                {combo?.options?.map((item, indexs) => (
                  <MenuDetailsComboItems
                    item={item}
                    key={indexs}
                    mainIndex={index}
                    combo={combo}
                    index={indexs}
                  />
                ))}
              </div> */}
              <div className="flex flex-wrap gap-4">
                {combo?.options?.map((item, indexs) => (
                  <SelectMenuOptionComboItems
                    combo={combo}
                    items={item}
                    mainIndex={index}
                    comboIndex={indexs}
                    key={item?.id}
                    comboPriceType={comboPriceType}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export function IndividualMenuDetailsComboPrice({
  mainIndex,
  index,
}: {
  mainIndex: number;
  index: number;
}) {
  const { control } = useFormContext<AddMenuItemsForm>();

  const watchIsSplit = useWatch({
    control,
    name: `config.isSplit`,
  });

  const watchCombo =
    useWatch({
      control,
      name: `data.${mainIndex}.optionGroups.${index}.itemsPrice`,
    }) || 0;

  return (
    <>
      {watchCombo > 0 && (
        <p>+ $ {watchIsSplit ? watchCombo / 2 : watchCombo}</p>
      )}
    </>
  );
}

export default memo(MenuDetailsCombo);
