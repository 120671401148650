
import MenuDetailsExtraOptionItem from "./MenuDetailsExtraOptionItem";
import { Option2, OptionGroup } from "@/app/_types/extraMenuPriceInfo";

interface MenuDetailsExtraOptionAccordion {
  option: OptionGroup;
  optionGroups: OptionGroup[];
  optionName: Option2[];
  open: number | null;
  setOpen: React.Dispatch<React.SetStateAction<number | null>>;
  index: number;
}
function MenuDetailsExtraOptionAccordion({
  option,
  optionGroups,
  optionName,
  index,
  open,
  setOpen,
}: MenuDetailsExtraOptionAccordion) {
  // const [open, setOpen] = useState(false);

  const AccordionHandler = () => {
    // setOpen((prev) => !prev);
    if (open === index) return setOpen(null);
    setOpen(index);
  };

  return (
    <div className="py-[1px]">
      <button
        className=" w-full bg-gray-200 px-2 py-2 rounded-md flex items-center justify-between"
        onClick={AccordionHandler}
      >
        <p className="">{option.name}</p>
        {/* <Icon icon="icon-park:down" /> */}
        {index === open ? (
          <img src="/images/icons/up.svg" alt="v" width={16} height={16} />
        ) : (
          <img src="/images/icons/down.svg" alt="v" width={16} height={16} />
        )}
      </button>
      <div
        className={
          open === index
            ? `divide-y-2 h-auto bg-white transition-all`
            : "scale-0 h-0"
        }
      >
        {option?.options?.map((item, index) => (
          <MenuDetailsExtraOptionItem
            item={item}
            key={index}
            optionName={optionName}
            option={option}
            optionGroups={optionGroups}
          />
        ))}
      </div>
    </div>
  );
}

export default MenuDetailsExtraOptionAccordion;
