// import {
//   OriginalColorData,
//   TransformedItem,
// } from "@/app/_types/EcommerceTheme.interface";

import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import {
  changeStoreLogo,
  changeStoreThemeData,
  changeUIColors,
} from "@/app/_redux/slice/UISlice/UISlice";
import {
  ImageData,
  ImageUUIDMapValue,
  OriginalColorData,
  OriginalData,
  TransformedItem,
} from "@/app/_types/EcommerceTheme.interface";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCartList } from "@/app/_axios/serverApi";
import { useLocation } from "react-router-dom";

const storeGroup = import.meta.env.VITE_STORE

function Footer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedStore } = useSelector(selectStore);
  const { data } = useQuery({
    queryKey: ["getThemeColor"],
    queryFn: () => fetchRequest(serverPath.getThemeColor),
  });

  const { data: logoData } = useQuery({
    queryKey: ["getLogoImage"],
    queryFn: () => fetchRequest(serverPath?.getLogoImage),
  });

  const { data: storeTheme } = useQuery({
    queryKey: ["getTheme"],
    queryFn: () => fetchRequest(serverPath?.getTheme),
  });

  const storages = localStorage.getItem("cart");

  const cartDetailsResponse = storages ? getCartList(storages) : null;

  const cartDetails =
    cartDetailsResponse?.status === 200 ? cartDetailsResponse : null;

  const storeId =
    window.location.pathname === "/checkout"
      ? cartDetails?.location || ""
      : selectedStore?.id || "";

  // console.log("hit place storeInfo", selectedStore, storeId)
  const { data: storeInfo } = useQuery({
    queryKey: ["getEcommerceStoreInfo", storeId],
    queryFn: () => fetchRequest(serverPath.getEcommerceStoreInfo(storeId)),
    enabled: !!storeId,
  });

  console.log("hit place storeInfo", storeInfo, cartDetails?.location, storeId);

  const convertToOriginalFormatWithImageURLs = (
    transformedData: TransformedItem[]
  ): OriginalData => {
    const originalData: OriginalData = {
      data: {
        chooseColor: { color: { primary: "", secondary: "", footerText: "" } },
        hero: {
          status: {},
          titles: [],
          imagesData: [],
          design: {},
        },
        about: {
          title: { text: "", id: "", key: "" },
          subTitles: [],
          imagesData: [],
        },
        location: {
          stores: [],
        },
      },
    };

    const imageUUIDMap = new Map<string, ImageUUIDMapValue>();

    // First pass to build the imageUUIDMap
    transformedData?.forEach((item) => {
      const { key, value, type, id } = item;
      if (type === "IMAGE" || type === "VIDEO" || type === "CARD") {
        imageUUIDMap.set(key, { url: value, id: id!, type });
      }
    });

    // Function to sort the array based on the key
    const sortByKey = (a: { key: string }, b: { key: string }): number => {
      const aKey = parseInt(a.key.split("_").pop()!, 10);
      const bKey = parseInt(b.key.split("_").pop()!, 10);
      return aKey - bKey;
    };

    // Second pass to build the original data structure
    transformedData?.forEach((item) => {
      const { section, key, type, value, id } = item;

      // Handle stores as separate sections
      if (section.startsWith("store_")) {
        const storeIndex = parseInt(section.split("_")[1], 10) - 1;
        if (!originalData.data.location.stores[storeIndex]) {
          originalData.data.location.stores[storeIndex] = { imagesData: [] };
        }

        if (type === "OTHERS") {
          let parsedValue: any;
          try {
            parsedValue = JSON.parse(value);
          } catch (e) {
            console.error(
              `Error parsing JSON for key: ${key}, value: ${value}`,
              e
            );
            parsedValue = {};
          }

          if (parsedValue.image) {
            parsedValue.image = parsedValue.image
              .map((img: ImageData) => {
                const imgData = imageUUIDMap.get(img?.imageUUID);
                return imgData
                  ? { ...img, file: imgData.url, id: imgData.id }
                  : null;
              })
              .filter((img: ImageData | null) => img && img.id && img.file);
          }

          if (key === "aboutStore_1") {
            originalData.data.location.stores[storeIndex].aboutStore = {
              ...parsedValue,
              id,
            };
          } else if (key.startsWith("imagesData")) {
            originalData.data.location.stores[storeIndex].imagesData!.push({
              ...parsedValue,
              id,
              key,
            });
          } else {
            originalData.data.location.stores[storeIndex][key] = parsedValue;
          }
        }
      } else if (type === "OTHERS") {
        let parsedValue: any;
        try {
          parsedValue = JSON.parse(value);
        } catch (e) {
          parsedValue = value;
        }
        if (typeof parsedValue === "object" && parsedValue.image) {
          parsedValue.image = parsedValue.image
            .map((img: ImageData) => {
              const imgData = imageUUIDMap.get(img.imageUUID);
              return imgData
                ? { ...img, file: imgData.url, id: imgData.id }
                : null;
            })
            .filter((img: ImageData | null) => img && img.id && img.file);
        }
        if (section === "hero") {
          if (key.startsWith("titles")) {
            originalData.data.hero.titles!.push({ ...parsedValue, id, key });
          } else if (key.startsWith("imagesData")) {
            originalData.data.hero.imagesData!.push({
              ...parsedValue,
              id,
              key,
            });
          } else if (key === "design") {
            originalData.data.hero.design = { ...parsedValue, id, key };
          } else {
            originalData.data.hero[key.replace(/_\d+$/, "")] = {
              ...parsedValue,
              id,
              key,
            };
          }
        } else if (section === "about") {
          if (key.startsWith("subTitles")) {
            originalData.data.about.subTitles!.push({
              ...parsedValue,
              id,
              key,
            });
          } else if (key.startsWith("imagesData")) {
            originalData.data.about.imagesData!.push({
              ...parsedValue,
              id,
              key,
            });
          } else {
            originalData.data.about[key.replace(/_\d+$/, "")] = {
              ...parsedValue,
              id,
              key,
            };
          }
        } else if (section === "chooseColor") {
          originalData.data.chooseColor.color = { ...parsedValue, id, key };
        }
      } else if (section === "hero") {
        originalData.data.hero[key.replace(/_\d+$/, "")] = value;
      } else if (section === "about") {
        originalData.data.about[key.replace(/_\d+$/, "")] = value;
      }
    });

    // Ensure that imagesData contains at least one entry with status "IMAGE" if empty
    const ensureImagesDataHasStatusImage = (imagesData: Array<any>) => {
      if (imagesData.length === 0) {
        imagesData.push({ status: "IMAGE" });
      }
    };

    ensureImagesDataHasStatusImage(originalData.data.hero.imagesData!);
    ensureImagesDataHasStatusImage(originalData.data.about.imagesData!);
    originalData.data.location.stores.forEach((store) => {
      ensureImagesDataHasStatusImage(store.imagesData!);
    });

    // Remove the variables with names matching imageUUID and values as URLs
    Object.keys(originalData.data.hero).forEach((key) => {
      if (key.match(/^[a-f0-9-]{36}$/)) {
        delete originalData.data.hero[key];
      }
    });
    Object.keys(originalData.data.about).forEach((key) => {
      if (key.match(/^[a-f0-9-]{36}$/)) {
        delete originalData?.data?.about[key];
      }
    });

    // Remove status: 'IMAGE' if there is actual data in imagesData
    originalData.data.location.stores.forEach((store) => {
      if (
        store?.imagesData!.length > 1 ||
        (store?.imagesData!.length === 1 && store.imagesData![0].image)
      ) {
        store.imagesData = store.imagesData!.filter((data) => data.image);
      }
    });

    // Final pass to remove unnecessary status fields in stores
    originalData?.data?.location?.stores?.forEach((store) => {
      if (store?.status === "IMAGE") {
        delete store?.status;
      }
      // Remove id and key if they are outside of imagesData
      delete store.id;
      delete store.key;
    });

    // Sort arrays based on key values
    originalData?.data.hero.titles!.sort(sortByKey);
    originalData.data.hero.imagesData!.sort(sortByKey);
    originalData.data.about.subTitles!.sort(sortByKey);
    originalData.data.about.imagesData!.sort(sortByKey);
    originalData.data.location.stores.forEach((store) => {
      store.imagesData!.sort(sortByKey);
    });

    return originalData;
  };

  const ThemeFormattedData: OriginalData =
    convertToOriginalFormatWithImageURLs(storeTheme);

  console.log("ThemeFormattedData", ThemeFormattedData);

  const ECommerceColorTheme: TransformedItem[] | undefined = data;

  const revertFormattedData = (
    formattedData: TransformedItem[] | undefined
  ) => {
    if (!formattedData) return;
    if (
      !formattedData ||
      !Array.isArray(formattedData) ||
      formattedData.length === 0
    ) {
      console.error("Invalid or empty formattedData.");
      return {
        data: {
          chooseColor: {
            color: { primary: "", secondary: "", footerText: "" },
          },
        },
      };
    }

    const originalData: OriginalColorData = {
      data: {
        chooseColor: { color: { primary: "", secondary: "", footerText: "" } },
      },
    };

    // Iterate over each entry in formattedData
    formattedData.forEach((item) => {
      const sectionKey =
        item.section === "section-color" ? "chooseColor" : item.section;

      if (!originalData.data[sectionKey]) {
        originalData.data[sectionKey] = {};
      }

      if (item.value) {
        originalData.data[sectionKey][item.key] = JSON.parse(item.value);
      } else {
        originalData.data[sectionKey][item.key] = {};
      }

      if (item.id && !originalData.data[sectionKey].id) {
        originalData.data[sectionKey].id = item.id;
      }
    });

    return originalData;
  };

  const ThemeColorFormattedData: OriginalColorData | undefined =
    revertFormattedData(ECommerceColorTheme);

  const defaultColors = {
    footerText: "#fff",
    primary: "#000",
    secondary: "#111",
  };

  const colors =
    ThemeColorFormattedData?.data?.chooseColor?.color || defaultColors;

  useEffect(() => {
    dispatch(changeUIColors(colors));
  }, [colors, dispatch]);

  useEffect(() => {
    if (!logoData) return;
    dispatch(changeStoreLogo(logoData));
  }, [dispatch, logoData]);

  useEffect(() => {
    dispatch(changeStoreThemeData(ThemeFormattedData));
  }, [ThemeFormattedData, dispatch]);

  const isHomePage = location.pathname === "/";

  return (
    <footer className="p-8 pb-4 " style={{ background: colors?.primary }}>
      <div className="maxContainer">
        <div
          className="flex flex-col gap-6"
          style={{ color: colors?.footerText }}
        >
          <div className="flex justify-center md:justify-between  gap-6 items-center flex-wrap  ">
            {/* Left Section */}
            <div className="flex flex-col gap-3">
              {" "}
              {/* Adjust width as needed */}
              <Link to={"/"} className="h-[6rem] md:h-[6rem] w-full max-w-full">
                <img
                  // src="/images/icons/logo.png"
                  src={logoData?.logoImageUrl}
                  alt="Logo"
                  className=" h-[6rem] md:h-[6rem] w-full max-w-full object-contain aspect-square"
                />
              </Link>
            </div>
            {/* Middle Section */}
            <div className="flex  gap-4 items-center">
              <Link to={"/privacy-policy"}>PRIVACY POLICY</Link>
              <Link to={"/terms-and-condition"}>TERMS & CONDITIONS</Link>
            </div>
            {/* Right Section */}
            <div className=" flex gap-4 flex-col items-center">
              {!isHomePage && (
                <>
                  <div className="flex gap-4">
                    {storeInfo?.facebookLink && (
                      <a
                        href={
                          storeInfo?.facebookLink.startsWith("http")
                            ? storeInfo.facebookLink
                            : `https://${storeInfo.facebookLink}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/icons/facebookLogo.svg"
                          width={30}
                          height={30}
                          alt="Connect with facebook"
                        />
                      </a>
                    )}
                    {storeInfo?.xlink && (
                      <a
                        href={
                          storeInfo?.xlink.startsWith("http")
                            ? storeInfo?.xlink
                            : `https://${storeInfo.xlink}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/icons/twitter.svg"
                          width={30}
                          height={30}
                          alt="Connect with twitter"
                        />
                      </a>
                    )}
                    {storeInfo?.instagramLink && (
                      <a
                        href={
                          storeInfo?.instagramLink.startsWith("http")
                            ? storeInfo?.instagramLink
                            : `https://${storeInfo.instagramLink}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/images/icons/instagram.svg"
                          width={30}
                          height={30}
                          alt="Connect with insagram"
                        />
                      </a>
                    )}
                  </div>
                  {storeInfo?.primaryEmail && (
                    <div className="justify-center">
                      <p className="underline">{storeInfo?.primaryEmail}</p>
                    </div>
                  )}
                  {storeInfo?.secondaryEmail && (
                    <div className="justify-center">
                      <p className="underline">{storeInfo?.secondaryEmail}</p>
                    </div>
                  )}
                </>
              )}
            </div>{" "}
            {/* Adjust width as needed */}
          </div>

          <div className="flex justify-center gap-3">
            {storeGroup === "supero" ? (
              <>
              <p>Powered by superopizza.com.au</p>
              <p>&copy; Supero Pizza, All Rights Reserved 2025</p>
              </>
            ): (
              <>
            <p>Powered by woodfiredpizzabar.com.au</p>
            <p>&copy; WoodFired, All Rights Reserved 2025</p>
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
