export const serverPath = {
  // public

  storeList: `/public/store/${import.meta.env.VITE_STORE_GROUP_ID}`,
  getStoreEntityDetails: (storeId: string) =>
    `/public/store-details/${storeId}`,
  tradingHours: (storeId: string) => `/public/tradingHours/${storeId}`,
  todayTradingHours: (storeId: string) =>
    `/public/todaysTradingHours/${storeId}`,
  deliveryInfo: (storeId: string) => `/public/deliveryInfo/${storeId}`,
  categoriesWithMenu: (storeId: string, tradingPeriodId: string | null) => {
    const baseUrl = `/public/categoriesWithMenus/${storeId}?source=WEB`;
    return tradingPeriodId
      ? `${baseUrl}&tradingPeriodId=${tradingPeriodId}`
      : baseUrl;
  },
  // getMenuDetails: (storeId: string, menuId: string) =>
  //   `/public/menuInfo/${menuId}/${storeId}`,
  getMenuDetails: (
    storeId: string,
    menuId: string,
    tradingPeriodId: string
  ) => {
    const baseUrl = `/public/menuInfo/${menuId}/${storeId}`;
    return tradingPeriodId
      ? `${baseUrl}?tradingPeriodId=${tradingPeriodId}`
      : baseUrl;
  },
  getMenuDealDetails: (
    storeId: string,
    dealId: string,
    dayOfWeek: string,
    tradingPeriodId?: string
  ) => {
    const baseUrl = `/public/dealInfo/${dealId}/${storeId}?dayOfWeek=${dayOfWeek}`;
    return tradingPeriodId
      ? `${baseUrl}&tradingPeriodId=${tradingPeriodId}`
      : baseUrl;
  },
  getMenuOfferDetails: (
    storeId: string,
    offerId: string,
    dayOfWeek: string,
    tradingPeriodId?: string
  ) => {
    const baseUrl = `/public/offerInfo/${offerId}/${storeId}?dayOfWeek=${dayOfWeek}`;
    return tradingPeriodId
      ? `${baseUrl}&tradingPeriodId=${tradingPeriodId}`
      : baseUrl;
  },

  getExtraPriceInfo: (storeId: string) => `/public/extraPricesInfo/${storeId}`,
  searchStreetsByStore: (storeId: string, streetName: string) =>
    `/public/searchStreets/${storeId}?streetName=${streetName}`,
  getMenusForHalfAndHalf: (sizeId: string, storeId: string) =>
    `/public/getMenusFor2ndHH/${sizeId}/${storeId}`,
  getEstimatedTime: (storeId: string) => `/public/estimatedTime/${storeId}`,
  getStoreDT: (storeId: string) => `/public/dateTime/${storeId}`,
  getTheme: `/public/eCommerceData/${import.meta.env.VITE_STORE_GROUP_ID}`,
  getThemeColor: `/public/eCommerceData/sectionColor/${
    import.meta.env.VITE_STORE_GROUP_ID
  }`,
  getReActivationTimes: (storeId: string) =>
    `/public/reActivationTimes/${storeId}`,
  getEcommerceStoreImageData: (storeId: string) =>
    `/public/eCommerceStoreData/${storeId}`,

  getStripePublishableKey: (storeId: string) =>
    `/public/getStripePublicKey/${storeId}`,
  getSurcharge: (storeId: string) => `/public/surcharge/${storeId}`,
  getEvents: (storeId: string) => `/public/getEvent/${storeId}`,
  getHolidays: (storeId: string) => `/public/getHolidaysAtDt/${storeId}`,
  getMinOrderAmount: (storeId: string) =>
    `/public/getMinOrderAmount/${storeId}`,
  getCheckoutPageDetails: `/public/checkoutpage-ecommerce`,
  getLogoImage: `/public/eCommerceData/logo-desktop/${import.meta.env.VITE_STORE_GROUP_ID}`,
  getEcommerceStoreInfo: (storeId: string) => `/public/ecommerceStoreInfo/${storeId}`,
  sendCateringOrder: `/public/catering`,
  // authentication
  loginWithUsernameAndPassword: "/customerAuth/login",
  loginWithProvider: "/customerAuth/loginWithProvider",

  // customer information
  currentCustomer: "/customer/me",
  getLastOrderDetails: `/customer/latestOrdersInfo`,

  getCustomerAddressByStore: (storeId: string) =>
    `/customer/address/${storeId}`,
  updateCustomerAddress: "/customer/address",
  customerOrderList: (storeId: string, pageNo: number, pageSize: number) =>
    `/customer/myOrders/${storeId}?pageNo=${pageNo}&pageSize=${pageSize}`,
  createCustomer: "/customer/updateInfo",
  createOrder: "/customer/order",
  tableBook: "/public/reservation",
  deliveryPrice: (addressType: string, storeId: string) =>
    `/customer/deliveryPrice/${addressType}/${storeId}`,
  getCustomerOrderDetails: (storeId: string, orderId: string) =>
    `/customer/order/${orderId}/${storeId}`,
  getCustomerPaymentDetails: (storeId: string, orderId: string) =>
    `/customer/orderPaymentInfo/${orderId}/${storeId}`,
  getCustomerVoucher: (storeId: string) =>
    `/customer/getAvailableVouchers/${storeId}`,
  customerVoucher: (storeId: string, searchTxt: string) =>
    `/customer/searchPublicVoucher/${storeId}/${searchTxt}`,
  customerInvoiceEmail: "/customer/emailInvoice",
  getVoucherConfig: (storeId: string) =>
    `/public/multipleDiscountConfs/${storeId}`,
  getStoreDetails: `/public/${import.meta.env.VITE_STORE_GROUP_ID}`,
  // menu and categories

  // paypal
  paypalTokenCapture: (token: string, storeId: string) =>
    `/paypal/capture/${storeId}?paypalOrderId=${token}`,
  // customer OTP AUTH
  RequestOTP: "/customerAuth/customer",
  customerOTPconfirm: (OTP: string) => `/customerAuth/customerVerify/${OTP}`,
  customerForgotPassword: `/customerAuth/forgotPassword`,
  customerVerifyForgotPassword: (OTP: string) =>
    `/customerAuth/verifyForgotPassword/${OTP}`,
  resetRefreshToken: "/customerAuth/refreshtoken",
  customerEmailUnsubscribe : (token: string)=>`/customer/emailUnsubscribe?token=${token}`
};
