import { useMutation } from "@tanstack/react-query";
import { serverPath } from "../_axios/path";
import fetchRequest from "../_axios/fetchRequest";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const EmailUnsubscribePage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams?.get("token");

  // Local state to handle missing token
  const [missingToken, setMissingToken] = useState(false);

  const {
    mutate,
    isLoading,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: ({ token }: { token: string }) =>
      fetchRequest(serverPath?.customerEmailUnsubscribe(token), "PUT"),
    onSuccess: () => {
      // Additional actions on success if needed
    },
    onError: () => {
      // Handle specific error actions here if needed
    },
  });

  useEffect(() => {
    if (!token) {
      setMissingToken(true);
      return;
    }
    mutate({ token });
  }, [mutate, token]);

  return (
    <div className="flex items-center justify-center" style={{ height: "65vh" }}>
      <div className="bg-white shadow-md rounded-lg p-8 w-11/12 max-w-md">
        {missingToken && (
          <div className="text-red-500 text-center text-lg">
            Something went wrong. Token is missing.
          </div>
        )}
        {!missingToken && isLoading && (
          <div className="text-gray-700 text-center text-lg">
            Processing your request...
          </div>
        )}
        {!missingToken && isSuccess && (
          <div className="text-green-500 text-center text-lg">
            Your email has been successfully unsubscribed.
          </div>
        )}
        {!missingToken && isError && (
          <div className="text-red-500 text-center text-lg">
            Something went wrong. Please try again later.
          </div>
        )}
        {!missingToken && !isLoading && !isSuccess && !isError && (
          <div className="text-gray-500 text-center text-lg">
            Initializing...
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailUnsubscribePage;
