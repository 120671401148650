import { useSelector } from "react-redux";
import { MenuItems } from "@/app/_types/menuWithCategories";
import CustomModalClient from "@/app/global/modal/CustomModalClient";
import { useQuery } from "@tanstack/react-query";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { formatString } from "@/app/utils/formatString";

function SelectMenuModal({
  open,
  setOpen,
  sizeId,
  getSelectedMenu,
}: {
  sizeGroupId: string;
  categoryId?: string | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sizeId: string;
  getSelectedMenu(menu: MenuItems): void;
}) {
  const { selectedStore } = useSelector(selectStore);

  const { isLoading, data } = useQuery({
    queryKey: ["menuListWithSize", sizeId, selectedStore?.id],
    queryFn: () =>
      fetchRequest(
        serverPath.getMenusForHalfAndHalf(sizeId, selectedStore?.id as string)
      ),
  });

  const menuList: MenuItems[] = data?.menus;

  console.log(menuList);

  return (
    <CustomModalClient
      open={open}
      setOpen={setOpen}
      title="Select Another Menu Items"
    >
      <div className="overflow-y-auto pt-16">
        {isLoading ? (
          <div className="flex flex-wrap gap-4">
            {[1, 2, 3].map((el) => {
              return (
                <div
                  key={el}
                  className="flex flex-col items-start justify-start gap-2 p-4"
                >
                  <div className=" w-22 h-22 md:w-32 md:h-32 animate-pulse bg-background rounded-md" />
                  <div className="loading-text w-22 md:w-32" key={el} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="grid grid-cols-custom-150 lg:grid-cols-custom-150 gap-5 p-4 ">
            {menuList?.map((menu, index) => (
              <button
                key={index}
                onClick={() => {
                  getSelectedMenu(menu);
                  setOpen(false);
                }}
              >
                <div
                  className={` 
          shadow-[1.95px_1.95px_2.6px_rgba(0,0,0,0.06)]  
          cursor-pointer pb-3 flex flex-col rounded-md relative `}
                >
                  <div>
                    <img
                      src={
                        menu?.thumbnailUrl || "/images/icons/noMenuImage.jpg"
                      }
                      width={100}
                      height={100}
                      alt={menu?.name}
                      className="object-cover object-center h-28  md:h-40  min-w-full rounded-t-md"
                    />
                  </div>
                  <div className="pt-1 pl-1  ">
                    <h2 className="font-medium text-left">
                      {formatString(menu?.name)}
                    </h2>
                    {/* <p className="text-20 text-gray-500 text-left">{menu?.description}</p> */}
                    <div className="flex items-center justify-between">
                      <p className="text-gray-600  text-sm">
                        $ {menu?.basePrice}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </CustomModalClient>
  );
}

export default SelectMenuModal;
