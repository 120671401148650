import { changeStoreDT } from "@/app/_redux/slice/UISlice/UISlice";
import {
  addSelectedStore,
  addStoreList,
} from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { Children } from "@/app/_types/common";
import { StoreEntity } from "@/app/_types/store.interface";
import { useEffect } from "react";

interface StoreClientWrapperProps extends Children {
  storeList?: StoreEntity[];
  selectedStore: StoreEntity;
  storeDT: string;
}

function StoreClientWrapper({
  children,
  storeList,
  selectedStore,
  storeDT,
}: StoreClientWrapperProps) {
  const dispatch = useAppDispatch();
  console.log({ selectedStore });
  useEffect(() => {
    dispatch(addStoreList(storeList));
    dispatch(addSelectedStore(selectedStore));
    dispatch(changeStoreDT(storeDT));
  }, [storeList, selectedStore, dispatch, storeDT]);
  return <>{children}</>;
}

export default StoreClientWrapper;
