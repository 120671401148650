

import MenuOfferFormItem from "./MenuOfferFormItem";
import { useFormContext, useWatch } from "react-hook-form";
import MenuOfferSelectMenu from "./MenuOfferSelectMenu/MenuOfferSelectMenu";
import MenuOfferEditMenu from "./MenuOfferEditMenu/MenuOfferEditMenu";
import MenuOfferAddToCart from "./MenuOfferEditMenu/MenuOfferCart/MenuOfferAddToCart";
import {
  MenuOfferForm,
  MenuOfferFormArray,
} from "@/app/_types/menuOffers.interface";

interface MenuDealFormListProps {
  isModal: boolean;
}

function MenuOfferFormList({ isModal }: MenuDealFormListProps) {
  const { control, register } = useFormContext<MenuOfferForm>();
  const selectedPage = useWatch({
    control,
    name: "config.selectedPage",
  });

  const watchOfferList: MenuOfferFormArray[] = useWatch({
    control,
    name: "menuOffer",
  });

  const optionalOfferList = watchOfferList.filter((el) => el.isOptional);
  const requiredOfferList = watchOfferList.filter((el) => !el.isOptional);

  function checkUI() {
    switch (true) {
      case selectedPage === "EDIT_MENU":
        return <MenuOfferEditMenu />;
      case selectedPage === "SELECT_MENU":
        return <MenuOfferSelectMenu />;
      default:
        return (
          <div className="bg-background p-4 flex flex-col gap-2 overflow-y-auto">
            {requiredOfferList?.map((offerCategories, index) => (
              <MenuOfferFormItem
                key={index}
                offerCategories={offerCategories}
                mainIndex={offerCategories.offerCategoryIndex}
              />
            ))}
            {optionalOfferList.length > 0 && <h1>Optional Offers</h1>}
            {optionalOfferList?.map((offerCategories, index) => (
              <MenuOfferFormItem
                key={index}
                offerCategories={offerCategories}
                mainIndex={offerCategories.offerCategoryIndex}
              />
            ))}
          </div>
        );
    }
  }

  return (
    <>
      <div>{checkUI()}</div>
      <div className="flex flex-col gap-4 py-4 px-2">
        <div className="flex items-center w-full p-2 rounded  border border-black">
          <input
            {...register(`note`)}
            className="w-full outline-none"
            type="text"
            placeholder="Notes"
          />
        </div>
      </div>
      <MenuOfferAddToCart isModal={isModal} />
    </>
  );
}

export default MenuOfferFormList;
