import HomePageNavBar from "../NavBar/HomePageNavBar";
import { Hero } from "@/app/_types/EcommerceTheme.interface";

// import HomePageHeaderBackGround from "./HomePageHeaderBackGround";
import { StoreEntity } from "@/app/_types/store.interface";
// import HomePageOtherHeader from "./HomePageOtherHeader";

import HomePageHeaderBackGround from "./HomePageHeaderBackGround";
import HomePageOtherHeader from "./HomePageOtherHeader";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";

interface HomePageInterface {
  hero: Hero;
  storeList: StoreEntity[];
}
function HomePageHeader({ hero, storeList }: HomePageInterface) {
  const { UIColors } = useSelector(selectUI);
  return (
    <div
      className={`relative ${
        hero?.status?.text !== "BACKGROUND" ? "" : "text-white"
      }`}
    >
      <HomePageNavBar EcommerceColor={UIColors} storeList={storeList} />
      <HomePageHeaderBackGround hero={hero} />
      <HomePageOtherHeader color={UIColors} hero={hero} storeList={storeList} />
    </div>
  );
}

export default HomePageHeader;
