import { useMemo, useEffect } from "react";
import LocationLoading from "./loading";
import { useQueries } from "@tanstack/react-query";
import { serverPath } from "../_axios/path";
import NotFound404 from "../global/NoData/NotFound404";
import LocationDetails from "./LocationDetails";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import fetchRequest from "../_axios/fetchRequest";

const StoreLocationPage = () => {
  const { location } = useParams<{ location: string }>();
  const navigate = useNavigate();

  // Map location names to IDs
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const locationMapping: Record<string, string> = {
    coolalinga: "4fa00d39-c793-4686-b307-e5da486677a4",
    casuarina: "ab24357f-744a-4028-a4d6-131d0bbccfeb",
    palmerston: "9a5e01c7-bb51-48c1-b374-b558a6c72d1d",
  };

  useEffect(() => {
    // Check if the location matches one of the predefined names
    if (location && locationMapping[location]) {
      navigate(`/${locationMapping[location]}`, { replace: true });
    }
  }, [location, navigate, locationMapping]);

  const [searchParams] = useSearchParams();
  const tradingPeriodId = searchParams?.get("tradingPeriodId");

  const [getStoreQuery, getStoreDetailsQuery] = useQueries({
    queries: [
      {
        queryKey: ["storeList"],
        queryFn: () => fetchRequest(serverPath?.storeList, "GET"),
      },
      {
        queryKey: ["getStoreDetails"],
        queryFn: () => fetchRequest(serverPath?.getStoreDetails, "GET"),
      },
    ],
  });

  const isLoading = useMemo(() => {
    return [getStoreQuery, getStoreDetailsQuery]?.some(
      (query) => query?.isLoading
    );
  }, [getStoreQuery, getStoreDetailsQuery]);

  if (isLoading) return <LocationLoading />;

  if (!getStoreQuery?.data || !getStoreDetailsQuery?.data) return;

  // Use the location as it is (either original or mapped) for querying stores
  const findStore = getStoreQuery?.data?.find(
    (el: { id: string }) => el?.id === location
  );

  if (!findStore || !location) {
    return <NotFound404 />;
  }

  return (
    <>
      <LocationDetails
        params={{ location }}
        findStore={findStore}
        searchParams={{ tradingPeriodId }}
        getStoreDetails={getStoreDetailsQuery?.data}
        getStore={getStoreQuery?.data}
      />
    </>
  );
};

export default StoreLocationPage;
