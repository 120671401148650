import { MenuDealForm } from "@/app/_types/menuDeals.interface";
import { MenuOptionGroup } from "@/app/_types/menuDetails";
import { memo, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import MenuDealEditComboItem from "./MenuDealEditExtra/MenuDealEditComboItem";

export interface MenuDealEditComboProps {
  menuCombos: MenuOptionGroup[];
}

function MenuDealEditCombo() {
  const { control, setValue } = useFormContext<MenuDealForm>();

  const selectedIndex = useWatch({
    control,
    name: `config.selectedDealIndex`,
  });

  const selectedMenuIndex = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.config.selectedMenuIndex`,
  });

  const menuCombos = useWatch({
    control,
    name: `menuDeal.${selectedIndex}.menus.${selectedMenuIndex}`,
  });

  console.log("menu combos => ", menuCombos);

  useEffect(() => {
    if (!menuCombos?.optionGroups?.length || !menuCombos)
      setValue("config.canConfirm", true);
  }, [setValue, menuCombos]);

  return (
    <>
      {menuCombos?.optionGroups?.length ? (
        <div className="bg-gray-200 px-4 py-2">
          <p className="text-lg font-bold mb-2">Choose Combo</p>
          <div className="flex flex-col gap-2 ">
            {menuCombos?.optionGroups?.map((combo, index) => (
              <MenuCombo combo={combo} index={index} key={combo?.id} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

function MenuCombo({
  combo,
  index,
}: {
  combo: MenuOptionGroup;
  index: number;
}) {
  const { setValue } = useFormContext<MenuDealForm>();

  useEffect(() => {
    if (!combo?.isMandatory) return;

    const checkMenuCombo = combo?.options?.some((el) => el?.qty > 0);

    setValue("config.canConfirm", checkMenuCombo);
  }, [combo, setValue]);

  return (
    <div className="bg-white rounded-md flex flex-col  gap-2 p-2 pb-4">
      <div className="flex justify-between">
        <p className="font-bold">
          {combo?.optionGroupName}
          {combo?.isMandatory && <span className="text-error"> *</span>}{" "}
        </p>
        <IndividualMenuDetailsComboPrice index={index} key={combo?.id} />
      </div>
      <div className="flex flex-wrap gap-4">
        {combo?.options?.map((item, indexs) => (
          <MenuDealEditComboItem
            key={item?.id}
            items={item}
            comboIndex={index}
            comboItemIndex={indexs}
            combo={combo}
          />
        ))}
      </div>
    </div>
  );
}

function IndividualMenuDetailsComboPrice({ index }: { index: number }) {
  const { control } = useFormContext<MenuDealForm>();

  // const watchIsSplit = useWatch({
  //   control,
  //   name: `config.isSplit`,
  // });

  const selectedDealIndex =
    useWatch({
      control,
      name: `config.selectedDealIndex`,
    }) || 0;

  console.log("Selected Deal index => ", selectedDealIndex);

  const selectedMenuIndex = useWatch({
    control,
    name: `menuDeal.${selectedDealIndex}.config.selectedMenuIndex`,
  });

  const watchIsSplit = useWatch({
    control,
    name: `menuDeal.${selectedDealIndex}.config.isSplit`,
  });

  const watchCombo =
    useWatch({
      control,
      name: `menuDeal.${selectedDealIndex}.menus.${selectedMenuIndex}.optionGroups.${index}.itemsPrice`,
    }) || 0;

  return (
    <>
      {watchCombo > 0 && (
        <p>+ $ {watchIsSplit ? watchCombo / 2 : watchCombo}</p>
      )}
    </>
  );
}

export default memo(MenuDealEditCombo);
