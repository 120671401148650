import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { EstimatedTime, StoreLogo, UIColors, UISliceState } from "./UISlice.d";
import { OriginalData } from "@/app/_types/EcommerceTheme.interface";

const initialState: UISliceState = {
  activeCategory: null,
  subActiveCategory: null,
  selectedOrderStatus: "PICKUP",
  estimatedTime: null,
  storeDT: null,
  cartOpen: false,
  UIColors: {
    footerText: "#fff",
    primary: "#000",
    secondary: "#111"
  },
  storeLogo: null,
  storeThemeData: null
};

export const UISlice = createSlice({
  name: "UISlice",
  initialState,
  reducers: {
    changeActiveCategory(state, action: PayloadAction<string>) {
      state.activeCategory = action.payload;
    },
    changeSubActiveCategory(state, action: PayloadAction<string>) {
      state.subActiveCategory = action.payload;
    },
    changeSelectedOrderStatus(
      state,
      action: PayloadAction<"DELIVERY" | "PICKUP">
    ) {
      state.selectedOrderStatus = action.payload;
    },
    changeEstimatedTime(state, action: PayloadAction<null | EstimatedTime>) {
      state.estimatedTime = action.payload;
    },
    changeStoreDT(state, action: PayloadAction<string>) {
      state.storeDT = action.payload;
    },
    changeCartOpen(state, action: PayloadAction<boolean>){
      state.cartOpen = action.payload
    },
      changeUIColors(state,action:PayloadAction<UIColors>){
        state.UIColors = action.payload
    },
    changeStoreLogo(state, action:PayloadAction<StoreLogo>){
      state.storeLogo = action.payload
    },
    changeStoreThemeData(state, action:PayloadAction<OriginalData>){
      state.storeThemeData = action.payload
    }
  },
});

export const {
  changeActiveCategory,
  changeSubActiveCategory,
  changeSelectedOrderStatus,
  changeEstimatedTime,
  changeStoreDT,
  changeCartOpen,
  changeUIColors,
  changeStoreLogo,
  changeStoreThemeData,
} = UISlice.actions;
export const selectUI = (state: RootState) => state.UI;

export default UISlice.reducer;
