

function CartButton() {
  return (
    // <Icon
    //   icon="bx:cart-add"
    //   className=" text-4xl md:text-5xl p-2 md:p-3   text-white"
    // />
    <img
      src="/images/icons/mingcuteAdd.svg"
      alt="+"
      width={40}
      height={40}
      className="text-4xl md:text-5xl p-2 md:p-3text-white"
    />
    // <Icon
    //   icon="mingcute:add-fill"
    //   className=" text-4xl md:text-5xl p-2 md:p-3   text-white"
    // />
  );
}

export default CartButton;
