import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { signIn } from "next-auth/react";
import { twMerge } from "tailwind-merge";
import { countriesCode } from "../countryCode";
import { useSelector } from "react-redux";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
// import ErrorAstrick from "@/app/global/elements/ErrorAstrick";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { serverPath } from "@/app/_axios/path";
import fetchRequest from "@/app/_axios/fetchRequest";
import {
  axiosErrorMessage,
  AxiosErrorMessage,
} from "@/app/_axios/handleAxiosError";
import CustomerSupportMsg from "../../CustomerSupportMsg";
import ErrorAstrick from "@/app/global/elements/ErrorAstrick";

interface LoginForm {
  phoneNo: string;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  checkbox: boolean;
  errors?: string;
  isLoading: boolean;
}

function LoginForm({
  changeStatus,
  handleClose,
  isModal,
}: {
  changeStatus?(status: "LOGIN" | "REGISTER"): void;
  handleClose?(): void;
  isModal?: boolean;
}) {
  const { storeDetails } = useSelector(selectStore);
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") || "/";

  const CountryCodeIndex =
    countriesCode?.findIndex(
      (country) => country?.iso === storeDetails?.countryISO || "AUS"
    ) || 13;
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<LoginForm>();

  const [selectedCountry, setSelectedCountry] = useState({
    iso: countriesCode[CountryCodeIndex]?.iso,
    code: countriesCode[CountryCodeIndex]?.code,
  });
  // const [showPassword, setShowPassword] = useState(false);
  const router = useNavigate();
  const location = useLocation();
  const getErrors = watch("errors");
  const isLoading = watch("isLoading");
  const client = useQueryClient();

  const SignUpHandler = () => {
    if (changeStatus) {
      changeStatus("REGISTER");
    } else {
      router("/auth/register");
    }
  };

  console.log(SignUpHandler);

  const { mutate } = useMutation({
    mutationKey: ["loginWithUsernameAndPassword"],
    mutationFn: (data: LoginForm) =>
      fetchRequest(
        serverPath?.loginWithUsernameAndPassword,
        "POST",
        {
          firstName: data?.firstName,
          lastName: data?.lastName,
          username: data?.phoneNo,
          password: data?.password,
          countryCode: selectedCountry?.code,
          storeGroupId: import.meta.env.VITE_STORE_GROUP_ID,
        },
        {
          "Content-Type": "application/json",
        }
      ),
    onSuccess: (data) => {
      console.log("Login here successful", data);
      localStorage.setItem("session", JSON.stringify(data?.tokenDTO));
      setValue("isLoading", false);
      toast.success("Login Successfully");
      client.invalidateQueries(["currentCustomer"]);
      if (location?.pathname === "/auth/login") {
        // window.location.replace("/");
        window.location.replace(`${redirect}`);
      } else {
        window.location.reload();
        // handleClose ? handleClose() : window.location.reload()
      }
    },
    onError: (error: AxiosErrorMessage) => {
      setValue(
        "errors",
        axiosErrorMessage(error || "Something went Wrong. Please Try Again")
      );
      setValue("isLoading", false);
      toast.error(
        axiosErrorMessage(error || "Something went Wrong. Please Try Again.")
      );
    },
  });

  const LoginHandler = (data: LoginForm) => {
    // console.log("hit",data)
    setValue("isLoading", true);
    mutate(data);
  };

  const checkError = () => {
    if (getErrors) {
      setValue("errors", "");
    }
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIso = e.target.value;
    const Countryselected =
      countriesCode?.find((country) => country.iso === selectedIso) ||
      selectedCountry;
    setSelectedCountry({
      iso: Countryselected?.iso,
      code: Countryselected?.code,
    });
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("phoneNo", e.target.value);
  };

  useEffect(() => {
    if (CountryCodeIndex !== -1 && countriesCode[CountryCodeIndex]) {
      setSelectedCountry({
        iso: countriesCode[CountryCodeIndex].iso,
        code: countriesCode[CountryCodeIndex].code,
      });
    }
  }, [CountryCodeIndex]);

  return (
    <form className="form-control" onSubmit={handleSubmit(LoginHandler)}>
      <div className="p-4 md:p-6">
        <div className="flex justify-between items-center">
          {/* <h1 className="font-bold text-xl ">Sign in to Your Account</h1> */}
          <h1 className="font-bold text-xl ">Contact Information</h1>

          <button
            type="button"
            onClick={handleClose}
            // className={twMerge("lg:hidden", !isModal && "hidden")}
            className={!isModal ? "hidden" : undefined}
          >
            <img
              src="/images/icons/close.svg"
              width={25}
              height={25}
              alt="close"
            />
          </button>
        </div>
        <div className="flex gap-5 md:gap-0 flex-wrap">
          <div className="flex flex-col gap-4 md:w-1/2">
            <div className="py-3 px-1 bg-purple-100 border-l-4 border-purple-500 text-purple-800 rounded-md shadow-md mt-2">
              <p className="font-semibold text-sm">
                {/* Dear Customer, we have transitioned to in-house IT services. If
                this is your first time here, please complete the one-time
                signup process to continue ordering. */}
                Dear Customer, Please Enter your phone number so we can reach
                you regarding your order.
              </p>
            </div>
            <div className="form-div">
            <div className=" flex gap-4">
        <div className="form-div w-1/2">
          <label htmlFor="firstName" className="form-label">
            First Name
            {errors?.firstName && <ErrorAstrick />}
          </label>
          {errors?.firstName && (
            <p className="text-red-500 text-sm">{errors?.firstName?.message}</p>
          )}
          <input
            type="firstName"
            id="firstName"
            {...register("firstName", {
              required: "FirstName is Required",
            })}
            className="form-input w-full"
            onChange={(e) => {
              checkError();
              setValue("firstName", e.target.value);
              clearErrors();
            }}
          />
        </div>
        <div className="form-div w-1/2">
          <label htmlFor="lastName" className="form-label">
            Last Name
            {/* {errors?.lastName && <ErrorAstrick />} */}
          </label>
          {errors?.lastName && (
            <p className="text-red-500 text-sm">{errors?.lastName?.message}</p>
          )}
          <input
            type="lastName"
            id="lastName"
            {...register("lastName", {
              // required: "LastName is Required",
            })}
            className="form-input w-full"
            onChange={(e) => {
              checkError();
              setValue("lastName", e.target.value);
              clearErrors();
            }}
          />
        </div>
      </div>
            </div>

            <div className="form-div">
              {/* <label htmlFor="email" className="form-label">
                Phone No
                {errors?.phoneNo && <ErrorAstrick />}
              </label> */}
              {errors.phoneNo && (
                <p className="text-red-500 text-sm">{errors.phoneNo.message}</p>
              )}
              <div className="flex gap-2">
                <select
                  value={selectedCountry?.iso}
                  onChange={(e) => handleCountryChange(e)}
                  className="form-select"
                  style={{ width: "150px" }} // Adjust the width as needed
                >
                  {countriesCode.map((country, index) => (
                    <option key={index} value={country?.iso}>
                      {country?.iso} ({country?.code})
                    </option>
                  ))}
                </select>
                <input
                  type="tel"
                  id="phoneNo"
                  maxLength={16}
                  {...register("phoneNo", {
                    required: "Phone number is required",
                  })}
                  className="form-input w-full"
                  onChange={(e) => {
                    checkError();
                    handlePhoneChange(e);
                    clearErrors();
                  }}
                />
              </div>
            </div>
            {/* <div className="form-div">
              <label htmlFor="password" className="form-label">
                Password
                {errors?.password && <ErrorAstrick />}
              </label>
              {errors.password && (
                <p className="text-red-500 text-sm">
                  {errors.password.message}
                </p>
              )}
              <input
                type={`${showPassword ? "text" : "password"}`}
                id="password"
                maxLength={20}
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                  maxLength: {
                    value: 20,
                    message: "Password must be below 20 characters",
                  },
                })}
                className="form-input"
                onChange={(e) => {
                  checkError();
                  setValue("password", e.target.value);
                  clearErrors();
                }}
              />
            </div> */}
            {/* <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                checked={showPassword}
                onChange={() => setShowPassword((prev) => !prev)}
              />
              <label htmlFor="show-password" className="text-sm text-gray-700">
                Show Password
              </label>
            </div>
            <div className="flex justify-end">
              <Link
                to={{
                  pathname: "/forget",
                  search: `?iso=${selectedCountry?.iso}&phoneNo=${watch(
                    "phoneNo"
                  )}&redirect=${location?.pathname}`,
                }}
                className=" text-primary"
              >
                Forget Password ?
              </Link>
            </div> */}
            <div className="flex justify-end items-end my-4">
              <button
                // type="button"
                onClick={handleSubmit(LoginHandler)}
                className={twMerge("form-btn", isLoading && "disable")}
                disabled={isLoading}
              >
                {/* Login */}
                Confirm Order Contact
              </button>
            </div>
            {/* <div className="flex justify-start items-end">
              <p>
                Don&apos;t have an account Yet ?{" "}
                <Link
                  to={{
                    pathname: "/auth/register",
                    search: `?redirect=${redirect}`,
                  }}
                  className=" text-primary font-semibold"
                >
                  Sign Up
                </Link>
              </p>
            </div> */}
          </div>
          <div className="w-full md:w-1/2 pl-4">
            <CustomerSupportMsg />
          </div>
        </div>
      </div>
    </form>
  );
}

export default LoginForm;
