import { useEffect, useState } from "react";
import ProfileMenuDropDown from "./ProfileMenuDropDown";
import { useDispatch } from "react-redux";
import { addCustomerDetails } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { CustomerInfo } from "@/app/_types/customer.interface";

function UserDetailsNavItems({ customerDetails }: { customerDetails: CustomerInfo }) {
  const [openModal, setOpenModal] = useState(false);
  const SignOutHandler = () => {
    setOpenModal(true);
  };

  const dispatch = useDispatch()

  useEffect(()=>{
    const fetchCustomerData = () => {
      dispatch(addCustomerDetails(customerDetails))
    }

    fetchCustomerData()
  },[customerDetails, dispatch])

  // useEffect(() => {
  //   const fetchCustomerData = async () => {
  //     if (data) {
  //       const res = await fetchRequest(
  //         serverPath.currentCustomer,
  //         "GET",
  //         undefined,
  //         { Authorization: `Bearer ${data?.user?.tokenDTO?.acessToken}` },
  //         "res",
  //         data?.user?.tokenDTO?.refreshToken
  //       );
  //       if (res?.status === 200) {
  //         const body = await res.json();
  //         dispatch(addCustomerDetails(body));
  //       } else {
  //         console.log("Not auth");
  //       }
  //     }
  //   };

  //   fetchCustomerData();
  // }, [data, dispatch, update]);

  const firstName = customerDetails?.firstName || "";
  // const lastName = customerDetails?.lastName || "";
  // const fullName = firstName + " " + lastName;
  const fullName = firstName
  const truncatedName =
    fullName.length > 20 ? fullName.slice(0, 17) + "..." : fullName;
  return (
    <>
      <li className="hidden md:block">
        <p>{truncatedName}</p>
      </li>
      <li>
        <button className="w-10 h-10 rounded-full" onClick={SignOutHandler}>
          <img
            src={"/defaultProfile.png"}
            width={25}
            height={25}
            alt="profile"
            className="w-[inherit] h-[inherit] rounded-full cursor-pointer"
          />
        </button>
        {openModal && (
          <ProfileMenuDropDown onClose={() => setOpenModal(false)} />
        )}
      </li>
    </>
  );
}

export default UserDetailsNavItems;
