import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function CartExistModal({
  open,
  acceptData,
  previousLocation,
}: {
  open: boolean;
  previousLocation: string;
  handleClose(): void;
  acceptData(): void;
}) {
  const { storeList } = useSelector(selectStore);
  const findStore =
    storeList?.find((store) => store?.id === previousLocation)?.name ||
    previousLocation;

  const handleCloseNew = () => {};
  return (
    <CustomModalClients
      open={open}
      handleClose={handleCloseNew}
      className="w-full md:w-5/6 lg:w-fit"
    >
      <div>
        <div className="flex justify-between items-center p-4 md:p-6 border-b-2">
          <h1 className="font-bold text-xl ">Cart Already Exist</h1>
        </div>
        <div className=" pt-4 p-8">
          <p>
            It looks like you already have cart Items in{" "}
            <span className="font-bold">{findStore} </span>. Your Cart Item of{" "}
            {findStore} will be removed if you continue. Would you like to
            continue ?
          </p>
          <div className="flex items-center justify-end pt-4 gap-6">
            <button
              className="bg-primary text-white px-4 py-2 rounded-md"
              onClick={acceptData}
            >
              Continue
            </button>
            <Link to="/">
              <button className="text-error">Go Back</button>
            </Link>
          </div>
        </div>
      </div>
    </CustomModalClients>
  );
}

export default CartExistModal;
