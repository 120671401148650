import LoginForm from "@/app/auth/login/components/LoginForm";
import RegisterForm from "@/app/auth/register/components/RegisterForm";
import { useState } from "react";

function CheckoutLogin() {
  const [newStatus, setNewStatus] = useState("LOGIN");

  const changeStatus = (data: "LOGIN" | "REGISTER") => {
    setNewStatus(data);
  };

  return (
    <div className="flex flex-col gap-4 shadow-xl">
      <p>
        Looks Like You haven&apos;t{" "}
        {newStatus === "LOGIN" ? " Sign In. " : " Sign Up. "}
        <span className="font-bold animate-pulse text-lg">
          {" "}
          {newStatus === "LOGIN"
            ? " Sign In To Continue"
            : " Sign Up To Continue"}
        </span>
      </p>
      {/* <ExtraSignIn /> */}
      {newStatus === "LOGIN" && <LoginForm changeStatus={changeStatus} />}
      {newStatus === "REGISTER" && <RegisterForm changeStatus={changeStatus} />}
      {/* <button
        className="text-right text-primary text-sm underline"
        type="button"
        onClick={() => setOpen(true)}
      >
        Try Different Sign In Method
      </button>

      {open && (
        <LoginModal
          handleClose={handleClose}
          open={open}
          status="REGISTER"
          key={"Login-checkout-key"}
        />
      )} */}
    </div>
  );
}

export default CheckoutLogin;
