import MenuDetailsCurrentOptionItem from "../MenuDetailsCurrentOptionItem";
import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";
import { useFormContext, useWatch } from "react-hook-form";
import { AddMenuItemsForm } from "@/app/_types/menuDetails";

function MenuDetailsAdditionalOption({
  optionGroups,
}: {
  optionGroups: OptionGroup[];
}) {
  // const ingredientContext = useContext(IngredientProvider);
  // const ingredientFields = ingredientContext?.fields;

  const { control } = useFormContext<AddMenuItemsForm>();

  const watchSelectedIndex = useWatch({
    control,
    name: "config.selectedIndex",
  });

  const ingredientFields = useWatch({
    control,
    name: `data.${watchSelectedIndex}.ingredients`,
  });


  const additionalItems = ingredientFields?.filter(
    (item) => item?.status === "added"
  );

  console.log(additionalItems);

  return (
    <>
      {additionalItems?.length ? (
        <div className="px-4 py-2">
          <p className="text-lg font-bold">Additional Toppings</p>
          <div className="divide-y-2  ">
            {additionalItems?.map((option, index: number) => (
              <MenuDetailsCurrentOptionItem
                item={option}
                key={index}
                isExtraOption={true}
                optionGroups={optionGroups}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MenuDetailsAdditionalOption;
