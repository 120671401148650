import { MenuItems } from "@/app/_types/menuWithCategories";

import CartButton from "../AddToCart/CartButton";
import { formatString } from "@/app/utils/formatString";

type MenuItemCard = {
  menu: MenuItems;
  status?: boolean;
  menuItemStatus?: boolean;
  reActivationTimes: boolean;
};

function MenuItemCard({
  menu,
  status = true,
  menuItemStatus,
  reActivationTimes,
}: MenuItemCard) {
  return (
    <div
      className={` 
      shadow-[1.95px_1.95px_2.6px_rgba(0,0,0,0.06)]  
      cursor-pointer flex rounded-md relative h-full`}
    >
      {menu?.thumbnailUrl ? (
        <div className="flex-1 relative">
          <img
            src={menu?.thumbnailUrl}
            width={100}
            height={100}
            alt={menu?.name}
            // unoptimized={true}
            className="object-cover object-center h-28  md:h-40  min-w-full rounded-l-md"
            // priority
          />
          {menu?.visibilityStatus === "OUT_OF_STOCK" ? (
            <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
              Out of Stock
            </p>
          ) : (
            (!status || (status && !menuItemStatus) || !reActivationTimes) && (
              <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
                Opens Soon
              </p>
            )
          )}
        </div>
      ) : (
        <div className="flex-1 relative">
          <img
            src="/images/icons/noMenuImage.jpg"
            width={100}
            height={100}
            alt={menu?.name}
            className="object-cover object-center h-28  md:h-40  min-w-full rounded-l-md"
          />
          {menu?.visibilityStatus === "OUT_OF_STOCK" ? (
            <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
              Out of Stock
            </p>
          ) : (
            (!status || (status && !menuItemStatus) || !reActivationTimes) && (
              <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
                Opens Soon
              </p>
            )
          )}
        </div>
      )}
      <div className="pt-1 pl-1 flex-[2]">
        <h2 className="font-medium pb-2">{formatString(menu?.name, 30)}</h2>
        <p className="text-20 text-gray-500 text-left pb-2">
          {menu?.description}
        </p>
        <div className="flex items-center justify-between">
          <p className="text-gray-600  text-sm">$ {menu?.basePrice}</p>
        </div>
      </div>

      <div className="absolute top-[4rem] md:top-[6.5rem]  right-2 bg-[rgba(0,0,0,0.8)] border-r-50 rounded-full ">
        <CartButton />
      </div>
    </div>
  );
}

export default MenuItemCard;
