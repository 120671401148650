import { Option, Option2, OptionGroup } from "@/app/_types/extraMenuPriceInfo";

import { useContext } from "react";
import { OfferMenuIngredientProvider } from "../../../../context";
import toast from "react-hot-toast";
import {
  AddDealMenuIngredientHandler,
  RemoveDealMenuIngredientsHandler,
} from "../../../../offerOptionChangeUtil";
import { useFormContext } from "react-hook-form";
import { MenuOfferForm } from "@/app/_types/menuOffers.interface";

interface MenuDealExtraOptionItemProps {
  item: Option;
  optionName: Option2[];
  optionGroups: OptionGroup[];
}

function MenuOfferExtraOptionItem({
  item,
  optionName,
  optionGroups,
}: MenuDealExtraOptionItemProps) {
  const optionDetails = optionName?.find((option) => option.id === item.id);
  const { getValues, setValue } = useFormContext<MenuOfferForm>();

  const ingredientContext = useContext(OfferMenuIngredientProvider);
  const ingredientFields = ingredientContext?.fields;

  const checkIngredient = ingredientFields?.find((obj) => obj?.id === item?.id);

  if (!ingredientContext) return toast.error("Ing Context not working");

  return (
    <div className="flex justify-between px-4 py-2">
      <p
        className={
          checkIngredient && checkIngredient?.qty === 0
            ? "line-through text-red-400"
            : "no-underline"
        }
      >
        {optionDetails?.name}{" "}
      </p>
      <div className="flex items-center gap-2">
        {checkIngredient && checkIngredient?.qty > 0 && (
          <>
            <button
              onClick={() =>
                RemoveDealMenuIngredientsHandler(
                  ingredientContext,
                  getValues,
                  setValue,
                  {
                    id: item?.id,
                    menuSizes: item?.sizes?.map((el) => ({
                      ...el,
                      sizeId: el?.id,
                    })),
                    name: optionDetails?.name || "",
                    isSingle: item?.isSingle,
                    optionGroupId: optionDetails?.optionGroupId || "",
                  }
                )
              }
            >
              <img
                src="/images/icons/minus.svg"
                alt="minus"
                width="30"
                height="30"
                className="bg-slate-200 p-2 rounded-full"
              />
            </button>
            <p>{checkIngredient?.qty}</p>
          </>
        )}
        <button
          onClick={() =>
            AddDealMenuIngredientHandler(
              ingredientContext,
              getValues,
              setValue,
              {
                id: item?.id,
                menuSizes: item?.sizes?.map((el) => ({
                  ...el,
                  sizeId: el?.id,
                })),
                name: optionDetails?.name || "",
                isSingle: item?.isSingle,
                optionGroupId: optionDetails?.optionGroupId || "",
              },
              optionGroups
            )
          }
        >
          <img
            src="/images/icons/add.svg"
            alt="add"
            width="30"
            height="30"
            className="bg-gray-200 p-2 rounded-md"
          />
        </button>
      </div>
    </div>
  );
}

export default MenuOfferExtraOptionItem;
