import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

function CheckoutRealProcess({
  children,
  publishableKey,
  stripeAccountId,
}: {
  children: React.ReactNode;
  publishableKey: string | null;
  stripeAccountId: string | null;
}) {
  const { control } = useFormContext<ConfirmOrderForm>();

  const watchFinalAmountForWallet = useWatch({
    control,
    name: "config.finalTotalOrderForWallet",
  });

  // if (watchFinalAmountForWallet <= 0) return;

  const options: StripeElementsOptions = {
    mode: "payment",
    amount: Number((watchFinalAmountForWallet * 100).toFixed(2)),
    currency: "aud",
  };

  return (
    <Elements
      stripe={
        publishableKey && stripeAccountId
          ? loadStripe(publishableKey, {
              stripeAccount: stripeAccountId,
            })
          : publishableKey
          ? loadStripe(publishableKey)
          : null
      }
      options={options}
    >
      <form>{children}</form>
    </Elements>
  );
}

export default CheckoutRealProcess;
