import {
  DealDetailsInCart,
  DealItemsDetailsInCart,
} from "@/app/_types/menuDeals.interface";
import { Fragment } from "react";
import { fixedDecimal } from "@/app/utils/DecimalAndNumbers";

import { CartIngredients, ComboPortion } from "./AddToCartItemsName";
import { formatString } from "@/app/utils/formatString";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";

import { useSelector } from "react-redux";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import {
  changeIsLoading,
  order,
  removeDealList,
  removeOrderList,
  updateDealDetails,
} from "@/app/_redux/slice/Order/OrderSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { CalculateMenuTradingPeriod } from "../MenuTradingPeriods/CalculateTradingPeriod";
import moment from "moment";
import {
  updateCartInformation,
  UpdateCartInformation,
} from "@/app/_axios/serverApi";

interface AddToCartDealItemsProps {
  deal: DealDetailsInCart;
  isLoading?: boolean;
  location: string;
  todayTradingHoursData: TradingHour[];
}

function AddToCartDealItems({
  deal,
  isLoading,
  location,
}: AddToCartDealItemsProps) {
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);
  const { schedule } = useSelector(order);

  const dispatch = useAppDispatch();

  const OrderManage = async (
    status: "ADD" | "REMOVE",
    deal: DealDetailsInCart
  ) => {
    const getToken = localStorage.getItem("cart") || "";
    const finalData: UpdateCartInformation = {
      location: location,
      status,
      itemStatus: "DEAL",
      dealDetails: deal,
    };

    dispatch(changeIsLoading(true));
    const res = updateCartInformation(finalData, getToken);
    if (res?.status === 200) {
      const datas: { message: string; newToken: string } = res;
      localStorage.setItem("cart", datas?.newToken);
      dispatch(updateDealDetails({ deal, status }));
      dispatch(changeIsLoading(false));
    } else {
      localStorage.removeItem("cart");
      dispatch(removeOrderList());
      dispatch(removeDealList());
      dispatch(changeIsLoading(false));
      window.location.reload();
    }
  };

  if (!storeDT) return;

  const selectedDay = schedule?.selectedDay?.day
    ? schedule?.selectedDay?.day
    : moment(storeDT).format("ddd").toUpperCase();

  const CheckTradingPeriod = CalculateMenuTradingPeriod({
    selectedOrderStatus,
    tradingHoursData: deal?.tradingHours,
    tradingPeriodId: deal?.periodId,
    selectedTime: schedule?.selectedTime?.date
      ? schedule?.selectedTime?.date
      : storeDT,
    selectedDay,
    storeDT,
  });

  const CheckingTradingPeriodFromTheSelectedMenus = () => {
    let hasFalseStatus = false;

    deal?.dealItems?.some((dealItem) => {
      return dealItem?.menus?.some((menu) => {
        const tradingHours = menu?.tradingHours;
        const result = CalculateMenuTradingPeriod({
          selectedOrderStatus,
          tradingHoursData: tradingHours,
          tradingPeriodId: deal?.periodId,
          selectedTime: schedule?.selectedTime?.date
            ? schedule?.selectedTime?.date
            : storeDT,
          selectedDay,
          storeDT,
        });

        // Check if the status is false
        if (!result.status) {
          hasFalseStatus = true;
          return true; // Exit the inner some loop
        }
      });
    });

    return !hasFalseStatus;
  };

  const MenusCheckingCart = CheckingTradingPeriodFromTheSelectedMenus();

  // const CheckTradingPeriod = CalculateTradingPeriod({
  //   tradingPeriodId: deal.periodId,
  //   todayTradingHoursData,
  //   selectedOrderStatus,
  //   storeDT,
  // });

  return (
    <div className="py-4">
      <div className="flex items-center gap-2 justify-between pb-2">
        {(!CheckTradingPeriod.status || !MenusCheckingCart) && (
          <img
            src={"images/icons/red-close.svg"}
            alt="Close"
            width="20"
            height="20"
          />
        )}

        {deal?.imageUrl ? (
          <>
            <img
              src={deal?.imageUrl || ""}
              alt="image"
              width="80"
              height="50"
              className="rounded-md w-12 h-8 md:w-14 md:h-10 object-cover object-center"
            />
          </>
        ) : (
          <div className="w-14 h-10" />
        )}
        <div
          className={`${
            !CheckTradingPeriod.status ? "text-red-500" : ""
          } mr-auto`}
        >
          {formatString(deal?.name, 20)}
        </div>

        <div className=" grid grid-cols-[0.4fr_0.6fr_0.4fr_1fr] items-center justify-items-center  w-[47%]">
          <button
            type="button"
            onClick={() => OrderManage("REMOVE", deal)}
            disabled={isLoading}
            className={isLoading ? "cursor-wait" : ""}
          >
            <img
              src="/images/icons/minus.svg"
              alt="minus"
              width="30"
              height="30"
              className="bg-slate-200 p-2 rounded-full"
            />
          </button>
          <p className="text-sm">{deal?.qty}</p>
          <button
            type="button"
            onClick={() => OrderManage("ADD", deal)}
            disabled={isLoading}
            className={isLoading ? "cursor-wait" : ""}
          >
            <img
              src="/images/icons/add.svg"
              alt="add"
              width="30"
              height="30"
              className="bg-gray-300 p-2 rounded-md"
            />
          </button>
          <div className="ml-auto">
            <p className="text-xs text-bold">
              $ {fixedDecimal(deal?.totalPrice * deal?.qty)}
            </p>
            <DiscountPrice deal={deal} />
          </div>
        </div>
      </div>
      <div className="pl-8 text-sm flex flex-col">
        {deal?.dealItems
          ?.filter((el) => el?.menus?.length > 0)
          ?.map((dealItems, index) => {
            return (
              <Fragment key={index}>
                {dealItems?.config?.isSplit ? (
                  <div>
                    <li key={index} className="text-black list-disc">
                      <span> Half / Half</span>
                    </li>
                    <DealItemsCart dealItems={dealItems} />
                  </div>
                ) : (
                  <li key={index} className="text-black list-disc ">
                    <DealItemsCart dealItems={dealItems} />
                  </li>
                )}
              </Fragment>
            );
          })}
      </div>
      {(!CheckTradingPeriod.status || !MenusCheckingCart) && (
        <p className="pl-3 pt-2 text-xs text-red-500">
          Menu is not available in {selectedOrderStatus}
        </p>
      )}
      {deal?.note && (
          <div className="flex items-center space-x-2">
            <img
              src="/images/icons/receipt.svg"
              alt="L"
              width={15}
              height={15}
            />
            <p className="text-sm text-gray-500">{deal?.note}</p>
          </div>
        )}
    </div>
  );
}

function DealItemsCart({ dealItems }: { dealItems: DealItemsDetailsInCart }) {
  return (
    <div className="flex-col gap-2 inline-flex">
      {dealItems?.menus?.map((menu, index) => {
        return (
          <div key={index} className="flex flex-col py-2">
            <div className="flex items-center gap-2">
              {index === 0 && dealItems?.config?.isSplit ? (
                <img
                  src="/images/icons/leftHalf.svg"
                  alt="L"
                  width={15}
                  height={15}
                />
              ) : null}
              {index === 1 && dealItems?.config?.isSplit ? (
                <img
                  src="/images/icons/rightHalf.svg"
                  alt="L"
                  width={13}
                  height={13}
                />
              ) : null}
              <p>
                {menu?.menuSizeCount !== 1 && dealItems?.sizeName} {menu?.name}
              </p>
            </div>
            <div className="text-xs italic text-secondaryText">
              {menu?.optionGroups?.length
                ? menu?.optionGroups?.map((combo, index) => (
                    <ComboPortion
                      combo={combo}
                      isSplit={dealItems?.config?.isSplit || false}
                      key={index}
                    />
                  ))
                : null}
              <CartIngredients
                ingredients={menu?.ingredients}
                isSplit={dealItems?.config?.isSplit}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function DiscountPrice({ deal }: { deal: DealDetailsInCart }) {
  const finalPriceValueofFixed =
    deal?.dealType === "FIXED" && deal?.cartType === "Deal"
      ? deal?.dealItems?.reduce((acc, current) => {
          const currentValue = current?.menus?.reduce((accs, currents) => {
            return accs + currents?.finalPrice + (currents?.specialPrice || 0);
          }, 0);
          return acc + currentValue;
        }, 0)
      : 0;

  const finalPriceValueofDiscount =
    deal?.dealType === "PERCENT" && deal?.cartType === "Deal"
      ? ((deal?.discountRate || 0) / 100) *
        deal?.dealItems?.reduce((acc, current) => {
          const currentValue = current?.menus?.reduce((accs, currents) => {
            return accs + currents?.finalPrice;
          }, 0);
          return acc + currentValue;
        }, 0)
      : 0;

  const finalPrice =
    deal?.cartType === "Offer"
      ? deal?.dealItems?.reduce((acc, el) => {
          if (!el?.menus?.length) return acc;
          const finalPrice = el?.menus?.reduce((accs, els) => {
            return accs + els?.finalPrice;
          }, 0);
          return acc + finalPrice;
        }, 0)
      : 0;

  const finalPriceWithDiscount = deal?.dealItems?.reduce((acc, el) => {
    if (!el?.menus?.length) return acc;
    const discount = el?.discount || 0;
    const finalPriceMenu = el?.menus?.reduce((accs, els) => {
      return (els?.finalPrice || 0) + accs;
    }, 0);
    const discountFinalPrice =
      finalPriceMenu - (discount / 100) * finalPriceMenu;
    return acc + discountFinalPrice;
  }, 0);

  return (
    <>
      {deal?.cartType === "Deal" && deal?.dealType === "PERCENT" && (
        <p className="italic text-xs bg-primary p-1 text-white font-semibold rounded-md">
          ${fixedDecimal(finalPriceValueofDiscount)} off
        </p>
      )}
      {deal?.cartType === "Deal" && deal?.dealType === "FIXED" && (
        <p className="italic text-xs bg-primary p-1 text-white font-semibold rounded-md">
          ${(finalPriceValueofFixed - deal?.sellingPrice).toFixed(2)} off
        </p>
      )}

      {deal?.cartType === "Offer" && (
        <p className="italic text-xs bg-primary p-1 text-white font-semibold rounded-md">
          ${fixedDecimal(finalPrice - finalPriceWithDiscount)} off
        </p>
      )}
    </>
  );
}

export default AddToCartDealItems;
