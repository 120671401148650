
import {
  Header,
  OrderTypeButton,
  ScheduleButtonForm,
  SelectedTradingHours,
} from "./ScheduleButton";
import ScheduleButtonDateAndTimeSelect, {
  SelectedDayTimeList,
} from "./ScheduleButtonDateAndTimeSelect";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";

import { useFormContext, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getSchedules } from "@/app/_redux/slice/Order/OrderSlice";
import { changeSelectedOrderStatus } from "@/app/_redux/slice/UISlice/UISlice";

interface ScheduleModalProps {
  scheduleOpen: boolean;
  isDelivery: boolean;
  handleClose: () => void;
  handleSelectedTradingPeriod?: ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => Promise<void>;
}

const ScheduleModal = ({
  scheduleOpen,
  handleClose,
  handleSelectedTradingPeriod,
  isDelivery,
}: ScheduleModalProps) => {
  const dispatch = useDispatch();
  const methods = useFormContext<ScheduleButtonForm>();

  const { handleSubmit, control, watch } = methods;

  const selectedTime = useWatch({
    control,
    name: "selectedTime",
  });

  console.log("ScheduleButtonForm", watch());
  const onSubmit = (data: ScheduleButtonForm) => {
    dispatch(getSchedules(data));
    dispatch(changeSelectedOrderStatus(data?.orderType));
    localStorage?.setItem("selectedOrderStatus", data?.orderType);
    localStorage.setItem("scheduleData", JSON.stringify(data));
    const tradingPeriodId = data?.selectedDay?.tradingPeriodId;
    if (tradingPeriodId && handleSelectedTradingPeriod) {
      handleSelectedTradingPeriod({ tradingPeriodId: tradingPeriodId });
    }
    handleClose();
  };
  return (
    <CustomModalClients
      open={scheduleOpen}
      handleClose={handleClose}
      className="modal-white-parallel"
      // scrollDisable
    >
      <>
        <div className="flex justify-between items-center p-4 md:p-6 border-b-2 ">
          <h1 className="font-bold text-xl ">Schedule Date </h1>
          <button
            onClick={handleClose}
            // className={twMerge("lg:hidden", !isModal && "hidden")}
          >
            <img
              src="/images/icons/close.svg"
              width={25}
              height={25}
              alt="close"
            />
          </button>
        </div>
        <div className="px-4 md:px-6 h-[85%] flex flex-col ">
          <div className="sticky top-0 bg-white py-2">
            {/* <div className="flex justify-between gap-4 flex-col lg:flex-row"> */}
            <div className="flex flex-col justify-between gap-4">
              <div>
                {/* <h2 className="text-lg lg:text-xl font-semibold pb-4 capitalize">
                  Order Type
                </h2> */}
                <OrderTypeButton isDelivery={isDelivery}/>
              </div>
              <div>
                <Header />
                <SelectedTradingHours />
              </div>
            </div>
            <ScheduleButtonDateAndTimeSelect />
          </div>
          {/* <BottomFeature /> */}
          <SelectedDayTimeList />
          <div className="pt-2">
            <button
              onClick={handleSubmit(onSubmit)}
              className={`p-3 lg:p-4 border rounded-md text-sm lg:text-base ${
                selectedTime ? "bg-black" : "bg-neutral-500"
              } text-white`}
              disabled={selectedTime === null ? true : false}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    </CustomModalClients>
  );
};

export default ScheduleModal;
