import {
  AddMenuItemsForm,
} from "@/app/_types/menuDetails";
import { memo, useContext } from "react";
import { IngredientProvider } from "../../MenuDetailsForm";
import { useFormContext } from "react-hook-form";
import { Option, Option2, OptionGroup } from "@/app/_types/extraMenuPriceInfo";

import {
  AddMenuIngredientHandler,
  RemoveMenuIngredientsHandler,
} from "../../../utils/changeIngredientlogic";
import toast from "react-hot-toast";

interface MenuDetailsExtraOptionItem {
  item: Option;
  option: OptionGroup;
  optionName: Option2[];
  optionGroups: OptionGroup[];
}

function MenuDetailsExtraOptionItem({
  item,
  option,
  optionName,
  optionGroups,
}: MenuDetailsExtraOptionItem) {
  const ingredientContext = useContext(IngredientProvider);
  const { getValues, setValue } = useFormContext<AddMenuItemsForm>();

  const ingredientFields = ingredientContext?.fields;

  const optionDetails = optionName?.find((option) => option.id === item.id);
  const checkIngredient = ingredientFields?.find((obj) => obj?.id === item?.id);

  // const items: ManageExtrasIngredient = {
  //   id: item?.id,
  //   menuSizes: item?.sizes?.map((el) => ({ ...el, sizeId: el?.id })),
  //   name: optionDetails?.name || "",
  //   isSingle: item?.isSingle,
  // };
  if (!ingredientContext) return toast.error("Ing Context not working");

  return (
    <div className="flex justify-between px-4 py-2">
      <p
        className={
          checkIngredient && checkIngredient?.qty === 0
            ? "line-through text-red-400"
            : "no-underline"
        }
      >
        {optionDetails?.name}{" "}
      </p>
      <div className="flex items-center gap-2">
        {checkIngredient && checkIngredient?.qty > 0 && (
          <>
            <button
              onClick={() =>
                RemoveMenuIngredientsHandler(
                  ingredientContext,
                  getValues,
                  setValue,
                  {
                    id: item?.id,
                    menuSizes: item?.sizes?.map((el) => ({
                      ...el,
                      sizeId: el?.id,
                    })),
                    name: optionDetails?.name || "",
                    isSingle: item?.isSingle,
                    optionGroupId: option?.id,
                  }
                )
              }
            >
              <img
                src="/images/icons/minus.svg"
                alt="minus"
                width="30"
                height="30"
                className="bg-slate-200 p-2 rounded-full"
              />
            </button>
            <p>{checkIngredient?.qty}</p>
          </>
        )}
        <button
          onClick={() =>
            AddMenuIngredientHandler(
              ingredientContext,
              getValues,
              setValue,
              {
                id: item?.id,
                menuSizes: item?.sizes?.map((el) => ({
                  ...el,
                  sizeId: el?.id,
                })),
                name: optionDetails?.name || "",
                isSingle: item?.isSingle,
                optionGroupId: option?.id,
              },
              optionGroups
            )
          }
        >
          <img
            src="/images/icons/add.svg"
            alt="add"
            width="30"
            height="30"
            className="bg-gray-200 p-2 rounded-md"
          />
        </button>
      </div>
    </div>
  );
}

export default memo(MenuDetailsExtraOptionItem);
