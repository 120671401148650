import {
  MenuDealForm,
  MenuDealFormArray,
} from "@/app/_types/menuDeals.interface";
import MenuDealFormItem from "./MenuDealFormItem";
import { useFormContext, useWatch } from "react-hook-form";

import MenuDealAddToCart from "./MenuDealEditMenu/MenuDealCart/MenuDealAddToCart";
import MenuDealEditMenu from "./MenuDealEditMenu/MenuDealEditMenu";
import MenuDealSelectMenu from "./MenuDealSelectMenu/MenuDealSelectMenu";

interface MenuDealFormListProps {
  isModal: boolean;
}

function MenuDealFormList({ isModal }: MenuDealFormListProps) {
  const { control, register } = useFormContext<MenuDealForm>();
  const selectedPage = useWatch({
    control,
    name: "config.selectedPage",
  });

  const watchDealList: MenuDealFormArray[] = useWatch({
    control,
    name: "menuDeal",
  });

  function checkUI() {
    switch (true) {
      case selectedPage === "EDIT_MENU":
        return <MenuDealEditMenu />;
      case selectedPage === "SELECT_MENU":
        return <MenuDealSelectMenu />;
      default:
        return (
          <div className="bg-background p-4 flex flex-col gap-2 overflow-y-auto">
            {watchDealList?.map((dealCategories, index) => (
              <MenuDealFormItem
                key={index}
                dealCategories={dealCategories}
                mainIndex={index}
              />
            ))}
          </div>
        );
    }
  }

  return (
    <>
      <div>{checkUI()}</div>
      <div className="flex flex-col gap-4 py-4 px-2">
        <div className="flex items-center w-full p-2 rounded  border border-black">
          <input
            {...register(`note`)}
            className="w-full outline-none"
            type="text"
            placeholder="Notes"
          />
        </div>
      </div>
      <MenuDealAddToCart isModal={isModal} />
    </>
  );
}

export default MenuDealFormList;
