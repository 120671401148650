import MenuOfferDetails from "./components/MenuOfferDetails";
import GoBackButtonForItems from "@/app/[location]/components/GoBackButtonForItems";
import { useParams } from "react-router-dom";
import NotFound404 from "@/app/global/NoData/NotFound404";
import { useEffect, useRef } from "react";

function OfferDetailsPage() {
  const { name, location, periodId } = useParams<{
    name: string;
    location: string;
    periodId: string;
  }>();
  const headingRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!headingRef) return;
    headingRef?.current?.scrollIntoView({ behavior: "smooth" });
    window.scrollTo(0, 0);
  }, [headingRef]);

  if (!name || !location || !periodId) {
    return <NotFound404 />;
  }
  return (
    <div className="w-full md:w-3/4 m-auto my-4 min-h-[70vh]" ref={headingRef}>
      <GoBackButtonForItems />
      <MenuOfferDetails params={{ name, location, periodId }} isModal={false} />
    </div>
  );
}

export default OfferDetailsPage;
