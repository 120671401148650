import Logo from "@/app/global/logo/Logo";
import UserDetailsNavItems from "./UserDetailsNavItems";
import { useQueryClient } from "@tanstack/react-query";
import { CustomerInfo } from "@/app/_types/customer.interface";

function LogoNavBar() {
  const client = useQueryClient();
  const data = client.getQueryData<CustomerInfo>(["currentCustomer"]);

  return (
    <nav className="navbars">
      <ul className="  h-full flex items-center  px-2 md:px-4 w-full ">
        <li className="flex items-center w-fit  h-[inherit] p-0">
          <Logo />
        </li>
        {data?.firstName ? (
          <ol className="md:child:p-2 flex-1 justify-end h-[inherit] flex items-center gap-2">
            <UserDetailsNavItems customerDetails={data}/>
          </ol>
        ) : null}
      </ul>
    </nav>
  );
}

export default LogoNavBar;
