import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
import CustomRadioButtonClick from "@/app/global/custom/CustomRadioButtonClick";
import { useFormContext, useWatch } from "react-hook-form";
import CheckoutManageOrderType from "./CheckoutManageOrderType";
import { changeSelectedOrderStatus } from "@/app/_redux/slice/UISlice/UISlice";
import { useAppDispatch } from "@/app/_redux/store";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  getSchedules,
  order,
  scheduleModel,
} from "@/app/_redux/slice/Order/OrderSlice";
import { useQueryClient } from "@tanstack/react-query";
import { CustomerInfo } from "@/app/_types/customer.interface";

const ORDER_TYPE = [
  { id: 1, name: "Delivery", value: "DELIVERY" },
  { id: 2, name: "Pickup", value: "STORE" },
];

const ORDER_PICKUP_TYPE = [{ id: 2, name: "Pickup", value: "STORE" }];

function CheckoutManageDelivery() {
  const dispatch = useAppDispatch();
  const { schedule, OrderLocation } = useSelector(order);

  const { setValue, getValues, control } = useFormContext<ConfirmOrderForm>();

  const watchOrderValid = useWatch({
    control,
    name: "config.isOrderForFuture",
  });

  const deliveryDistancePossible = useWatch({
    control,
    name: `deliveryOrderDistance`,
  });

  const addressInfo = useWatch({
    control,
    name: `customer`,
  });

  const addressType = useWatch({
    control,
    name: `addressType`,
  });
  console.log(addressInfo);
  console.log(addressType);

  const client = useQueryClient();
  const auth = client.getQueryData<CustomerInfo>(["currentCustomer"]);

  const OrderTypeChangeHandler = () => {
    setValue("scheduledDT", null);
    setValue("config.isOrderForFuture", false);

    const orderType = getValues("orderType");
    localStorage.removeItem("scheduleData");
    console.log(orderType);
    if (orderType === "STORE") {
      localStorage?.setItem("selectedOrderStatus", "PICKUP");
      dispatch(changeSelectedOrderStatus("PICKUP"));
      console.log(orderType);
      console.log(schedule?.orderType);
      if (schedule?.orderType !== "PICKUP" && schedule !== null) {
        console.log("click");
        dispatch(getSchedules(null));
        dispatch(scheduleModel(true));
      }
    } else {
      localStorage?.setItem("selectedOrderStatus", "DELIVERY");
      dispatch(changeSelectedOrderStatus("DELIVERY"));
      console.log(orderType);
      if (schedule?.orderType !== "DELIVERY" && schedule !== null) {
        dispatch(getSchedules(null));
        dispatch(scheduleModel(true));
      }
    }
    const customerDetails = getValues("customer");

    const checkUser =
      (orderType === "DELIVERY" &&
        customerDetails?.firstName &&
        customerDetails?.address?.[0]?.address?.id) ||
      (orderType === "STORE" && customerDetails?.firstName)
        ? true
        : false;
    if (!checkUser) {
      setValue("config.isUserInformationEdit", true);
      toast.error("Please Provide All the Information");
      return;
    }
    setValue("config.isUserInformationEdit", false);
  };

  return (
    <div className="flex flex-col gap-4 divide-y-2 relative">
      {auth?.firstName && (
        <div className="py-2 flex flex-col gap-2">
          <h2 className="font-bold text-lg">Choose Your Order Type</h2>
          <div className="flex flex-col gap-2">
            <div className="flex gap-4">
              {(OrderLocation?.isDeliveryEnabled !== false
                ? ORDER_TYPE
                : ORDER_PICKUP_TYPE
              )?.map((type, index) => (
                <CustomRadioButtonClick
                  key={index}
                  id={type?.id}
                  name="orderType"
                  value={type?.value}
                  onChange={OrderTypeChangeHandler}
                  watchOrderValid={watchOrderValid}
                >
                  {type?.name}
                </CustomRadioButtonClick>
              ))}
            </div>
            {!deliveryDistancePossible && (
              //  <p className="text-red-500 text-sm">Delivery is currently not available in the {currentAddress?.streetName || currentAddress?.suburbName || "selected location"}.</p>
              <p className="text-red-500 text-sm">
                The address you selected is not available. Please choose a different one.
              </p>
            )}
          </div>
          <CheckoutManageOrderType />
        </div>
      )}

      {!auth && <div className="absolute bg-black/60 inset-0 rounded-md" />}
    </div>
  );
}

export default CheckoutManageDelivery;
