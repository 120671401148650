import CustomRadioButtonClick from "@/app/global/custom/CustomRadioButtonClick";
import { useEffect, useState } from "react";
import CheckoutPaymentMethod from "./CheckoutPayment/CheckoutPaymentMethod";
import { useFormContext, useWatch } from "react-hook-form";
import { ConfirmOrderForm } from "@/app/_types/checkout.interface";

// import fetchRequest from "@/app/_axios/fetchRequest";
// import { serverPath } from "@/app/_axios/path";
import { CalculateOrderManagement } from "@/app/[location]/components/MenuTradingPeriods/CalculateTradingPeriod";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { CheckoutPageDetails } from "@/app/_redux/slice/UISlice/UISlice.d";
import { useQueryClient } from "@tanstack/react-query";

const PAYMENT_METHOD = [
  { id: 1, method: "card", value: "STRIPE" },
  { id: 2, method: "paypal", value: "PAYPAL" },
  { id: 3, method: "paylater", value: "PAY LATER" },
];

function CheckoutPayment() {
  const { storeDT } = useSelector(selectUI);
  const [OrderManagement, setOrderManagement] = useState<{
    status: boolean;
    activationTime?: Date | undefined;
  }>({ status: true });

  const { control } = useFormContext<ConfirmOrderForm>();
  const { OrderLocation, schedule } = useSelector(order);

  const isUserEdit = useWatch({
    control,
    name: "config.isUserInformationEdit",
  });

  const storeLocationId = useWatch({
    control,
    name: `storeLocationId`,
  });

  const OrderType = useWatch({
    control,
    name: `orderType`,
  });

  const selectedDate = schedule?.selectedTime?.date
    ? schedule?.selectedTime?.date
    : storeDT;

  const body = {
    storeId: storeLocationId,
    // storeDt: selectedDate,
    // tradingPeriodId: OrderList[0]?.periodId || dealList[0]?.periodId,
  };

  const client = useQueryClient();
  const checkoutPageDetails = client.getQueryData<CheckoutPageDetails>([
    "getCheckoutPageDetails",
    body,
  ]);

  useEffect(() => {
    if (!storeLocationId) return;

    const fetchReActivationTimes = () => {
      const reActivationTimesData = checkoutPageDetails?.reactivationTimes;
      if (!reActivationTimesData) return;
      const OrderManagementDetail = CalculateOrderManagement({
        selectedOrderStatus: OrderType,
        storeDT: selectedDate,
        reActivationTimesData,
      });

      if (OrderManagementDetail !== undefined) {
        setOrderManagement(OrderManagementDetail);
      } else {
        setOrderManagement({ status: true });
      }
      //   try {
      //     const [reActivationTimesData] = await Promise.all([
      //       fetchRequest(
      //         serverPath?.getReActivationTimes(storeLocationId),
      //         "GET",
      //         undefined,
      //         {
      //           cache: "no-cache",
      //         }
      //       ),
      //     ]);

      //     const OrderManagementDetail = CalculateOrderManagement({
      //       selectedOrderStatus: OrderType,
      //       storeDT,
      //       reActivationTimesData,
      //     });

      //     if (OrderManagementDetail !== undefined) {
      //       setOrderManagement(OrderManagementDetail);
      //     } else {
      //       setOrderManagement({ status: true });
      //     }

      //     console.log(reActivationTimesData);
      //     console.log(OrderManagementDetail);
      //   } catch (error) {
      //     console.error("Error fetching reActivationTimes:", error);
      //   }
    };

    fetchReActivationTimes();
  }, [
    OrderType,
    checkoutPageDetails?.reactivationTimes,
    selectedDate,
    storeLocationId,
  ]);

  return (
    <div className="relative">
      <h2 className="font-bold text-lg pb-4">Make Payment</h2>
      <div className="flex flex-col gap-4 divide-y-2">
        {!OrderManagement?.status ? (
          <div className="flex justify-center items-center">
            <p className="text-red-500 text-2xl">{OrderType} is Paused</p>
          </div>
        ) : (
          <>
            <div className="flex gap-4 flex-wrap">
              {PAYMENT_METHOD?.map((payment, index) => {
                return (
                  <CustomRadioButtonClick
                    name="paymentGatewayType"
                    id={`card-payment-${payment?.id}`}
                    value={payment?.value}
                    key={index}
                    parentStyle={`${
                      (payment?.method === "paylater" && !OrderLocation?.isPayLaterEnabled) ||
                      (payment?.method === "paypal" && !OrderLocation?.isPaypalPaymentEnabled) || 
                      (payment?.method === "card" && OrderLocation?.isStripeEnabled === false)
                        ? "hidden"
                        : ""
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      {payment?.method === "paypal" && (
                        // <Icon icon="logos:paypal" width={20} />
                        <img
                          src="/images/icons/paypal.svg"
                          width="20"
                          height="20"
                          alt="paypal"
                        />
                      )}
                      {payment?.method === "card" && (
                        // <Icon icon="fluent-mdl2:payment-card" width={20} />
                        <img
                          src="/images/icons/creditCard.png"
                          alt="card"
                          width={30}
                          height={30}
                        />
                      )}
                      {payment?.method === "paylater" && (
                        // <Icon icon="fluent-mdl2:payment-card" width={20} />
                        // <Icon
                        //   icon="uiw:pay"
                        //   width="1.2rem"
                        //   height="1.2rem"
                        //   style={{ color: "#5ba7e1" }}
                        // />
                        <img
                          src="/images/icons/paylater.svg"
                          alt="card"
                          width={20}
                          height={20}
                        />
                      )}

                      <p>
                        {payment?.value === "STRIPE" ? "CARD" : payment?.value}
                      </p>
                    </div>
                  </CustomRadioButtonClick>
                );
              })}
            </div>
            <CheckoutPaymentMethod />
          </>
        )}
      </div>
      {isUserEdit === true && (
        <div className="absolute bg-black/60 inset-0 rounded-md" />
      )}
    </div>
  );
}

export default CheckoutPayment;
