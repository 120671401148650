import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";
import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import toast from "react-hot-toast";
import {
  axiosErrorMessage,
  AxiosErrorMessage,
} from "@/app/_axios/handleAxiosError";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";

interface CateringFormProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  phone: Yup.string().required(),
  email: Yup.string().email().required(),
  address: Yup.string().required(),
  isPrivateLand: Yup.boolean().required(),
  eventType: Yup.string().required(),
  noOfGuest: Yup.number().typeError("").required().min(5, ""),
  date: Yup.string().required(),
  time: Yup.string().required(),
});

type ValidationSchemaType = Yup.InferType<typeof validationSchema>;

const CateringForm = ({ open, setOpen }: CateringFormProps) => {
  const { storeLogo } = useSelector(selectUI);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const now = new Date();
    setCurrentDate(now.toISOString().split("T")[0]);
  }, []);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      address: "",
      isPrivateLand: true,
      eventType: "",
      noOfGuest: 1,
      date: "",
      time: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    setOpen(false);
  };

  const { mutate } = useMutation({
    mutationKey: ["sendCateringOrder"],
    mutationFn: (data: ValidationSchemaType) =>
      fetchRequest(
        serverPath?.sendCateringOrder,
        "POST",
        {
          ...data,
          storeGroupId: import.meta.env.VITE_STORE_GROUP_ID,
        },
        {
          "Content-Type": "application/json",
        }
      ),
    onSuccess: () => {
      toast.success("Catering Order has been successfully sent");
      handleClose();
    },
    onError: (error: AxiosErrorMessage) => {
      toast.error(
        axiosErrorMessage(error || "Something went Wrong. Please Try Again.")
      );
    },
  });

  const onSubmit = (data: ValidationSchemaType) => {
    mutate(data);
  };

  console.log(errors);

  return (
    <CustomModalClients
      open={open}
      handleClose={handleClose}
      className="p-3 bg-gray-100 w-screen sm:w-4/5 md:w-3/4 lg:w-7/12"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 p-6 bg-white rounded-md shadow-md  max-w-4xl mx-auto"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-gray-700">Catering Form</h2>
            <button onClick={handleClose}>
              <img
                src="/images/icons/close.svg"
                width={25}
                height={25}
                alt="close"
              />
            </button>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={storeLogo?.logoImageUrl}
              alt="Shop Logo"
              className="h-16 w-auto mb-4"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-600"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.name ? "border-red-500" : ""
                }`}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="phone"
                className="text-sm font-medium text-gray-600"
              >
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                {...register("phone")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.phone ? "border-red-500" : ""
                }`}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="address"
                className="text-sm font-medium text-gray-600"
              >
                Address
              </label>
              <input
                type="text"
                id="address"
                {...register("address")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.address ? "border-red-500" : ""
                }`}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="isPrivateLand"
                className="text-sm font-medium text-gray-600"
              >
                Is the Event Held on Private Land?
              </label>
              <select
                id="isPrivateLand"
                {...register("isPrivateLand")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.isPrivateLand ? "border-red-500" : ""
                }`}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="eventType"
                className="text-sm font-medium text-gray-600"
              >
                What is the Event For?
              </label>
              <input
                type="text"
                id="eventType"
                {...register("eventType")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.eventType ? "border-red-500" : ""
                }`}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="noOfGuest"
                className="text-sm font-medium text-gray-600"
              >
                Number of Guests
              </label>
              <input
                type="number"
                id="noOfGuest"
                {...register("noOfGuest")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.noOfGuest ? "border-red-500" : ""
                }`}
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="time"
                className="text-sm font-medium text-gray-600"
              >
                Event Time
              </label>
              <input
                type="time"
                id="time"
                {...register("time")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.time ? "border-red-500" : ""
                }`}
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="date"
                className="text-sm font-medium text-gray-600"
              >
                Event Date
              </label>
              <input
                type="date"
                id="date"
                min={currentDate}
                {...register("date")}
                className={`p-2 border rounded-md focus:outline-none ${
                  errors.date ? "border-red-500" : ""
                }`}
              />
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </FormProvider>
    </CustomModalClients>
  );
};

export default CateringForm;
