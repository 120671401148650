import { termsData } from "./TermandCondition";

function TermsAndConditionPage() {
  return (
    <div className="">
      {/* <LogoNavBar /> */}
      <div className="px-20">
      <h1 className="font-bold text-5xl">Terms and Conditions</h1>
      {termsData?.map((section, index) => (
        <div key={index} className="py-5">
          <h1 className="font-bold text-4xl"> {section.title}</h1>
          {section.content && (
            <p className="py-5" dangerouslySetInnerHTML={{ __html: section.content }}></p>
          )}
          {section.subsections && (
            <div className="terms-subsections">
              {section.subsections.map((subsection, subIndex) => (
                <div key={subIndex} className="terms-subsection">
                  {subsection.title && <h1 className="font-bold py-5"> {subsection.title}</h1>}
                  <p dangerouslySetInnerHTML={{ __html: subsection.content}}></p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
    </div>
  );
}

export default TermsAndConditionPage;
