import { Hero } from "@/app/_types/EcommerceTheme.interface";
import { useEffect, useState } from "react";
import { useTransition, animated } from "@react-spring/web";

function HomePageHeaderBackGround({ hero }: { hero: Hero }) {
  const [index, setIndex] = useState(0);

  // Define transitions for fade in and out
  const transitions = useTransition(hero?.imagesData[index], {
    key: index, // Ensure unique key for each media item
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  });

  // Automatically go to the next media every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % hero?.imagesData.length);
    }, 3000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [hero?.imagesData.length]);
  return (
    <div>
      <div className="absolute right-0 bottom-0 w-full h-full -z-[1] overflow-hidden">
        <div className="absolute right-0 bottom-0 w-full h-full -z-[-3] overflow-hidden bg-[rgba(0,0,0,0.7)]" />
        {hero?.status?.text === "BACKGROUND" &&
          transitions((style, imgData) => (
            <animated.div style={style} className="absolute w-full h-full">
              {imgData?.image?.map((img) => (
                <div key={img?.id} className="w-full h-full">
                  {imgData?.status === "VIDEO" ? (
                    <video
                      autoPlay
                      loop
                      playsInline
                      muted
                      className="object-cover w-full h-full"
                      preload="metadata"
                    >
                      <source src={img?.file} />
                    </video>
                  ) : (
                    <img
                      src={img?.file}
                      width={1000}
                      height={1000}
                      className="object-cover object-center rounded-md homepage-shadows w-full h-[100vh]"
                      alt="Images"
                    />
                  )}
                </div>
              ))}
            </animated.div>
          ))}

        {!hero?.status?.text ? (
          <div>
            <img
              src={"/logo/restologo.png"}
              width={1000}
              height={1000}
              className="object-cover object-center rounded-md homepage-shadows w-full h-[100vh]"
              alt="Images"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default HomePageHeaderBackGround;
