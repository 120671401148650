import MenuDetailsCurrentOptionItem from "./MenuDetailsCurrentOptionItem";
import { OptionGroup } from "@/app/_types/extraMenuPriceInfo";
import { useFormContext, useWatch } from "react-hook-form";
import { AddMenuItemsForm } from "@/app/_types/menuDetails";

function MenuDetailsCurrentOption({
  optionGroups,
}: {
  optionGroups: OptionGroup[];
}) {
  // const ingredientContext = useContext(IngredientProvider);
  // const ingredientFields = ingredientContext?.fields || [];

  const { control } = useFormContext<AddMenuItemsForm>();

  const watchSelectedIndex = useWatch({
    control,
    name: "config.selectedIndex",
  });

  const ingredientFields = useWatch({
    control,
    name: `data.${watchSelectedIndex}.ingredients`,
  });

  console.log(ingredientFields);

  return (
    <>
      {ingredientFields?.length > 0 && (
        <div className="px-4">
          <p className="text-lg font-bold">Current Toppings</p>
          <div className="divide-y-2">
            {ingredientFields
              ?.filter((item) => item.status === "current")
              .map((option, index: number) => (
                <MenuDetailsCurrentOptionItem
                  item={option}
                  key={index}
                  isExtraOption={false}
                  optionGroups={optionGroups}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default MenuDetailsCurrentOption;
