import {
  AddMenuItemsForm,
  MenuOptionGroupOption,
} from "@/app/_types/menuDetails";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

export function getTotalComboPriceOfSingleData(
  getValues: UseFormGetValues<AddMenuItemsForm>,
  setValue: UseFormSetValue<AddMenuItemsForm>,
  // comboType: "AVERAGE" | "HIGHEST",
  selectedIndex: number
) {
  const comboList = getValues(`data.${selectedIndex}.optionGroups`);

  const finalPrice = comboList?.reduce((acc, current, index) => {
    const comboItems = current?.options;

    const comboItemsPrice = comboItems?.reduce((accs, current) => {
      return accs + (current?.price || 0);
    }, 0);

    setValue(
      `data.${selectedIndex}.optionGroups.${index}.itemsPrice`,
      comboItemsPrice
    );

    return acc + comboItemsPrice;
  }, 0);
  setValue(`data.${selectedIndex}.comboPrice`, finalPrice);
}

export function calculatingIsFirstFreeAndGettingPrice(
  items: MenuOptionGroupOption,
  newQty: number,
  sizePrice: number
) {
  const newPrice =
    newQty === 0
      ? 0
      : items?.isFirstFree
      ? (newQty - 1) * sizePrice
      : newQty * sizePrice;

  return newPrice;
}
