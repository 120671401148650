import { AddMenuItemsForm } from "@/app/_types/menuDetails";

import { useNavigate } from "react-router-dom";

import { useFormContext, useWatch } from "react-hook-form";

function MenuDetailsTitle({ isModal }: { isModal: boolean }) {
  const { control } = useFormContext<AddMenuItemsForm>();
  const router = useNavigate();

  const watchConfig = useWatch({
    control,
    name: "config",
  });
  const watchData = useWatch({
    control,
    name: "data",
  });

  const watchDescription = useWatch({
    control,
    name: `data.${watchConfig?.selectedIndex || 0}.description`,
  });

  let name:string = "";
  console.log(name);

  const getName = () => {
    switch (true) {
      case !watchConfig?.isSplit:
        return (name = watchData?.[watchConfig?.selectedIndex || 0]?.name);
      case watchConfig?.isSplit && watchConfig?.canAddToCart:
        return (name = "Choose Your Items");
      case watchConfig?.isSplit && !watchConfig?.canAddToCart:
        return (name = `1/2 ${
          watchData?.[watchConfig?.selectedIndex || 0]?.name
        }`);
    }
  };

  const CloseHandler = () => {
    router(-1);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-2xl  font-extrabold">{getName()}</h1>
        {isModal ? (
          <button onClick={CloseHandler}>
            <img src="/images/icons/cross.svg" width="20" height="20" alt="x" />
          </button>
        ) : null}
      </div>
      <p className="text-sm">{watchDescription}</p>
    </>
  );
}

export default MenuDetailsTitle;
