import {
  UpdateCartInformation,
  updateCartInformation,
} from "@/app/_axios/serverApi";
import { updateOrderList } from "@/app/_redux/slice/Order/OrderSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { AddMenuItemsForm } from "@/app/_types/menuDetails";

import { useParams, useNavigate } from "react-router-dom";
import { memo, useCallback, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";

function AddMenuCartButton({ isModal }: { isModal: boolean }) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { control, setValue, handleSubmit } =
    useFormContext<AddMenuItemsForm>();

  const watchData = useWatch({
    control,
    name: "data",
  });

  const watchConfig = useWatch({
    control,
    name: "config",
  });

  const watchDataItem = useWatch({
    control,
    name: `data.${watchConfig?.selectedIndex || 0}.optionGroups`,
  });

  // const isWatchData = watchData?.map((el) => el?.id).includes(null);

  const isValid = () => {
    const isTrueList: boolean[] = [];
    watchData?.forEach((item) => {
      const comboList = item?.optionGroups;
      comboList?.forEach((option) => {
        const isMandatory = option?.isMandatory;
        if (isMandatory) {
          const isComboValid = option?.options?.some(
            (option) => option?.qty > 0
          );

          if (isComboValid) {
            isTrueList.push(true);
          } else {
            isTrueList.push(false);
          }
        } else {
          isTrueList.push(true);
        }
      });
    });
    const checkIsTrueList = isTrueList.every((item) => item === true);
    return checkIsTrueList;
  };

  const isComboNotOptional = () =>
    watchDataItem
      ?.map((comboItem) => {
        if (!comboItem?.isMandatory) return true;
        const items = comboItem?.options?.some((el) => el?.qty > 0);
        return items;
      })
      .includes(false);

  const router = useNavigate();

  const onDismiss = useCallback(() => {
    router(-1);
  }, [router]);

  const OnConfirm = () => {
    setValue(`config.canAddToCart`, true);
  };

  const params: { location?: string; name?: string; periodId?: string } =
    useParams();

  const AddToCartHandler = (data: AddMenuItemsForm) => {
    const getToken = localStorage.getItem("cart") || "";

    console.log("Menu Add to details", data);

    const finalData: UpdateCartInformation = {
      data,
      status: "NEW",
      itemStatus: "MENU",
      location: params?.location,
    };

    setIsLoading(true);
    const res = updateCartInformation(finalData, getToken);

    if (res?.status === 200) {
      const datas: { message: string; newToken: string } = res;
      localStorage.setItem("cart", datas?.newToken);
      dispatch(updateOrderList(data));
      setIsLoading(false);
      toast.success("Item added to cart");
    } else {
      toast.error(
        "Due to some error all orders are removed. Please Place Order Again."
      );
      localStorage.removeItem("cart");
      setIsLoading(false);
      return;
    }

    if (isModal) {
      onDismiss();
    } else {
      // const referrer = document.referrer;
      // if (referrer && referrer.includes(window.location.origin)) {
      //   router.back();
      // } else {
      //   router(`/${params?.location}?tradingPeriodId=${params?.periodId}`);
      // }
      const backPath = `/${params?.location}`;
      router(backPath, { state: { scrollId: params?.name } });
    }
  };
  return (
    <>
      {watchConfig?.canAddToCart ? (
        <button
          className={twMerge(
            "bg-[rgba(141,190,73)] text-white p-3 mr-4 rounded-lg flex items-center gap-2 justify-center w-full",
            !isValid() && "bg-gray-200"
          )}
          onClick={handleSubmit(AddToCartHandler)}
          disabled={isLoading || !isValid()}
        >
          <img
            src="/images/icons/cart-white.svg"
            width="20"
            height="20"
            alt="x"
            className="text-white"
          />
          Add to Cart
        </button>
      ) : (
        <button
          className={twMerge(
            "bg-[rgba(141,190,73)] p-3 mr-4 rounded-lg flex items-center gap-2 justify-center w-full text-white",
            isComboNotOptional() && "bg-gray-200"
          )}
          onClick={OnConfirm}
          disabled={isComboNotOptional()}
        >
          <img
            src="/images/icons/cart-white.svg"
            width="20"
            height="20"
            alt="x"
            className="text-white"
          />
          Confirm
        </button>
      )}
    </>
  );
}

export default memo(AddMenuCartButton);
