import CartNavBar from "../component/NavBar/CartNavBar";
import LogoNavBar from "../component/NavBar/LogoNavBar";

function AuthNavbarProvider({
  children,
  isLogoNavbarOnly,
}: {
  children: React.ReactNode;
  isLogoNavbarOnly?: boolean;
}) {
  return (
    <>
      {isLogoNavbarOnly ? <LogoNavBar /> : <CartNavBar />}
      {children}
    </>
  );
}

export default AuthNavbarProvider;
