import { useMemo } from "react";
import NotFound404 from "../global/NoData/NotFound404";
import { serverPath } from "../_axios/path";

import { useQueries } from "@tanstack/react-query";
import MenuDetailsInfo from "./MenuDetailsInfo";
import CategoryListHeaders from "./components/Headers/CategoryListHeaders";
import LocationRedux from "./deal/[periodId]/[name]/components/LocationRedux";
import CategoryMenuInRedux from "./components/CategoryMenuInRedux";
import LocationLoading from "./loading";
import { StoreEntity, StoreEntityDetails } from "../_types/store.interface";
import fetchRequest from "../_axios/fetchRequest";
import AddtoCartMiniButton from "./components/AddToCart/AddtoCartMiniButton";
import CartExistsOtherLocation from "./components/AddToCart/CartExistsOtherLocation";

interface LocationDetails {
  params: { location: string };
  findStore: StoreEntity;
  searchParams: { tradingPeriodId: string | null };
  getStore: StoreEntity[];
  getStoreDetails: StoreEntityDetails[];
}

const LocationDetails = ({
  params,
  findStore,
  searchParams,
  getStoreDetails,
  getStore,
}: LocationDetails) => {
  const [
    DataQuery,
    tradingHoursQuery,
    todayTradingHoursQuery,
    estimatedTimeDataQuery,
    storeDateQuery,
    reActivationTimesQuery,
    EcommerceStoreImageQuery,
    deliveryInfoQuery,
  ] = useQueries({
    queries: [
      {
        queryKey: ["categoriesWithMenu", findStore?.id],
        queryFn: () =>
          fetchRequest(
            serverPath?.categoriesWithMenu(
              findStore?.id,
              searchParams?.tradingPeriodId),
            "GET"
          ),
        // enabled: Boolean(findStore?.id && searchParams?.tradingPeriodId),
      },
      {
        queryKey: ["tradingHours", findStore?.id],
        queryFn: () =>
          fetchRequest(serverPath?.tradingHours(findStore?.id), "GET"),
      },
      {
        queryKey: ["todayTradingHours", findStore?.id],
        queryFn: () =>
          fetchRequest(serverPath?.todayTradingHours(findStore?.id), "GET"),
      },
      {
        queryKey: ["getEstimatedTime", findStore?.id],
        queryFn: () =>
          fetchRequest(serverPath?.getEstimatedTime(findStore?.id), "GET"),
      },
      {
        queryKey: ["getStoreDT", findStore?.id],
        queryFn: () =>
          fetchRequest(serverPath?.getStoreDT(findStore?.id), "GET"),
      },
      {
        queryKey: ["getReActivationTimes", findStore?.id],
        queryFn: () =>
          fetchRequest(serverPath?.getReActivationTimes(findStore?.id), "GET"),
      },
      {
        queryKey: ["getEcommerceStoreImageData", findStore?.id],
        queryFn: () =>
          fetchRequest(
            serverPath?.getEcommerceStoreImageData(findStore?.id),
            "GET"
          ),
      },
      {
        queryKey: ["getDeliveryInfo", findStore?.id],
        queryFn: () =>
          fetchRequest(
            serverPath?.deliveryInfo(findStore?.id),
            "GET"
          ),
      },
    ],
  });

  const isLoading = useMemo(() => {
    return [
      DataQuery,
      tradingHoursQuery,
      todayTradingHoursQuery,
      estimatedTimeDataQuery,
      storeDateQuery,
      reActivationTimesQuery,
      EcommerceStoreImageQuery,
      deliveryInfoQuery,
    ]?.some((query) => query?.isLoading);
  }, [
    DataQuery,
    tradingHoursQuery,
    todayTradingHoursQuery,
    estimatedTimeDataQuery,
    storeDateQuery,
    reActivationTimesQuery,
    EcommerceStoreImageQuery,
    deliveryInfoQuery,
  ]);

  if (isLoading) return <LocationLoading />;

  if (
    !DataQuery?.data ||
    !tradingHoursQuery?.data ||
    !todayTradingHoursQuery?.data ||
    !estimatedTimeDataQuery?.data ||
    !storeDateQuery?.data ||
    !reActivationTimesQuery?.data ||
    !EcommerceStoreImageQuery?.data
  )
    return <NotFound404 />;

  console.log(storeDateQuery);

  const storeDate = storeDateQuery?.data?.storeCurrentDt;
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {todayTradingHoursQuery?.data?.length < 1 ||
      !storeDateQuery?.data ||
      !estimatedTimeDataQuery?.data ? (
        <div>
          <LocationLoading />
        </div>
      ) : (
        <CategoryMenuInRedux
          storeList={getStore}
          selectedStore={findStore}
          todayTradingHours={todayTradingHoursQuery?.data}
          tradingHours={tradingHoursQuery?.data}
          storeDetailList={getStoreDetails}
        >
          <LocationRedux
            estimatedTime={estimatedTimeDataQuery?.data}
            storeDT={storeDate}
            storeId={findStore?.id}
          >
            <div className="maxContainer">
              <CategoryListHeaders
                data={DataQuery?.data}
                store={findStore}
                tradingHours={tradingHoursQuery?.data}
                estimatedTime={estimatedTimeDataQuery?.data}
                storeImages={EcommerceStoreImageQuery?.data}
                storeDT={storeDate}
              />
              <MenuDetailsInfo
                data={DataQuery?.data}
                location={params?.location}
                reActivationTimesData={reActivationTimesQuery?.data}
                tradingHours={tradingHoursQuery?.data}
                store={findStore}
                storeDT={storeDate}
                estimatedTime={estimatedTimeDataQuery?.data}
              />
            </div>
          </LocationRedux>
          <AddtoCartMiniButton/>
        </CategoryMenuInRedux>
      )}
      <CartExistsOtherLocation location={params?.location} />
    </div>
  );
};

export default LocationDetails;
