import HeroOrderNowButton from "../NavBar/HeroOrderNowButton";
import { twMerge } from "tailwind-merge";
import MediaList from "./MediaList";
import { Hero, SelectedColor } from "@/app/_types/EcommerceTheme.interface";
import { StoreEntity } from "@/app/_types/store.interface";

function HomePageOtherHeader({
  hero,
  color,
  storeList,
}: {
  hero: Hero;
  color: SelectedColor;
  storeList: StoreEntity[];
}) {
  return (
    <header className=" min-h-[calc(100vh-5rem)] flex ">
      <div
        className={twMerge(
          "w-1/2 flex flex-col",
          hero?.design?.contentStatus === "CENTER" &&
            "justify-center items-center w-full text-center"
        )}
      >
        <div className="px-4 md:px-8 pt-20">
          <div className="sm:w-full homepage-headings flex flex-col gap-4">
            {hero?.titles?.map((title, index) => (
              <div
                key={index}
                className="responsive-heading"
                dangerouslySetInnerHTML={{ __html: title?.title }}
              />
            ))}
          </div>

          <div
            className={`flex gap-6 mt-6 ${
              hero?.design?.contentStatus === "CENTER" && "justify-center"
            } `}
          >
            <HeroOrderNowButton
              bgColor={color?.secondary}
              storeList={storeList}
            />
          </div>
        </div>
      </div>
      {hero?.status?.text === "RIGHT_SIDE" && (
        <>
          <MediaList MediaLists={hero?.imagesData} />

          <div className="absolute z-[-2] scale-x-[-1] bottom-[0rem] right-0 left-0 ">
            <svg
              width="100%"
              height="100%"
              id="svg"
              viewBox="0 0 1440 690"
              xmlns="http://www.w3.org/2000/svg"
              className="transition duration-300 ease-in-out delay-150"
            >
              <path
                d="M 0,700 L 0,262 C 150.89285714285717,219.05357142857144 301.78571428571433,176.10714285714286 423,199 C 544.2142857142857,221.89285714285714 635.7499999999999,310.625 743,356 C 850.2500000000001,401.375 973.2142857142858,403.39285714285717 1092,418 C 1210.7857142857142,432.60714285714283 1325.392857142857,459.80357142857144 1440,487 L 1440,700 L 0,700 Z"
                stroke="none"
                stroke-width="0"
                fill={color?.primary}
                fill-opacity="1"
                className="transition-all duration-300 ease-in-out delay-150 path-0"
              ></path>
            </svg>
          </div>
        </>
      )}
    </header>
  );
}

export default HomePageOtherHeader;
