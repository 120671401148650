import { CalculateMenuTradingPeriod } from "@/app/[location]/components/MenuTradingPeriods/CalculateTradingPeriod";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "@/app/[location]/menu/utils/sizeChangeUtils";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import {
  order,
  updateOfferPriceDetails,
} from "@/app/_redux/slice/Order/OrderSlice";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import {
  MenuDetailsEntity,
  MenuOptionGroup,
  OrderIngredient,
} from "@/app/_types/menuDetails";
import {
  MenuOfferForm,
  MenuOfferFormArray,
} from "@/app/_types/menuOffers.interface";
import moment from "moment";


import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

interface MenuOfferFormItemProps {
  offerCategories: MenuOfferFormArray;
  mainIndex: number;
}

function MenuOfferFormItem({
  offerCategories,
  mainIndex,
}: MenuOfferFormItemProps) {
  const { control, setValue } = useFormContext<MenuOfferForm>();
  const { offerDetails, schedule } = useSelector(order);
  const { selectedStore } = useSelector(selectStore);
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);

  const dispatch = useDispatch();

  const selectedOfferInfo = useWatch({
    control,
    name: `menuOffer.${mainIndex}.isSelected`,
  });

  const menus = useWatch({
    control,
    name: `menuOffer.${mainIndex}.menus`,
  });

  const isSplit = useWatch({
    control,
    name: `menuOffer.${mainIndex}.config.isSplit`,
  });

  console.log("menus & isSplit", menus, isSplit);

  // offerMenu watch

  const tradingPeriodId = useWatch({
    control,
    name: `periodId`,
  });

  const ChangeOfferHandler = async () => {
    setValue("config.selectedOfferIndex", mainIndex);
    setValue(
      "config.selectedOfferCategoryIndex",
      offerCategories?.offerCategoryIndex
    );

    const offerCategoriesList = offerDetails?.offerCategories?.find(
      (el) => el?.id === offerCategories?.offerCategoryId
    )?.offerCategoryMenus;

    console.log("offerCategoriesList", offerCategoriesList);

    if (offerCategoriesList?.length === 1 && !selectedOfferInfo) {
      const menu = offerCategoriesList?.[0];

      if (!selectedStore?.id) {
        return toast.error("Something went wrong. Please Try Again.");
      }

      if (!storeDT) return toast.error("No store Time");

      const selectedTime = schedule?.selectedTime?.date
        ? schedule?.selectedTime?.date
        : storeDT;

      const selectedDay = schedule?.selectedDay?.day
        ? schedule?.selectedDay?.day
        : moment(storeDT).format("ddd").toUpperCase();

      const menuDetails: MenuDetailsEntity = await fetchRequest(
        serverPath.getMenuDetails(
          selectedStore?.id,
          menu?.menuId,
          tradingPeriodId
        ),
        "GET",
        undefined,
        { cache: "no-store" }
      );

      const priceInfo: ExtraPriceInfo = await fetchRequest(
        serverPath.getExtraPriceInfo(selectedStore?.id),
        "GET",
        undefined
      );

      console.log(menuDetails);

      const tradingHoursData = menuDetails?.tradingHours;

      const CheckTradingPeriod = CalculateMenuTradingPeriod({
        tradingPeriodId,
        tradingHoursData,
        selectedOrderStatus,
        selectedTime,
        selectedDay,
        storeDT,
      });

      if (!CheckTradingPeriod?.status)
        return toast.error("Selected option menu is unavailable");

      dispatch(
        updateOfferPriceDetails({
          index: offerCategories?.offerCategoryIndex,
          extraPrice: priceInfo,
        })
      );

      const comboList = ManageComboPayInitial(
        menuDetails?.menuOptionGroups || [],
        menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
      );

      const comboPrices = getIndividualMenuComboPrice(comboList);

      const finalData = {
        id: menuDetails?.menu?.id,
        name: menu?.menuName,
        basePrice: menuDetails?.menu?.basePrice || 0,
        finalPrice: menu?.finalPrice || 0,
        sizeGroupId: menu?.sizeGroupId,
        menuSizeId: menu?.menuSizeId,
        comboPrice: comboPrices,
        hasHalfHalf: menu?.hasHalfHalf,
        hasExtras: menu?.hasExtras,
        ingredientPrice: 0,
        specialPrice: menu?.specialPrice || 0,
        // isComplete: true,
        combo: comboList || [],
        optionGroups: comboList || [],
        ingredients: menuDetails?.menuOptions || [],
        tradingHours: menuDetails?.tradingHours || [],
        menuSizeCount: menuDetails?.menuSizes?.length || 0,
      };
      setValue(`menuOffer.${mainIndex}.isSelected`, true);
      setValue(`menuOffer.${mainIndex}.menus.0`, finalData);
      setValue("config.selectedPage", "EDIT_MENU");
      return;
    }

    if (!selectedOfferInfo)
      return setValue("config.selectedPage", "SELECT_MENU");

    setValue("config.selectedPage", "EDIT_MENU");
  };

  return (
    <button
      className="bg-white group rounded-md relative w-full p-4 flex  justify-between gap-2 "
      onClick={ChangeOfferHandler}
    >
      {selectedOfferInfo ? (
        <div className="flex flex-col gap-2">
          {menus?.map((data, index) => (
            <div className="flex-1 flex items-start flex-col" key={index}>
              <div className="flex gap-2 items-center">
                <p className="font-bold text-base text-left">
                  {menus?.length === 2 ? `Half ${data?.name}` : data?.name}
                </p>
              </div>
              <div className="text-sm text-left w-full text-secondaryText">
                {data?.optionGroups?.length
                  ? data?.optionGroups?.map((combo, index) => (
                      <ComboPortion
                        combo={combo}
                        key={index}
                        isSplit={isSplit}
                      />
                    ))
                  : null}

                <AdditionalIngredient
                  ingredientList={data?.ingredients}
                  isSplit={isSplit}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <p>
            Choose {offerCategories?.sizeName} {offerCategories?.name}
          </p>
        </>
      )}
      <div className="flex flex-row items-center gap-2">
        {offerCategories?.discount ? (
          <div className="text-sm italic">{offerCategories?.discount}% off</div>
        ) : null}

        {selectedOfferInfo ? (
          <img
            src="/images/icons/check-green.svg"
            width={20}
            height={25}
            alt="- selected"
          />
        ) : null}
        <img
          src={"/images/icons/right.svg"}
          width={20}
          height={20}
          alt={">"}
          className=" group-hover:scale-125 transition-all duration-300"
        />
      </div>
    </button>
  );
}

function ComboPortion({
  combo,
  isSplit,
}: {
  combo: MenuOptionGroup;
  isSplit: boolean;
}) {
  const checkedCombo = combo?.options?.filter((item) => item?.qty > 0);

  if (!checkedCombo?.length) return null;
  return (
    <p className="flex gap-1">
      {combo?.optionGroupName} :{" "}
      {checkedCombo?.map((item, index) => (
        <div key={item?.id} className="italic flex items-center justify-center">
          <span>{item?.name}</span>
          {item?.qty > 1 ? (
            <div className="flex items-center justify-center">
              <img
                src="/images/icons/cross.svg"
                width="15"
                height="15"
                alt="x"
              />
              {item?.qty}
            </div>
          ) : null}{" "}
          {(item?.price || 0) > 0 ? (
            isSplit ? (
              <span>({`$${item?.price / 2}`})</span>
            ) : (
              <span>({`$${item?.price || 0}`})</span>
            )
          ) : null}
          {index === checkedCombo?.length - 1 ? null : ", "}
        </div>
      ))}
    </p>
  );
}

function AdditionalIngredient({
  ingredientList,
  isSplit,
}: {
  ingredientList: OrderIngredient[];
  isSplit: boolean;
}) {
  const addedIngredients = ingredientList?.filter(
    (el) =>
      el?.status === "added" ||
      (el?.status === "current" && el?.qty > 1) ||
      (el?.status === "current" && el?.qty === 0)
  );

  const removeIng = addedIngredients?.filter((el) => el?.qty <= 0);

  const addedIng = addedIngredients?.filter((el) => el?.qty > 0);

  if (!addedIngredients?.length) return null;
  return (
    <p className="flex flex-wrap flex-col">
      {addedIng?.length ? (
        <div className="flex flex-wrap gap-x-0.2 pl-0.5">
          {addedIng?.map((added, index) => (
            <div
              key={index}
              className={`flex items-center ${
                added?.qty === 0 && "italic line-through text-error"
              }`}
            >
              {added?.name}
              {added?.qty <= 1 ? null : (
                <>
                  {added?.status === "current" &&
                  added?.qty - 1 === 1 ? null : added?.status === "current" ? (
                    <>
                      <img
                        src="/images/icons/cross.svg"
                        width="15"
                        height="15"
                        alt="x"
                      />
                      {added?.qty - 1}
                    </>
                  ) : (
                    <>
                      <img
                        src="/images/icons/cross.svg"
                        width="15"
                        height="15"
                        alt="x"
                      />
                      {added?.qty}
                    </>
                  )}
                </>
              )}
              {added?.qty === 0 ? null : (
                <>
                  <span className="pl-0.2">
                    {(added?.total || added?.price || 0) > 0
                      ? isSplit
                        ? `($${(added?.total || added?.price || 0) / 2})`
                        : `($${added?.total || added?.price || 0})`
                      : null}
                  </span>
                  {index === addedIng?.length - 1 ? null : ","}
                </>
              )}
            </div>
          ))}
        </div>
      ) : null}
      <div className="flex gap-1">
        {removeIng?.length === 0
          ? null
          : removeIng?.map((Ing, index) => (
              <span className="flex" key={index}>
                <div
                  className={`flex items-center italic line-through text-error`}
                >
                  {Ing?.name}
                </div>
                {index === removeIng?.length - 1 ? null : ","}
              </span>
            ))}
      </div>
    </p>
  );
}

export default MenuOfferFormItem;
