import { MenuOfferForm } from "@/app/_types/menuOffers.interface";
import { fixedDecimal } from "@/app/utils/DecimalAndNumbers";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

function MenuOfferTotalPrice() {
  const { control, setValue } = useFormContext<MenuOfferForm>();

  const menuOffer =
    useWatch({
      control,
      name: "menuOffer",
    }) || [];

  const qty = useWatch({
    control,
    name: "qty",
  });

  const totalPrice = menuOffer
    ?.filter((el) => el.isSelected)
    .reduce((acc, current) => {
      const menuData = current?.menus;
      const isSplit = current?.config?.isSplit;
      let menuPrice = 0;

      if (isSplit) {
        // this one is a bit confusing, if dealType is percent than this is the total Price excluding additional prices
        // if dealType is fixed than this is the special amount prices
        menuPrice = current?.menusHalfHalfPrice || 0;
      }

      if (!isSplit) {
        menuPrice = menuData?.reduce((accs, currents) => {
          const finalPrice =
            currents?.finalPrice -
            ((current?.discount || 0) / 100) * currents?.finalPrice;
          return accs + finalPrice;
        }, 0);
      }

      const additionalPrice = menuData?.reduce((accs, currents) => {
        return (
          accs +
          (isSplit
            ? (currents?.comboPrice || 0) / 2
            : currents?.comboPrice || 0) +
          (isSplit
            ? (currents?.ingredientPrice || 0) / 2
            : currents?.ingredientPrice)
        );
      }, 0);

      return acc + additionalPrice + menuPrice;
    }, 0);

  useEffect(() => {
    setValue("totalPrice", totalPrice);
  }, [totalPrice, setValue, qty]);

  return (
    <p className="font-bold pl-4 text-2xl">
      $ {fixedDecimal(totalPrice * qty)}
    </p>
  );
}

export default MenuOfferTotalPrice;
