import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { updateMenuWithCategoryList } from "@/app/_redux/slice/menuWithCategories.ts/menuWithCategoriesSlice";
import { getSchedules } from "@/app/_redux/slice/Order/OrderSlice";
import {
  changeEstimatedTime,
  changeSelectedOrderStatus,
  changeStoreDT,
} from "@/app/_redux/slice/UISlice/UISlice";
import { EstimatedTime } from "@/app/_redux/slice/UISlice/UISlice.d";
import { useAppDispatch } from "@/app/_redux/store";
import { MenuWithCategories } from "@/app/_types/menuWithCategories";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function LocationRedux({
  estimatedTime,
  storeDT,
  storeId,
  children,
}: {
  estimatedTime: EstimatedTime | undefined;
  storeDT: string;
  children: React.ReactNode;
  storeId: string;
}) {
  const { location } = useParams<{ location: string }>();

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!estimatedTime || !storeDT) return;

    dispatch(changeEstimatedTime(estimatedTime));
    dispatch(changeStoreDT(storeDT));
  }, [estimatedTime, storeDT, dispatch]);

  useEffect(() => {
    const scheduleData = localStorage.getItem("scheduleData");
    const orderSelectedStatus = localStorage.getItem("selectedOrderStatus");
    console.log(orderSelectedStatus);
    console.log(scheduleData);
    let scheduleDataObj;
    if (scheduleData) {
      scheduleDataObj = JSON.parse(scheduleData);
      console.log("schedule Data Parse", scheduleDataObj);
      console.log(scheduleDataObj?.selectedTime?.date);
      console.log(storeDT);
      if (scheduleDataObj?.selectedStore?.id !== location) {
        localStorage.removeItem("scheduleData");
        dispatch(getSchedules(null));
      } else {
      if (
        moment(scheduleDataObj?.selectedTime?.date).isBefore(moment(storeDT))
      ) {
        console.log("true");
        scheduleDataObj = {};
        localStorage.removeItem("scheduleData");
        console.log(orderSelectedStatus);
        if (orderSelectedStatus === "DELIVERY") {
          dispatch(changeSelectedOrderStatus("DELIVERY"));
        } else {
          dispatch(changeSelectedOrderStatus("PICKUP"));
        }
      } else {
        console.log(scheduleDataObj?.orderType);
        dispatch(getSchedules(scheduleDataObj));
        const orderType = scheduleDataObj?.orderType;
        if (orderType) {
          console.log(orderType);
          if (orderType === "STORE" || orderType === "PICKUP") {
            dispatch(changeSelectedOrderStatus("PICKUP"));
          } else {
            dispatch(changeSelectedOrderStatus("DELIVERY"));
          }
        }
          const tradingPeriodId = scheduleDataObj?.selectedDay?.tradingPeriodId;
          console.log("MenuInfoData", tradingPeriodId);
          const fetchCatWithMenu = async () => {
            const catWithMenu: MenuWithCategories = await fetchRequest(
              serverPath.categoriesWithMenu(storeId, tradingPeriodId),
              "GET",
              undefined,
              { cache: "no-cache" }
            );
            dispatch(updateMenuWithCategoryList(catWithMenu));
          };
          fetchCatWithMenu();
        }
      }
    }
  }, [dispatch, location, queryClient, storeDT, storeId]);
  return <>{children}</>;
}

export default LocationRedux;
