import { ScheduleButtonForm } from "./ScheduleButton";
import { useFormContext, useWatch } from "react-hook-form";
import { getDateTimeList, getNextSixDaysWithNames } from "./tradingHourLogic";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { getPreviousDatefromCurrentDay } from "@/app/utils/dateUtil";
import { ScheduleDTEntity } from "@/app/_types/checkout.interface";

import moment from "moment";

function ScheduleButtonDateAndTimeSelect() {
  const { control, setValue, getValues } = useFormContext<ScheduleButtonForm>();
  const { storeDT } = useSelector(selectUI);

  const selectedDay = useWatch({
    control,
    name: "selectedDay",
  });

  if (!storeDT) {
    return <p>Getting Store Date...</p>;
  }

  const sixDays = getNextSixDaysWithNames(storeDT);

  const selectedDayHandler = (data: string) => {
    const tradingHourPeriod = getValues("selectedTradingPeriod");
    const findDay = tradingHourPeriod?.value?.find(
      (days) => days?.day === data
    );
    if (!findDay) {
      toast.error("No Trading Hour Found");
      return;
    }

    setValue("selectedDay", findDay);
  };

  //   if (!tradingHourPeriod) return <></>;

  return (
    <div className="py-4 flex flex-row gap-2 overflow-auto">
      {sixDays?.map((days, index) => {
        return (
          <div
            key={index}
            className={twMerge(
              "border-background border-2  p-2 lg:p-3 rounded-lg min-w-[6rem] lg:min-w-[8rem] items-center justify-center flex flex-col cursor-pointer text-xs lg:text-base",
              selectedDay?.day === days?.day && "bg-selectedValue text-white"
            )}
            onClick={() => selectedDayHandler(days?.day)}
          >
            <p className="font-semibold">
              {days?.name ? days?.name : days?.day}
            </p>
            <p>{days?.date}</p>
          </div>
        );
      })}
    </div>
  );
}

export function SelectedDayTimeList() {
  const { control } = useFormContext<ScheduleButtonForm>();
  const { estimatedTime, storeDT } = useSelector(selectUI);

  const selectedDay = useWatch({
    control,
    name: "selectedDay",
  });

  // const selectedTime = useWatch({
  //   control,
  //   name: "selectedTime",
  // });

  const selectedTradingPeriod = useWatch({
    control,
    name: "selectedTradingPeriod",
  });

  console.log("selectedTradingPeriod",selectedTradingPeriod)

  if (!storeDT || !selectedDay)
    return (
      <p className="text-sm italic">
        Please select an order type first, then choose a day.
      </p>
    );
  const previousDay = getPreviousDatefromCurrentDay(selectedDay?.day);

  if (selectedDay?.isClosed) {
    return <p className="text-sm italic">Store is closed.</p>;
  }

  const previousDayTradingHour = selectedTradingPeriod?.value?.find(
    (el) => el?.day === previousDay
  );

  console.log(selectedDay)

  const estimatedTimes =
    selectedDay?.orderType === "DELIVERY"
      ? estimatedTime?.webDeliveryMins || 0
      : estimatedTime?.webPickupMins || 0;

  const timeArray = getDateTimeList(
    selectedDay,
    estimatedTimes,
    storeDT,
    previousDayTradingHour
  );

  console.log(timeArray)

  return <ShowTimeList timeArray={timeArray} />;
}

function ShowTimeList({ timeArray }: { timeArray: ScheduleDTEntity[] }) {
  const { control, setValue } = useFormContext<ScheduleButtonForm>();

  const selectedTime = useWatch({
    control,
    name: "selectedTime",
  });

  const orderType = useWatch({
    control,
    name: "orderType",
  });

  const selectTradingHourTimeHandler = (el: ScheduleDTEntity) => {
    setValue("selectedTime", el);
  };

  return (
    <div className="flex flex-col gap-2 overflow-y-auto">
      {timeArray?.length === 0 ? (
        <p>No Time to Select</p>
      ) : (
        timeArray?.map((el, index) => {
          return (
            <div
              key={index}
              className={twMerge(
                "py-4 px-2 border-b-[1px] border-background  cursor-pointer flex flex-row gap-4 items-center",
                el?.value === selectedTime?.value &&
                  "border-selectedValue border-l-[1px] rounded-md"
              )}
              onClick={() => selectTradingHourTimeHandler(el)}
            >
              <p>
                {el?.label}
                {orderType === "DELIVERY" &&
                  " - " +
                    moment(el?.label, "HH:mm A")
                      .add(15, "minutes")
                      .format("h:mm A")}
              </p>
              {el?.value === selectedTime?.value && (
                <img
                  src={"/images/icons/tick.svg"}
                  width={20}
                  height={20}
                  alt="Selected"
                />
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

export default ScheduleButtonDateAndTimeSelect;
