// components/ContentSlider.js
import { useState, useEffect } from "react";
import { useTransition, animated } from "@react-spring/web";
import { ImagesDaum } from "@/app/_types/EcommerceTheme.interface";

const ContentSlider = ({ MediaLists }: { MediaLists: ImagesDaum[] }) => {
  const [index, setIndex] = useState(0);

  // Define transitions for fade in and out
  const transitions = useTransition(MediaLists[index], {
    key: index, // Ensure unique key for each media item
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  });

  // Automatically go to the next media every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % MediaLists.length);
    }, 3000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [MediaLists.length]);

  return (
    <div className="hidden md:flex w-1/2 relative">
      {transitions((style, media) => (
        <animated.div style={style} className="absolute w-full h-full">
          {media?.status === "CARD" && (
            <>
              <div className="absolute bottom-0 md:top-16 right-[30%] z-[-1] skew-x-[-16deg] skew-y-[12deg]">
                <img
                  src={media?.image?.[0]?.file || ""}
                  className="w-[15rem] h-[20rem] lg:w-[25rem] lg:h-[30rem] object-cover object-center rounded-md homepage-shadows"
                  alt="Images"
                />
              </div>
              <div className="hidden md:block absolute top-[12rem] right-[8%] z-[-1]">
                <img
                  src={media?.image[1]?.file || ""}
                  className="w-[15rem] h-[20rem] lg:w-[25rem] lg:h-[30rem] object-cover object-center rounded-md homepage-shadows"
                  alt="Images"
                />
              </div>
            </>
          )}
          {media?.status === "IMAGE" && (
            <div className="absolute bottom-0 md:top-16 right-[20%] z-[-1]">
              <img
                src={media?.image?.[0]?.file}
                className="w-[15rem] h-[20rem] lg:w-[25rem] lg:h-[30rem] object-cover object-center rounded-md homepage-shadows"
                alt="Images"
              />
            </div>
          )}
          {media?.status === "VIDEO" && (
            <div className="absolute bottom-0 md:top-16 right-[10%] z-[-1]">
              <video
                controls={false}
                autoPlay
                loop
                playsInline
                muted
                className="w-[25rem] h-[20rem] lg:w-[45rem] lg:h-[30rem] object-cover object-center rounded-md homepage-shadows"
              >
                <source src={media?.image[0]?.file} />
                Browser not supported
              </video>
            </div>
          )}
        </animated.div>
      ))}
    </div>
  );
};

export default ContentSlider;
