import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface CustomDrawerClient {
  onClose(): void;
  open: boolean;
  children: React.ReactNode | React.ReactPortal;
}

function CustomDrawerClient({
  onClose,
  open,
  children,
}: CustomDrawerClient): React.ReactElement | null {
  const overlay = useRef(null);
  const wrapper = useRef(null);

  const onCloseHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === overlay.current) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.classList.add("modal-open");

    return () => document.body.classList.remove("modal-open");
  }, []);

  const drawerContent = open ? (
    <div
      ref={overlay}
      className="modal-open maxContainer ease-in-out duration-500 fixed inset-0 z-[2000] flex items-center justify-center"
      onClick={(e) => onCloseHandler(e)}
    >
      <div
        ref={wrapper}
        className="bg-white w-full min-w-[10rem] md:min-w-[40rem] md:w-max h-[100dvh] z-[62] fixed inset-0 ml-auto rounded-sm text-black overflow-y-auto flex flex-col"
      >
        {children}
      </div>
    </div>
  ) : null;

  if (open && drawerContent && document.getElementById("drawer-root")) {
    return createPortal(drawerContent, document.getElementById("drawer-root")!);
  }

  return null;
}

export default CustomDrawerClient;
