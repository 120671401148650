import { TradingHour } from "@/app/_types/tradingHours.interface";
import { useEffect } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";
import moment from "moment";
import { ScheduleDTEntity } from "@/app/_types/checkout.interface";
import { useDispatch, useSelector } from "react-redux";
import { order, scheduleModel } from "@/app/_redux/slice/Order/OrderSlice";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import ScheduleModal from "./ScheduleModal";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { StoreEntity } from "@/app/_types/store.interface";

type TradingPeriodData = {
  data: string;
  value: TradingHour[];
};

export interface ScheduleButtonForm {
  selectedStore: StoreEntity | null;
  tradingHours: TradingHour[];
  orderType: "PICKUP" | "DELIVERY";
  selectedTradingPeriod: TradingPeriodData | null;
  selectedDay: TradingHour | null;
  selectedTime: ScheduleDTEntity | null;
}

function ScheduleButton({
  tradingHours,
  btnClass,
  btnText,
  watchOrderValid = true,
  handleSelectedTradingPeriod,
  isDelivery,
}: {
  tradingHours: TradingHour[];
  btnClass?: string;
  btnText?: string;
  watchOrderValid?: boolean;
  handleSelectedTradingPeriod?: ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => Promise<void>;
  isDelivery: boolean;
}) {
  const { schedule, scheduleOpen } = useSelector(order);
  const { selectedOrderStatus, storeDT } = useSelector(selectUI);
    const { selectedStore } = useSelector(selectStore);
  

  const dispatch = useDispatch();

  const orderStatus = selectedOrderStatus === "PICKUP" ? "STORE" : "DELIVERY"

  const orderTradingHour = tradingHours?.filter(
    (el) => el?.orderType === orderStatus
  );

  const tradingPeriodDataList = orderTradingHour?.reduce(
    (acc: TradingPeriodData[], current) => {
      const tradingPeriodName = current?.tradingPeriodName;
      const findTradingPeriod = acc?.find(
        (el) => el?.data === tradingPeriodName
      );

      if (findTradingPeriod) {
        findTradingPeriod?.value?.push(current);
        return acc;
      } else {
        acc.push({ data: tradingPeriodName, value: [current] });
        return acc;
      }
    },
    []
  );

  const newSelectedDate = moment(storeDT).format("ddd").toUpperCase();
  const findDay = tradingPeriodDataList?.[0]?.value?.find(
    (days) => days?.day === newSelectedDate
  );

  const defaultValues: ScheduleButtonForm = {
    selectedStore,
    tradingHours: tradingHours,
    orderType: selectedOrderStatus ? selectedOrderStatus : "PICKUP",
    selectedTradingPeriod: tradingPeriodDataList?.[0] || null,
    selectedDay: findDay || null,
    selectedTime: null,
  };

  const methods = useForm({ defaultValues });

  const { setValue } = methods;

  const { reset } = methods;

  const handleOpen = () => {
    dispatch(scheduleModel(true));
  };

  const handleClose = () => {
    dispatch(scheduleModel(false));
  };

  // const onSubmit = (data: ScheduleButtonForm) => {
  //   dispatch(getSchedules(data));
  //   dispatch(changeSelectedOrderStatus(data?.orderType));
  //   localStorage?.setItem("selectedOrderStatus", data?.orderType);
  //   localStorage.setItem("scheduleData", JSON.stringify(data));
  //   const tradingPeriodId = data?.selectedDay?.tradingPeriodId;
  //   if (tradingPeriodId && handleSelectedTradingPeriod) {
  //     handleSelectedTradingPeriod({ tradingPeriodId: tradingPeriodId });
  //   }
  //   handleClose();
  // };

  useEffect(() => {
    console.log("tradingHours CategoryListHeadersInfo tradingHOurs",tradingHours);
    console.log("tradingHours CategoryListHeadersInfo",schedule);
    if (schedule) {
      reset(schedule);
    } else {
      console.log("hit", selectedOrderStatus)
      setValue("tradingHours", tradingHours);
      setValue(
        "orderType",
        selectedOrderStatus ? selectedOrderStatus : "PICKUP"
      );
      setValue("selectedTime", null);
      setValue("selectedTradingPeriod", tradingPeriodDataList?.[0] || null)
      setValue("selectedDay", findDay || null)
      setValue("selectedStore", selectedStore)
      // reset({
      //   tradingHours: tradingHours,
      //   orderType: selectedOrderStatus ? selectedOrderStatus : "PICKUP",
      //   selectedTime: null,
      // });
    }
  }, [
    reset,
    schedule,
    scheduleOpen,
    selectedOrderStatus,
    setValue,
    tradingHours,
  ]);

  return (
    <FormProvider {...methods}>
      {/* <button
          type="button"
          className={twMerge(
            "flex flex-row gap-1 items-center rounded-t-md rounded md:px-6 px-3 py-2 sm:py-[0.7rem] md:py-[1.2rem] bg-background ",
          
            btnClass && btnClass
          )}
          onClick={handleOpen}
        > */}
      <button
        type="button"
        className={twMerge(
          "flex flex-row gap-2 items-center bg-gray-200 rounded-full h-10 px-3 text-sm font-medium",
          btnClass && btnClass
        )}
        onClick={handleOpen}
      >
        {/* <Icon icon="akar-icons:schedule" className="text-lg" /> */}
        <img
          src="/images/icons/schedule.svg"
          width="18"
          height="18"
          alt="sch"
        />
        <p className="hidden sm:block">{btnText ? btnText : "Schedule"}</p>
        <p className="sm:hidden">{btnText ? btnText : "Sch"}</p>
      </button>
      {scheduleOpen && watchOrderValid ? (
        <ScheduleModal
          handleClose={handleClose}
          scheduleOpen={scheduleOpen}
          handleSelectedTradingPeriod={handleSelectedTradingPeriod}
          isDelivery={isDelivery}
        />
      ) : null}
    </FormProvider>
  );
}

export function BottomFeature() {
  return (
    <div className="flex-1">
      <button>Cancel Button</button>
    </div>
  );
}

export function OrderTypeButton({ isDelivery }: { isDelivery: boolean }) {
  const { control, setValue } = useFormContext<ScheduleButtonForm>();

  const watchOrderType = useWatch({
    control,
    name: "orderType",
  });

  const tradingHours = useWatch({
    control,
    name: "tradingHours",
  });

  const previousSelectedTradingPeriod = useWatch({
    control,
    name: "selectedTradingPeriod",
  });
  
  const changeWatchType = (status: "PICKUP" | "DELIVERY") => {
    const newOrderType = status === "PICKUP" ? "STORE" : status;

  const orderTradingHour: TradingHour[] = tradingHours?.filter(
    (el: TradingHour) => el?.orderType === newOrderType
  );

  const TradingPeriodDataList = orderTradingHour?.reduce(
    (acc: TradingPeriodData[], current: TradingHour) => {
      const tradingPeriodName = current?.tradingPeriodName;
      const findTradingPeriod = acc?.find(
        (el) => el?.data === tradingPeriodName
      );

      if (findTradingPeriod) {
        findTradingPeriod?.value?.push(current);
        return acc;
      } else {
        acc.push({ data: tradingPeriodName, value: [current] });
        return acc;
      }
    },
    []
  );

  const selectedTradingPeriod = TradingPeriodDataList?.find((el) => 
    el?.data === previousSelectedTradingPeriod?.data
  ) || null
    // console.log("delivery change component", TradingPeriodDataList, previousSelectedTradingPeriod, selectedTradingPeriod)
    setValue("orderType", status);
    setValue("selectedTradingPeriod", selectedTradingPeriod);
    setValue("selectedDay", null);
    setValue("selectedTime", null);
  };


  return (
    // <div className="gap-4 flex flex-row items-center">
    //   <button
    //     className={twMerge(
    //       "px-4 py-3 xl:py-4 xl:px-8 bg-background rounded-full text-sm lg:text-base",
    //       watchOrderType === "PICKUP" && "bg-selectedValue text-white"
    //     )}
    //     onClick={() => changeWatchType("PICKUP")}
    //   >
    //     Pickup
    //   </button>
    //   {isDelivery && (
    //     <button
    //       className={twMerge(
    //         "px-4 py-3 xl:py-4 xl:px-8 bg-background rounded-full text-sm lg:text-base",
    //         watchOrderType === "DELIVERY" && "bg-selectedValue text-white"
    //       )}
    //       onClick={() => changeWatchType("DELIVERY")}
    //     >
    //       Delivery
    //     </button>
    //   )}
    // </div>
    <div className="border-b border-gray-200">
      <div className="flex space-x-8">
        {/* Pickup Tab */}
        <button
          className={twMerge(
            "relative py-3 text-gray-600 text-sm font-medium transition-all",
            watchOrderType === "PICKUP" && "text-black font-semibold"
          )}
          onClick={() => changeWatchType("PICKUP")}
        >
          Pickup
          {watchOrderType === "PICKUP" && (
            <span className="absolute left-0 bottom-[-1px] w-full h-[3px] bg-black rounded"></span>
          )}
        </button>

        {isDelivery && (
          <button
            className={twMerge(
              "relative py-3 text-gray-600 text-sm font-medium transition-all",
              watchOrderType === "DELIVERY" && "text-black font-semibold"
            )}
            onClick={() => changeWatchType("DELIVERY")}
          >
            Delivery
            {watchOrderType === "DELIVERY" && (
              <span className="absolute left-0 bottom-[-1px] w-full h-[3px] bg-black rounded"></span>
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export function Header() {
  const { control } = useFormContext<ScheduleButtonForm>();

  // const watchOrderType = useWatch({
  //   control,
  //   name: "orderType",
  // });

  const selectedTradingPeriod = useWatch({
    control,
    name: 'selectedTradingPeriod'
  })

  console.log(selectedTradingPeriod)

  return (
    <div>
      <h2 className="text-lg lg:text-xl font-semibold capitalize">
        {/* Schedule {watchOrderType === "DELIVERY" ? "Delivery" : "Pickup"} Order * */}
        Trading Hour <span className={`${!selectedTradingPeriod ? "text-red-600" : "hidden"}`}>*</span>
      </h2>
    </div>
  );
}

export function SelectedTradingHours() {
  const { control } = useFormContext<ScheduleButtonForm>();

  const tradingHours = useWatch({
    control,
    name: "tradingHours",
  });

  const orderType = useWatch({
    control,
    name: "orderType",
  });

  const newOrderType = orderType === "PICKUP" ? "STORE" : orderType;

  const orderTradingHour: TradingHour[] = tradingHours?.filter(
    (el: TradingHour) => el?.orderType === newOrderType
  );

  if (!orderTradingHour?.length) {
    return (
      <p className="text-semibold font-lg">
        No Date Found. Please Try Again Later.
      </p>
    );
  }

  const TradingPeriodDataList = orderTradingHour?.reduce(
    (acc: TradingPeriodData[], current: TradingHour) => {
      const tradingPeriodName = current?.tradingPeriodName;
      const findTradingPeriod = acc?.find(
        (el) => el?.data === tradingPeriodName
      );

      if (findTradingPeriod) {
        findTradingPeriod?.value?.push(current);
        return acc;
      } else {
        acc.push({ data: tradingPeriodName, value: [current] });
        return acc;
      }
    },
    []
  );

  if (!TradingPeriodDataList?.length) {
    return (
      <p className="text-semibold font-lg">
        No Data Found. Please Try Again Later.
      </p>
    );
  }

  return (
    <>
      <TradingPeriodDataListMap tradingPeriod={TradingPeriodDataList} />
    </>
  );
}

function TradingPeriodDataListMap({
  tradingPeriod,
}: {
  tradingPeriod: TradingPeriodData[];
}) {
  const { storeDT } = useSelector(selectUI);

  const { control, setValue } = useFormContext<ScheduleButtonForm>();

  const selectedTradingPeriod = useWatch({
    control,
    name: "selectedTradingPeriod",
  });

  const selectedTradingList = (data: TradingPeriodData) => {
    setValue(`selectedTradingPeriod`, data);
    const newSelectedDate = moment(storeDT).format("ddd").toUpperCase();
    const value = data?.value;

    const findDay = value?.find((days) => days?.day === newSelectedDate);

    if (!findDay) {
      setValue("selectedDay", value?.[0]);
    } else {
      setValue("selectedDay", findDay);
    }

    setValue("selectedTime", null);
  };

  return (
    <div className="flex gap-2 items-center flex-row pt-4 flex-wrap">
      {tradingPeriod?.map((el, index) => {
        return (
          <button
            key={index}
            className={twMerge(
              "py-3 px-4 xl:py-4 xl:px-8 bg-background rounded-full text-sm lg:text-base",
              selectedTradingPeriod &&
                selectedTradingPeriod?.data === el?.data &&
                "bg-selectedValue text-white"
            )}
            onClick={() => selectedTradingList(el)}
          >
            {el?.data}
          </button>
        );
      })}
    </div>
  );
}

export default ScheduleButton;
