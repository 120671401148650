import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import UISlice from "./slice/UISlice/UISlice";
import menuWithCategoriesSlice from "./slice/menuWithCategories.ts/menuWithCategoriesSlice";
import OrderSlice from "./slice/Order/OrderSlice";
import storeSlice from "./slice/storeslice.ts/storeSlice";
const appReducer = combineReducers({
  UI: UISlice,
  menuWithCategories: menuWithCategoriesSlice,
  order: OrderSlice,
  store: storeSlice,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export { rootReducer, appReducer };
