import moment from "moment";
import { ScheduleDTEntity } from "../_types/checkout.interface";
import { TradingHour } from "../_types/tradingHours.interface";

const dayOrder: { [key: string]: number } = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};

export function formatStringDateToAnotherFormat(
  date: string,
  format: string = "DD/MM/YYYY"
): string {
  return moment(date).format(format);
}

export function previousDayTradingHourCheck(
  previousTradingHour: TradingHour,
  estimatedTime: number,
  storeDT: string
) {
  if (
    previousTradingHour?.isClosed ||
    !previousTradingHour?.startTime ||
    !previousTradingHour?.endTime ||
    !previousTradingHour?.isTomorrow
  )
    return null;

  console.log("previous trading hour => ", previousTradingHour);

  const timeArray: ScheduleDTEntity[] = [];

  const previousTHStartTime = moment(previousTradingHour?.startTime);
  let newStartTime = moment()
    .clone()
    .isoWeekday(dayOrder[previousTradingHour?.day])
    .set({
      hour: previousTHStartTime.hour(),
      minute: previousTHStartTime.minute(),
      second: previousTHStartTime.second(),
    })
    .add(estimatedTime, "minute");

  const previousTHEndTime = moment(previousTradingHour?.endTime);

  let newEndTime = moment()
    .clone()
    .isoWeekday(dayOrder[previousTradingHour?.day])
    .add(1, "day")
    .set({
      hour: previousTHEndTime.hour(),
      minute: previousTHEndTime.minute(),
      second: previousTHEndTime.second(),
    });

  console.log("previous trading hour start time => ", newStartTime, newEndTime);

  while (newStartTime.isBefore(newEndTime)) {
    if (
      // newStartTime.isSame(moment(storeDT), "day") &&
      moment(storeDT).isBefore(newStartTime) &&
      moment(newStartTime).isSame(moment(newEndTime), "day")
    ) {
      timeArray.push({
        label: newStartTime?.format("hh:mm A"),
        date: newStartTime.format("YYYY-MM-DDTHH:mm:ss"),
        value: newStartTime?.format("Do MMM - hh:mm A"),
      });
    }

    newStartTime = moment(newStartTime).clone().add(15, "minutes");
  }

  return timeArray;
}

/* this function is called when a list of available time is get from the two dates 
for example [{label : "11:45 pm",date : "2023/05/22", value : "11:45 pm" }]

*/

export function getPreviousDatefromCurrentDay(day: string) {
  const currentDay = dayOrder[day];
  let dayNumber: number = currentDay - 1;
  if (currentDay === 1) {
    dayNumber = 7;
  }
  return moment().day(dayNumber).format("ddd").toUpperCase();
}

// export function sortWeekDayTradingHours(data: TradingHour[]) {
//   const finalData: TradingHour[] = [];
//   data?.forEach((item) => {
//     const dateList = item?.tradingHours;
//     const sortedDateList = dateList?.sort(
//       (a, b) => dayOrder[a?.day] - dayOrder[b?.day]
//     );
//     finalData.push({ ...item, tradingHours: sortedDateList });
//   });

//   return finalData;
// }

export function removeExpiredDayTradingHours(
  tradingHour: TradingHour[],
  storeDT: string
) {
  if (!tradingHour) return [];
  const finalData: TradingHour[] = [];
  const currentDate = moment(storeDT);

  tradingHour?.forEach((item) => {
    if (item?.isClosed || !item?.startTime) return;
    const startTime = moment(item?.startTime);
    const getWeeks = moment().clone().isoWeekday(dayOrder[item?.day]).set({
      hour: startTime.hour(),
      minute: startTime.minute(),
      second: startTime.second(),
    });

    console.log("start time => ", item?.startTime, currentDate.toString());

    if (getWeeks.isSameOrAfter(currentDate)) {
      finalData.push({
        ...item,
        startTime: getWeeks.format("YYYY-MM-DDTHH:mm:ss"),
      });
    }
    console.log("start time array => ", finalData);
  });
  return finalData;
}
