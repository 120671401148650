import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { useSelector } from "react-redux";
import AddToCartOrderItems from "./AddToCartOrderItems";

function AddToCartMenuList({ location }: { location: string }) {
  const { OrderList, isLoading, todayTradingHours, schedule } =
    useSelector(order);

  return (
    <>
      {OrderList && OrderList?.length ? (
        <div className=" p-1 mt-auto text-sm md:text-base">
          {/* <p className=" font-semibold">Menu</p> */}
          <div className="divide-y-2">
            {OrderList?.map((order, index) => (
              <AddToCartOrderItems
                key={index}
                order={order}
                isLoading={isLoading}
                location={location}
                todayTradingHoursData={todayTradingHours}
                schedule={schedule}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AddToCartMenuList;
