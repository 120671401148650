import { reActivationTimes } from "@/app/_types/menuWithCategories";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import moment from "moment";

interface TradingCalculationProps {
  tradingPeriodId: string;
  todayTradingHoursData: TradingHour[];
  selectedOrderStatus: string | null;
  storeDT: string;
}

export const CalculateTradingPeriod = ({
  tradingPeriodId,
  todayTradingHoursData,
  selectedOrderStatus,
  storeDT,
}: TradingCalculationProps) => {
  const filterOrders = todayTradingHoursData?.filter((order) => {
    if (selectedOrderStatus === "PICKUP") {
      return order.orderType === "STORE";
    }
    return order.orderType === selectedOrderStatus;
  });

  const tradingHours = filterOrders.find(
    (hour) => hour.tradingPeriodId === tradingPeriodId
  );

  if (tradingHours?.isClosed) {
    const data = {
      startTime: moment(tradingHours?.startTime, "HH:mm:ss").format("hh:mm A"),
      endTime: moment(tradingHours?.endTime, "HH:mm:ss").format("hh:mm A"),
      status: false,
      isClosed: tradingHours?.isClosed,
    };
    return data;
  }

  if (tradingHours && storeDT) {
    const storetime = moment.utc(storeDT).format("HH:mm:ss");
    const currentTime = moment(storetime, "HH:mm:ss");
    let startTime = moment(tradingHours.startTime, "HH:mm:ss");
    let endTime = moment(tradingHours.endTime, "HH:mm:ss");

    // Check if endTime is less than startTime
    if (endTime.isBefore(startTime)) {
      // Add 1 day to endTime
      endTime = endTime.add(1, "day");
    }

    // Set today's date for startTime and endTime
    startTime = moment(
      `${moment().format("YYYY-MM-DD")} ${tradingHours.startTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );

    if (currentTime.isBetween(startTime, endTime)) {
      const data = {
        startTime: startTime.format("hh:mm A"),
        endTime: endTime.format("hh:mm A"),
        status: true,
        isClosed: tradingHours?.isClosed,
      };
      return data;
    } else {
      const data = {
        startTime: startTime.format("hh:mm A"),
        endTime: endTime.format("hh:mm A"),
        status: false,
        isClosed: tradingHours?.isClosed,
      };
      return data;
    }
  } else {
    return { startTime: null, endTime: null, status: false, isClosed: null };
  }
};

interface OrderManagement {
  selectedOrderStatus: string | null;
  storeDT: string | null;
  reActivationTimesData: reActivationTimes[];
}

export const CalculateOrderManagement = ({
  selectedOrderStatus,
  storeDT,
  reActivationTimesData,
}: OrderManagement) => {
  if (!storeDT) return {status: false};
  if (!selectedOrderStatus) return {status: false};

  const orderTypeToCompare =
    selectedOrderStatus === "PICKUP" ? "STORE" : selectedOrderStatus;
  const order = reActivationTimesData?.find(
    (order) => order?.orderType === orderTypeToCompare
  );
  if (order) {
    const activationTime = new Date(order?.activationDT);
    const storeTime = new Date(storeDT);
    const status = storeTime >= activationTime;
    return { status, activationTime };
  }
  const status = false;
  return { status };
};

interface CalculateMenuTradingPeriodProps {
  tradingPeriodId: string;
  tradingHoursData: TradingHour[];
  selectedOrderStatus: string | null;
  selectedTime: string;
  selectedDay: string;
  storeDT:string
}

type DayOfWeek = "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT";

export const CalculateMenuTradingPeriod = ({
  tradingPeriodId,
  tradingHoursData,
  selectedOrderStatus,
  selectedTime,
  selectedDay,
  storeDT,
}: CalculateMenuTradingPeriodProps) => {
  const filterOrders = tradingHoursData?.filter((order) => {
    if (selectedOrderStatus === "PICKUP") {
      return order?.orderType === "STORE";
    }
    return order?.orderType === selectedOrderStatus;
  });

  const tradingHoursSelectedDay = filterOrders?.filter(
    (order) => order?.day === selectedDay
  );

  const tradingHours = tradingHoursSelectedDay?.find(
    (hour) => hour?.tradingPeriodId === tradingPeriodId
  );

  if (tradingHours?.isClosed) {
    const data = {
      startTime: tradingHours?.startTime
      ? moment(tradingHours.startTime, "HH:mm:ss").format("hh:mm A")
      : "",
      endTime: tradingHours?.endTime ? moment(tradingHours?.endTime, "HH:mm:ss").format("hh:mm A") : "",
      status: false,
      isClosed: tradingHours?.isClosed,
    };
    return data;
  }

  if (tradingHours && selectedTime) {
    const  currentTime = moment(selectedTime, "YYYY-MM-DD HH:mm:ss");
    let startTime = moment(tradingHours.startTime, "HH:mm:ss");
    let endTime = moment(tradingHours.endTime, "HH:mm:ss");

    const targetDay = tradingHours?.day;

    const daysOfWeek: Record<DayOfWeek, number> = {
      SUN: 0,
      MON: 1,
      TUE: 2,
      WED: 3,
      THU: 4,
      FRI: 5,
      SAT: 6,
    };

    const today = moment(storeDT).day();
    const targetDayNum = daysOfWeek[targetDay.toUpperCase() as DayOfWeek];


    // Calculate the number of days to add to get to the target day
    // If target day is today, we add 0 days; otherwise, calculate days until target
    const daysUntilTarget =
      targetDayNum >= today ? targetDayNum - today : targetDayNum + 7 - today;

    // Get the target date based on the next occurrence of `tradingHours.day`
    const targetDate = moment(storeDT)
      .add(daysUntilTarget, "days")
      .format("YYYY-MM-DD");

    // Set today's date for startTime and endTime
    startTime = moment(
      `${targetDate} ${tradingHours?.startTime}`,
      "YYYY-MM-DDTHH:mm:ss"
    );

    endTime = moment(
      `${targetDate} ${tradingHours?.endTime}`,
      "YYYY-MM-DDTHH:mm:ss"
    );

    // Check if endTime is less than startTime
    if (endTime.isBefore(startTime)) {
      // Add 1 day to endTime
      console.log("true");
      endTime = endTime.clone().add(1, "day"); // Clone and then add 1 day to avoid mutation issues
      console.log("Adjusted endTime:", endTime);
    }

    if (
      currentTime.isSameOrAfter(startTime) &&
      currentTime.isSameOrBefore(endTime)
    ) {
      const data = {
        startTime: startTime.format("hh:mm A"),
        endTime: endTime.format("hh:mm A"),
        status: true,
        isClosed: tradingHours?.isClosed,
      };
      return data;
    } else {
      const data = {
        startTime: startTime.format("hh:mm A"),
        endTime: endTime.format("hh:mm A"),
        status: false,
        isClosed: tradingHours?.isClosed,
      };
      return data;
    }
  } else {
    return { startTime: null, endTime: null, status: false, isClosed: null };
  }
};
