import MenuOfferCartButton from "./MenuOfferCartButton";
import MenuOfferTotalPrice from "./MenuOfferTotalPrice";
import MenuOfferQty from "./MenuOfferQty";

function MenuOfferAddToCart({ isModal }: { isModal: boolean }) {
  return (
    <div className="bg-white absolute bottom-0 w-full">
      <div className=" p-2 flex flex-col justify-between">
        <div className="flex gap-4 items-center justify-between mb-3">
        <MenuOfferTotalPrice />
        <MenuOfferQty/>
        </div>
        <MenuOfferCartButton isModal={isModal} />
      </div>
    </div>
  );
}

export default MenuOfferAddToCart;
