import MenuDetailsTotalPrice from "./MenuDetailsTotalPrice";

import AddMenuCartButton from "./AddMenuCartButton";
import MenuDetailsQty from "./MenuDetailsQty";

function MenuDetailsAddToCart({ isModal }: { isModal: boolean }) {
  return (
    <div className="bg-white absolute bottom-0 w-full">
      <div className="text-right p-2 flex flex-col justify-between">
        <div className="flex gap-4 items-center justify-between mb-3">
          <MenuDetailsTotalPrice />
          <MenuDetailsQty />
        </div>
        <AddMenuCartButton isModal={isModal} />
      </div>
    </div>
  );
}

export default MenuDetailsAddToCart;
