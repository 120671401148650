import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { getCartList } from "@/app/_axios/serverApi";
import {
  getEntireDealList,
  getEntireOrderList,
  getSchedules,
  getTodayTradingHours,
  getTradingHourAction,
  setOrderLocation,
} from "@/app/_redux/slice/Order/OrderSlice";
import { CheckoutPageDetails } from "@/app/_redux/slice/UISlice/UISlice.d";
import {
  changeEstimatedTime,
  changeSelectedOrderStatus,
  changeStoreDT,
} from "@/app/_redux/slice/UISlice/UISlice";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { useAppDispatch } from "@/app/_redux/store";
import { ConfirmOrderForm, UserAddress } from "@/app/_types/checkout.interface";
import { CustomerInfo } from "@/app/_types/customer.interface";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import PaymentStatus from "./CheckoutUserDetails/PaymentStatus";
import { useMutation, useQuery } from "@tanstack/react-query";
import CheckoutRealProcess from "./CheckoutRealProcess";
import { clearOrderSuccessStorage } from "./CheckoutPayment/CheckoutOrderPayment";
import { calculateTotalAmount } from "../paymentLogic";

function CheckoutRHFWrapper({
  children,
  customer,
}: {
  children: React.ReactNode;
  customer?: CustomerInfo;
}) {
  const { selectedOrderStatus } = useSelector(selectUI);
  const [publishableKey, setPublishableKey] = useState<null | string>(null);
  const [stripeAccountId, setStripeAccountId] = useState<null | string>(null);

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const getPayToken = searchParams.get("token");

  const todayDay = moment().format("ddd").toUpperCase();

  const checkUser =
    (selectedOrderStatus === "DELIVERY" &&
      customer?.firstName &&
      customer?.customerAddresses?.[0]?.id) ||
    (selectedOrderStatus === "PICKUP" && customer?.firstName)
      ? false
      : true;

  const storages = localStorage.getItem("cart");

  const cartDetailsResponse = storages ? getCartList(storages) : null;

  const cartDetails =
    cartDetailsResponse?.status === 200 ? cartDetailsResponse : null;

  const cartLocationId = cartDetails?.location;

  const totalAmountofCart =
    calculateTotalAmount({
      dealList: cartDetails?.dealList || [],
      OrderList: cartDetails?.itemList || [],
    }) || 0;

  const defaultValue: ConfirmOrderForm = {
    customer: {
      firstName: customer?.firstName || "",
      lastName: customer?.lastName || "",
      email: customer?.email || "",
      phone: customer?.phone || "",
      gender: customer?.gender || null,
      address: [],
    },
    editCustomer: {
      firstName: customer?.firstName || "",
      lastName: customer?.lastName || "",
      email: customer?.email || "",
      phone: customer?.phone || "",
      gender: customer?.gender || null,
      address: [],
    },
    orderType: "PICKUP",
    addressType: "PRIMARY",
    scheduledDT: null,
    selectedDay: todayDay,
    selectedScheduleDate: null,
    specialInstruction: "",
    config: {
      isUserInformationEdit: checkUser,
      isUserInformationEditOpeningStatus: checkUser ? "CUSTOMER_ERROR" : null,
      isAddressInformationEdit: false,
      selectedAddress: "PRIMARY",
      isOrderForFuture: false,
      isPageValid: null,
      isPaymentSuccess: false,
      paymentsuccessStatus: null,
      finalTotalOrderForWallet: totalAmountofCart || 0,
      isCustomerAvailable: customer?.firstName ? true : false,
    },
    totalDiscount: 0,
  };

  const methods = useForm<ConfirmOrderForm>({
    defaultValues: defaultValue,
  });

  const { reset, getValues, setValue, control } = methods;

  // console.log("watch => ", watch());

  // const isPageValid = watch("config.isPageValid");

  // const isSuccess = watch("config.isPaymentSuccess");

  const isSuccess = useWatch({
    control,
    name: "config.isPaymentSuccess",
  });

  const isPageValid = useWatch({
    control,
    name: "config.isPageValid",
  });

  console.log("CheckoutPage wrapper =>", cartDetails, isPageValid);

  // const scheduleDataString = localStorage.getItem("scheduleData");

  // const scheduleParse = scheduleDataString ? JSON.parse(scheduleDataString)?.selectedTime?.date : null

  const body = {
    storeId: cartDetails?.location,
    // tradingPeriodId:
    //   cartDetails?.itemList[0]?.periodId || cartDetails?.dealList[0]?.id,
    // storeDt: scheduleParse || ""
  };

  const { data } = useQuery<CheckoutPageDetails>({
    queryKey: ["getCheckoutPageDetails", body],
    queryFn: () =>
      fetchRequest(serverPath?.getCheckoutPageDetails, "POST", body),
    enabled: !!cartDetails?.location,
  });

  const { data: customerAddress } = useQuery<UserAddress[]>({
    queryKey: ["getCustomerAddressByStore"],
    queryFn: () =>
      fetchRequest(
        serverPath.getCustomerAddressByStore(cartDetails?.location as string),
        "GET"
      ),
    enabled: !!cartDetails?.location,
  });

  console.log(customerAddress)

  const { mutate } = useMutation({
    mutationFn: ({
      payToken,
      cartLocation,
    }: {
      payToken: string;
      cartLocation: string;
    }) =>
      fetchRequest(
        serverPath?.paypalTokenCapture(payToken, cartLocation),
        "PUT"
      ),
    onSuccess: (data: any) => {
      console.log("hit", data);
      setValue("config.isPaymentSuccess", true);
      setValue("config.paymentsuccessStatus", "PAID");
      setValue("config.isPageValid", true);
      setValue("config.orderId", data?.id);
      setValue("config.orderNumber", data?.orderNumber);
      setValue("config.finalTotalResponse", data?.finalAmount);
      setValue("storeLocationId", cartLocationId);
      clearOrderSuccessStorage();
    },
    onError: () => {
      console.log("hit false");
      setValue("config.isPaymentSuccess", false);
      setValue("config.isPageValid", false);
      // localStorage.clear();
      clearOrderSuccessStorage();
      // setHasCalledPayPalAPI(true);
    },
  });

  useEffect(() => {
    console.log("main one running when there is token outside");

    if (getPayToken) return;
    console.log("main one running when there is token");
    if (data) {
      dispatch(setOrderLocation(data?.storeDetails));

      if (data?.storeResponse?.storeCurrentDt) {
        dispatch(changeStoreDT(data?.storeResponse?.storeCurrentDt));
        const scheduleData = localStorage.getItem("scheduleData");
        const orderSelectedStatus = localStorage.getItem("selectedOrderStatus");
        console.log(orderSelectedStatus);
        console.log(scheduleData);
        let scheduleDataObj;
        if (scheduleData) {
          scheduleDataObj = JSON.parse(scheduleData);
          console.log(scheduleDataObj);
          console.log(scheduleDataObj?.selectedTime?.date);
          console.log(data?.storeResponse?.storeCurrentDt);
          if (
            moment(scheduleDataObj?.selectedTime?.date).isBefore(
              moment(data?.storeResponse?.storeCurrentDt)
            )
          ) {
            console.log("true");
            scheduleDataObj = {};
            localStorage.removeItem("scheduleData");
            console.log(orderSelectedStatus);
            if (orderSelectedStatus === "DELIVERY") {
              dispatch(changeSelectedOrderStatus("DELIVERY"));
            } else {
              dispatch(changeSelectedOrderStatus("PICKUP"));
            }
          } else {
            console.log(scheduleDataObj?.orderType);
            dispatch(getSchedules(scheduleDataObj));
            const orderType = scheduleDataObj?.orderType;
            if (orderType) {
              console.log(orderType);
              if (orderType === "STORE" || orderType === "PICKUP") {
                dispatch(changeSelectedOrderStatus("PICKUP"));
              } else {
                dispatch(changeSelectedOrderStatus("DELIVERY"));
              }
            }
          }
        }else{
          if (orderSelectedStatus === "DELIVERY") {
            dispatch(changeSelectedOrderStatus("DELIVERY"));
          } else {
            dispatch(changeSelectedOrderStatus("PICKUP"));
          }
        }
      }
      if (data?.stripePublicKey) {
        setPublishableKey(data?.stripePublicKey);
      }
      if (data?.stripeAccountId) {
        setStripeAccountId(data?.stripeAccountId);
      }

      if (data?.estimatedTime) {
        dispatch(changeEstimatedTime(data?.estimatedTime));
      }
      if (data?.tradingHours?.length) {
        dispatch(getTradingHourAction(data?.tradingHours));

        const todayTradinghour = data?.tradingHours
          ?.filter((el) => el?.orderType === selectedOrderStatus || "STORE")
          ?.find(
            (day) =>
              day?.day ===
              moment(data?.storeResponse?.storeCurrentDt)
                .format("ddd")
                .toUpperCase()
          );

        if (selectedOrderStatus) {
          const finalSelectedStatus =
            selectedOrderStatus === "DELIVERY" ? "DELIVERY" : "STORE";
          setValue("orderType", finalSelectedStatus);
        }
        const customer = getValues("customer");
        const checkUserEdit =
          (selectedOrderStatus === "DELIVERY" &&
            customer?.firstName &&
            customerAddress?.[0]?.address?.id) ||
          (selectedOrderStatus === "PICKUP" && customer?.firstName)
            ? false
            : true;

        reset({
          ...getValues(),
          customer: {
            ...getValues("customer"),
            address: customerAddress || [{ type: 'PRIMARY' }, { type: 'SECONDARY' }],
          },
          storeLocationId: data?.storeDetails?.id,
          selectedScheduleDate: todayTradinghour || null,
          orderType:
            selectedOrderStatus && selectedOrderStatus === "PICKUP"
              ? "STORE"
              : "DELIVERY",
          config: {
            ...getValues("config"),
            isUserInformationEdit: checkUserEdit,
            isOrderForFuture: true,
          },
        });

        return setValue("config.isPageValid", true);
      }
      if (data?.todaysTradingHours?.length) {
        dispatch(getTodayTradingHours(data?.todaysTradingHours));
      }
    } else {
      return setValue("config.isPageValid", false);
    }
  }, [
    customerAddress,
    data,
    dispatch,
    getValues,
    publishableKey,
    reset,
    selectedOrderStatus,
    setValue,
    searchParams,
    getPayToken,
  ]);

  useEffect(() => {
    if (!getPayToken) return;

    if (cartLocationId && getPayToken) {
      mutate({
        cartLocation: cartLocationId,
        payToken: getPayToken,
      });
    } else {
      setValue("config.isPageValid", false);
      setValue("config.isPaymentSuccess", false);
      setValue("config.paymentsuccessStatus", null);
    }
  }, []);

  useEffect(() => {
    if (!cartDetails || getPayToken) return;
    dispatch(getEntireOrderList(cartDetails?.itemList));
    dispatch(getEntireDealList(cartDetails?.dealList));
  }, [cartDetails, dispatch, getPayToken]);

  function renderComponent() {
    if (
      isPageValid === null ||
      (isPageValid === false && cartDetails?.location)
    ) {
      return (
        <div className="flex items-center justify-center min-h-[90vh]">
          <img
            src="/images/icons/loading.svg"
            alt="Loading..."
            className="w-20"
          />
        </div>
      );
    } else if (isPageValid === false && !cartDetails?.location && !isSuccess) {
      return (
        <div className="flex min-h-[80vh] flex-col items-center justify-center gap-4">
          {/* <Icon icon="nonicons:not-found-16" width={60} /> */}
          <img
            src="/images/icons/notFound.svg"
            width="60"
            height="60"
            alt="Not Found"
          />

          <p className="font-bold">Looks Like Cart Is Empty ! </p>
          <div>
            <Link to={"/"}>
              <button className="form-btn flex gap-2 items-center justify-center ">
                {/* <Icon icon="icon-park-outline:return" color="white" /> */}
                <img
                  src="/images/icons/return.svg"
                  width="20"
                  height="20"
                  alt="return"
                />
                Return to HomePage
              </button>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <FormProvider {...methods}>
          {isSuccess ? (
            <PaymentStatus />
          ) : publishableKey ? (
            <CheckoutRealProcess
              children={children}
              publishableKey={publishableKey}
              stripeAccountId={stripeAccountId}
            />
          ) : null}

          {/* <Elements stripe={publishableKey ? loadStripe(publishableKey) : null}>
            <form>{children}</form>
          </Elements> */}
        </FormProvider>
      );
    }
  }

  return <>{renderComponent()}</>;
}

export default CheckoutRHFWrapper;
