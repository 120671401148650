import { privacyPolicySections } from "./PrivatePolicy";

function PrivacyPolicyPage() {
  return (
    <div>
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
        {privacyPolicySections.map((section, index) => (
          <div key={index} className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
            {section.content.map((item, idx) => (
              <div key={idx} className="mb-6">
                {item?.subtitle && (
                  <h3 className="text-xl font-medium mb-2">{item?.subtitle}</h3>
                )}

                <p className="text-gray-700 mb-2">{item.description}</p>
                {item?.listItems && (
                  <ul className="list-disc list-inside text-gray-700 pl-4">
                    {item?.listItems.map((listItem, i) => (
                      <li key={i}>{listItem}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
