import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { UserAddress } from "@/app/_types/checkout.interface";
import { SearchStreet } from "@/app/_types/store.interface";
import { UserEditFormForm } from "@/app/component/UserEditForm";
import ErrorAstrick from "@/app/global/elements/ErrorAstrick";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

function UserEditAddress({
  item,
  findIndex,
  checkPrimaryAddress,
}: {
  findIndex: number;
  checkPrimaryAddress: boolean;
  item: UserAddress;
}) {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<UserEditFormForm>();

  const { OrderLocation } = useSelector(order);

  const [selectedAddress, setSelectedAddress] =
    useState<SingleValue<SearchStreet>>(null);

  const AddressOptions = debounce(async (inputValue: string) => {
    if(!inputValue) return []
    if(inputValue?.length <=3) return []
    const storeId = OrderLocation?.id;
    if (!storeId) return [];
    const data: SearchStreet[] = await fetchRequest(
      serverPath.searchStreetsByStore(storeId, inputValue),
      "POST",
      {},
      { "content-type": "application/json" }
    );
    return data;
  }, 500);

  const SelectedAddress = (e: SingleValue<SearchStreet>) => {
    // if (!e?.address) return toast.error("Please Select Street");

    console.log(e);
    if (!e) return;
    // if (!e?.latitude || !e?.longitude) {
    //   toast.error(
    //     "Selected location is not available. Please choose another location."
    //   );
    //   setSelectedAddress(null);
    //   return;
    // }

    if (e?.isTooFar)
      return toast.error(
        "The selected location is too far. Please choose another location."
      );

    console.log("hit");
    setSelectedAddress(e);

    setValue(`address.${findIndex}.address.streetName`, e?.streetName || "");

    setValue(`address.${findIndex}.address.streetNo`, e?.streetNo || "");

    setValue(`address.${findIndex}.address.suburbId`, e?.suburbId);

    setValue(`address.${findIndex}.address.suburbName`, e?.suburbName);

    setValue(`address.${findIndex}.address.type`, item?.type);

    setValue(`address.${findIndex}.address.unitNo`, e?.unitNo || "");
    setValue(`address.${findIndex}.address.longitude`, e?.longitude || 0);
    setValue(`address.${findIndex}.address.latitude`, e?.latitude || 0);
    setValue(`address.${findIndex}.address.pointOfInterest`, e?.pointOfInterest || "")
  };

  return (
    <div
      className="flex flex-col gap-2 p-2 even:bg-background"
      onClick={() => console.log("Running here..")}
    >
      {checkPrimaryAddress ? (
        <div className="flex gap-2 items-center justify-between">
          <p className="font-semibold  first-letter:capitalize">
            <span className="lowercase">{item?.type}</span> Delivery Address
          </p>
        </div>
      ) : null}

      <div className="flex flex-col gap-2">
        <div className=" form-div ">
          <label htmlFor={`unitNo${item.type}`} className="form-label">
            Search Street
            {errors?.address?.[findIndex]?.address?.streetName && (
              <ErrorAstrick />
            )}
          </label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            isClearable
            value={selectedAddress}
            placeholder="Search Street Name..."
            getOptionLabel={(e) => e?.address}
            onChange={(e) => SelectedAddress(e)}
            loadOptions={AddressOptions}
          />
        </div>

        <div className="flex gap-2 flex-wrap">
          <div className=" form-div w-36">
            <label htmlFor={`unitNo${item.type}`} className="form-label">
              Unit
            </label>
            <input
              type="text"
              id={`unitNo${item.type}`}
              {...register(`address.${findIndex}.address.unitNo`)}
              className="form-input"
              placeholder="1234"
            />
          </div>
          <div className="form-div w-24">
            <label htmlFor={`streetNo${item?.type}`} className="form-label">
              Street No
              {errors?.address?.[findIndex]?.address?.streetNo && (
                <span className="text-error text-xl font-bold">*</span>
              )}
            </label>
            <input
              type="text"
              id={`streetNo${item?.type}`}
              {...register(`address.${findIndex}.address.streetNo`, {
                required: findIndex === 0,
              })}
              className="form-input"
              placeholder="C4783"
            />
          </div>
          <div className="form-div">
            <label htmlFor="streetName" className="form-label">
              Street Name
            </label>
            <input
              type="text"
              id="streetName"
              {...register(`address.${findIndex}.address.streetName`, {
                required: findIndex === 0,
              })}
              className="form-input"
              title="Please Search Street"
              disabled
              placeholder=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEditAddress;
