import { order } from "@/app/_redux/slice/Order/OrderSlice";
import CustomDrawerClient from "@/app/global/drawer/CustomDrawerClient";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddToCartDeliveryType from "./AddToCartDeliveryType";

import { Link } from "react-router-dom";
import CartEmpty from "@/app/global/NoData/CartEmpty";
import AddToCartDealList from "./AddToCartDealList";
import AddToCartMenuList from "./AddToCartMenuList";
import { useParams } from "react-router-dom";
import { changeCartOpen, selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import { CalculateMenuTradingPeriod } from "../MenuTradingPeriods/CalculateTradingPeriod";

import moment from "moment";

function AddToCartDrawer({
  onClose,
  open,
}: {
  onClose(): void;
  open: boolean;
}) {
  const { OrderList, dealList, todayTradingHours, schedule } =
    useSelector(order);

  const params: { location?: string } = useParams();

  const dispatch = useDispatch();

  const [tradingStatus, setTradingStatus] = useState(true);
  const [dealOfferMenuStatus, setDealOfferMenuStatus] = useState(true);

  const deal = dealList?.filter((el) => el.cartType === "Deal");
  const offer = dealList?.filter((el) => el.cartType === "Offer");

  const allCartDetails = useMemo(() => {
    return [...dealList, ...OrderList];
  }, [OrderList, dealList]);

  const { selectedOrderStatus, storeDT } = useSelector(selectUI);

  useEffect(() => {
    if (!storeDT) return;
    setTradingStatus(true);
    setDealOfferMenuStatus(true);
    const selectedTime = schedule?.selectedTime?.date
      ? schedule?.selectedTime?.date
      : storeDT;

    let tradingPeriodStatus = true;

    console.log(tradingPeriodStatus);
    allCartDetails.forEach((item) => {
      // const cartStatus = CalculateTradingPeriod({
      //   tradingPeriodId: item?.periodId,
      //   todayTradingHoursData: todayTradingHours,
      //   selectedOrderStatus,
      //   storeDT,
      // });

      const selectedDay = schedule?.selectedDay?.day
        ? schedule?.selectedDay?.day
        : moment(storeDT).format("ddd").toUpperCase();

      const cartStatus = CalculateMenuTradingPeriod({
        selectedOrderStatus,
        tradingHoursData: item?.tradingHours,
        tradingPeriodId: item?.periodId,
        selectedTime,
        selectedDay,
        storeDT,
      });

      if (cartStatus?.status === false) {
        tradingPeriodStatus = false;
        setTradingStatus(false);
        return;
      }

      dealList?.forEach((deals) => {
        deals?.dealItems?.some((dealItem) => {
          return dealItem?.menus?.some((menu) => {
            const tradingHours = menu?.tradingHours;
            const result = CalculateMenuTradingPeriod({
              selectedOrderStatus,
              tradingHoursData: tradingHours,
              tradingPeriodId: deals?.periodId,
              selectedTime: schedule?.selectedTime?.date
                ? schedule?.selectedTime?.date
                : storeDT,
              selectedDay,
              storeDT,
            });

            // Check if the status is false
            if (!result.status) {
              tradingPeriodStatus = false;
              setDealOfferMenuStatus(false);
              return true;
            }
          });
        });
      });

      // if (!tradingPeriodStatus) {
      //   toast.error(`Please remove the crossed orders.`);
      // }
    });
  }, [
    todayTradingHours,
    selectedOrderStatus,
    storeDT,
    allCartDetails,
    tradingStatus,
    schedule?.selectedTime?.date,
    dealList,
    dealOfferMenuStatus,
  ]);

  if (!params?.location) return null;

  return (
    <CustomDrawerClient onClose={onClose} open={open}>
      <div className="h-[100dvh] relative flex flex-col">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl p-2">Cart Details</h2>
          <button className="pr-4" onClick={onClose}>
            <img
              src={"/images/icons/close.svg"}
              width={25}
              height={25}
              alt="close"
              className="text-red-500"
            />
          </button>
        </div>
        {OrderList?.length || dealList?.length ? (
          <>
            {/* {(selectedStore?.isDeliveryEnabled ?? true) && ( */}
              <AddToCartDeliveryType />
            {/* )} */}
            {!tradingStatus ||
              (!dealOfferMenuStatus && (
                <div className="flex justify-center">
                  <p className="text-red-500">
                    Please remove the crossed orders.
                  </p>
                </div>
              ))}
            <div className="overflow-y-auto grow py-4">
              <AddToCartDealList
                location={params?.location}
                dealList={deal}
                title="Deal"
              />
              <AddToCartDealList
                location={params?.location}
                dealList={offer}
                title="Offer"
              />
              <AddToCartMenuList location={params?.location} />
            </div>
            <div className="bg-white md:p-1">
              <div className="flex">
                <Link
                  onClick={() => dispatch(changeCartOpen(false))}
                  to={tradingStatus && dealOfferMenuStatus ? "/checkout" : "#"}
                  className={`text-center p-4 rounded-lg m-2 text-white flex-1 ${
                    tradingStatus && dealOfferMenuStatus
                      ? "bg-[rgba(141,190,73)]"
                      : "bg-gray-500"
                  }`}
                >
                  CheckOut
                </Link>
              </div>
            </div>
          </>
        ) : (
          <CartEmpty />
        )}
      </div>
    </CustomDrawerClient>
  );
}

export default AddToCartDrawer;
