import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
import { CustomerInfo } from "@/app/_types/customer.interface";
import { useQueryClient } from "@tanstack/react-query";

import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

function CheckoutStoreInformation() {
  const { OrderLocation } = useSelector(order);
  console.log(
    "🚀 => CheckoutStoreInformation => OrderLocation=>",
    OrderLocation
  );

  const client = useQueryClient();
  const auth = client.getQueryData<CustomerInfo>(["currentCustomer"]);

  const { control } = useFormContext<ConfirmOrderForm>();

  const isUserEdit = useWatch({
    control,
    name: "config.isUserInformationEdit",
  });
  return (
    <div className="flex flex-col gap-4 divide-y-2 relative">
      <div className=" flex gap-4 flex-col lg:flex-row">
        <div className="flex flex-col gap-4">
          <div>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28245.873938499564!2d85.32251448471185!3d27.756341901825223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19547edb4d09%3A0xbc7c8c4ba38da134!2sDharampur%2044600!5e0!3m2!1sen!2snp!4v1693558768252!5m2!1sen!2snp"
              width="300"
              height="200"
              style={{ border: 0 }}
              loading="lazy"
              className="w-full"
              // referrerpolicy={no-referrer-when-downgrade}
            ></iframe> */}
            {OrderLocation?.latitude && OrderLocation?.longitude ? (
              <DynamicMap
                lat={OrderLocation?.latitude}
                lng={OrderLocation?.longitude}
              />
            ) : null}
          </div>
        </div>
        <div>
          <h2 className="font-bold text-lg pb-4">{OrderLocation?.name}</h2>
          <p className="text-sm">{OrderLocation?.address}</p>
          <p className="text-sm">{OrderLocation?.phone}</p>
          <p className="text-sm">{OrderLocation?.email}</p>
        </div>
      </div>
      {(!auth?.firstName || isUserEdit === true) && (
        <div className="absolute bg-black/60 inset-0 rounded-md" />
      )}
      {/* {isUserEdit === true && (
        <div className="absolute bg-black/60 inset-0 rounded-md" />
      )} */}
    </div>
  );
}

export function DynamicMap({ lat, lng }: { lat: number; lng: number }) {
  // const src = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d28245.873938499564!2d${lng}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v${Date.now()}!5m2!1sen!2snp`;

  const src = `https://www.google.com/maps?q=${lat},${lng}&hl=es;z=14&output=embed`;
  return (
    <iframe
      src={src}
      width="300"
      height="200"
      style={{ border: 0 }}
      loading="lazy"
    ></iframe>
  );

  // const src = `https://www.google.com/maps?q=${lat},${lng}&hl=es;z=14&output=embed`;

  // return (
  //   <iframe
  //     src={src}
  //     width="300"
  //     height="200"
  //     style={{ border: 0 }}
  //     // allowFullScreen
  //     loading="lazy"
  //   ></iframe>
  // );
}

export default CheckoutStoreInformation;
