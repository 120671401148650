import { useRoutes } from "react-router-dom";
import HomePage from "../HomePageIndex";
import StoreLocationPage from "../[location]/StoreLocationPage";
// import ProfilePage from "../profile/details/ProfilePage";
// import UserAddress from "../profile/address/UserAddress";
// import UserOrderHistory from "../profile/order-history/UserOrderHistory";
// import UserLastOrderDetails from "../profile/map/UserLastOrderDetails";
import MenuDetailsPage from "../[location]/menu/[periodId]/[name]/page";
import DealDetailsPage from "../[location]/deal/[periodId]/[name]/page";
import OfferDetailsPage from "../[location]/offer/[periodId]/[name]/page";
import LoginPage from "../auth/login/page";
// import RegisterPage from "../auth/register/page";
import AuthNavbarProvider from "../guard/AuthNavbarProvider";
import CheckoutPage from "../checkout/page";
// import ForgetPage from "../forget/page";
import PrivacyPolicyPage from "../privacy-policy/page";
import TermsAndConditionPage from "../terms-and-condition/page";
import EmailUnsubscribePage from "../UnsubscribeEmail/page";
import NotFound404 from "../global/NoData/NotFound404";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },

    // {
    //   path: "/profile",
    //   element: (
    //     <AuthNavbarProvider isLogoNavbarOnly>
    //       <ProfilePage />
    //     </AuthNavbarProvider>
    //   ),
    // },
    // {
    //   path: "/profile/address",
    //   element: (
    //     <AuthNavbarProvider isLogoNavbarOnly>
    //       <UserAddress />
    //     </AuthNavbarProvider>
    //   ),
    // },
    // {
    //   path: "/profile/order-history",
    //   element: (
    //     <AuthNavbarProvider isLogoNavbarOnly>
    //       <UserOrderHistory />
    //     </AuthNavbarProvider>
    //   ),
    // },
    // {
    //   path: "/profile/last-order",
    //   element: (
    //     <AuthNavbarProvider isLogoNavbarOnly>
    //       <UserLastOrderDetails />
    //     </AuthNavbarProvider>
    //   ),
    // },

    {
      path: "/:location",
      element: (
        <AuthNavbarProvider>
          <StoreLocationPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/:location/menu/:periodId/:name",
      element: (
        <AuthNavbarProvider>
          <MenuDetailsPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/:location/deal/:periodId/:name",
      element: (
        <AuthNavbarProvider>
          <DealDetailsPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/:location/offer/:periodId/:name",
      element: (
        <AuthNavbarProvider>
          <OfferDetailsPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/auth/login",
      element: (
        <AuthNavbarProvider isLogoNavbarOnly>
          <LoginPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/auth/register",
      element: (
        <AuthNavbarProvider isLogoNavbarOnly>
          {/* <RegisterPage /> */}
          <LoginPage />
        </AuthNavbarProvider>
      ),
    },
    // {
    //   path: "/forget",
    //   element: (
    //     <AuthNavbarProvider isLogoNavbarOnly>
    //       <ForgetPage />
    //     </AuthNavbarProvider>
    //   ),
    // },
    {
      path: "/checkout",
      element: (
        <AuthNavbarProvider isLogoNavbarOnly>
          <CheckoutPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <AuthNavbarProvider isLogoNavbarOnly>
          <PrivacyPolicyPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/terms-and-condition",
      element: (
        <AuthNavbarProvider isLogoNavbarOnly>
          <TermsAndConditionPage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "/email/unsubscribe",
      element: (
        <AuthNavbarProvider isLogoNavbarOnly>
          <EmailUnsubscribePage />
        </AuthNavbarProvider>
      ),
    },
    {
      path: "*",
      element: (
        <AuthNavbarProvider isLogoNavbarOnly>
          <NotFound404 />
        </AuthNavbarProvider>
      ),
    },
  ]);
}
