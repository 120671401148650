import { StoreEntity } from "@/app/_types/store.interface";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import { EstimatedTime } from "@/app/_redux/slice/UISlice/UISlice.d";
import {
  EcommerceStoreImageData,
  MenuWithCategories,
} from "@/app/_types/menuWithCategories";
import CategoryListHeadersImage from "./CategoryListHeadersImage";

interface CategoryListHeaders {
  store?: StoreEntity;
  tradingHours: TradingHour[];
  estimatedTime?: EstimatedTime;
  storeImages: EcommerceStoreImageData[];
  data: MenuWithCategories;
  storeDT: string;
}

function CategoryListHeaders({ storeImages }: CategoryListHeaders) {
  return (
    <div className="relative h-[50vh] inset-0 z-[-1]">
      {storeImages?.length > 0 ? (
        <CategoryListHeadersImage storeImages={storeImages} />
      ) : (
        <img
          src={"/defaultStore.png"}
          width="800"
          height="800"
          alt="Location"
          className="w-full h-full object-cover object-center"
        />
      )}
    </div>
  );
}

export default CategoryListHeaders;
