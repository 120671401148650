import {
  getSchedules,
  order,
  scheduleModel,
} from "@/app/_redux/slice/Order/OrderSlice";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import {
  changeSelectedOrderStatus,
  selectUI,
} from "@/app/_redux/slice/UISlice/UISlice";
import { useAppDispatch } from "@/app/_redux/store";
import { useCallback, useEffect } from "react";

import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";

interface DeliveryType {
  id: number;
  name: string;
  value: "DELIVERY" | "PICKUP";
}

const DELIVERY_TYPE: DeliveryType[] = [
  { id: 1, name: "Delivery", value: "DELIVERY" },
  { id: 2, name: "PickUp", value: "PICKUP" },
];

function DeliveryType() {
  const dispatch = useAppDispatch();
  const { selectedOrderStatus } = useSelector(selectUI);
  const { schedule } = useSelector(order);
  const { selectedStore } = useSelector(selectStore);

  const handleChange = useCallback((value: "DELIVERY" | "PICKUP") => {
    localStorage.setItem("selectedOrderStatus", value);
    dispatch(changeSelectedOrderStatus(value));
    if (schedule?.orderType !== value && schedule !== null) {
      dispatch(getSchedules(null));
      dispatch(scheduleModel(true));
    }
  },[dispatch, schedule]);

  useEffect(() => {
    if(selectedStore?.isDeliveryEnabled === false){
      handleChange("PICKUP")
    }
  },[handleChange, selectedStore?.isDeliveryEnabled])

  return (
    <div className={twMerge(`relative h-10 bg-gray-200 rounded-full p-1 flex items-center`, (selectedStore?.isDeliveryEnabled ?? true) ? "w-48" : "w-24")}>
      {/* Moving Circle */}
      <div
        className={twMerge(
          `absolute h-8 bg-white rounded-full shadow-md 
            transition-transform duration-300 ease-in-out`,
          selectedOrderStatus === "PICKUP"
            ? "translate-x-[100%]"
            : "translate-x-0",
            (selectedStore?.isDeliveryEnabled ?? true) ? "w-[48%]" : "hidden"
        )}
      />
      {/* Options */}
      {DELIVERY_TYPE.map((type) => (
        <div
          key={type.id}
          className={twMerge(
            `flex-1 text-center z-10 cursor-pointer`,
            selectedStore?.isDeliveryEnabled === false && type.value === "DELIVERY" && "hidden"
          )}
          onClick={() => handleChange(type.value)}
        >
          <span
            className={twMerge(
              `text-sm font-medium leading-10 transition-colors duration-300 ease-in-out`,
              selectedOrderStatus === type.value
                ? "text-black"
                : "text-gray-500"
            )}
          >
            {type.name}
          </span>
        </div>
      ))}
    </div>
  );
}

export default DeliveryType;
