
import { twMerge } from "tailwind-merge";

function MenuDetailsLoadingScreen() {
  return (
    <div className="w-full md:w-3/4 m-auto my-4 min-h-[90vh]">
      <div className="container mx-auto flex h-full gap-4 flex-wrap md:flex-nowrap ">
        <div className="w-full md:w-1/2 overflow-y-auto flex flex-col">
          <div className={twMerge("loading-images h-[40vh] md:h-[80vh] ")} />
        </div>

        <div className="w-full md:w-1/2 overflow-y-auto flex flex-col gap-4">
          <div className="heading-loading-text" />
          <div className="loading-text" />
          <div className="loading-text" />
          <div className="loading-text" />
          <div className="flex flex-row gap-4 flex-wrap">
            <div className="loading-text" />
            <div className="loading-text" />
            <div className="loading-text" />
          </div>
          <div className="loading-text" />
        </div>
      </div>
    </div>
  );
}

export default MenuDetailsLoadingScreen;
