import { PaymentElement } from "@stripe/react-stripe-js";
import CheckoutCardPaymentButton from "./CheckoutCardPaymentButton";
// import { useState } from "react";
// import { StripeExpressCheckoutElementReadyEvent } from "@stripe/stripe-js";

function CheckoutCardPayment() {
  // const [isLoading, setIsLoading] = useState(true);

  // const onReady = (
  //   availablePaymentMethods: StripeExpressCheckoutElementReadyEvent
  // ) => {
  //   if (!availablePaymentMethods) {
  //     // No buttons will show
  //   } else {
  //     // Optional: Animate in the Element
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div className="py-4 flex flex-col gap-4">
      {/* {isLoading && <div>Loading...</div>} */}
      {/* <ExpressCheckoutElement
        onConfirm={() => {}}
        onReady={(event) => onReady(event)}
      /> */}
      <PaymentElement />
      {/* <div className="form-div">
        <label htmlFor="firstName" className="form-label">
          Card Number
        </label>
        <CardNumberElement className="form-input" />
      </div> */}
      {/* <div className="flex gap-4">
        <div className="form-div">
          <label htmlFor="firstName" className="form-label">
            Expiry Date
          </label>
          <CardExpiryElement className="form-input" />
        </div>
        <div className="form-div">
          <label htmlFor="firstName" className="form-label">
            CVC
          </label>
          <CardCvcElement className="form-input min-w-[5rem]" />
        </div>
      </div> */}
      <div className="flex border-t-2">
        <CheckoutCardPaymentButton />
      </div>
    </div>
  );
}

export default CheckoutCardPayment;
