import { Option2, OptionGroup } from "@/app/_types/extraMenuPriceInfo";
import React from "react";
import MenuDealExtraOptionItem from "./MenuDealExtraOptionItem";

interface MenuDealExtraOptionAccordionProps {
  option: OptionGroup;
  optionName: Option2[];
  open: number | null;
  setOpen: React.Dispatch<React.SetStateAction<number | null>>;
  index: number;
  optionGroups: OptionGroup[];
}

function MenuDealExtraOptionAccordion({
  option,
  optionName,
  open,
  setOpen,
  index,
  optionGroups,
}: MenuDealExtraOptionAccordionProps) {
  const AccordionHandler = () => {
    if (open === index) return setOpen(null);
    setOpen(index);
  };
  return (
    <div className="py-[1px]">
      <button
        className=" w-full bg-gray-200 px-2 py-2 rounded-md flex items-center justify-between"
        onClick={AccordionHandler}
      >
        <p className="">{option.name}</p>
        {index === open ? (
          <img src="/images/icons/up.svg" alt="v" width={16} height={16} />
        ) : (
          <img src="/images/icons/down.svg" alt="v" width={16} height={16} />
        )}
      </button>
      <div
        className={
          open === index
            ? `divide-y-2 h-auto bg-white transition-all`
            : "scale-0 h-0"
        }
      >
        {option?.options?.map((item, index) => (
          <MenuDealExtraOptionItem
            item={item}
            key={index}
            optionName={optionName}
            optionGroups={optionGroups}
          />
        ))}
      </div>
    </div>
  );
}

export default MenuDealExtraOptionAccordion;
