import ScheduleButton from "@/app/[location]/components/ScheduleButton";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { updateMenuWithCategoryList } from "@/app/_redux/slice/menuWithCategories.ts/menuWithCategoriesSlice";
import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { ConfirmOrderForm } from "@/app/_types/checkout.interface";
import { MenuWithCategories } from "@/app/_types/menuWithCategories";


import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function CheckoutManageFutureOrderButton() {
  const { control } = useFormContext<ConfirmOrderForm>();
  const { tradingHours, OrderLocation } = useSelector(order);
  const dispatch = useDispatch();

  const watchOrderValid = useWatch({
    control,
    name: "config.isOrderForFuture",
  });

  const storeId = useWatch({
    control,
    name: "storeLocationId",
  });

  const handleSelectedTradingPeriod = async ({
    tradingPeriodId,
  }: {
    tradingPeriodId: string;
  }) => {
    if (!storeId) return;
    const catWithMenu: MenuWithCategories = await fetchRequest(
      serverPath.categoriesWithMenu(storeId, tradingPeriodId),
      "GET",
      undefined,
      { cache: "no-cache" }
    );
    console.log(catWithMenu);
    if (catWithMenu?.tradingPeriods) {
      console.log(catWithMenu?.tradingPeriods);
      dispatch(updateMenuWithCategoryList(catWithMenu));
    } else {
      toast.error("Something went wrong.While fetching category");
    }
  };

  return (
    <>
      <ScheduleButton
        tradingHours={tradingHours}
        btnClass="border-0 rounded-none p-0 bg-transparent text-primary "
        btnText="Schedule Order ? "
        watchOrderValid={watchOrderValid}
        handleSelectedTradingPeriod={handleSelectedTradingPeriod}
        isDelivery={OrderLocation?.isDeliveryEnabled ?? true}
      />
    </>
  );
}

export default CheckoutManageFutureOrderButton;
