import { ModalProps } from "@/app/_types/common";
import { StoreEntity } from "@/app/_types/store.interface";

// import StoreLocationItem from "../HomePage/StoreLocationItem";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";
import HomePageStoreLocationItem from "../HomePage/HomePageStoreLocationItem";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";

interface SelectStoreModalProps extends ModalProps {
  storeList: StoreEntity[];
}

function SelectStoreModal({
  openModal,
  storeList,
  handleClose,
}: SelectStoreModalProps) {
  // function getSelectedStore() {
  //   handleClose();
  // }
    const { storeThemeData } = useSelector(selectUI);

    const ThemeStoreData = storeList?.map((store) => {
      const location = storeThemeData?.data?.location?.stores?.find(
        (loc) => loc?.aboutStore?.storeid === store?.id.toString()
      );
      if (location) {
        return { ...store, imagesData: location?.imagesData };
      } else {
        return store;
      }
    });
  

  return (
    <CustomModalClients
      open={openModal}
      handleClose={handleClose}
      // className="w-full h-full"
      className="w-full h-full overflow-auto"
    >
      <div>
        <div className="flex justify-between items-center p-4 md:p-6 border-b-2">
          <h1 className="font-bold text-xl ">We are Located in</h1>
          <button
            onClick={handleClose}
            // className={twMerge("lg:hidden", !isModal && "hidden")}
          >
            <img
              src="/images/icons/close.svg"
              width={25}
              height={25}
              alt="close"
            />
          </button>
        </div>
        <div className="  p-4 md:p-6 grid  grid-cols-custom-300 gap-2 md:gap-8 ">
          {ThemeStoreData?.map((store, index) => (
            // <StoreLocationItem
            //   store={store}
            //   getSelectedStore={getSelectedStore}
            //   key={index}
            // />
            <HomePageStoreLocationItem store={store} key={index}/>
          ))}
        </div>
      </div>
    </CustomModalClients>
  );
}

export default SelectStoreModal;
