import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { StoreSlice } from "./storeSlice.interface";

const initialState: StoreSlice = {
  storeList: [],
  selectedStore: null,
  storeDetails: null,
  customerDetails: null,
};

export const storeSlice = createSlice({
  name: "storeSlice",
  initialState,
  reducers: {
    addSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
    },

    addStoreList: (state, action) => {
      state.storeList = action.payload;
    },
    addStoreDetails: (state, action) => {
      state.storeDetails = action.payload;
    },
    clearSelectedStore: (state) => {
      state.selectedStore = null;
    },

    clearStoreList: (state) => {
      state.storeList = [];
    },
    addCustomerDetails: (state, action) => {
      state.customerDetails = action.payload;
    },
  },
});

export const {
  addSelectedStore,
  addStoreList,
  addStoreDetails,
  clearSelectedStore,
  clearStoreList,
  addCustomerDetails,
} = storeSlice.actions;
export const selectStore = (state: RootState) => state.store;

export default storeSlice.reducer;
