import SelectStoreButton from "./SelectStoreButton";
import { serverPath } from "@/app/_axios/path";
import fetchRequest from "@/app/_axios/fetchRequest";
import { useQuery } from "@tanstack/react-query";

function SelectStore() {
  const { data, isLoading } = useQuery({
    queryKey: ["storeList"],
    queryFn: () =>
      fetchRequest(serverPath.storeList, "GET", undefined, {
        cache: "no-store",
      }),
  });

  if (isLoading) return <div>loading..</div>;

  return <SelectStoreButton storeList={data} />;
}

export default SelectStore;
