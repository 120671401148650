import { ScheduleDTEntity } from "@/app/_types/checkout.interface";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import moment, { Moment } from "moment";

const dayOrder: { [key: string]: number } = {
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
  SUN: 7,
};

export function getNextSixDaysWithNames(storeDate: string) {
  const days = [];
  const today = moment(storeDate);

  for (let i = 0; i <= 6; i++) {
    let dayName = "";
    if (i === 0) {
      dayName = "TODAY";
    } else if (i === 1) {
      dayName = "TOMORROW";
    }

    const dateObject = {
      day: today.clone().add(i, "days").format("ddd").toUpperCase(),
      date: today.clone().add(i, "days").format("D MMM"),
      name: dayName,
    };

    days.push(dateObject);
  }

  return days;
}

// export function getDateTimeList(
//   days: TradingHour,
//   estimatedTime: number,
//   storeDT: string,
//   previousDay?: TradingHour
// ) {
//   const timeArray: ScheduleDTEntity[] = [];

//   let getPreviousDateTime: null | ScheduleDTEntity[] = null;

//   if (previousDay) {
//     getPreviousDateTime = previousDayTradingHourCheck(
//       previousDay,
//       estimatedTime,
//       storeDT
//     );
//   }


//   let startTime = {};
//   const daysStartTime = moment(days?.startTime, "HH:mm:ss")

//   if (days?.day === moment(storeDT).format("ddd").toUpperCase()) {
//     if (days?.startTime < moment(storeDT).format("HH:mm:ss")) {
//       // startTime = storeDT;
//       startTime = moment(storeDT)
//         .add(5 - (moment(storeDT).minute() % 5), "minutes")
//         .startOf("minute");
//     } else {
//       startTime = moment(days?.startTime, "HH:mm:ss");
//     }
//   } else {
//     startTime = moment(days?.startTime, "HH:mm:ss");
//   }

//   // console.log("timeArray1", days?.startTime, moment(storeDT).format("HH:mm:ss"),days?.startTime < moment(storeDT).format("HH:mm:ss"), moment(storeDT).format("ddd").toUpperCase())
  
//   const startingDateHour = moment(startTime).add(estimatedTime, "minute");
//   console.log("timeArray1",startingDateHour, startTime,days?.day,moment(storeDT).format("ddd").toUpperCase(),days?.startTime, moment(storeDT).format("HH:mm:ss"))
//   // const startingDateHour = moment(days?.startTime, "HH:mm:ss").add(estimatedTime, "minute");

//   const startingHour = startingDateHour.hour();
//   const startingMinute = startingDateHour.minute();
//   const startingDay = startingDateHour.day();

//   const endingDateHour = moment(days?.endTime, "HH:mm:ss");
//   const endingHour = endingDateHour.hour();
//   const endingMinute = endingDateHour.minute();

//   const daysStartDateHour = daysStartTime.hour();
//   const daysStartMinute = daysStartTime.minute();

//   let daysNewStartMinute = moment()
//   .clone()
//   .day(days.day)
//   .hour(daysStartDateHour)
//   .minute(daysStartMinute)
//   .second(0)
//   .millisecond(0)

//   let newStartTime = moment()
//     .clone()
//     .day(days?.day)
//     .hour(startingHour)
//     .minute(startingMinute)
//     .second(0)
//     .millisecond(0);


//   let newEndTime: Moment = moment()
//     .clone()
//     .day(days?.day)
//     .hour(endingHour)
//     .minute(endingMinute)
//     .second(0)
//     .millisecond(0);

//   console.log("timeArray newEndTIme", newEndTime, days, daysNewStartMinute)
//   console.log("timeArray newStartTIme",newStartTime, days?.startTime < moment(storeDT).format("HH:mm:ss"), startTime, startingDay)


//   if (days?.isTomorrow) {
//     newEndTime = newEndTime.add(1, "days");
//   }

//   if (
//     newEndTime.isBefore(daysNewStartMinute)
//     // days?.day !== moment(storeDT).format("ddd").toUpperCase()
//   ) {
//     // Add 1 day to endTime
//     newEndTime = newEndTime.add(1, "days");
//   }

//   while (newStartTime <= newEndTime) {
//     // if (moment(storeDT).isBefore(newStartTime)) {
//     timeArray.push({
//       label: newStartTime?.format("hh:mm A"),
//       // date: newStartTime.format("YYYY-MM-DDTHH:mm:ss"),
//       date: moment().isAfter(newStartTime)
//         ? moment(newStartTime).add(7, "days").format("YYYY-MM-DDTHH:mm:ss")
//         : moment(newStartTime).format("YYYY-MM-DDTHH:mm:ss"),
//       value: moment().isAfter(newStartTime)
//         ? moment(newStartTime)?.add(7, "days").format("Do MMM - hh:mm A")
//         : moment(newStartTime).format("Do MMM - hh:mm A"),
//     });
//     // }
//     newStartTime = moment(newStartTime).clone().add(15, "minutes");
//   }

//   // console.log("timeArray",newStartTime, newEndTime, newStartTime <= newEndTime,storeDT, moment(storeDT), timeArray)
//   // console.log("timeArray",days,storeDT, timeArray, newStartTime, newEndTime,moment(newStartTime).format("HH:MM:ss A"), moment(newEndTime).format("HH:MM:ss A"))
//   if (!getPreviousDateTime?.length) {
//     return timeArray;
//   } else {
//     return [...getPreviousDateTime, ...timeArray];
//   }
// }

// checking if the trading hour falls on previous day as well

function getNextOccurrenceOfDay(dayStr: string, storeDT: string): Moment {
  const d = moment(storeDT).day(dayStr);
  if (d.isBefore(moment(storeDT), "day")) {
    d.add(1, "week");
  }
  return d;
}

/**
 * Main function:
 * Builds an array of times (every 15 min) between [startTime, endTime]
 * factoring in storeDT so we don't list past slots.
 */
export function getDateTimeList(
  days: TradingHour,
  estimatedTime: number,
  storeDT: string,
  previousDay?: TradingHour
): ScheduleDTEntity[] {
  const timeArray: ScheduleDTEntity[] = [];

  // 1) Possibly gather "previousDay" data
  let getPreviousDateTime: ScheduleDTEntity[] | null = null;
  if (previousDay) {
    getPreviousDateTime = previousDayTradingHourCheck(
      previousDay,
      estimatedTime,
      storeDT
    );
  }

  // 2) Convert storeDT and start/end to Moment
  const storeDTMoment = moment(storeDT); // The "current" or "reference" time
  const dayStartTime = moment(days.startTime, "HH:mm:ss"); // e.g., 17:00:00 for 5 PM
  const dayEndTime   = moment(days.endTime,   "HH:mm:ss"); // e.g., 20:30:00 for 8:30 PM
  const storeDayName = storeDTMoment.format("ddd").toUpperCase(); // e.g., "FRI"

  // 3) Decide the base "startTime" for generating slots.
  //    a) If the requested day is *today* (days.day === storeDayName),
  //       we stay on today's date/time (no "getNextOccurrenceOfDay" jump).
  //    b) Otherwise, we jump to the next occurrence of that weekday.
  let baseDateForThisDay: Moment;

  if (days.day === storeDayName) {
    // If it's "today," build the official "endTime for today."
    const endTimeForToday = storeDTMoment
      .clone()
      .set({
        hour:   dayEndTime.hour(),
        minute: dayEndTime.minute(),
        second: 0,
        millisecond: 0,
      });

    // If the storeDT is already past today's endTime, no slots
    if (storeDTMoment.isAfter(endTimeForToday)) {
      // e.g. It's 10:49 PM, store closes 8:30 PM => return []
      if (getPreviousDateTime?.length) return getPreviousDateTime;
      return [];
    }

    // Otherwise, see if storeDT is after the day's official startTime
    // If so, round up to the next 5-min boundary from storeDT
    const startTimeForToday = storeDTMoment
      .clone()
      .set({
        hour:   dayStartTime.hour(),
        minute: dayStartTime.minute(),
        second: 0,
        millisecond: 0,
      });

    if (storeDTMoment.isAfter(startTimeForToday)) {
      // If storeDT is 6:10 PM but dayStartTime is 5:00 PM,
      // we move up to storeDT (rounded to next 5 min).
      const minuteRemainder = storeDTMoment.minute() % 5;
      baseDateForThisDay = storeDTMoment
        .clone()
        .add(5 - minuteRemainder, "minutes")
        .startOf("minute");
    } else {
      // If storeDT is before the official start, just use dayStartTime
      baseDateForThisDay = startTimeForToday;
    }
  } else {
    // Different weekday
    const dayTarget = getNextOccurrenceOfDay(days.day, storeDT);
    baseDateForThisDay = dayTarget
      .clone()
      .hour(dayStartTime.hour())
      .minute(dayStartTime.minute())
      .second(0)
      .millisecond(0);
  }

  // 4) Add "estimatedTime" offset => earliest possible slot
  const earliestSlotTime = baseDateForThisDay.clone().add(estimatedTime, "minutes");

  // 5) Build newStartTime / newEndTime
  let newStartTime = earliestSlotTime.clone();
  let newEndTime: Moment;

  if (days.day === storeDayName) {
    // If it's the same day as "today," tie the endTime to today's date
    newEndTime = baseDateForThisDay
      .clone()
      .hour(dayEndTime.hour())
      .minute(dayEndTime.minute());
  } else {
    // Otherwise, tie the endTime to the next occurrence of that day
    const dayTarget = getNextOccurrenceOfDay(days.day, storeDT);
    newEndTime = dayTarget
      .clone()
      .hour(dayEndTime.hour())
      .minute(dayEndTime.minute())
      .second(0)
      .millisecond(0);
  }

  // 6) If isTomorrow is set, meaning it crosses midnight, add 1 day to end
  if (days.isTomorrow) {
    newEndTime.add(1, "day");
  }

  // If end time ended up behind start time, shift it a day
  if (newEndTime.isBefore(newStartTime)) {
    newEndTime.add(1, "day");
  }

  console.log("hit tradingHourLogic", days, newEndTime, newStartTime, storeDTMoment)

  // 7) Build slots in 15-min increments
  while (newStartTime.isSameOrBefore(newEndTime)) {
    // Skip if this slot is already behind storeDT 
    if (newStartTime.isBefore(storeDTMoment)) {
      newStartTime.add(15, "minutes");
      continue;
    }

    timeArray.push({
      label: newStartTime.format("hh:mm A"),
      date:  newStartTime.format("YYYY-MM-DDTHH:mm:ss"),
      value: newStartTime.format("Do MMM - hh:mm A"),
    });

    newStartTime.add(15, "minutes");
  }

  console.log(getPreviousDateTime)
  console.log("hit tradingHourLogic", getPreviousDateTime)

  // 8) Merge any previousDay data if needed
  if (getPreviousDateTime?.length) {
    return [...getPreviousDateTime, ...timeArray];
  }
  return timeArray;
}

export function previousDayTradingHourCheck(
  previousTradingHour: TradingHour,
  estimatedTime: number,
  storeDT: string
) {
  if (
    previousTradingHour?.isClosed ||
    !previousTradingHour?.startTime ||
    !previousTradingHour?.endTime ||
    !previousTradingHour?.isTomorrow
  )
    return null;


  const timeArray: ScheduleDTEntity[] = [];

  const previousTHStartTime = moment(
    previousTradingHour?.startTime,
    "HH:mm:ss"
  );
  let newStartTime = moment()
    .clone()
    .isoWeekday(dayOrder[previousTradingHour?.day])
    .set({
      hour: previousTHStartTime.hour(),
      minute: previousTHStartTime.minute(),
      second: previousTHStartTime.second(),
    })
    .add(estimatedTime, "minute");

  const previousTHEndTime = moment(previousTradingHour?.endTime);

  let newEndTime = moment()
    .clone()
    .isoWeekday(dayOrder[previousTradingHour?.day])
    .add(1, "day")
    .set({
      hour: previousTHEndTime.hour(),
      minute: previousTHEndTime.minute(),
      second: previousTHEndTime.second(),
    });


  while (newStartTime.isBefore(newEndTime)) {
    if (
      // newStartTime.isSame(moment(storeDT), "day") &&
      moment(storeDT).isBefore(newStartTime) &&
      moment(newStartTime).isSame(moment(newEndTime), "day")
    ) {
      timeArray.push({
        label: newStartTime?.format("hh:mm A"),
        date: newStartTime.format("YYYY-MM-DDTHH:mm:ss"),
        value: newStartTime?.format("Do MMM - hh:mm A"),
      });
    }

    newStartTime = moment(newStartTime).clone().add(15, "minutes");
  }

  return timeArray;
}
