import {
  order,
} from "@/app/_redux/slice/Order/OrderSlice";
import { useSelector } from "react-redux";
import AddToCartDrawer from "./AddToCartDrawer";

import { useAppDispatch } from "@/app/_redux/store";
import { changeCartOpen, selectUI } from "@/app/_redux/slice/UISlice/UISlice";

function AddToCartButton() {
  // const [open, setOpen] = useState(false);
  const { OrderList, dealList } = useSelector(order);
  const dispatch = useAppDispatch();
 

  const { cartOpen } = useSelector(selectUI);


  const OpenHandler = () => {
    // setOpen(true);
    dispatch(changeCartOpen(true))
  };

  const CloseHandler = () => {
    // setOpen(false);
    dispatch(changeCartOpen(false))

  };

  const dealLength = dealList?.reduce((acc, current) => current?.qty + acc, 0);
  const orderLength = OrderList?.reduce(
    (acc, current) => current?.qty + acc,
    0
  );

  const totalLength = dealLength + orderLength;

  
  return (
    <>
      <button
        className=" p-2 md:p-4 text-sm border-purple-500 flex gap-2 items-center border-b-2 border-r-2 rounded-md cursor-pointer"
        onClick={OpenHandler}
      >
        {/* <Icon icon="grommet-icons:cart" /> */}
        <img src={"/images/icons/cart.svg"} width={20} height={20} alt="cart" />
        {totalLength}
        <p className="hidden md:block"> - Cart</p>
      </button>
      {cartOpen && <AddToCartDrawer onClose={CloseHandler} open={cartOpen} />}
    </>
  );
}

export default AddToCartButton;
