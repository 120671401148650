import { Deals } from "@/app/_types/menuWithCategories";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MenuDealLink from "./MenuDealLink";

interface MenuDealList {
  params: string;
  dealList: Deals[];
  status: boolean;
  tradingPeriodId: string;
}

// eslint-disable-next-line react-refresh/only-export-components
export const carouselResponsiveMenus = {
  largeDesktop: {
    breakpoint: { max: 3000, min: 1800 },
    items: 5,
    paritialVisibilityGutter: 50,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1330 },
    items: 3,
    paritialVisibilityGutter: 30,
  },
  smallDesktop: {
    breakpoint: { max: 1330, min: 900 },
    items: 2,
    paritialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 900, min: 640 },
    items: 2,
    paritialVisibilityGutter: 35,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

function MenuDealList({
  params,
  dealList,
  status,
  tradingPeriodId,
}: MenuDealList) {

  const visibilityStatusDeals = dealList?.filter(
    (deal) =>
      deal?.visibilityStatus !== "HIDDEN" &&
      deal?.visibilityStatus !== "HIDE_FOR_APP_AND_WEB"
  );
  if (visibilityStatusDeals?.length === 0) return null;

  return (
    <>
      <div id={`${tradingPeriodId}dealsList`} className="flex flex-col gap-4">
        <h1 className="font-semibold text-xl">Deal</h1>
        <div className="grid box-border gap-10">
          <Carousel
            responsive={carouselResponsiveMenus}
            draggable
            infinite
            centerMode={false}
            keyBoardControl
            minimumTouchDrag={80}
            containerClass="carausalContainer"
            itemClass={"carausalItem"}
            partialVisbile={false}
            pauseOnHover
            showDots={false}
            slidesToSlide={1}
            swipeable
          >
            {visibilityStatusDeals?.map((deal, index) => (
              <MenuDealLink
                key={index}
                deal={deal}
                params={params}
                status={status}
                tradingPeriodId={tradingPeriodId}
              />
              // <Link
              //   to={
              //     status
              //       ? `/${params}/deal/${tradingPeriodId}/${deal?.id}`
              //       : "#"
              //   }
              //   key={index}
              //   scroll={false}
              // >
              //   <MenuDealItem
              //     item={deal}
              //     onSelectDeal={onSelectDeal}
              //     status={status}
              //   />
              // </Link>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default MenuDealList;
