import { Deals } from "@/app/_types/menuWithCategories";

interface MenuDealItem {
  item: Deals;
  status: boolean;
  dealStatus: boolean;
  reActivationTimes: boolean;
}

function MenuDealItem({
  item,
  status,
  dealStatus,
  reActivationTimes,
}: MenuDealItem) {
  const sellingPrice =
    item?.dealType === "FIXED"
      ? `$${item?.sellingPrice}`
      : `${item?.discountRate}%`;
  return (
    <div className="flex gap-2 shadow-md h-full">
      <div className="w-fit relative">
        <img
          src={item?.thumbnailUrl || "/images/icons/noMenuImage.jpg"}
          alt="Deal Image"
          width="100"
          height="100"
          // className="w-[10rem] h-full  md:w-[13rem]  lg:w-[16rem] object-cover object-center"
          className="w-[10rem] h-[8rem] md:w-[10rem] md:h-[10rem] lg:w-[12rem] lg:h-[10rem] object-cover object-center"
        />
        {item?.visibilityStatus === "OUT_OF_STOCK" ? (
          <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
            Out of Stock
          </p>
        ) : (
          (!status || (status && !dealStatus) || !reActivationTimes) && (
            <p className="absolute top-0 right-0 text-white bg-red-500 p-1 rounded-bl-md">
              Opens Soon
            </p>
          )
        )}
      </div>
      <div className="py-2 px-4 lg:min-w-[10rem] flex ">
        <div>
          <h2 className="text-lg font-semibold ">{item?.title}</h2>
          <p className="text-20 text-gray-500 text-left pb-2">
            {item?.description}
          </p>
        </div>
        <p className="text-sm  text-gray-600 mt-auto">{sellingPrice}</p>
      </div>
    </div>
  );
}

export default MenuDealItem;
