import { useState } from "react";
import MenuDetailsExtraOptionAccordion from "./MenuDetailsExtraOption/MenuDetailsExtraOptionAccordion";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import { useFormContext } from "react-hook-form";
import CircularSpinner from "@/app/global/CircularSpinner";

interface MenuDetailsExtraOptions {
  extraOptions?: ExtraPriceInfo;
  menuSizeGroupId: string;
}

function MenuDetailsExtraOptions({
  extraOptions,
  menuSizeGroupId,
}: MenuDetailsExtraOptions) {
  const [open, setOpen] = useState<number | null>(null);
  const { watch } = useFormContext();
  if (!extraOptions?.sizeGroups.length || !extraOptions)
    return <CircularSpinner />;

  const sizeGroupId = extraOptions?.sizeGroups?.find(
    (group) => group?.id === menuSizeGroupId
  );

  console.log("size group id => ", sizeGroupId, watch());
  const ShowOptionGroups = sizeGroupId?.optionGroups?.filter(
    (optionGroup) => optionGroup.isHideFromExtras === false
  );

  return (
    <>
      {(ShowOptionGroups || [])?.length > 0 && (
        <div className=" pb-4 px-4">
          <p className="text-lg font-bold pb-2">Extra Toppings</p>
          <div>
            {ShowOptionGroups?.map((option, index) => (
              <MenuDetailsExtraOptionAccordion
                option={option}
                open={open}
                index={index}
                setOpen={setOpen}
                optionName={extraOptions?.options}
                key={index}
                optionGroups={sizeGroupId?.optionGroups || []}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default MenuDetailsExtraOptions;
