import MenuOfferFormList from "./MenuOfferFormList";
import { useForm, FormProvider } from "react-hook-form";
import {
  MenuOfferForm,
  MenuOfferFormArray,
} from "@/app/_types/menuOffers.interface";
import { TradingHour } from "@/app/_types/tradingHours.interface";

interface MenuOfferFormProps {
  offerForm: MenuOfferFormArray[];
  // sellingPrice: number;
  isModal: boolean;
  periodId: string;
  offerInformation: {
    id: string;
    name: string;
    description?: string;
    imageUrl?: string;
    maxOptional: number;
    tradingHours: TradingHour[];
  };
}

function MenuOfferForms({
  offerForm,
  // sellingPrice,
  isModal,
  periodId,
  offerInformation,
}: MenuOfferFormProps) {
  const defaultValues: MenuOfferForm = {
    periodId: periodId,
    cartType: "Offer",
    menuOffer: offerForm,
    id: offerInformation?.id,
    name: offerInformation?.name,
    qty: 1,
    description: offerInformation?.description || null,
    imageUrl: offerInformation?.imageUrl || null,
    sellingPrice: 0,
    totalPrice: 0,
    maxOptionalCategorySelectable: offerInformation?.maxOptional,
    tradingHours: offerInformation?.tradingHours,
    config: {
      isLoading: false,
      selectedOfferIndex: 0,
      canConfirm: true,
      isMenuHalfHalf: false,
      selectedPage: "SELECT_DEAL",
    },
  };

  const methods = useForm<MenuOfferForm>({ defaultValues });

  console.log("watch offer => ", methods.watch());

  return (
    <FormProvider {...methods}>
      <MenuOfferFormList isModal={isModal} />
    </FormProvider>
  );
}

export default MenuOfferForms;
