import { AddMenuItemsForm } from "@/app/_types/menuDetails";

import { useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormMenuDataProvider } from "./MenuDetailsForm";
import { twMerge } from "tailwind-merge";

function MenuDetailsImage() {
  const { control, setValue } = useFormContext<AddMenuItemsForm>();
  const dataField = useContext(FormMenuDataProvider);

  const watchData = useWatch({
    control,
    name: "data",
  });

  const watchConfig = useWatch({
    control,
    name: "config",
  });

  const changeDataHandler = (index: number) => {
    const isDataTrue = watchData?.map((el) => el?.id)?.includes(null);
    if (!watchConfig?.canAddToCart || isDataTrue) return;
    setValue(`config.selectedIndex`, index);
    setValue(`config.canAddToCart`, false);
  };

  return (
    <div className="hidden md:block w-1/2">
      {/* {watchConfig?.isSplit ? ( */}
      <div className="flex flex-col h-full gap-[5px] ">
        {dataField?.fields?.map((datas, index) => {
          return (
            <div
              key={index}
              className={twMerge(
                "h-full relative",
                watchConfig?.isSplit && "h-[49%]"
              )}
              onClick={() => changeDataHandler(index)}
            >
              <img
                src={
                  datas?.imageUrl
                    ? datas?.imageUrl
                    : "/images/icons/noMenuImage.jpg"
                }
                width="400"
                height="400"
                alt="menu items"
                className="w-full h-full object-cover object-center"
              />
              <div className="absolute bottom-6 left-0 p-2 rounded-r-lg text-white bg-black/70">
                <p>{datas?.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MenuDetailsImage;
