import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { selectStore } from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { MenuDetailsEntity } from "@/app/_types/menuDetails";
import CustomModalClients from "@/app/global/modal/CustomModalWithoutWhite";

import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import MenuOfferSelectMenuItemCard from "./MenuOfferSelectMenuItemCard";
import { ExtraPriceInfo } from "@/app/_types/extraMenuPriceInfo";
import {
  order,
  updateOfferPriceDetails,
} from "@/app/_redux/slice/Order/OrderSlice";
import { useAppDispatch } from "@/app/_redux/store";
import {
  IndividualOfferOrderItems,
  MenuOfferForm,
  OfferCategoryMenu,
} from "@/app/_types/menuOffers.interface";
import {
  ManageComboPayInitial,
  getIndividualMenuComboPrice,
} from "@/app/[location]/menu/utils/sizeChangeUtils";
import { handleOptionalMenu } from "../MenuOfferEditMenu/MenuOfferEditLogic";

function MenuOfferSelectMenu() {
  const [open, setOpen] = useState(true);
  const { selectedStore } = useSelector(selectStore);
  const { offerDetails } = useSelector(order);
  const { control, setValue, getValues } = useFormContext<MenuOfferForm>();
  const dispatch = useAppDispatch();

  console.log(offerDetails);

  const handleClose = () => {
    setOpen(false);
    setValue(`config.selectedPage`, "SELECT_DEAL");
  };

  const offerCategoriesReduxIndex = useWatch({
    control,
    name: `config.selectedOfferCategoryIndex`,
  });

  const periodId = useWatch({
    control,
    name: `periodId`,
  });

  if (typeof offerCategoriesReduxIndex !== "number") return <p>Not Found</p>;

  const offerCategoriesList = offerDetails?.offerCategories?.at(
    offerCategoriesReduxIndex
  )?.offerCategoryMenus;

  const SelectMenuItemHandler = async (
    menu: OfferCategoryMenu,
    // index: number
  ) => {
    console.log(menu);
    if (!selectedStore?.id)
      return toast.error(
        "Department Not Found. Please Refresh the Page and Try Again"
      );

    if (menu?.visibilityStatus === "OUT_OF_STOCK") {
      toast.error(`${menu?.menuName} is out of stock`);
      return;
    }
    const selectedOfferIndex = getValues("config.selectedOfferIndex");
    const sizeId = getValues(`menuOffer.${selectedOfferIndex}.size`);

    const selectedMenuIndex = getValues(
      `menuOffer.${selectedOfferIndex}.config.selectedMenuIndex`
    );
    // const selectedMenus = getValues(`menuOffer.${selectedOfferIndex}.menus`);

    const [PromiseMenuDetails, PromiseExtraPriceInfo] =
      await Promise.allSettled([
        fetchRequest(
          serverPath.getMenuDetails(selectedStore?.id, menu?.menuId, periodId),
          "GET",
          undefined,
          { cache: "no-store" }
        ),
        fetchRequest(
          serverPath.getExtraPriceInfo(selectedStore?.id),
          "GET",
          undefined
        ),
      ]);

    if (PromiseMenuDetails.status === "rejected")
      return toast.error("Cannot get Menu Details. Please Try Again");
    if (PromiseExtraPriceInfo.status === "rejected")
      return toast.error("Error While getting Extra Price Info");

    const menuDetails: MenuDetailsEntity = PromiseMenuDetails?.value;
    const priceInfo: ExtraPriceInfo = PromiseExtraPriceInfo?.value;

    console.log("menu details inside => ", menuDetails);

    const menuSizeId = menuDetails?.menuSizes?.find(
      (size) => size?.sizeId === sizeId
    )?.id;

    dispatch(
      updateOfferPriceDetails({
        index: offerCategoriesReduxIndex,
        extraPrice: priceInfo,
      })
    );

    if (!menuSizeId)
      return toast.error("Menu Size Not Found. Please Try Again");

    setValue("comboPriceType", priceInfo?.combosPricesType);

    console.log("menu details => ", menuDetails);

    const comboList = ManageComboPayInitial(
      menuDetails?.menuOptionGroups || [],
      // menuDetails?.menuSizes?.[0]?.sizeId.toString() || ""
      sizeId
    );

    const comboPrices = getIndividualMenuComboPrice(comboList);

    const menuData: IndividualOfferOrderItems = {
      id: menuDetails?.menu?.id,
      menuSizeId,
      name: menuDetails?.menu?.name,
      hasExtras: menu?.hasExtras || false,
      hasHalfHalf: menuDetails?.menu?.hasHalfAndHalf,
      finalPrice: menu?.finalPrice,
      basePrice: menuDetails?.menu?.basePrice,
      sizeGroupId: menuDetails?.menu?.sizeGroupId,
      specialPrice: menu?.finalPrice || 0, // might need to change in ts with finalPrice
      comboPrice: comboPrices,
      ingredientPrice: 0,
      optionGroups: comboList,
      ingredients: menuDetails?.menuOptions,
      tradingHours: menu?.tradingHours,
      menuSizeCount: menuDetails?.menuSizes?.length
    };

    // if (!selectedMenus?.length) {
    //   setValue(`menuDeal.${selectedDealIndex}.menus.0`, menuData);
    // } else {
    // }
    setValue(
      `menuOffer.${selectedOfferIndex}.menus.${selectedMenuIndex}`,
      menuData
    );
    setValue(`menuOffer.${selectedOfferIndex}.isSelected`, true);

    if (
      (!menuDetails?.menuCombos?.length || !menuDetails?.menuCombos) &&
      (!menuDetails?.menuOptions?.length || !menuDetails?.menuOptions)
      // (menuDetails?.menuSizes?.length === 1 ||
      //   !menuDetails?.menuSizes?.length ||
      //   !menuDetails?.menuSizes)
    ) {
      handleOptionalMenu(getValues, setValue); //validate the number of optional selectable
      setValue(`config.selectedPage`, "SELECT_DEAL");
      return;
    }

    setValue(`config.selectedPage`, "EDIT_MENU");
  };

  return (
    <CustomModalClients
      open={open}
      handleClose={handleClose}
      className="modal-white-parallel"
      scrollDisable={true}
      disableCloseOnOverlay={true}
    >
      <div className="flex justify-between items-center p-4 md:p-6 border-b-2">
        <h1 className="font-bold text-xl ">Select Your Menu </h1>
        <button
          onClick={handleClose}
          // className={twMerge("lg:hidden", !isModal && "hidden")}
        >
          <img
            src="/images/icons/close.svg"
            width={25}
            height={25}
            alt="close"
          />
        </button>
      </div>
      <OfferSelectItems
        offerCategoriesList={offerCategoriesList || []}
        onSelectedMenu={SelectMenuItemHandler}
      />
    </CustomModalClients>
  );
}

export function OfferSelectItems({
  offerCategoriesList,
  onSelectedMenu,
}: {
  offerCategoriesList: OfferCategoryMenu[];
  onSelectedMenu: (menu: OfferCategoryMenu, index: number) => void;
}) {
  return (
    <>
      <div className="p-4">
        <div className="grid grid-cols-custom-150 lg:grid-cols-custom-180 gap-5">
          {offerCategoriesList?.map((menu, index) => {
            const hiddenStatuses = ["HIDDEN", "HIDE_FOR_APP_AND_WEB"];
            return (
              !hiddenStatuses.includes(menu?.visibilityStatus) && (
                <MenuOfferSelectMenuItemCard
                  key={index}
                  menu={menu}
                  onSelectedMenu={onSelectedMenu}
                  index={index}
                />
              )
            );
          })}
        </div>
      </div>
    </>
  );
}

export default MenuOfferSelectMenu;
