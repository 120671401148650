import { useFormContext, useWatch } from "react-hook-form";
import CheckoutCardPayment from "./CheckoutCardPayment";
import CheckoutPaypalPaymentButton from "./CheckoutPaypalPaymentButton";
import CheckoutPayLaterButton from "./CheckoutPayLaterButton";

function CheckoutPaymentMethod() {
  const { control } = useFormContext();

  const watchPayment = useWatch({
    control,
    name: "paymentGatewayType",
  });
  return (
    <>
      {watchPayment === "STRIPE" ? (
        <CheckoutCardPayment />
      ) : watchPayment === "PAYPAL" ? (
        <CheckoutPaypalPaymentButton />
      ) : watchPayment === "PAY LATER" ? (
        <CheckoutPayLaterButton />
      ) : null}
    </>
  );
}

export default CheckoutPaymentMethod;
