import { AddMenuItemsForm, MenuSize } from "@/app/_types/menuDetails";
import ManageLoadingClient from "@/app/global/ManageLoadingClient";
import CustomRadioButtonClick from "@/app/global/custom/CustomRadioButtonClick";
import { fixedDecimal } from "@/app/utils/DecimalAndNumbers";
import { useFormContext } from "react-hook-form";
import {
  calculatingIsFirstFreeAndGettingPrice,
  getTotalComboPriceOfSingleData,
} from "./SelectMenuFirstTab/MenuComboUtils";
import toast from "react-hot-toast";

interface MenuDetailsSize {
  isLoading?: boolean;
  menuSizes?: MenuSize[];
}

function MenuDetailsSize({ menuSizes }: MenuDetailsSize) {
  const { setValue, getValues } = useFormContext<AddMenuItemsForm>();

  // const comboPriceType = getValues("comboPriceType");

  const updateTotalPriceOfIngredient = (index: number) => {
    const getIngredient = getValues(`data.${index}.ingredients`);
    const ingredientTotal = getIngredient?.reduce(
      (acc, current) => (current?.total || 0) + acc,
      0
    );

    setValue(`data.${index}.ingredientPrice`, ingredientTotal);
  };

  const sizeOnchangeHandler = (size: MenuSize) => {
    const sizeId = size?.sizeId;
    setValue("sizePrice", size?.additionalPrice);
    // setValue('sizeName', size?.sizeName)
    setValue("basePrice", size?.basePrice);
    setValue("sizeName", size?.sizeName);
    const getData = getValues("data");
    getData?.map((data, mainIndex) => {
      // for changing combo prices in UI...
      const findMenuSizeItem = data?.menuSizeList?.find(
        (el) => el?.sizeId === size?.sizeId
      );
      if (findMenuSizeItem) {
        setValue(`data.${mainIndex}.menuSizeId`, findMenuSizeItem.id);
      }

      // for comboList
      const comboList = data?.optionGroups;
      // looping through combo
      comboList?.map((combo, listIndex) => {
        // looping throguh combo items and changing the price of checked item
        combo?.options?.forEach((items, itemIndex) => {
          // finding new price of combo items as per new selected size
          const findSizePrice = items?.menuSizes?.find(
            (comboSize) => comboSize?.sizeId === sizeId
          );
          if (findSizePrice) {
            const newPrice = calculatingIsFirstFreeAndGettingPrice(
              items,
              items?.qty,
              findSizePrice?.extraPrice
            );
            setValue(
              `data.${mainIndex}.optionGroups.${listIndex}.options.${itemIndex}.price`,
              newPrice
            );
          } else {
            toast.error(
              "Cannot find Size ID. Please Refresh the Page or Review Configuration."
            );
          }
        });
        // after getting the list of prices getting the max number
        // there might be average as well and need to be looked at
      });
      getTotalComboPriceOfSingleData(
        getValues,
        setValue,
        mainIndex
      );

      // for ingredients
      const ingredientList = data?.ingredients;
      console.log("Size change ingredients", ingredientList);
      // looping through each ingredient  and setting new price on the basis of new selected size
      ingredientList?.forEach((ingredients, index: number) => {
        const newSizePrice = ingredients?.menuSizes?.find(
          (el) => el?.sizeId === sizeId
        );
        console.log(
          "Size change size find => ",
          newSizePrice,
          ingredientList,
          ingredients,
          sizeId
        );
        if (newSizePrice) {
          setValue(
            `data.${mainIndex}.ingredients.${index}.price`,
            newSizePrice?.extraPrice || 0
          );
          if (ingredients?.status === "current") {
            if (ingredients?.qty <= 1) {
              setValue(`data.${mainIndex}.ingredients.${index}.total`, 0);
            } else {
              const ingredientTotal =
                (ingredients?.qty - 1) * (newSizePrice?.extraPrice || 0);
              setValue(
                `data.${mainIndex}.ingredients.${index}.total`,
                ingredientTotal
              );
            }
          } else {
            const totalIngredientPrice =
              (newSizePrice?.extraPrice || 0) * ingredients?.qty;
            setValue(
              `data.${mainIndex}.ingredients.${index}.total`,
              totalIngredientPrice
            );
          }
        }
      });
      // getTotalComboPrice(mainIndex);
      updateTotalPriceOfIngredient(mainIndex);
    });
  };

  return (
    <div className="px-4">
      <p className="text-lg font-bold mb-2">Choose Size</p>
      <div className="flex flex-wrap gap-4">
        <ManageLoadingClient
          // isLoading={isLoading}
          loadingProgress={
            <>
              <div className="animate-pulse rounded-full bg-gray-300 px-4 py-2 w-24 h-9" />
              <div className="animate-pulse rounded-full bg-gray-300 px-4 py-2 w-24 h-9" />
              <div className="animate-pulse rounded-full bg-gray-300 px-4 py-2 w-24 h-9" />
            </>
          }
        >
          {menuSizes?.map((size, index: number) => (
            <CustomRadioButtonClick
              name="size"
              id={`menuSize${index}`}
              value={size?.sizeId}
              key={index}
              onChange={() => sizeOnchangeHandler(size)}
            >
              <p>
                {size?.sizeName}
                {size?.additionalPrice > 0 && (
                  <span> + ${fixedDecimal(size?.additionalPrice)}</span>
                )}
              </p>
            </CustomRadioButtonClick>
          ))}
        </ManageLoadingClient>
      </div>
    </div>
  );
}

export default MenuDetailsSize;
