import { Offer } from "@/app/_types/menuWithCategories";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import MenuDealItem from "./MenuDealItem";
import MenuOfferLink from "./MenuOfferLink";

interface MenuOfferList {
  params: string;
  //   tradingPeriod: TradingPeriods[];
  //   todayTradingHoursData: TradingHour[];
  offerList: Offer[];
  status: boolean;
  tradingPeriodId: string;
}

const responsive = {
  largeDesktop: {
    breakpoint: { max: 3000, min: 1800 },
    items: 5,
    paritialVisibilityGutter: 50,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1330 },
    items: 3,
    paritialVisibilityGutter: 30,
  },
  smallDesktop: {
    breakpoint: { max: 1330, min: 900 },
    items: 2,
    paritialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 900, min: 640 },
    items: 2,
    paritialVisibilityGutter: 35,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

function MenuOfferList({
  params,
  offerList,
  status,
  tradingPeriodId,
}: MenuOfferList) {

  const visibilityStatusOffers = offerList?.filter(
    (offer) =>
      offer?.visibilityStatus !== "HIDDEN" &&
      offer?.visibilityStatus !== "HIDE_FOR_APP_AND_WEB"
  );
  if (visibilityStatusOffers?.length === 0) return null;

  return (
    <>
      <div id={`${tradingPeriodId}offersList`} className="flex flex-col gap-4">
        <h1 className="font-semibold text-xl">Offer</h1>
        <div className="grid box-border gap-10">
          <Carousel
            responsive={responsive}
            draggable
            infinite
            centerMode={false}
            keyBoardControl
            minimumTouchDrag={80}
            containerClass="carausalContainer"
            itemClass={"carausalItem"}
            partialVisbile={false}
            pauseOnHover
            showDots={false}
            slidesToSlide={1}
            swipeable
          >
            {visibilityStatusOffers?.map((offer, index) => (
              <MenuOfferLink
                key={index}
                offer={offer}
                params={params}
                status={status}
                tradingPeriodId={tradingPeriodId}
              />
              // <Link
              //   to={
              //     status
              //       ? `/${params}/offer/${tradingPeriodId}/${offer?.id}`
              //       : "#"
              //   }
              //   key={index}
              //   scroll={false}
              // >
              //   <MenuOfferItem
              //     item={offer}
              //     onSelectDeal={onSelectOffer}
              //     status={status}
              //   />
              // </Link>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default MenuOfferList;
