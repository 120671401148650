function AvailableDevices() {
  const storeGroup = import.meta.env.VITE_STORE
  return (
    <section className="flex items-center justify-center pb-10 mt-5">
      <div className="flex flex-col gap-5 justify-center items-center">
        <p className="text-xl sm:text-2xl font-bold text-gray-800">
          Get Our Latest App on Google Play & App Store
        </p>
        <div className="flex items-center justify-center gap-5">
          <div className="flex flex-col gap-2">
            <a
              href={`${storeGroup === "supero" ? "https://bit.ly/4ads9ws" : "https://play.google.com/store/apps/details?id=ai.restobrain.woodfiredcustomer"}`}
              // href={
              //   "https://play.google.com/store/apps/details?id=ai.restobrain.woodfiredcustomer"
              // }
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download from Google Play"
            >
              
              <img
                src="/images/icons/googleStore.svg"
                alt="Download from Google Play"
                className="h-16 w-40 sm:h-20 sm:w-44 transition-transform duration-200 hover:scale-105"
              />
            </a>
            {storeGroup === "supero" && (
              <img
              src="/playStoreQR.png"
              // src="playStoreWoodFire.png"
              alt="Download from Google Play"
              className="h-40 w-40 sm:h-40 sm:w-44 transition-transform duration-200 hover:scale-105"
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <a
              href={`${storeGroup === "supero" ? "https://apple.co/40qwoRQ" : "https://apps.apple.com/app/id6736557654"}`}
              // href={"https://apps.apple.com/app/id6736557654"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Download from App Store"
            >
              <img
                src="/images/icons/appleStore.svg"
                alt="Download from Apple Store"
                className="h-16 w-40 sm:h-20 sm:w-44 transition-transform duration-200 hover:scale-105"
              />
            </a>
            {storeGroup === "supero" && (
            <img
              src="/appleStoreQR.png"
              // src="appleStoreQRWoodFire.png"
              alt="Download from Apple Store"
              className="h-40 w-40 sm:h-40 sm:w-44 transition-transform duration-200 hover:scale-105"
            />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AvailableDevices;
