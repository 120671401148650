import { twMerge } from "tailwind-merge";
import { RegisterFormInterface } from "./RegisterForm";
import { useFormContext, useWatch } from "react-hook-form";
import ErrorAstrick from "@/app/global/elements/ErrorAstrick";
import toast from "react-hot-toast";
import fetchRequest from "@/app/_axios/fetchRequest";
import { serverPath } from "@/app/_axios/path";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  axiosErrorMessage,
  AxiosErrorMessage,
} from "@/app/_axios/handleAxiosError";
import { useLocation, useSearchParams } from "react-router-dom";

interface RegisterUserFormInterface {
  isLoading: boolean;
  selectedCountry: {
    iso: string;
    code: string;
  };
  changeStatus?(status: "LOGIN" | "REGISTER"): void;
  handleClose?(): void;
  requestOTP: (phoneNo: string, countryCode: string) => Promise<unknown>;
  setTimer: React.Dispatch<React.SetStateAction<number>>;
  timer: number;
}

const RegisterUserForm = ({
  isLoading,
  selectedCountry,
  changeStatus,
  handleClose,
  requestOTP,
  setTimer,
  timer,
}: RegisterUserFormInterface) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    getValues,
    control,
  } = useFormContext<RegisterFormInterface>();

  const location = useLocation();
  const [searchParams] = useSearchParams();
    const redirect = searchParams.get("redirect") || "/";

  const [isDisabled, setIsDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const phone = useWatch({
    control,
    name: "phoneNo",
  });

  const getErrors = watch("error");
  const isLoadingOTP = watch("isLoadingOTP");

  const checkError = () => {
    if (getErrors) {
      setValue("error", "");
    }
  };

  const ResentOTP = async () => {
    const userPhoneNo = getValues("phoneNo");
    // const phoneNo = selectedCountry?.code + userPhoneNo;
    const phoneNo = userPhoneNo;
    console.log(phoneNo);
    if (!phoneNo) {
      return toast.error("Enter Phone Number");
    }

    const responseData = await requestOTP(phoneNo, selectedCountry?.code);

    if (responseData !== null) {
      console.log(responseData);
      if (responseData === false) {
        toast.error("Phone Number is already Register");
        if (changeStatus) {
          changeStatus("LOGIN");
        }
      } else {
        setTimer(30);
        toast.success("OTP sent to the Phone Number");
        setIsDisabled(true);
      }
    }
  };

  const { mutate: registerMutate } = useMutation({
    mutationKey: ["customerOTPconfirm"],
    mutationFn: (data: RegisterFormInterface) =>
      fetchRequest(
        serverPath.customerOTPconfirm(data?.OTP),
        "POST",
        {
          countryCode: selectedCountry?.code,
          phone: data?.phoneNo,
          firstName: data?.firstName,
          lastName: data?.lastName,
          password: data?.password,
          storeGroupId: import.meta.env.VITE_STORE_GROUP_ID,
        },
        {
          "Content-Type": "application/json",
        },
        "res"
      ),
    onSuccess: (data) => {
      console.log("Login successful", data);
      localStorage.setItem("session", JSON.stringify(data?.data?.tokenDTO));
      toast.success("User is register successfully");
      setValue("isLoadingOTP", false);
      if (location?.pathname === "/auth/register") {
        // window.location.replace("/");
        window.location.replace(`${redirect}`)
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        handleClose && handleClose();
        window.location.reload();
      }
    },
    onError: (error: AxiosErrorMessage) => {
      toast.error(axiosErrorMessage(error));
      setValue("isLoadingOTP", false);
    },
  });

  const ConfirmOTP = async (data: RegisterFormInterface) => {
    console.log(data);
    if (!data?.OTP) {
      toast?.error("Please enter OTP");
      return;
    }
    if (data?.password !== data?.confirmPassword) {
      toast?.error("Password and Confirm Password do not match");
      return;
    }
    registerMutate(data);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev: number) => prev - 1);
      }, 1000);
    } else {
      setIsDisabled(false);
    }
    return () => clearInterval(interval);
  }, [setTimer, timer]);

  return (
    <>
      <p className={"text-error"}>{getErrors}</p>
      <div className="form-div">
        <label htmlFor="OTP" className="form-label">
          OTP
          {errors?.OTP && <ErrorAstrick />}
        </label>
        <p className="text-gray-500 text-sm">
          We've send OTP to your phone ({phone}).
        </p>
        {errors?.OTP && (
          <p className="text-red-500 text-sm">{errors?.OTP?.message}</p>
        )}
        <input
          type="text"
          id="OTP"
          {...register("OTP", { required: "OTP is Required" })}
          className="form-input w-full"
          onChange={(e) => {
            checkError();
            setValue("OTP", e.target.value);
            clearErrors();
          }}
        />
      </div>
      <div className=" flex gap-4">
        <div className="form-div w-1/2">
          <label htmlFor="firstName" className="form-label">
            First Name
            {errors?.firstName && <ErrorAstrick />}
          </label>
          {errors?.firstName && (
            <p className="text-red-500 text-sm">{errors?.firstName?.message}</p>
          )}
          <input
            type="firstName"
            id="firstName"
            {...register("firstName", {
              required: "FirstName is Required",
            })}
            className="form-input w-full"
            onChange={(e) => {
              checkError();
              setValue("firstName", e.target.value);
              clearErrors();
            }}
          />
        </div>
        <div className="form-div w-1/2">
          <label htmlFor="lastName" className="form-label">
            Last Name
            {errors?.lastName && <ErrorAstrick />}
          </label>
          {errors?.lastName && (
            <p className="text-red-500 text-sm">{errors?.lastName?.message}</p>
          )}
          <input
            type="lastName"
            id="lastName"
            {...register("lastName", {
              required: "LastName is Required",
            })}
            className="form-input w-full"
            onChange={(e) => {
              checkError();
              setValue("lastName", e.target.value);
              clearErrors();
            }}
          />
        </div>
      </div>
      <div className="form-div">
        <label htmlFor="password" className="form-label">
          Password
          {errors?.password && <ErrorAstrick />}
        </label>
        {errors?.password && (
          <p className="text-red-500 text-sm">{errors?.password?.message}</p>
        )}
        <input
          type={`${showPassword ? "text" : "password"}`}
          id="password"
          {...register("password", {
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long",
            },
            maxLength: {
              value: 20,
              message: "Password must be below 20 characters",
            },
          })}
          className="form-input"
          onChange={(e) => {
            checkError();
            setValue("password", e.target.value);
            clearErrors();
          }}
        />
      </div>
      <div className="form-div">
        <label htmlFor="confirmPassword" className="form-label">
          Confirm Password
          {errors?.confirmPassword && <ErrorAstrick />}
        </label>
        {errors?.confirmPassword && (
          <p className="text-red-500 text-sm">
            {errors?.confirmPassword?.message}
          </p>
        )}
        <input
          type={`${showPassword ? "text" : "password"}`}
          id="confirmPassword"
          {...register("confirmPassword", {
            required: "Confirm Password is required",
            minLength: {
              value: 8,
              message: "Confirm Password must be at least 8 characters long",
            },
            maxLength: {
              value: 20,
              message: "Password must be below 20 characters",
            },
          })}
          className="form-input"
          onChange={(e) => {
            checkError();
            setValue("confirmPassword", e.target.value);
            clearErrors();
          }}
        />
      </div>
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 text-blue-600"
          checked={showPassword}
          onChange={() => setShowPassword((prev) => !prev)}
        />
        <label htmlFor="show-password" className="text-sm text-gray-700">
          Show Password
        </label>
      </div>
      <div className="flex justify-between items-center">
        <p>Did not recieved OTP?</p>
        <button
          className={`${
            isDisabled ? "text-gray-500" : "text-primary"
          } font-semibold`}
          onClick={ResentOTP}
          type="button"
          disabled={isDisabled}
        >
          {isDisabled ? `RESEND OTP (${timer}s)` : "RESEND OTP"}
        </button>
      </div>
      <div className=" flex justify-end items-end my-4">
        <button
          className={twMerge(
            "form-btn flex gap-2 items-center",
            isLoading && "bg-gray-300",
            isLoadingOTP && "disable"
          )}
          // type="button"
          onClick={handleSubmit(ConfirmOTP)}
          disabled={isLoading}
        >
          {isLoading && (
            <div className="animate-spin w-5 h-5 rounded-full border-4 border-primary" />
          )}
          Register
        </button>
      </div>
    </>
  );
};

export default RegisterUserForm;
