import React, { useEffect } from 'react'
import { useAppDispatch } from '../_redux/store'
import { addStoreDetails } from '../_redux/slice/storeslice.ts/storeSlice'
import { StoreEntityDetails } from '../_types/store.interface'

const StoreDetailsInRedux = ({children,
    storeDetailList}: {storeDetailList: StoreEntityDetails[];
        children: React.ReactNode;}) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(addStoreDetails(storeDetailList));
    }, [dispatch, storeDetailList])
  return (
    <>
      {children}
    </>
  )
}

export default StoreDetailsInRedux