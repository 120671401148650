import {
  AddMenuItemsForm,
  MenuOptionGroup,
  OrderIngredient,
} from "@/app/_types/menuDetails";


import { twMerge } from "tailwind-merge";

interface AddToCartItemsName {
  order: AddMenuItemsForm;
}

function AddToCartItemsName({ order }: AddToCartItemsName) {
  const menuElement = () => {
    switch (true) {
      case order?.config?.isSplit:
        return (
          <>
            <div className="text-sm pl-8 flex flex-col flex-wrap gap-2">
              {order?.data?.map((menu, index) => {
                return (
                  <div key={index} className="flex gap-1  flex-col  ">
                    <div className="text-black flex items-center gap-4 ">
                      {index === 0 ? (
                        <img
                          src="/images/icons/leftHalf.svg"
                          alt="L"
                          width={15}
                          height={15}
                        />
                      ) : null}
                      {index === 1 ? (
                        <img
                          src="/images/icons/rightHalf.svg"
                          alt="L"
                          width={13}
                          height={13}
                        />
                      ) : null}
                      {order?.sizeName} {menu?.name}
                    </div>
                    {menu?.optionGroups?.length
                      ? menu?.optionGroups?.map((combo, index) => (
                          <ComboPortion
                            combo={combo}
                            isSplit={order?.config?.isSplit || false}
                            key={index}
                          />
                        ))
                      : null}
                    <CartIngredients
                      ingredients={menu?.ingredients}
                      isSplit={order?.config?.isSplit || false}
                    />
                  </div>
                );
              })}
            </div>
          </>
        );

      default:
        return (
          <div className=" text-xs pl-8 pt-1 italic  ">
            {order?.data?.map((data, index) => {
              return (
                <div key={index} className=" pl-6 text-secondaryText">
                  {data?.optionGroups?.length
                    ? data?.optionGroups?.map((combo, index) => (
                        <ComboPortion
                          combo={combo}
                          isSplit={order?.config?.isSplit || false}
                          key={index}
                        />
                      ))
                    : null}
                  <CartIngredients
                    ingredients={data?.ingredients}
                    isSplit={order?.config?.isSplit || false}
                  />
                </div>
              );
            })}
          </div>
        );
    }
  };

  return <div>{menuElement()}</div>;
}

export function CartIngredients({
  ingredients,
  isSplit,
}: {
  ingredients: OrderIngredient[];
  isSplit: boolean;
}) {
  const additionalIng = ingredients?.filter(
    (el) =>
      el?.status === "added" || (el?.status === "current" && el?.qty !== 1)
  );

  const addedIng = additionalIng?.filter((el) => el.qty > 0);

  const removedIng = additionalIng?.filter((el) => el.qty === 0);
  return (
    <>
      <div
        className={twMerge(
          "flex items-center flex-wrap text-xs gap-1",
          isSplit && "pl-5"
        )}
      >
        {addedIng?.map((ing, indexss) => {
          return (
            <span
              className={
                "italic flex items-center gap-[4px] text-secondaryText "
              }
              key={indexss}
            >
              <span>{ing?.name}</span>
              <>
                <span className="flex items-center">
                  {ing?.qty === 1 ? null : (
                    <>
                      {ing?.status === "current" &&
                      ing?.qty - 1 === 1 ? null : ing?.status === "current" ? (
                        <>
                          <img
                            src="/images/icons/cross.svg"
                            width="15"
                            height="15"
                            alt="x"
                          />
                          {ing?.qty - 1}
                        </>
                      ) : (
                        <>
                          <img
                            src="/images/icons/cross.svg"
                            width="15"
                            height="15"
                            alt="x"
                          />
                          {ing?.qty}
                        </>
                      )}
                    </>
                  )}
                  {ing?.total && ing?.total > 0 ? (
                    <span> [${isSplit ? ing?.total / 2 : ing?.total}] </span>
                  ) : null}
                  {indexss === addedIng.length - 1 ? null : ", "}
                </span>
              </>
            </span>
          );
        })}
      </div>

      <div
        className={twMerge(
          "flex items-center flex-wrap text-xs",
          isSplit && "pl-5"
        )}
      >
        {removedIng?.map((ing, indexss) => (
          <span className="text-secondaryText" key={indexss}>
            <span className="italic line-through text-error ">{ing?.name}</span>
            {indexss === removedIng.length - 1 ? null : ", "}
          </span>
        ))}
      </div>
    </>
  );
}

export default AddToCartItemsName;

export function ComboPortion({
  combo,
  isSplit,
}: {
  combo: MenuOptionGroup;
  isSplit: boolean;
}) {
  const checkedCombo = combo?.options?.filter((item) => item?.qty > 0);
  if (!checkedCombo?.length) return null;
  return (
    <div
      className={twMerge(
        "flex flex-wrap gap-1 text-xs italic text-secondaryText",
        isSplit && "pl-5"
      )}
    >
      {combo?.optionGroupName}:{"  "}
      {checkedCombo?.map((item, index) => (
        <div key={item?.id} className="italic flex items-center justify-center">
          <span>{item?.name}</span>
          {item?.qty > 1 ? (
            <div className="flex items-center justify-center">
              {/* <Icon icon="basil:cross-outline" width={15} /> */}
              <img
                src="/images/icons/cross.svg"
                width="15"
                height="15"
                alt="x"
              />
              {item?.qty}
            </div>
          ) : null}{" "}
          {(item?.price || 0) > 0 ? (
            isSplit ? (
              <span>({`$${item?.price / 2}`})</span>
            ) : (
              <span>({`$${item?.price || 0}`})</span>
            )
          ) : null}
          {index === checkedCombo?.length - 1 ? null : ", "}
        </div>
      ))}
    </div>
  );
}
