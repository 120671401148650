import { ConfirmOrderForm, Customer } from "@/app/_types/checkout.interface";

import ErrorAstrick from "@/app/global/elements/ErrorAstrick";

import React, { Fragment, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { useSelector } from "react-redux";
import { selectUI } from "@/app/_redux/slice/UISlice/UISlice";
import UserEditFormModal from "@/app/component/UserEditForm";

function CheckoutUserPersonalDetails() {
  const {
    getValues,
    setValue,
    formState: { errors },
    control,
    resetField,
  } = useFormContext<ConfirmOrderForm>();
  const { selectedOrderStatus } = useSelector(selectUI);

  // to check if the user is new or not and making sure they fill the details
  const [isNewUser, setIsNewUser] = React.useState(false);

  const watchPhoneNumber = getValues("customer.phone");
  const watchGender = getValues("customer.gender");
  const watchFirstName = getValues("customer.firstName");

  const watchOrderType = useWatch({
    control,
    name: "orderType",
  });

  const watchAddress = useWatch({
    control,
    name: "customer.address",
  });

  const isUserEdit: boolean = useWatch({
    control,
    name: "config.isUserInformationEdit",
  });

  const customer = useWatch({
    control,
    name: `customer`,
  });

  const watchAddressType = useWatch({
    control,
    name: "addressType",
  });

  const watchOrderFuture = useWatch({
    control,
    name: "config.isOrderForFuture",
  });

  console.log(watchOrderFuture);
  console.log(watchAddress);

  const EditUserInformation = (status: "OPEN" | "CLOSE") => {
    const customerDetails = getValues("customer");
    if (status === "OPEN") {
      setValue("config.isUserInformationEdit", true);
    } else {
      // const CustomerAvailable = getValues("isCustomerAvailable");
      // if (!CustomerAvailable) {
      //   toast.error("Please Provide All the Information");
      //   return;
      // }
      const checkUser =
        (selectedOrderStatus === "DELIVERY" &&
          customerDetails?.firstName &&
          customerDetails?.address?.[0]?.address?.id) ||
        (selectedOrderStatus === "PICKUP" && customerDetails?.firstName)
          ? true
          : false;
      if (checkUser === false) {
        toast.error("Please Provide All the Information");
        return;
      }
      setValue("config.isUserInformationEdit", false);
      setValue("editCustomer", { ...customerDetails });
      resetField("customer.phone");
      resetField("customer.gender");
    }
  };

  // for UI
  const EditInformationButton = () => {
    switch (true) {
      // case isUserEdit && isNewUser:
      //   return null;
      case isUserEdit:
        return (
          <button
            onClick={() => EditUserInformation("CLOSE")}
            title={
              !watchPhoneNumber || !watchFirstName
                ? "Please fill all the fields and Save"
                : ""
            }
            type="button"
            disabled={
              // !watchPhoneNumber || !watchGender || isNewUser || isLoading
              !watchPhoneNumber || !watchFirstName
            }
          >
            <img
              src="/images/icons/close.svg"
              alt="close"
              width={25}
              height={25}
            />
          </button>
        );
      default:
        return (
          <button
            className={`text-sm  underline items-center ${
              watchOrderFuture ? "text-primary" : "text-neutral-500"
            }`}
            onClick={() => EditUserInformation("OPEN")}
            type="button"
            disabled={!watchOrderFuture}
          >
            Edit Information
          </button>
        );
    }
  };

  const UpdateUserData = (data: Customer) => {
    setValue("customer", { ...data });
    EditInformationClose();
  };

  const EditInformationClose = () => {
    // const customerDetails = getValues("customer");
    // console.log(customerDetails);
    // const checkUser =
    //   (selectedOrderStatus === "DELIVERY" &&
    //     customerDetails?.firstName &&
    //     customerDetails?.address?.[0]?.address?.streetNo &&
    //     customerDetails?.address?.[0]?.address?.streetName) ||
    //   (selectedOrderStatus === "PICKUP" &&
    //     customerDetails?.firstName &&
    //     customerDetails?.phone)
    //     ? true
    //     : false;
    // if (checkUser === false) {
    //   toast.error("Please Provide All the Information");
    //   return;
    // }
    setValue("config.isUserInformationEdit", false);
  };

  const ChangeAddressHandler = (type: "PRIMARY" | "SECONDARY") => {
    setValue("addressType", type);
  };

  useEffect(() => {
    const getPhoneNUmber = getValues("customer.phone");
    const getGender = getValues("customer.gender");
    const getFirstName = getValues("customer.firstName");

    console.log(getFirstName);
    console.log(getGender);

    if (!getPhoneNUmber || !getFirstName) {
      setValue("config.isUserInformationEdit", true);
      setIsNewUser(true);
    }
  }, [getValues, setValue]);

  // console.log("Errors => ", errors);

  // console.log("Confirm Form Watch", watch());

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-lg">User Details</h2>
        {EditInformationButton()}
      </div>
      <div
        className={twMerge(
          "flex flex-col gap-2  pb-4",
          isUserEdit && "bg-background p-2"
        )}
      >
        <UserUnchangedPersonalDetails />
        {watchGender ? (
          <div className="flex gap-2">
            <span>
              Gender
              {errors?.customer?.gender && <ErrorAstrick />}:
            </span>
            <span>{watchGender}</span>
          </div>
        ) : null}

        <div className="flex gap-2 items-center">
          <span>
            <img
              src="/images/icons/contact.svg"
              width={25}
              height={25}
              alt="Email :"
            />
          </span>

          <span>{watchPhoneNumber}</span>
        </div>
        {watchOrderType === "DELIVERY" && (
          <div className={"flex flex-col pt-3"}>
            {watchAddress?.map((el, index) => {
              if (el?.type === "SECONDARY" && isNewUser) return null;

              return (
                <Fragment key={index}>
                  {el?.address && el?.address?.streetNo ? (
                    <>
                      <div className="flex gap-2 items-center justify-between">
                        <p className="font-semibold  first-letter:capitalize">
                          <span className="lowercase">{el?.type}</span> Delivery
                          Address
                        </p>
                      </div>
                      <div
                        className="flex gap-4 items-center justify-start cursor-pointer hover:bg-primary hover:bg-opacity-10 py-2"
                        onClick={() => ChangeAddressHandler(el?.type)}
                      >
                        <p>
                          {el?.address?.unitNo && (
                            <>Unit {el?.address?.unitNo} / </>
                          )}
                          <span>
                            {el?.address?.streetNo} {el?.address?.streetName},{" "}
                            {el?.address?.suburbName}
                          </span>
                        </p>
                        {watchAddressType === el?.type && (
                          <div className="flex gap-2 items-center">
                            {/* <Icon
                              icon="gg:check-o"
                              color="#00bf00"
                              width={30}
                            /> */}
                            <img
                              src="/images/icons/checkO.svg"
                              width="30"
                              height="30"
                              alt="x"
                            />

                            <p className="text-sm text-green-500 underline">
                              Selected
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    el?.type === "SECONDARY" && !el?.address && null
                  )}
                  {/* <CheckUserAddressItem
                    key={index}
                    item={el}
                    findIndex={index}
                  /> */}
                </Fragment>
              );
            })}
          </div>
        )}

        {/* {isUserEdit && (
          <div className="flex justify-end" onClick={validateUserInformation}>
            <button className="form-btn" type="button" disabled={isLoading}>
              {isLoading ? <CircularSpinner /> : "Confirm"}
            </button>
          </div>
        )} */}
      </div>
      {isUserEdit && (
        <UserEditFormModal
          open={isUserEdit}
          customerInfo={customer}
          userEditStatus={"CUSTOMER_ERROR"}
          handleClose={EditInformationClose}
          updateUserData={UpdateUserData}
        />
      )}
      {/* {userPhoneEdit && (
        <DynamicEditUserPhoneNoModel
          OpenEditUserPhoneNo={OpenEditUserPhoneNo}
          CloseEditUserPhoneNo={CloseEditUserPhoneNo}
          userPhoneEdit={userPhoneEdit}
        />
      )} */}
    </>
  );
}

function UserUnchangedPersonalDetails() {
  const { getValues } = useFormContext<ConfirmOrderForm>();

  const getFirstName = getValues("customer.firstName");
  const getLastName = getValues("customer.lastName");
  const fullName = `${getFirstName || ""} ${getLastName || ""}`;
  const getEmail = getValues("customer.email");

  return (
    <div className="flex flex-col gap-2">
      <p className="flex gap-2">
        <>
          <img
            src="/images/icons/profile.svg"
            width={25}
            height={25}
            alt="User :"
          />
        </>

        <span>{fullName}</span>
      </p>
      {getEmail ? (
        <p className="flex gap-2">
          <img
            src="/images/icons/email.svg"
            width={25}
            height={25}
            alt="Email :"
          />
          <span>{getEmail}</span>
        </p>
      ) : null}
    </div>
  );
}

export default CheckoutUserPersonalDetails;
