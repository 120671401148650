import {
  getTodayTradingHours,
  getTradingHourAction,
} from "@/app/_redux/slice/Order/OrderSlice";
import {
  addSelectedStore,
  addStoreDetails,
  addStoreList,
} from "@/app/_redux/slice/storeslice.ts/storeSlice";
import { useAppDispatch } from "@/app/_redux/store";
import { StoreEntity, StoreEntityDetails } from "@/app/_types/store.interface";
import { TradingHour } from "@/app/_types/tradingHours.interface";
import React, { useEffect } from "react";

function CategoryMenuInRedux({
  storeList,
  selectedStore,
  todayTradingHours,
  tradingHours,
  children,
  storeDetailList,
}: {
  storeList?: StoreEntity[];
  selectedStore: StoreEntity;
  todayTradingHours: TradingHour[];
  tradingHours: TradingHour[];
  storeDetailList?: StoreEntityDetails[];
  children: React.ReactNode;
}) {
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   if (!checkisEmptyData(data)) return;
  //   dispatch(updateMenuWithCategoryList(data));
  // }, [data, dispatch]);

  useEffect(() => {
    // const checkOrderStatus = () => {
    //   if (orderSelectedStatus === "DELIVERY") {
    //     return "DELIVERY";
    //   } else {
    //     return "PICKUP";
    //   }
    // };
    // dispatch(changeSelectedOrderStatus(checkOrderStatus()));
    dispatch(addStoreList(storeList));
    dispatch(addStoreDetails(storeDetailList));
    dispatch(addSelectedStore(selectedStore));
    dispatch(getTodayTradingHours(todayTradingHours));
    dispatch(getTradingHourAction(tradingHours));
  }, [
    storeList,
    selectedStore,
    todayTradingHours,
    dispatch,
    tradingHours,
    storeDetailList,
  ]);

  return <>{children}</>;
}

export default CategoryMenuInRedux;
