import { order } from "@/app/_redux/slice/Order/OrderSlice";
import { DealDetailsInCart } from "@/app/_types/menuDeals.interface";

import { useSelector } from "react-redux";
import AddToCartDealItems from "./AddToCartDealItems";

function AddToCartDealList({
  location,
  dealList,
  title,
}: {
  location: string;
  dealList: DealDetailsInCart[];
  title: string;
}) {
  const { isLoading, todayTradingHours } = useSelector(order);

  // const { dealList, isLoading } = useSelector(order);

  return (
    <>
      {dealList?.length ? (
        <div className="px-4 py-1">
          <p className="font-semibold">{title}</p>
          <div className="divide-y-2">
            {dealList?.map((deal, index) => (
              <AddToCartDealItems
                deal={deal}
                isLoading={isLoading}
                location={location}
                todayTradingHoursData={todayTradingHours}
                key={index}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AddToCartDealList;
