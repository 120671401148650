const companyName = "Resto";

export const termsData = [
  {
    title: "Introduction",
    content: `Welcome to ${companyName}'s website ("the Site"). By accessing and using our Site, you agree to comply with and be bound by the following Terms and Conditions. Access and use of the services constitute your agreement to these Terms, establishing a contractual relationship between you and ${companyName}. If you do not agree with these terms, please refrain from using our Site.`,
  },
  {
    title: "Account Registration",
    content: `To make purchases on the Site, you must create an account using your phone number. During registration, we will collect the following information:`,
    subsections: [
      {
        title: "",
        content: `
          <ul class="list-disc pl-5">
            <li><strong>First Name</strong></li>
            <li><strong>Last Name</strong></li>
            <li><strong>Email Address</strong></li>
            <li><strong>Gender</strong></li>
            <li><strong>Contact Information (including phone number)</strong></li>
          </ul>
        `,
      },
      {
        title: "",
        content: `You are responsible for maintaining the confidentiality of your account details, including your phone number and login credentials. All activities under your account are your responsibility. You agree not to authorize third parties to use your account unless they are accompanied by you. Additionally, you may not transfer your account to any other person or entity without prior written consent from ${companyName}.`,
      },
      {
        title: "Eligibility",
        content: `You must be at least 18 years of age to create an account and make purchases on the Site.`,
      },
    ],
  },
  {
    title: "Contractual Relationship",
    content: `By using our Site and services, you acknowledge that these Terms and Conditions create a binding contractual relationship between you and ${companyName}. This agreement governs your access to and use of the Site and its services, including the collection and use of personal data for marketing purposes. You agree that ${companyName} may use your contact details, including email and phone number, for marketing and promotional communications aimed at boosting sales, unless you opt out of such communications in accordance with our Privacy Policy.`,
  },
  {
    title: "Network Access and Devices",
    content: `You are responsible for obtaining the data network access necessary to use the Services. Your mobile network's data and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device, and you shall be responsible for such rates and fees. ${companyName} does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. A compatible device, with the necessary software, is required to access and use the Services and Application. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and Applications and any updates thereto. ${companyName} does not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices.`,
  },
  {
    title: "Ordering and Payment",
    subsections: [
      {
        title: "Charges",
        content: `You understand that the use of the Services may result in charges to you for the services or goods you receive from ${companyName} or a Third Party Provider (“Charges”). After receiving services or goods obtained through your use of the Services, ${companyName} will facilitate your payment of the applicable Charges on behalf of the Third Party Provider as the Third Party Provider’s limited payment collection agent. Payment of the Charges in such manner shall be considered the same as payment made directly by you to the Third Party Provider. Charges will be inclusive of applicable taxes where required by law. Charges paid by you are final and non-refundable unless otherwise determined by ${companyName}.`,
      },
      {
        title: "Price Adjustments and High Demand Pricing",
        content: `${companyName} reserves the right to establish, remove, and/or revise Charges for any or all services or goods obtained through the use of the Services at any time at its sole discretion. You acknowledge and agree that Charges applicable in certain geographical areas may increase substantially during times of high demand. ${companyName} will use reasonable efforts to inform you of Charges that may apply, but you are responsible for Charges incurred under your Account regardless of your awareness of such Charges or the amounts thereof.`,
      },
      {
        title: "Promotional Offers and Discounts",
        content: `${companyName} may, from time to time, provide certain users with promotional offers and discounts that may result in different amounts charged for the same or similar services or goods obtained through the use of the Services. You agree that such promotional offers and discounts, unless also made available to you, shall have no bearing on your use of the Services or the Charges applied to you.`,
      },
      {
        title: "Cancellation Policy",
        content: `You may elect to cancel your request for services or goods from ${companyName} or a Third Party Provider at any time prior to the provider’s arrival, in which case you may be charged a cancellation fee.`,
      },
      {
        title: "Gratuities",
        content: `This payment structure is intended to fully compensate the Third Party Provider for the services or goods provided. ${companyName} does not designate any portion of your payment as a tip or gratuity to the Third Party Provider. Any representation by ${companyName} (on its website, in the Application, or in ${companyName}’s marketing materials) to the effect that tipping is “voluntary,” “not required,” and/or “included” in the payments you make for services or goods provided is not intended to suggest that ${companyName} provides any additional amounts, beyond those described above, to the Third Party Provider. You understand and agree that, while you are free to provide additional payment as a gratuity to any Third Party Provider who provides you with services or goods obtained through the Services, you are under no obligation to do so. Gratuities are voluntary. After receiving services or goods obtained through the Services, you will have the opportunity to rate your experience and leave additional feedback about your Third Party Provider.`,
      },
      {
        title: "Repair or Cleaning Fees",
        content: `You shall be responsible for the cost of repair for damage to, or necessary cleaning of, Third Party Provider vehicles and property resulting from your use of the Services under your Account, in excess of normal “wear and tear” damages and necessary cleaning (“Repair or Cleaning”). In the event that a Third Party Provider reports the need for Repair or Cleaning, and such request is verified by ${companyName} in its reasonable discretion, ${companyName} reserves the right to facilitate payment for the reasonable cost of such Repair or Cleaning on behalf of the Third Party Provider using your payment method designated in your Account. Such amounts will be transferred by ${companyName} to the applicable Third Party Provider and are non-refundable.`,
      },
    ],
  },
  {
    title: "Delivery",
    content: `Delivery times provided are estimates and not guarantees. ${companyName} is not responsible for delays caused by third-party delivery services. Delivery fees, if applicable, will be calculated at checkout.`,
  },
  {
    title: "User Conduct",
    content: `You agree not to use the Site for any illegal or unauthorized purpose. In your use of the services, you must not cause nuisance, annoyance, inconvenience, or property damage, whether to the third-party provider, ${companyName}, or any other party. Any fraudulent activities, such as using stolen credit card information, will result in immediate termination of your account and may lead to legal action.`,
  },
  {
    title: "Text Communications",
    content: `By creating an account, you agree that the services may send you text messages (SMS) as part of the normal business operation of your use of the services. You acknowledge that opting out of receiving text messages may impact your use of the services.`,
  },
  {
    title: "Intellectual Property",
    content: `All content on the Site, including text, graphics, logos, images, and software, is the property of ${companyName} or its content suppliers and is protected by Australian and international copyright laws. You may not reproduce, distribute, or create derivative works from any content on the Site without our express written permission.`,
  },
  {
    title: "Limitation of Liability",
    content: `${companyName} provides the Site and its services on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation of the Site or the information, content, materials, or products included on the Site. To the fullest extent permitted by law, ${companyName} disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. ${companyName} will not be liable for any damages of any kind arising from the use of the Site, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.`,
  },
  {
    title: "Governing Law",
    content: `These Terms and Conditions are governed by and construed in accordance with the laws of the State of [Your State] and the Commonwealth of Australia. Any disputes arising out of or related to these Terms and Conditions will be resolved in the courts of [Your State].`,
  },
  {
    title: "Changes to Terms and Conditions",
    content: `${companyName} reserves the right to update or modify these Terms and Conditions at any time without prior notice. Your continued use of the Site following any changes constitutes acceptance of those changes. Please review these Terms and Conditions periodically for any updates.`,
  },
];
