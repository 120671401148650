import { MenuOfferForm } from "@/app/_types/menuOffers.interface";
import { createContext } from "react";
import { UseFieldArrayReturn } from "react-hook-form";

export const OfferMenuIngredientProvider = createContext<
  | UseFieldArrayReturn<
      MenuOfferForm,
      `menuOffer.${number}.menus.${number}.ingredients`,
      "fieldId"
    >
  | undefined
>(undefined);
